/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2017 Gravity Department
* @license    All rights reserved.
*/

// ==============================================
// Accordion
// ==============================================

.accordion {
    position: relative;
    margin-bottom: $gap;
}

// ==============================================
// Accordion - Nav
// ==============================================

//.accordion_nav {}

// ==============================================
// Accordion - Item
// ==============================================

.accordion_item {
    + .accordion_item {
        margin-top: 20px;

        .accordion_group & {
            margin-top: 0;

            .accordion_header {
                border-top: 0;
            }
        }
    }
}

// ==============================================
// Accordion - Header
// ==============================================

.accordion_header {
    border: 1px solid $c-border;
    background: $c-background;
    display: flex;
    flex-wrap: wrap;
    line-height: 1.2;
    position: relative;
    cursor: pointer;
    align-items: baseline;
    width: 100%;

    &.color--light,
    .color--light & {
        background: #fff;
    }

    &.color--dark,
    .color--dark & {
        background: $c-gray-slate;
        color: #fff;

        h2,
        h3,
        p {
            color: #fff;
        }

        h2::after {
            @include svg(arrow-11-down-light);
        }
    }

    // Icon
    &::after {
        @include svg(arrow-11-down-dark);
        content: '';
        position: absolute;
        top: 28px;
        left: 15px;
        width: 11px;
        height: 11px;
        margin-top: calc(11px / -2);
        transform: rotate(-90deg);
        transition: transform 150ms linear;
        pointer-events: none;
    }

    p {
        margin: 0;
        font-size: 14px;

        &.description {
            width: 100%;
        }
    }

    .accordion_title {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        user-select: none;
        flex: 1;
        padding: 18px 20px 18px 42px;
    }

    &.active,
    &.open,
    &[aria-expanded='true'] {
        // Icon
        &::after {
            transform: rotate(0deg);
        }
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .accordion_header {
        .accordion_title {
            padding-right: 15px;
            padding-left: 36px;
        }

        &::after {
            left: 15px;
        }
    }
}

// ==============================================
// Accordion - Body
// ==============================================

//.accordion_body {}

.accordion_body > *:last-child {
    margin-bottom: 0;
}

.accordion_body {
    display: none;
    padding: 20px;
    border-left: 1px solid $c-border;
    border-right: 1px solid $c-border;
    border-bottom: 1px solid $c-border;

    &.active,
    &.open {
        display: block;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .accordion_body {
        padding-right: 15px;
        padding-left: 15px;
    }
}
