/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Price Box - Standard
// ==============================================

.price-box p {
    margin-bottom: 0;
}

//.price-box .regular-price {}

.price-notice {
    color: $c-text-subtle;
}


// ==============================================
// Price Box - Configured
// ==============================================

.price-as-configured .price-label {
    color: $c-text-subtle;
}


// ==============================================
// Price Box - Minimal
// ==============================================

//.price-box .minimal-price {}

.price-box .minimal-price-link {
    padding-left: 1em;
    color: $c-green;
}


// ==============================================
// Price Box - Range
// ==============================================

//.price-box .price-from {}
//.price-box .price-to {}


// ==============================================
// Price Box - Special
// ==============================================

.price-box .old-price,
.price-box .special-price {
    display: inline;
}

.price-box .old-price {
    padding-right: 1em;
    color: $c-text-subtle;
    text-decoration: line-through;
}

.price-box .old-price .price-label {
    display: none;
}

.price-box .special-price {
    color: $c-green;
}

.price-box .special-price .price-label {
    display: none;
}

// ==============================================
// Price Hidden
// ==============================================

.price-hidden {
    font-size: $font-size-xs;
    font-weight: normal;
    line-height: $line-height-xs;
}

// ==============================================
// Price Wrap
// ==============================================

.price-wrap {
    margin-bottom: 15px;
}

.price-wrap_liner {
    &::after {
        @include clearfix;
    }
}

.price-wrap .price-box {
    float: left;
    margin-right: 0.5em;
    line-height: 24px;
}

.price-wrap .price-units {
    float: left;
    color: $c-text-subtle;
    font-size: $font-size-xxs;
    line-height: 24px;
    text-transform: uppercase;
}


// ==============================================
// Modifier: Large
// ==============================================

.price-wrap--l .price-box {
    font-size: $font-size-xl;
    font-weight: 300;
    line-height: 30px;
}

.price-wrap--l .price-units {
    font-size: $font-size-xs;
    line-height: 30px;
}


// ==============================================
// Modifier: Extra Large
// ==============================================

.price-wrap--xl .price-box {
    font-size: $font-size-xxl;
    font-weight: 300;
    line-height: 30px;
}

.price-wrap--xl .price-units {
    font-size: $font-size-xs;
    line-height: 30px;
}


// ==============================================
// Price Container
// ==============================================

.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee {
    margin-top: $space-xs;
}

.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee,
.price-container .price-including-tax + .price-excluding-tax .price,
.price-container .weee .price,
.price-container .weee + .price-excluding-tax::before,
.price-container .weee + .price-excluding-tax .price {
    font-size: $font-size-xxs;
}

.price-container .weee {
    &::before {
        content: '('attr(data-label) ': ';
    }

    &::after {
        content: ')';
    }
}

.price-container .weee .price-excluding-tax {
    &::before {
        content: attr(data-label) ': ';
    }
}

// ==============================================
// Guest Pricing
// ==============================================

.guest-customer {

    .product_cart_item_container .price-box,
    #cart-totals {
        display: none;
    }


    .cart-price {
        display: none !important;
    }
}
