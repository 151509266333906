/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$c-help-list-bg: #A8A6A6 !default;
$c-help-list-text:   $c-help !default;


// ==============================================
// Help List Wrapper
// ==============================================

.help-list-wrapper {
    position: relative;
}

// ----------------------------------------------
// Modifier: Arrow Up Left

.help-list-wrapper--arrow-up-left {
    padding-top: 20px;
    padding-left: 25px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -10px;
        width: 24px;
        height: 33px;
        @include svg (arrow-help-up-left);
    }
}


// ==============================================
// Help List
// ==============================================

.help-list {
    counter-reset: help-list-counter;
    list-style: none;
    margin-bottom: $margin-bottom;
    font-size: $font-size-s;
    line-height: $line-height-xs;
}

.help-list_item {
    position: relative;
    min-height: 28px;
    margin-bottom: 1em;
    padding-top: 4px;
    padding-left: (25px + 15px);

    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        content: counter(help-list-counter);
        counter-increment: help-list-counter;
        position: absolute;
        top: 0;
        left: 0;
        width: 28px;
        height: 28px;
        background: $c-help-list-bg;
        border-radius: (28px / 2);
        color: $c-white;
        font-size: 14px;
        line-height: 28px;
        text-align: center;
    }
}


// ==============================================
// Media Print
// ==============================================

@media print {

    .help-list {
        font-size: 12px;
        color: #000;
        list-style: decimal outside;
    }

    .help-list_item {
        min-height: auto;
        padding: 0 0 0 1em;
        margin-left: 1em;
        margin-bottom: 0;

        &::before {
            display: none;
        }
    }

}

