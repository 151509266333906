/**
* Gemaire Distributing
*/


// ==============================================
// Shipping Information
// ==============================================

.step-summary {
    position: relative;
    margin-bottom: $space-xxl;
}

.step-summary_action {
    position: absolute;
    right: 0;
    top: 8px;
    cursor: pointer;
}


