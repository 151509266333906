/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// CVV Security Code
// ==============================================

.cvv-what-is-this {
    margin-left: 10px;
    font-size: $font-size-xs;
    line-height: 24px;
}
