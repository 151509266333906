/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2015 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Media Carousel
// ==============================================

.media-carousel {
    position: relative;
    z-index: $z-owl-carousel; // [refactor] unclear why this is needed. Can possibly remove.
    margin-bottom: ($gap * 1.25);
    padding-bottom: 40px; // Spacing for ".owl-pagination"
}


// ==============================================
// Media Carousel - Wrapper
// ==============================================

.media-carousel_wrapper {
    margin-right: ($liner-base - 8px);
    margin-left:  ($liner-base - 8px);
}

// ----------------------------------------------

@media (min-width: $bp-page-focal) {

    .media-carousel_wrapper {
        margin-right: ($liner-l - 8px);
        margin-left:  ($liner-l - 8px);
    }

}


// ==============================================
// Media Carousel - Item
// ==============================================

.media-carousel .item {
    margin: 0 8px;
}


// ==============================================
// Media Carousel - Owl Buttons
// ==============================================

.media-carousel .owl-next,
.media-carousel .owl-prev {
    position: absolute;
    z-index: $z-owl-carousel-buttons;
    top: 50%;
    margin-top: (40px / -2);
}

.media-carousel .owl-prev {
    left: -5px;
}

.media-carousel .owl-next {
    right: -5px;
}

// ----------------------------------------------

@media (min-width: $bp-page-focal) {

    .media-carousel .owl-prev {
        left: -15px;
    }

    .media-carousel .owl-next {
        right: -15px;
    }

}


// ==============================================
// Media Carousel - Owl Pagination
// ==============================================

.media-carousel .owl-pagination {
    position: absolute;
    bottom: -40px;
}
