/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// CMS Section
// ==============================================

.cms-section {
    padding-top: 50px;
    padding-bottom: 35px;
    border-top: 5px solid $c-divider-border;
}

// ----------------------------------------------
// Modifier: Offset

.cms-section--offset {
    background: $c-background;
}

// ==============================================
// Page Layout - 1 column
// ==============================================

.page-layout-1column .columns .column.main {
    width: 100%;
}


// ==============================================
// Content Wrapper on CMS Page Layout - 1 column
// ==============================================


.page-layout-1column .content-wrapper {
    margin-right: -$layout-indent__width;
    margin-left: -$layout-indent__width;
}

@media (max-width: $bp-screen-m - 1px) {
    .page-layout-1column .content-wrapper {
        margin-right: -$layout__width-xs-indent;
        margin-left: -$layout__width-xs-indent;
    }
}
