/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Flex Inline
// ==============================================

.flex-inline {
    display: flex;
    align-items: center;
}

// ----------------------------------------------
// Modifier: Width 600

.flex-inline--width-600 {
    max-width: 600px;
}

// ----------------------------------------------
// Modifier: Center

.flex-inline--center {
    justify-content: center;
}


// ==============================================
// Flex Inline - Unit
// ==============================================

//.flex-inline_unit {}

// ----------------------------------------------
// Modifier: Pad

.flex-inline_unit--pad {
    margin-right: 0.75em;
}

// ----------------------------------------------
// Modifier: Grow

.flex-inline_unit--grow {
    flex: 1;
}

// ----------------------------------------------
// Modifier: space between

.flex-inline_space--between {
    justify-content: space-between;
}
