/**
* Gemaire    Frontend
* @author    Damian Bogdanowicz
* @copyright Gemaire Distributors. All rights reserved.
*/

// ==============================================
// Requisition List
// ==============================================

.requisition_list-requisition-index {
    .content-wrapper {
        padding-top: 0;
    }
}

.requisition_list-requisition-view {
    .requisition-header {
        overflow: visible;
    }

    .requisition-grid {
        [type='checkbox'] + label {
            margin-left: 0;
        }
    }
}

.requisition-list-modal-menu {
    li {
        padding: 5px $space-xs;
        display: block;

        &:hover {
            background: $c-background;
        }
    }
}


@media (max-width: $bp-screen-m - 1px) {
    .requisition_list-requisition-view {
        .requisition-header {
            padding: 20px 20px 0;
            border: 1px solid $c-border;
            margin: 0;
        }
    }
}

// ==============================================
// Search Requisition List
// ==============================================

.search-requisition-list {
    width: 100%;
    z-index: 1;
    position: absolute;
    background: $c-white;
    border: 1px solid $c-border;
    top: 55px;

    &:before {
        @include triangle($direction: up, $size: 8px, $color: $c-border);
        top: -8px;
        left: 16px;
    }

    &:after {
        @include triangle($direction: up, $size: 6px, $color: $c-white);
        top: -6px;
        left: 18px;
    }
}