/**
* Gemaire Distributing
*/


// ==============================================
// Control
// ==============================================

.control {
    position: relative;
    margin-top: 18px; // Offset for animated label. Update "field-row" when changing.
}

label ~ .control {
    margin-top: 0;
}

.control label {
    position: absolute;
    left: 0;
    color: $c-label;
    font-weight: normal;
    letter-spacing: $letter-spacing;
    transition: all ease 0.2s;
    pointer-events: none;
}

// ----------------------------------------------
// Modifier: Primary

//.control--primary {}

.control--primary input {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
}

.control--primary [type='radio'] + label {
    display: block;
    max-width: 40rem;
    padding: 10px 12px 10px (12px + 12px + 12px);
    border: 1px solid $c-border;
    border-radius: $radius-base;
    background: $c-background;
    line-height: 1.4;
    margin-left: 0;

    &:before {
        top: 12px;
        left: 12px;
    }

    &:after {
        left: 20px;
    }
}

// ----------------------------------------------
// Modifier: Primary Light

.control--primary-light label {
    background: #FFF;
}

.control--primary input:checked + label {
    border-color: $c-input-border-focus;
}


// ==============================================
// Control - Hint
// ==============================================

.control_hint {
    padding-top: 3px;
    color: $c-text-subtle;
    font-size: $font-size-s;
}
