/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2015 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Layered Nav
// ==============================================

//.layered-nav {}

//.filter-block {}

// ----------------------------------------------

@media (min-width: 900px) {

    .layered-nav .toggle-button {
        display: none;
    }

    .layered-nav .toggle-target {
        display: block;
    }

}


// ==============================================
// Layered Nav - Promos
// ==============================================

.layered-nav_promos {
    padding: 15px;
}

.layered-nav_promo {
    display: block;
    margin-bottom: 15px;
}


// ==============================================
// Filter Store
// ==============================================

.filter-store {
    padding: $liner-base;
    border-top: 1px solid $c-border;
}


// ==============================================
// Filter Active
// ==============================================

.filter-active {
    padding: $liner-base;
    border-top: 1px solid $c-border;
    background: $c-priority-background;
}

.filter-active-title {
    margin-bottom: 5px;
    font-size: $font-size-base;
    font-weight: bold;
    line-height: 22px;
    @include h3;
}

.filter-active-list {
    margin-bottom: 15px;
}

.filter-active-list a {
    position: relative;
    display: inline-block;
    padding: 5px 0 5px 20px;
    color: $c-text-base;
    font-size: $font-size-xs;
    text-decoration: none;

    &:hover {
        color: $c-link-base;
    }

    &::after {
        content: '\00d7';
        position: absolute;
        top: 6px;
        left: 0;
        font-size: $font-size-xl;
        line-height: 1;
    }

    //&:hover::after {}
    //&:active::after {}
}

//.remove-all-filters {}


// ==============================================
// Filter Facet List
// ==============================================

.filter-facet-list {
    border-top: 1px solid $c-border;
    border-bottom: 1px solid $c-border;
}

// ----------------------------------------------
// Filter Facet List - Title Icon

.filter-facet-list dt {
    position: relative;
    padding: 12px 12px 12px (12px + 11px + 10px);
    border-top: 1px solid $c-border;
    background: $c-background;
    font-size: $font-size-xs;
    font-weight: bold;
    cursor: pointer;
    user-select: none;

    &:first-child {
        border-top: 0;
    }
}

.no-touchevents .filter-facet-list dt:hover {
    background: $c-emphasis-background;
}

// ----------------------------------------------
// Filter Facet List - Title Icon

.filter-facet-list dt::after {
    @include svg (arrow-11-down-dark);
    content: '';
    position: absolute;
    top: 50%;
    left: 12px;
    width: 11px;
    height: 11px;
    margin-top: calc(11px / -2);
    transform: rotate(-90deg);
    transition: transform 150ms linear;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
.filter-facet-list dt[data-facet-visible='true'] {
    &::after {
        transform: rotate(0deg);
    }
}

// ----------------------------------------------
// Filter Facet List - Content

.filter-facet-list dd {
    display: none;
    border-top: 1px solid $c-border;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
.filter-facet-list dd[data-facet-visible='true'] {
    display: block;
}


// ==============================================
// Filter Option List
// ==============================================

.filter-option-list li {
    position: relative;
    min-height: 40px;
}

.filter-option-list [data-facet-option-hidden='true'] {
    display: none;
}

.filter-option-list[data-show-all-facets='true'] [data-facet-option-hidden='true'] {
    display: block;
}

// ----------------------------------------------
// Checkbox

.filter-option-list .checkbox {
    position: absolute;
    top: auto;
}

// ----------------------------------------------
// Label

.filter-option-list .checkbox + .label {
    display: block;
    padding-left: (14px + 10px);
}

// ----------------------------------------------
// Count

.filter-option-list .count {
    position: absolute;
    top: 10px;
    right: $liner-base;
    padding: 0 6px;
    border-radius: $radius-s;
    background: $c-action-secondary-base;
    color: #FFF;
    font-size: $font-size-xxs;
    line-height: 25px;
}

/* stylelint-disable-next-line selector-max-class, selector-max-specificity, selector-max-compound-selectors */
.no-touchevents .filter-option-list .filter-option-link:hover .count {
    background: $c-text-base;
}


// ==============================================
// Filter Option Link
// ==============================================

.filter-option-link {
    position: relative;
    display: block;
    padding: 15px ($liner-base + 36px + $liner-base) 15px $liner-base;
    border-bottom: 1px solid $c-border;
    color: $c-text-base;
    font-size: $font-size-xs;
    font-weight: normal;
    line-height: 16px;
    text-decoration: none;
    cursor: pointer;
}

.no-touchevents .filter-option-link:hover {
    background: $c-background;
    color: $c-link-base;
    text-decoration: none;
}

.filter-option-list li:last-child .filter-option-link {
    border-bottom: 0;
}

.filter-option-link--no-count {
    padding-right: $liner-base;
}


// ==============================================
// Toggle All Facet Options
// ==============================================

.toggle-all-facet-options-item {
    display: block;
}

.toggle-all-facet-options-link {
    font-style: italic;
}
