/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Stat
// ==============================================

.stat-label {
    font-size: $font-size-xxs;
    text-transform: uppercase;
}

.stat-value {
    font-size: 24px;
    font-weight: bold;
    line-height: $line-height-xxs;
}


// ==============================================
// Modifier: Horizontal
// ==============================================

.stat-list--horizontal {
    overflow: hidden;
}

.stat-list--horizontal li {
    float: left;
    padding: 5px 15px;
    border-left: 1px solid $c-border;
}

.stat-list--horizontal li:first-child {
    padding-left: 0;
    border-left: 0;
}


// ==============================================
// Modifier: Vertical
// ==============================================

//.stat-list--vertical {}

.stat-list--vertical li {
    padding: 15px 0;
    border-top: 1px solid $c-border;
}

.stat-list--vertical li:last-child {
    border-bottom: 1px solid $c-border;
}
