/**
* Gemaire Distributing
*/

.requisition-popup,
.stocking-popup {
    .action {
        &.hidden {
            display: none;
        }
    }
}

.requisition-header {
    &.sticky {
        position: fixed;
        top: 0;
        z-index: 11;
        width: 100%;
        left: 0;
        background: $c-yellow-light;
        box-shadow: $box-shadow-base;
    }

    .panel_header {
        > p {
            display: none;
            width: 100%;
        }
    }
}

.requisition-list-requisition-print {
    &.account {
        .column {
            &.main {
                padding-bottom: 0;
            }
        }
    }

    .requisition-grid {
        .actions-toolbar {
            display: none;
        }

        .col {
            &.number {
                width: 1rem;
            }

            &.col-checkbox {
                padding: 0;

                input[type='checkbox'] {
                    display: none;
                }
            }

            &.subtotal {
                padding-right: 0;
            }

            &.price,
            &.qty,
            &.subtotal {
                text-align: right;
            }

            .product-item-name {
                a {
                    pointer-events: none;
                }
            }

            .price {
                font-weight: $font-weight-semibold;
            }

            .price-excluding-tax {
                font-size: 1.1rem;

                .price {
                    font-size: $font-size-xs;
                }
            }

            .input-text {
                border-color: transparent;
                opacity: 1;
                padding-right: 0;
                pointer-events: none;
                text-align: right;
            }
        }

        .product-item-image {
            display: none;
        }

        .product-item-description {
            float: none;
            width: 100%;
        }

        tbody {
            td {
                border-top: 1px solid $c-border;
            }
        }
    }

    .copyright {
        background-color: transparent;
        font-size: $font-size-xs;
        margin-bottom: $space-l;
        padding: $space-base 0 0;
        text-align: left;

        .phone {
            float: right;
        }
    }
}


.add_to_requisition_popup,
.add_to_stocking_popup {
    .fieldset > .field:not(.choice) {
        > .label,
        > .control {
            float: none;
            width: auto;
        }
    }
}

@media print {
    .requisition-list-requisition-print {
        .table-wrapper {
            table {
                table-layout: auto;
            }
        }
    }
}

.requisition_select--mobile {
    margin: $space-base 0 $space-s 34px;
    display: none;
}

@media (min-width: $bp-table-linearize) {
    .requisition_select--mobile {
        display: none;
    }
}

// ==============================================
// Quicklist View
// ==============================================

.quicklist-description {
    word-wrap: break-word;
}

.requisition-grid {

    .ui-sortable-placeholder {
        height: 100px !important;
    }

    thead tr {
        border-bottom: 1px solid $c-border;
    }

    .product-item-info,
    .product-item-details {
        padding: 0;
    }

    .ui-sortable-helper td {
        border-top: none;
        border-bottom: none;
    }

    tbody tr.ui-sortable-placeholder td {
        border-top: 1px solid $c-border;
        &:first-child {
            border-top: none;
        }
    }

    .product-item-name .message {
        display: flex;
        margin-bottom: $space-base;
    }

    .product-item-image {
        width: 75px;
        min-width: 50px;
    }

    .supersede-section {
        .supersede-container .products-list .product-item-photo {
            flex-basis: 100px;
        }

        .actions {
            margin-left: 100px;
            clear: both;
        }
    }

    .product-item-list-right-column {
        .price-wrapper.product-price {
            display: flex;
            align-items: center;

            .individual-loading {
                vertical-align: middle;
            }
        }
    }
}

@media (min-width: $bp-screen-l) {

    .requisition-grid {

        .product-item-list-right-column {
            align-self: center;
            margin-bottom: 30px;
        }

    }

    .supersede-section {
        margin-left: 200px;
    }
}

@media (max-width: $bp-screen-l - 1px) {
    .requisition-grid {
        &.products-list .product-item-photo {
            flex-basis: 50px;
            margin-right: 10px;
        }

        .product-item-list-right-column {
            margin-top: 20px;
            flex-basis: 200px;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {    

    .requisition-header.sticky {

        .panel_header {
            > p {
                display: block;
            }

            .panel_action {
                flex-basis: auto;
            }
        }
    }
}

@media (max-width: $bp-table-linearize - 1px) {

    .requisition-header {
        .button {
            padding-left: 16px;
            padding-right: 16px;
        }

        .panel_header {
            display: flex;
        }

        &.sticky {
            .panel_header > p {
                display: none;
            }
        }

        .menu_content {
            .flex-inline {
                display: block;
            }
        }
    }
    
    .requisition-grid {
        tr {
            position: relative;
        }

        .col {

            &.product {
                margin-top: 30px;

                > .product-item-info {
                    display: block;
                }
            }

            &.select {
                position: absolute;
                top: 8px;
            }

            &.subtotal {
                display: none;
            }

            &.qty {
                display: flex;
                align-items: center;
                margin-left: 65px;
                width: auto;

                .label {
                    padding-right: 10px;
                }
            }

            &.actions {
                position: absolute;
                top: 18px;
                right: 0;
                width: auto;
            }

            &.sort {
                float: right;
                margin-top: -34px;
                display: none;
            }

        }

        .product-item-description {
            margin-bottom: $space-xs;
        }

        .product-price {
            font-weight: $font-weight-semibold;
        }

        .product-item-name,
        .product-attribute,
        .product-price {
            margin-left: 0px;
        }

        .supersede-section {
            .actions {
                margin-left: 0;
            }
        }
    }

}


// ==============================================
// Quicklists List
// ==============================================

.quicklists-list .cell-label-line-description {
    color: $c-text-muted;
}

// ==============================================
// Quicklists Actions
// ==============================================

.quicklist-clipboard {
    width: 100%;
    border: $c-border;
    background: $c-library-gray-0;
    padding: $space-xs;
}

.copy-items_confirm {
    width: 80px;
}
