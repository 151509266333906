/**
* Gemaire Frontend
*
* @author    Brendan Falkowski (http://gravitydept.com)
* @package   enterprise_gemaire
* @copyright Copyright 2015 Gravity Department
* @license   All rights reserved.
*/


// ==============================================
// Radius
// ==============================================

$radius-l:    7px !default;
$radius-base: 5px !default;
$radius-s:    3px !default;
