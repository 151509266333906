/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


/* stylelint-disable selector-max-compound-selectors, selector-max-type */


// ==============================================
// Messages
// ==============================================

.page-focal > .messages {
    margin-right: $liner-base;
    margin-left: $liner-base;

    &:first-child {
        margin-top: 20px;
    }
}

.messages > li > ul > li {
    position: relative;
    margin-bottom: 5px;
    padding: 15px;
    border-radius: $radius-s;
    background: $c-emphasis-background;
    line-height: $line-height-xs;
}

// Improve text readability for complex content in message.
.messages .std {
    font-size: $font-size-s;
    line-height: $line-height;
}

.messages .error-msg > ul > li {
    border-left: 12px solid $c-danger;
}

.messages .notice-msg > ul > li,
.messages .warning-msg > ul > li {
    border-left: 12px solid $c-warn;
}

.messages .success-msg > ul > li {
    border-left: 12px solid $c-success;
}

// ----------------------------------------------

@media (min-width: $bp-page-focal) {

    .page-focal > .messages {
        margin-right: $liner-l;
        margin-left: $liner-l;
    }

}


// ==============================================
// Messages - Misc.
// ==============================================

//.messages a {}

.messages ul > li ol:last-child,
.messages ul > li p:last-child,
.messages ul > li ul:last-child {
    margin-bottom: 0;
}


// ==============================================
// Media Print
// ==============================================

@media print {

    .messages {
        position: relative;
        padding: 8px 8px 8px 43px;
        border: 1px solid $c-border;
        border-radius: $radius-s;

        &::before {
            content: '⚠';
            position: absolute;
            top: 6px;
            left: 8px;
            font-size: 2em;
            font-weight: bold;
            line-height: 1;
            color: $c-text-base;
        }
    }

    .messages > li > ul > li {
        padding: 0;
        background: transparent;
    }

    .messages .error-msg > ul > li,
    .messages .notice-msg > ul > li,
    .messages .warning-msg > ul > li,
    .messages .success-msg > ul > li {
        border: 0;
    }

}


// ==============================================
// Message
// ==============================================

.message.info {
    @include lib-message-icon(info);
}

.message.error {
    @include lib-message-icon(error);
}

.message.warning {
    @include lib-message-icon(warning);
}

.message.notice {
    @include lib-message-icon(notice);
}

.message.success {
    @include lib-message-icon(success);

    &.blue {
        > *:first-child::before {
            background-color: #46B0FF;
        }
    }
}

.message ul {
    list-style: disc outside none;
    padding-left: $space-base;
}
