/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2015 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$c-badge:           $c-gray-slate !default;
$c-badge-clearance: $c-orange     !default;
$c-badge-new:       $c-green      !default;
$c-badge-online:    $c-red-ruby   !default;
$c-badge-sale:      $c-turquoise  !default;


// ==============================================
// Badges
// ==============================================
//.badges {}

// ----------------------------------------------
// Modifier: Compact

.badges--compact {
    margin-bottom: 5px;
}

// ==============================================
// Badge
// ==============================================

.badge {
    margin: 0 10px 10px 0;
    padding: 4px 8px;
    border: 1px solid $c-badge;
    border-left-width: 4px;
    border-radius: $radius-s;
    color: $c-badge;
    font-size: $font-size-s;
    white-space: nowrap;
    display: inline-block;
}

// ----------------------------------------------
// Modifier: Compact

.badges--compact .badge {
    margin: 0 5px 5px 0;
    padding: 2px 5px;
    font-size: $font-size-xxxxs;
}

// ----------------------------------------------
// Modifier: Type

.badge--clearance {
    border-color: $c-badge-clearance;
    color: $c-badge-clearance;
}

.badge--new {
    border-color: $c-badge-new;
    color: $c-badge-new;
}

.badge--online {
    border-color: $c-badge-online;
    color: $c-badge-online;
}

.badge--sale {
    border-color: $c-badge-sale;
    color: $c-badge-sale;
}


// ----------------------------------------------
// Modifier: Danger

.badge--danger {
    border-color: $c-danger;
    color: $c-danger;
}

// ----------------------------------------------
// Modifier: Info

.badge--info {
    border-color: $c-info;
    color: $c-info;
}

// ----------------------------------------------
// Modifier: Success

.badge--success {
    border-color: $c-success;
    color: $c-success;
}

// ----------------------------------------------
// Modifier: Warning

.badge--warning {
    border-color: $c-warn;
    color: $c-warn;
}

// ----------------------------------------------
// Modifier: Simple gray

.badge--simple-gray {
    border-color: $c-gray-slate;
    color: $c-gray-slate;
    background: #909090;
    color: #eee;
    border: none;
    text-transform: uppercase;
    line-height: 11px;
}


// ----------------------------------------------
// Print Pages

.page-print .badge {
    color: $c-text-base;
    text-shadow: none;
    font-weight: $font-weight-semibold;
    background: transparent;
}
