/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Ratings
// ==============================================

.ratings {
    overflow: hidden;
    font-size: $font-size-xs;
}

.ratings .rating-box {
    float: left;
}

.ratings .rating-links {
    float: left;
    margin: 0 0 0 15px;
    line-height: 27px; // Height of star rating
}

.ratings .add-your-review {
    margin-left: 15px;
}

// Rating Stars

.rating-box {
    //@include svg (rating-background);
    width: 147px;
    height: 27px;
}

.rating-box .rating {
    //@include svg (rating-foreground);
    height: 100%;
}
