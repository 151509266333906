.magnify-lens {
    position: absolute;
    top: 0;
    left: 0;
    color: $c-white;
    text-align: center;
    background: white(0.5);
    border: solid 1px $c-border;
    z-index: $z-index-10;
}

.magnify-opaque {
    opacity: 0.5;
}

.magnify-hidden {
    display: none;
}

.magnify-fullimage {
    display: none;

    // TODO from which css file is this?
    .fotorama__fullscreen & {
        display: inline-block;
    }
}
