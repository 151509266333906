/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Story
// ==============================================

.story {
    padding: 15px;
    border: 1px solid $c-border;
    border-radius: $radius-s;
    background: $c-background;
}

// ---------------------------------------------

@media (min-width: 700px) {

    .story {
        column-count: 2;
        column-gap: 40px;
        padding: 40px;
    }

}

// ---------------------------------------------

@media (min-width: 900px) {

    .story {
        column-count: 3;
    }

}
