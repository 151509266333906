/**
* Gemaire Distributing
*/


// ==============================================
// Sortable
// ==============================================

.ui-sortable-helper {
    background: $c-white;
    box-shadow: $box-shadow-input-text;
    height: auto !important;
}