/**
* Gravity Department - Frontend Starter
* https://github.com/gravitydepartment/frontend-starter
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Mixin - PNG
// ==============================================

@mixin png ($name) {
    background: url(#{$path-png}#{$name}.png) no-repeat;
}
