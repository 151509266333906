/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Page Popup
// ==============================================

.page-popup {
    padding: 20px;
    background: #FFF;
}

.page-popup h1 {
    margin: 0 0 0.5em;
    font-size: 36px;
}
