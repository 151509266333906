/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2017 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Typeahead - Query Adjustment
// ==============================================

.typeahead_query-adjustment {
    padding: 10px 15px;
    border-radius: $radius-s;
    background: $c-priority-background;
    font-size: $font-size-xs;
}


// ==============================================
// Typeahead - Button
// ==============================================

.typeahead_button {
    position: absolute;
    right: 12px;
    bottom: 12px;
}


// ==============================================
// Typeahead - Link
// ==============================================

.typeahead_link .product-image {
    width: 60px;
    height: 60px;
}

.typeahead_link .product-name {
    margin-bottom: 5px;
    line-height: $line-height-xs;
}
