/**
* Gemaire Frontend
*
* @author    Brendan Falkowski (http://gravitydept.com)
* @package   enterprise_gemaire
* @copyright Copyright 2015 Gravity Department
* @license   All rights reserved.
*/


// ==============================================
// Space
// ==============================================

$space-xxxl:  48px !default;
$space-xxl:   32px !default;
$space-xl:    24px !default;
$space-l:     20px !default;

$space-base:  16px !default;

$space-s:     10px !default;
$space-xs:    8px  !default;
$space-xxs:   4px  !default;
$space-xxxs:  2px  !default;


// ==============================================
// Units
// ==============================================

// Gap is the standard vertical spacing between modules.
$gap: $space-xxl !default;

// Gutter is the space between grid columns.
$gutter: $space-base !default;

// Liner is the whitespace between the content box and content.
$liner-base: 15px !default;
$liner-l:    33px !default; // min-width: 900px

// Trim is the whitespace between the content and viewport edge.
$trim: $space-l !default; // min-width: 900px


// ==============================================
// Content
// ==============================================

$content-max-width:  1440px !default;


// ==============================================
// Header
// ==============================================

$header-element-height: 50px !default;

$header-primary-small-height: 117px !default;
$header-primary-large-height: (100px + 1px) !default;

$header-secondary-height: (46px + 1px) !default;


// ==============================================
// Input
// ==============================================

$input-max-width-base: 40rem !default;
$input-max-width-s:    24rem !default;
$input-max-width-xs:   16rem !default;
$input-max-width-xxs:  8rem  !default;

// ----------------------------------------------
// Form

$form-elem-width-base: 40rem !default;
$form-elem-width-s:    28rem !default;
$form-elem-width-xs:   14rem !default;
$form-elem-width-xxs:  6rem  !default;
