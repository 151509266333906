/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2014 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Bill Of Materials List
// ==============================================

.bill-of-materials-list {
    margin-bottom: $margin-bottom;
    border-top: 1px solid $c-border;
}

.bill-of-materials-list .item {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $c-border;
}


// ==============================================
// Bill Of Materials List - Elements
// ==============================================

.bill-of-materials-list .wrapper {
    position: relative;
}

.bill-of-materials-list .product-name {
    display: block;
    line-height: $line-height-xxs;
}

// ----------------------------------------------

@media (min-width: 600px) {

    .bill-of-materials-list .wrapper {
        padding-left: 150px; // Spacer for ".product-numbers"
    }

    .bill-of-materials-list .product-numbers {
        position: absolute;
        top: 0;
        left: 0;
    }

}


// ==============================================
// BOM Item - Simple
// ==============================================

@media (max-width: 599px) {

    .bom-item--simple .product-name {
        margin-bottom: 5px;
    }

}


// ==============================================
// BOM Item - Complex
// ==============================================

.bom-item--complex .wrapper {
    position: relative;
    min-height: 63px; // Height of ".product-inventory"
}

// ----------------------------------------------

@media (max-width: 1199px) {

    .bom-item--complex .product-name {
        margin-bottom: 5px;
    }

    .bom-item--complex .product-numbers,
    .bom-item--complex .product-inventory {
        margin-bottom: 10px;
    }

}

// ----------------------------------------------

@media (min-width: 480px) and (max-width: 1199px) {

    .bom-grid .col {
        float: right;
        width: 50%;

        &:last-child {
            padding-right: 20px;
        }
    }

    .bom-item--complex .product-inventory {
        float: right;
    }

}

// ----------------------------------------------

@media (min-width: 1200px) {

    .bom-item--complex .wrapper {
        padding-right: (30px + 200px + 125px); // Fixed spacer for ".product-inventory" + ".product-actions"
    }

    .bom-item--complex .product-inventory {
        position: absolute;
        top: 0;
        right: 125px; // Offset from "product-actions"
    }

    .bom-item--complex .product-name {
        margin-bottom: 10px;
    }

    .bom-item--complex .product-actions {
        position: absolute;
        top: 0;
        right: 0;
    }

}


// ==============================================
// BOM Item - Substitute
// ==============================================

.bom-item--substitute {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px dashed $c-border;
}

.bom-item--substitute_note {
    display: inline-block;
    margin-bottom: 10px;
    padding: 0 0.5em;
    border: 1px solid $c-border;
    border-radius: $radius-s;
    background: $c-priority-background;
    font-size: $font-size-xxs;
}
