/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2018 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Input File
// ==============================================

.input-file {
    padding: 10px;
    border: 1px solid $c-border;
    border-radius: $radius-base;
    background: $c-background;
}
