/**
* Gemaire Distributing
*/


// ==============================================
// Flag
// ==============================================

.flag {
    padding: $space-xxxs 0.5em;
    border-radius: $radius-base;
    background: $c-library-green-2;
    color: $c-black;
    font-size: 9px;
    font-weight: $font-weight-semibold;
    letter-spacing: $letter-spacing;
    text-transform: uppercase;
    display: inline-block;
    position: absolute;
}