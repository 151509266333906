/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2018 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$inline-form-label-icon-size: 19px !default;


// ==============================================
// Inline Form Wrap
// ==============================================

.inline-form-wrap {
    margin-bottom: $gap;
    padding: 10px;
    border: 1px solid $c-border;
    border-radius: 3px;
    background: $c-priority-background;
}


// ==============================================
// Inline Form
// ==============================================

.inline-form {
    position: relative;
    margin-bottom: 20px;
}

.inline-form label {
    @include image-replacement;
    @include svg (search-input);
    position: absolute;
    top: 11px;
    left: 8px;
    width: $inline-form-label-icon-size;
    height: $inline-form-label-icon-size;
}

.inline-form-field {
    display: flex;
    flex-direction: column;
}

.inline-form-field-label-wrapper {
    font-size: 13px;
    color: $c-text-subtle;
}

.inline-form-field-label-wrapper::after{
    content:"*"; 
    color: inherit;
}

