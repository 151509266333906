/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/

// ==============================================
// Vars
// ==============================================

$bp-table-linearize: 600px !default; // When tables linearize for small screens

// ==============================================
// Table
// ==============================================

.table {
    width: 100%;
    margin-bottom: $trim;
    font-size: $font-size-s;
}

.table td,
.table th {
    padding: 10px;
    border-top: 1px solid $c-table-border;
    border-bottom: 1px solid $c-table-border;
    vertical-align: middle;
}

.table th {
    background: $c-table-background;
    font-weight: bold;
    // white-space: nowrap;
}

.table tbody tr {
    background: #fff;
}

.table tfoot tr {
    background: $c-table-background;
}

.tfoot_inline {
    > * {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
}

@media (min-width: $bp-table-linearize) {
    .tfoot_inline {
        display: flex;
        align-items: center;

        > * {
            margin-right: $space-xl;
            margin-bottom: 0;

            strong {
                margin-right: $space-xs;
            }

            &:last-child {
                margin-right: 0;
                margin-left: auto;
                font-weight: 700;
            }
        }
    }
}

// ----------------------------------------------

@media print {
    .table td,
    .table th {
        padding: 4px;
    }
}

// ==============================================
// Modifier: Border
// ==============================================

.table--border {
    border: 1px solid $c-table-border;
}

.table--border th,
.table--border td {
    border: 1px solid $c-table-border;
}

// ==============================================
// Modifier: Fixed
// ==============================================

.table--fixed {
    table-layout: fixed;
}

// ==============================================
// Modifier: Hover
// ==============================================

@media (min-width: $bp-screen-m + 1) {
    .table--hover tbody tr:hover td {
        /* stylelint-disable-line selector-max-compound-selectors, selector-max-type */
        background: $c-table-hover;
    }
}

// ==============================================
// Modifier: Linearize
// ==============================================

@media (max-width: $bp-table-linearize - 1px) {
    .table--linearize {
        border: 0;
    }

    .table--linearize tr,
    .table--linearize th,
    .table--linearize td {
        display: block;
        width: 100%;
    }

    .table--linearize th,
    .table--linearize td {
        border: 0;
    }

    // ----------------------------------------------
    // Head

    .table--linearize thead {
        display: none;
    }

    // ----------------------------------------------
    // Body - TR

    .table--linearize tbody tr {
        border-top: 1px solid $c-table-border;
        padding: 20px 0;

        &:last-child {
            border-bottom: 1px solid $c-table-border;
        }
    }

    // ----------------------------------------------
    // Body - TD

    .table--linearize tbody td {
        padding: 0;
        border: 0;

        &[data-label],
        &[data-th],
        &[data-head],
        &[data-header] {
            &::before {
                display: inline;
                font-size: $font-size-xs;
                color: $c-gray-slate;
            }
        }

        &[data-label] {
            &::before {
                content: attr(data-label) ': ';
            }
        }

        &[data-th] {
            &::before {
                content: attr(data-th) ': ';
            }
        }

        &[data-head] {
            &::before {
                content: attr(data-head) ': ';
            }
        }

        &[data-header] {
            &::before {
                content: attr(data-header) ': ';
            }
        }
    }

    // ----------------------------------------------
    // Foot

    .table--linearize tfoot tr {
        border-bottom: 1px solid $c-table-border;
    }
}

// ==============================================
// Linearize Utilities
// ==============================================

// These utility classes are designed for hiding table rows and showing
// other elements when a table is linearized for small screens. Typically
// the first cell remains visible and following cells are hidden with
// their content duplicated by JS into the first cell and shortened).

// ----------------------------------------------

@media (max-width: $bp-table-linearize - 1px) {
    .is-linearized-hide-cell,
    .is-linearized-hide-elem {
        display: none !important; /* stylelint-disable-line declaration-no-important */
    }

    .is-linearized-show-cell {
        display: table-cell !important; /* stylelint-disable-line declaration-no-important */
    }

    .is-linearized-show-elem {
        display: block !important; /* stylelint-disable-line declaration-no-important */
    }

    .is-linearized-unpad {
        padding: 0 !important; /* stylelint-disable-line declaration-no-important */
    }
}

// ----------------------------------------------

@media (min-width: $bp-table-linearize) {
    .is-linearized-hide-cell {
        display: table-cell !important; /* stylelint-disable-line declaration-no-important */
    }

    .is-linearized-hide-elem {
        display: block !important; /* stylelint-disable-line declaration-no-important */
    }

    .is-linearized-show-cell,
    .is-linearized-show-elem {
        display: none !important; /* stylelint-disable-line declaration-no-important */
    }

    .not-linearized-align-right {
        text-align: right !important;
    }

    .not-linearized-align-center {
        text-align: center !important;
    }
}

// ==============================================
// Modifier: Zebra
// ==============================================

.table--zebra tbody tr:nth-child(odd) {
    background: $c-table-zebra-light;
}

.table--zebra tbody tr:nth-child(even) {
    background: $c-table-zebra-dark;
}

// ==============================================
// Table - Detail Row
// ==============================================

// The ".table_detail-row" patern is applied to a <tr>.
// It simulates the expand/collapse functionality in a table, which is triggered by the previous <tr>.
// It should have one <td colspan="99"> as a direct child.

.table_detail-row {
    background-color: $c-priority-background !important; /* stylelint-disable-line declaration-no-important */
}

.table_detail-row > td {
    padding: $margin-bottom $liner-base !important; /* stylelint-disable-line declaration-no-important */
}

// ----------------------------------------------

@media (min-width: $bp-page-liner) {
    .table_detail-row > td {
        padding: $margin-bottom $liner-l !important; /* stylelint-disable-line declaration-no-important */
    }
}

// ----------------------------------------------

@media print {
    .table_detail-row li,
    .table_detail-row td {
        page-break-inside: avoid;
    }

    .table_detail-row > td {
        border: 0 !important; /* stylelint-disable-line declaration-no-important */
        padding: 0 !important; /* stylelint-disable-line declaration-no-important */
    }
}

// ==============================================
// Modifier: Zebra Dark
// ==============================================

.table--zebra-dark tbody tr:nth-child(odd) {
    background: $c-table-zebra-dark;
}

.table--zebra-dark tbody tr:nth-child(even) {
    background: $c-table-zebra-light;
}

// ==============================================
// Modifier: Zebra Light
// ==============================================

.table--zebra-light tbody tr:nth-child(odd) {
    background: $c-table-zebra-light;
}

.table--zebra-light tbody tr:nth-child(even) {
    background: $c-table-zebra-dark;
}
