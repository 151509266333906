/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2018 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Page Focal
// ==============================================

.page-focal {
    background: #FFF;

    // Required to fix inline product widget floats.
    &::after {
        @include clearfix;
    }
}

// ----------------------------------------------

@media (min-width: $bp-page-focal) {

    .page-focal {
        border: 1px solid $c-border;
        border-radius: $radius-s;
    }

}
