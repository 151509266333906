/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$c-facebook:    #3B5998 !default;
$c-google-plus: #E04B31 !default;
$c-instagram:   #D72F75 !default;
$c-linkedin:    #0075B6 !default;
$c-pinterest:   #DE2108 !default;
$c-tumblr:      #2C4762 !default;
$c-twitter:     #00ACED !default;
$c-vimeo:       #1AB7EA !default;
$c-vk:          #567DA4 !default;
$c-youtube:     #E52D27 !default;
$c-weibo:       #EBDBC4 !default;


// ==============================================
// Social Follow List
// ==============================================

.social-follow-list {
    overflow: hidden;
}

.social-follow-list li {
    float: left;
    margin-right: 10px;

    &:last-child {
        margin-right: 0;
    }
}

.social-follow-list a {
    @include image-replacement;
    overflow: hidden;
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: $radius-s;
    background: $c-background;

    &:hover {
        opacity: 0.75;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
    }
}


// ==============================================
// Social Link
// ==============================================

.social-link.facebook {
    background: $c-facebook;

    &::before {
        @include svg (facebook);
    }
}

.social-link.google-plus {
    background: $c-google-plus;

    &::before {
        @include svg (google-plus);
    }
}

.social-link.instagram {
    background: $c-instagram;

    &::before {
        @include svg (instagram);
    }
}

.social-link.linkedin {
    background: $c-linkedin;

    &::before {
        @include svg (linkedin);
    }
}

.social-link.twitter {
    background: $c-twitter;

    &::before {
        @include svg (twitter);
    }
}

.social-link.vimeo {
    background: $c-vimeo;

    &::before {
        @include svg (vimeo);
    }
}

.social-link.youtube {
    background: $c-youtube;

    &::before {
        @include svg (youtube);
    }
}


// ==============================================
// Social Share List
// ==============================================

/*
.social-share-list {
    overflow: hidden;
    margin-bottom: 20px;
    padding-top: 10px;
    border-top: 1px dashed $c-border;
}

.social-share-list li {
    float: left;
    margin-right: 10px;
}

.social-share-list li:last-child {
    padding-right: 0;
}

.social-share-list a {
    position: relative;
    display: inline-block;
    padding: 8px 8px 8px (8px + 17px + 8px);
    background: $c-background;
    color: #FFF;
    line-height: 17px;
}

.social-share-list a:hover {
    opacity: 0.8;
}

.social-share-list a::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    width: 17px;
    height: 17px;
}
*/
