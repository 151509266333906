/**
* Gemaire Distributing
*/


// ==============================================
// Radio
// ==============================================

[type='radio'] {
    margin-left: $space-xxs;
    opacity: 0;
}


// ==============================================
// Radio + Label
// ==============================================

[type='radio'] + label {
    display: inline-block;
    position: relative;
    margin-left: -16px;
    padding-left: 22px;
    cursor: pointer;

    &::before,
    &::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        transition: all .3s ease;
    }

    &::before {
        top: 3px;
        left: 1px;
        width: 18px;
        height: 18px;
        background: $c-white;
        box-shadow: inset 0 0 0 2px $c-gray-slate;
    }

    &::after {
        top: 49%;
        left: 9px;
        width: 45px;
        height: 45px;
        opacity: 0;
        background: $c-blue-light;
        transform: translate(-50%, -50%) scale(0);
    }
}

// ----------------------------------------------
// State: Checked

[type='radio']:checked + label {
    &::before {
        box-shadow: inset 0 0 0 6px $c-blue-base;
    }

    &::after {
        animation: choice-ripple 1s none;
        transform: translate(-50%, -50%) scale(1);
    }
}

// ----------------------------------------------
// State: Disabled

[type='radio']:disabled + label {
    &::before {
        box-shadow: inset 0 0 0 2px $c-border;
    }
}

[type='radio']:disabled:checked + label {
    &::before {
        box-shadow: inset 0 0 0 6px $c-border;
    }
}

.input-radio {
    position: relative;
    border: 0;
    background: 0;
    width: 18px;
    height: 18px;

    > span {
        display: none;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        transition: all .3s ease;
    }

    &::before {
        top: -1px;
        left: 0;
        width: 18px;
        height: 18px;
        background: $c-white;
        box-shadow: inset 0 0 0 2px $c-gray-slate;
    }

    &::after {
        top: 12px;
        left: 9px;
        width: 45px;
        height: 45px;
        opacity: 0;
        background: $c-blue-light;
        transform: translate(-50%, -50%) scale(0);
    }
}

.input-radio--checked {
    &::before {
        box-shadow: inset 0 0 0 6px $c-blue-base;
    }

    &::after {
        animation: choice-ripple 1s none;
        transform: translate(-50%, -50%) scale(1);
    }
}
