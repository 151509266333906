/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$c-note-danger-background: lighten($c-danger, 50%) !default;
$c-note-danger-border:     $c-danger !default;

$c-note-warn-background: $c-yellow-light !default;
$c-note-warn-border:     $c-yellow-base !default;


// ==============================================
// Note
// ==============================================

.note {
    margin-bottom: $margin-bottom;
    padding: 10px;
    border: 1px solid $c-border;
    background: $c-background;
    font-size: $font-size-xs;
}

.note p:last-child {
    margin-bottom: 0;
}

// ----------------------------------------------
// Modifier: Danger

.note--danger {
    border-color: $c-note-danger-border;
    background:   $c-note-danger-background;
}

// ----------------------------------------------
// Modifier: Warn

.note--warn {
    border-color: $c-note-warn-border;
    background:   $c-note-warn-background;
}
