//
//  Default appearance styles
//  _____________________________________________

.pagebuilder-column {
    box-sizing: border-box;
}

//
//  Responsive Classes
//  _____________________________________________

@media only screen and (max-width: $bp-screen-m - 1px) {
    .pagebuilder-column {
        background-attachment: scroll !important;
        flex-basis: 50%;
    }
}

@media only screen and (max-width: $bp-screen-s - 1px) {
    .pagebuilder-column {
        flex-basis: 100%;
    }
}
