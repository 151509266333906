/**
* Gemaire Frontend
*
* @package    enterprise_gemaire
* @copyright  Copyright 2019 Gemaire Distributors
* @license    All rights reserved.
*/


/* stylelint-disable declaration-no-important */

// ==============================================
// Flex
// ==============================================

.d-flex {
    display: flex;
}

@media (min-width: $bp-screen-xs) {
    .d-flex-xs {
        display: flex;
    }
}

@media (min-width: $bp-screen-s) {
    .d-flex-s {
        display: flex;
    }
}

@media (min-width: $bp-screen-m) {
    .d-flex-m {
        display: flex;
    }
}

@media (min-width: $bp-screen-l) {
    .d-flex-l {
        display: flex;
    }
}


// ==============================================
// Align Items
// ==============================================

.align-items-flex-start { align-items: flex-start !important; }
.align-items-center     { align-items: center     !important; }
.align-items-flex-end   { align-items: flex-end   !important; }


// ==============================================
// Flex Center
// ==============================================

// Used on a parent to visually center the child element(s).

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}


// ==============================================
// Flex Ghost
// See: https://codepen.io/brendanfalkowski/pen/pebayb
// ==============================================

// Used as a direct child of a `display: flex` parent with `flex-wrap: wrap`,
// add as many instances of this element as (n-1) items fit along the main axis.
// This prevents an uneven number of items on the last row/column from being
// distributed unlike other rows/columns.

.flex-ghost {
    height:         0 !important;
    margin-top:     0 !important;
    margin-bottom:  0 !important;
    padding-top:    0 !important;
    padding-bottom: 0 !important;
    border:         0 !important;
}


// ==============================================
// Flex Spacer
// ==============================================

// Used as a direct child of a `display: flex` parent, this element expands to fill
// the available space along the main axis.

.flex-spacer {
    flex: 1 0 0;
}

// ==============================================
// Flex Wrap
// ==============================================

.flex-wrap {
    flex-wrap: wrap;
}

.flex-no-wrap {
    flex-wrap: nowrap;
}

// ==============================================
// Flex Direction
// ==============================================

.flex-direction-column {
    flex-direction: column;
}

.flex-direction-row {
    flex-direction: row;
}

// ==============================================
// Flex Basis
// ==============================================

.flex-basis-content {
    flex-basis: content;
}

.flex-basis-auto {
    flex-basis: auto;
}

.flex-basis-100 {
    flex-basis: 100%;
}