/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Order List
// ==============================================

.order-list {
    margin-bottom: $margin-bottom;
    border-top: 1px solid $c-border;
}

.order-list li {
    position: relative;
    padding: 15px 0;
    border-bottom: 1px solid $c-border;
    font-size: $font-size-s;
}

.order-list .order-date {
    margin-bottom: 10px;
    font-size: $font-size-base;
    font-weight: bold;
}

.order-list-grid .col-3 {
    padding-top: 15px;
}

// ----------------------------------------------
// Modifier: Recent

.order-list--recent {
    border-top: 0;
}

.order-list--recent li:first-child {
    padding-top: 0;
}

// ----------------------------------------------

@media (min-width: 480px) {

    .order-list-grid .col-1 {
        float: left;
        width: 40%;
    }

    .order-list-grid .col-2 {
        float: left;
        width: 60%;
        padding-left: 20px;
    }

    .order-list-grid .col-3 {
        float: left;
        clear: left;
    }

}

// ----------------------------------------------

@media (min-width: 1024px) {

    .order-list-grid .col-1 {
        width: 25%;
    }

    .order-list-grid .col-2 {
        width: 45%;
        padding-right: 20px;
    }

    .order-list-grid .col-3 {
        clear: none;
        width: 30%;
        padding-top: 0;
        text-align: right;
    }

}
