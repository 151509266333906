/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Label
// ==============================================

//label {}

// ==============================================
// Label With Image
// ==============================================

// Default: Image to Left


.label-media-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.label-media_image {
    margin-right: 1em;
}

// ----------------------------------------------
// Modifier - Flip (Image to Right)

.label-media--flip {
    justify-content: space-between;
}

.label-media--flip .label-media_image {
    margin-right: 0;
    margin-left: 1em;
}

.radio-image-label {
    text-transform: capitalize;
}

// ==============================================
// Label floating
// ==============================================

.label-floating {
    top: -15px;
    font-size: 13px;
}

