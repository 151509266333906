/**
* Gemaire Frontend
*
* @package    enterprise_gemaire
* @copyright  Copyright 2019 Gemaire Distributors
* @license    All rights reserved.
*/


/* stylelint-disable declaration-no-important */


// ==============================================
// Font Style
// ==============================================

.font-style-normal { font-style: normal !important; }
.font-style-italic { font-style: italic !important; }


// ==============================================
// Font Weight
// ==============================================

.font-weight-light  { font-weight: 300    !important; }
.font-weight-normal { font-weight: normal !important; }
.font-weight-bold   { font-weight: bold   !important; }


// ==============================================
// Text Color
// ==============================================

.text-link    { color: $c-blue-bright  !important; }
.text-danger  { color: $c-text-danger  !important; }
.text-subtle  { color: $c-text-subtle  !important; }
.text-success { color: $c-text-success !important; }
.text-warn    { color: $c-text-warn    !important; }

.text-contrast-base   { color: $c-text-contrast-base   !important; }
.text-contrast-subtle { color: $c-text-contrast-subtle !important; }


// ==============================================
// Text Size
// ==============================================

.text-xxxl   { font-size: $font-size-xxxl  !important; }
.text-xxl    { font-size: $font-size-xxl   !important; }
.text-xl     { font-size: $font-size-xl    !important; }
.text-l      { font-size: $font-size-l     !important; }
.text-base   { font-size: $font-size-base  !important; }
.text-s      { font-size: $font-size-s     !important; }
.text-xs     { font-size: $font-size-xs    !important; }
.text-xxs    { font-size: $font-size-xxs   !important; }
.text-xxxs   { font-size: $font-size-xxxs  !important; }
.text-xxxxs  { font-size: $font-size-xxxxs !important; }


// ==============================================
// Text Transform
// ==============================================

.text-capitalize { text-transform: capitalize !important; }
.text-lowercase  { text-transform: lowercase  !important; }
.text-uppercase  { text-transform: uppercase  !important; }


// ==============================================
// Text Truncate
// ==============================================

.text-truncate {
    overflow:      hidden   !important;
    width:         100%     !important;
    text-overflow: ellipsis !important;
    white-space:   nowrap   !important;
}


// ==============================================
// Text Wrapping
// ==============================================

.text-nowrap,
.nobr {
    white-space: nowrap !important;
}

.word-break-break-all {
    word-break: break-all !important;
}

.word-wrap {
    @include word-wrap();
}

// ==============================================
// Line Height
// ==============================================

.lh-1 {
    line-height: 1;
}

.lh-2 {
    line-height: 2;
}