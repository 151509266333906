/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Brick
// ==============================================

.brick {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $gap;
    background: $c-background;
}

.brick_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 20px 0;
    text-align: center;
}


// ==============================================
// Modifier: Three
// ==============================================

@media (max-width: 899px) {

    .brick--three .brick_item {
        border-bottom: 4px solid #FFF;
    }

}

// ---------------------------------------------

@media (min-width: 900px) {

    .brick--three .brick_item {
        width: 33.333%;

        &:first-child {
            border-right: 4px solid #FFF;
        }

        &:last-child {
            border-left: 4px solid #FFF;
        }
    }

}


// ==============================================
// Modifier: Four
// ==============================================

@media (max-width: 599px) {

    .brick--four .brick_item {
        clear:both;
        width: 100%;
        border-bottom: 4px solid #FFF;
    }

}

// ---------------------------------------------

@media (min-width: 600px) {

    .brick--four .brick_item {
        width: 50%;
    }

    .brick--four .brick_item:nth-child(1),
    .brick--four .brick_item:nth-child(2) {
        border-bottom: 4px solid #FFF;
    }

    .brick--four .brick_item:nth-child(1),
    .brick--four .brick_item:nth-child(3) {
        border-right: 2px solid #FFF;
    }

    .brick--four .brick_item:nth-child(2),
    .brick--four .brick_item:nth-child(4) {
        border-left: 2px solid #FFF;
    }

}

// ---------------------------------------------

@media (min-width: 1000px) {

    .brick--four .brick_item {
        width: 25%;
    }

    .brick--four .brick_item:nth-child(1),
    .brick--four .brick_item:nth-child(2) {
        border-bottom: 0;
    }

    .brick--four .brick_item:nth-child(1),
    .brick--four .brick_item:nth-child(2),
    .brick--four .brick_item:nth-child(3) {
        border-right: 2px solid #FFF;
    }

    .brick--four .brick_item:nth-child(2),
    .brick--four .brick_item:nth-child(3),
    .brick--four .brick_item:nth-child(4) {
        border-left: 2px solid #FFF;
    }

}
