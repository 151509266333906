/**
* Gemaire Distributing
*/


// ==============================================
// Vars
// ==============================================

$form-elem-font-size: 15px;


// ==============================================
// Form
// ==============================================

.form {
    width: 100%;
    max-width: $form-elem-width-base;
    margin-bottom: $space-xxl;
}

// ----------------------------------------------
// Modifier: Contrast

.form--contrast {
    color: $c-white;
}

// ----------------------------------------------
// Modifier: Full

.form--full {
    max-width: none;
}


// ==============================================
// Input Button-like
// ==============================================

// [todo] move to .button class

[type='button'],
[type='reset'],
[type='submit'] {
    cursor: pointer;
    -webkit-appearance: button;
}


// ==============================================
// Input Button-like
// Input Text-like
// ==============================================

input:not([type='radio']):not([type='checkbox'])::-moz-focus-inner {
    border: 0;
    padding: 0;
}


// ==============================================
// Validation
// ==============================================

// [todo] need to split to partials

.field-error,
.input-text.mage-error,
.input-text.mage-error:focus {
    border-color: $c-input-focus-error;
    color: $c-input-focus-error;
}

div.mage-error[generated] {
    @include input-hint();
    color: $c-input-focus-error;
}

input.mage-error:focus ~ label {
    color: $c-input-focus-error;
}

.control-search {
    display: inline-block;
    width: 100%;
}

.control-search input {
    margin: 0;
    padding-right: 35px;
    position: static;
    border-radius: 2px;
    height: 38px;
}

.control-search button.magnifier {
    display: inline-block;
    @include lib-button-icon(
            $_icon-font-content: $icon-search,
            $_icon-font-text-hide: true,
            $_icon-font-color: $c-gray-slate
    );
    @include lib-button-reset();
    position: relative;
    float: right;
    margin-top: -30px;

    &:focus::before {
        color: $c-black;
    }
}
