/**
* Gemaire Distributing
*/

// ==============================================
// Dockside
// ==============================================

.dockside-pickup-container {
    max-width: $bp-screen-l;

    .status-branch-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin-bottom: 32px;
    }

    .sync-dockside-action {
        cursor: pointer;
        font-size: 20px;
        vertical-align: -5px;
    }
}

.dockside-pickup-confirmation {
    max-width: 800px;

    .sync-dockside-action {
        display: none;
    }
}

// ==============================================
// Dockside on Order View
// ==============================================

.order-view {

    .dockside-container {
        box-shadow: $box-shadow-base;
        flex: 1 1 460px;
        padding: 0 20px;
        margin-bottom: 30px;
        max-width: 700px;

        section h2 {
            font-size: 16px;
        }
    }

    .dockside-pickup-container {
        margin-bottom: -10px;
    }

    .status-branch-container {
        padding-top: 20px;
        padding-left: 30px;
    }

    .dockside-checkin-fields h2,
    .dockside-pickup-branch {
        display: none;
    }

    .order-dockside-header {
        background: $c-yellow-light;
        margin: 0 -20px 20px;
        padding: 15px 20px;
        position: relative;
    }
}

@media (min-width: $bp-screen-xs) {

    .dockside-header-icon {
        background: url('https://s7d2.scene7.com/is/image/Watscocom/gemaire_content_20210511_dockside-block-banner-bkg?hei=180&fmt=png-alpha') no-repeat right 0;
        height: 90px;
        width: 185px;
        position: absolute;
        top: 0;
        right: 0;
        background-size: 180px auto;
    }
}