//
//  Default appearance styles
//  _____________________________________________

div[data-content-type='slider'] {
    
    [data-content-type='slide']:not(:first-child) {
        display: none;
    }

    .slick-list,
    .slick-track,
    .slick-slide {
        min-height: inherit;

        > div {
            line-height: 0;
            min-height: inherit;
            margin: 0;
            overflow: hidden;
            width: 100%;
        }
    }

    .slick-prev,
    .slick-next {
        height: 40px;
        width: 40px;
        background: rgba(255, 255, 255, 0.2);

        &:hover {
            background: $c-white;
        }
    }

    .slick-dots {
        position: absolute;

        li {
            margin: 0 8px;

            button {
                width: 12px;
                height: 12px;
                background: $c-border;
                border-radius: 50%;
                padding: 0;
                border: 0;
                text-indent: -9999px;

                &:hover {
                    background: $c-blue-bright;
                }
            }

            &.slick-active {
                button {
                    background: $c-text-base;
                }
            }
        }
    }
}

[data-appearance="full-bleed"] {
    .slick-list {
        width: 100vw;
        max-width: 100%;
    }
}

@media (min-width: $bp-screen-l) {

    div[data-content-type='slider'] {

        .slick-prev,
        .slick-next {
            width: 60px;
            height: 60px;
        }

        .slick-dots {

            li {
                margin: 0;

                button {
                    width: auto;
                    height: auto;
                    background: transparent;
                    border-radius: 0%;
                    padding: 5px 20px;
                    text-indent: 0;
    
                    &:hover {
                        background: transparent;
                        color: $c-link-base;
                    }
                }
    
                &.slick-active {
                    button {
                        background: $c-background;
                    }
                }
            }
        }
    }
}

