/**
* Gemaire Distributing
*/


// ==============================================
// Field Row
// ==============================================

.field-row {
    display: flex;
    flex-wrap: nowrap;
    margin-left: ($space-base * -1);
}

.field-row .field {
    flex: 1 1 50%;
    margin-bottom: ($space-xxl - 18px); // Offset from "control" component.
    margin-left: $space-base;
}

.field-row_action {
    flex: 0 0 auto;
    margin-top: 18px;
    margin-left: $space-base;
}


// ==============================================
// Modifier: Stack
// ==============================================

@media (max-width: $bp-screen-s - 1px) {

    .field-row--stack {
        display: block;
        margin-left: 0;
    }

    .field-row--stack .field {
        margin-bottom: $space-xxl; // Reset to original "field" value
        margin-left: 0;
    }

    .field-row--stack .field-row_action {
        margin-top: 0;
        margin-left: 0;
    }

}
