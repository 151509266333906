/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2017 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Inline Text List
// ==============================================

.inline-text-list {
    overflow: hidden;
    margin-bottom: $margin-bottom;
}

.inline-text-list li {
    float: left;
    white-space: nowrap;

    &::after {
        content: '|';
        padding: 0 0.65em;
        color: $c-text-subtle;
    }

    &:last-child::after {
        display: none;
    }
}
