/**
* Gemaire Distributing
*/


// ==============================================
// Action Group
// ==============================================

.action-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -0.8rem;
    margin-left: -0.8rem;
}

// ----------------------------------------------
// Modifier: Center

.action-group--center {
    justify-content: center;
}

// ----------------------------------------------
// Modifier: Center

.action-group--right {
    justify-content: flex-end;
}


// ==============================================
// Action Group - Child
// ==============================================

.action-group > * {
    margin-top: 0.8rem;
    margin-left: 0.8rem;
}


// ==============================================
// Action Group - Label
// ==============================================

.button-group_label {
    white-space: nowrap;
}
