/**
* Gemaire Distributing
*/

// ==============================================
// Vars
// ==============================================

$bp-table-scroll-l: $bp-screen-l !default;
$bp-table-scroll-base: $bp-screen-m !default;
$table-scrollbar-margin-bottom: 10px !default;


// ==============================================
// Table Scroll Wrap
// ==============================================


.table-scroll-wrap {
    margin-bottom: $gap;
}

.table-scroll-wrap table {
    margin-bottom: $table-scrollbar-margin-bottom;
}

.table-scroll-wrap--visible {
    border-right: 16px solid $c-border;
}

.table-scroll-wrap--visible:after {
    @include triangle($direction: right, $color: $c-text-base);
    right: 5px;
    top: 48%;
}


@media (max-width: $bp-table-scroll-base - 1px) {
    .table-scroll-wrap {
        overflow-x: auto;
    }

    .table-scroll-wrap td,
    .table-scroll-wrap th {
        white-space: nowrap;
    }
}

@media (max-width: $bp-table-scroll-l - 1px) {
    .table-scroll-wrap--l {
        overflow-x: auto;
    }

    .table-scroll-wrap--l td,
    .table-scroll-wrap--l th {
        white-space: nowrap;
    }
}
