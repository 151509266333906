/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Lead
// ==============================================

.lead {
    font-size: 18px;
}

// ----------------------------------------------

@media (min-width: 600px) {

    .lead {
        font-size: 24px;
    }

}
