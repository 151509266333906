/**
* Gemaire Distributing
*/


// ==============================================
// Vars
// ==============================================

$toolbar-mode-icon-font-size: 24px;
$toolbar-element-background: $c-library-gray-0;


// ==============================================
// Toolbar Wrap
// ==============================================

//.toolbar-wrap {}
.toolbar-wrap--top {
    border-top: 1px solid $c-border;
}

// ==============================================
// Modifier: Bottom
// ==============================================

.toolbar-wrap--bottom {
    margin-bottom: 0;
    position: relative;

    .view-mode {
        display: none;
    }
}

.toolbar-wrap--bottom .toolbar::after {
    @include clearfix;
}

// ==============================================
// Toolbar
// ==============================================

//.toolbar {}


// ==============================================
// Toolbar Products
// ==============================================

.toolbar-products {
    display: flex;
    align-items: center;
}

.toolbar-wrap--top .toolbar-products {
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 20px $layout-indent__width 0;
}

.toolbar-wrap--bottom .toolbar-products {
    padding: 0 20px 20px;
}

@media (max-width: $bp-screen-s - 1px) {
    .toolbar-products {
        justify-content: center;
        flex-wrap: wrap;
    }
}

// ==============================================
// Modes
// ==============================================

.modes {
    font-size: inherit;
    display: flex;
}

.view-mode {
    display: inline-block;
    margin: 0 0 0 auto;
 }
 
 .modes {
     border: 1px solid $c-divider-border;
     font-size: 14px;
 }
 
 .modes-mode {
     padding: 9px 12px;
     font-weight: $font-weight-normal;
     line-height: 1;
     text-align: center;
     transition: $transition-all;
     background: $c-white;
 
     @include lib-icon-font($icon-grid,
         $_icon-font-size: $toolbar-mode-icon-font-size,
         $_icon-font-line-height: 21px,
         $_icon-font-position: after,
         $_icon-font-color: $c-gray-slate);
 
     &:not(.active),
     &:not(.active)::after {
         color: $c-link-base;
 
         &:hover,
         &:hover::after {
             color: $c-link-base;
             background: $c-library-gray-0;
             text-decoration: none;
             transition: $transition-all;
         }
     }
 
     &.active,
     &.active::after {
         background: $c-action-disabled-base;
         color: $c-text-muted-dark;
     }
 
     &::before {
         content: none;
     }
 }
 
 .mode-list {
     @include lib-icon-font($icon-list,
         $_icon-font-size: $toolbar-mode-icon-font-size,
         $_icon-font-line-height: 21px,
         $_icon-font-position: after,
         $_icon-font-color: $c-blue-base);
 }

 @media (max-width: $bp-screen-s - 1px) {

    .modes {
        display: flex;
        font-size: inherit;
    }

    .view-mode {
        display: none;
    }

    .modes-wrapper>span,
    .modes-wrapper>a>span {
        display: none;
    }

    .modes-mode {
        padding: 7px 6px;
    }
}

@media (max-width: $bp-screen-xs - 1px) {

    .modes-wrapper > span {
        display: none;
    }

    .modes-mode.active.mode-grid.modes-wrapper,
    .modes-wrapper {
        width: 100%;
    }

    .modes-wrapper > a {
        width: 100%
    }
}

// ==============================================
// Filters Mobile Toggle
// ==============================================

.filters-mobile-toggle {
    @include lib-icon-font(
        $icon-pointer-left,
        $_icon-font-size           : 16px,
        $_icon-font-line-height    : 30px
    );
}

.toolbar-wrap--bottom .filters-mobile-toggle {
    display: none;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .filters-mobile-toggle {
        display: none;
    }

}


// ==============================================
// Limiter
// ==============================================

.limiter-options {
    width: auto;
    margin: 0 5px 0 7px;
}

.toolbar-limiter {
    margin-left: auto;
}

.limiter-label {
    font-weight: 400;
}

.toolbar-limiter .control {
    display: inline-block;
}

.toolbar-wrap--top .toolbar-limiter {
    display: none;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .toolbar-limiter {
        text-align: center;
        width: 100%;
        margin-top: 15px;
    }

}


// ==============================================
// Pages
// ==============================================

.toolbar {
    .pages {
        cursor: pointer;
    }
}

.toolbar-wrap--top .pages {
    display: none;
}

.toolbar-wrap--bottom .pages {
    display: block;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .toolbar-wrap--bottom .pages {
        float: left;
        padding-right: 0;
    }

}


// ==============================================
// Toolbar Amount
// ==============================================

.toolbar-amount {
    margin-bottom: 0;
}

.toolbar-wrap--bottom .toolbar-amount {
    display: none;
}

.toolbar-amount-left .toolbar-amount {
    margin-left: 0;
}

// ==============================================
// Toolbar Sorter
// ==============================================

.toolbar-sorter {
    float: left;
}

.toolbar-wrap--bottom .toolbar-sorter {
    display: none;
}

.sorter-options {
    margin: 0 0 0 7px;
    width: auto;
}

.sorter-action {
    padding: 7px 10px;
    border: 1px solid $c-library-gray-0;
    border-radius: $radius-base;
    vertical-align: middle;
    transition: $transition-all;

    &:hover {
        text-decoration: none;
        background: $c-library-gray-0;
        transition: $transition-all;
    }

    @include lib-icon-font(
        $icon-arrow-up,
        $_icon-font-size        : 18px,
        $_icon-font-color       : $c-blue-base,
        $_icon-font-color-hover : $c-blue-base,
        $_icon-font-position    : after,
        $_icon-font-text-hide   : true
    );

    &::after {
        margin-top: -4px;
    }
}

.sort-desc {
    &::after {
        content: $icon-arrow-down;
    }
}

// ----------------------------------------------

@media (max-width: $bp-screen-xs - 1px) {

    .sorter-label {
        display: none;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .toolbar-sorter {
        float: right;
        margin-left: 50px;
    }

}

// ==============================================
// Modifier for customer account toolbar
// ==============================================

.account {
    .toolbar-wrap--bottom {
        margin-bottom: $space-base;

        .toolbar {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: $bp-screen-m - 1px) {
                flex-direction: column;
            }
        }
    }
}
