/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Product Numbers
// ==============================================

.product-numbers {
    overflow: hidden;
    color: $c-text-subtle;
    font-size: $font-size-xxs;
}

// ----------------------------------------------
// Modifier: Adjacent

.product-numbers--adjacent .item-number,
.product-numbers--adjacent .manufacturer-number {
    float: left;
    margin-right: 20px;
    white-space: nowrap;
}

// ----------------------------------------------
// Modifier: Large

.product-numbers--l {
    margin-bottom: $margin-bottom;
    font-size: $font-size-base;
}
