/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// 404
// ==============================================

.page-404 {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.cms-no-route {

    .site-matter {
        background-color: $c-white;
        padding: 0;
    }

    .page-focal {
        border: 0;
    }
}
