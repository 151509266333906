//
//  Slick slider styles
//  _____________________________________________

.slick-slider {
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-touch-action: pan-y;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    box-sizing: border-box;
    display: block;
    position: relative;
    touch-action: pan-y;
    user-select: none;
}
.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-track {
    display: block;
    left: 0;
    position: relative;
    top: 0;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    [dir='rtl'] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        border: 1px solid transparent;
        display: block;
        height: auto;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev {
    @include lib-icon-font(
            $_icon-font-content        : $icon-pointer-left,
            $_icon-font-vertical-align : middle,
            $_icon-font-margin         : 0 3px 0 0
        );
    left: 2rem;
}

.slick-next {
    @include lib-icon-font(
            $_icon-font-content        : $icon-pointer-right,
            $_icon-font-vertical-align : middle,
            $_icon-font-margin         : 0 0 0 3px
        );
    right: 2rem;   
}

.slick-prev,
.slick-next {
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    border: none;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 50px;
    line-height: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 50px;
    z-index: 5;

    &.slick-disabled {
        opacity: 0;
    }

    &:before,
    &:after {
        font-size: 38px;
        opacity: 1;
    }
}

.slick-dots {
    display: block;
    list-style: none;
    margin: 0;
    padding: 13px 0;
    text-align: center;
    width: 100%;
    li {
        cursor: pointer;
        display: inline-block; 
        margin: 0 10px;
        position: relative;
        vertical-align: middle;
        button {
            border: 0;
            box-shadow: none;
            cursor: pointer;
            color: $c-text-base;
            display: block;
            transition: .3s;

            &:active,
            &:focus,
            &:not(.primary) {
                box-shadow: none;
            }
        }
    }
}

//
//  Responsive Classes
//  _____________________________________________

@media only screen and (max-width: $bp-screen-m - 1px) {
  
    .slick-dots {

        li {
            height: 14px;
            margin: 0 5px;
            width: 14px;
            button {
                background: $c-white;
                border-radius: 10px;
                box-shadow: none;
                height: 14px;
                padding: 0;
                text-indent: -99999px;
                width: 14px;    
            }
    
            &.slick-active {
                button {
                    background: $c-library-blue-9;
                }
            }
        }
    }
}
