/**
* Gemaire Distributing
*/


// ==============================================
// Nav
// ==============================================

.nav .delimiter {
    display: block;
    margin: $space-base 1.8rem;
    border-top: 1px solid $c-border;
}
