/**
* Gemaire Distributing
* Copyright Gemaire Distributing. All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$step-background: $c-library-gray-0;
$step-size: 24px;

$step-number-background: $c-gray-slate;
$step-number-background-active: $c-blue-base;
$step-number-icon-complete: $icon-edit;
$step-number-outline-width: 5px;


// ==============================================
// Steps
// ==============================================

.steps {
    counter-reset: i;
    margin: 0 $space-s;
    display: inline-block;
}


// ==============================================
// Steps - Step
// ==============================================

.step {
    position: relative;
    vertical-align: top;
    margin-bottom: 0;
    min-height: 50px;
    cursor: pointer;
}

// ==============================================
// Steps - Number
// ==============================================

.step>span {
    display: inline-block;
    width: 100%;
    padding-left: ($step-size - 6);
    padding-top: 6px;
    word-wrap: break-word;
    opacity: 0.6;

    // Circle
    &::before {
        content: '';
        position: absolute;
        top: $step-number-outline-width;
        left: ($step-size / -2);
        width: $step-size;
        height: $step-size;
        border-radius: $step-size;
        background: $step-number-background;
        outline: $step-number-outline-width solid $c-white;
    }

    // Circle content
    &::after {
        content: counter(i);
        counter-increment: i;
        position: absolute;
        top: $step-number-outline-width;
        left: -12px;
        width: $step-size;
        height: $step-size;
        color: $c-white;
        font-size: 14px;
        line-height: $step-size;
        text-align: center;
    }
}

// ----------------------------------------------
// Modifier: Active

.step--active>span {
    opacity: 1;

    // Circle
    &::before {
        background: $step-number-background-active;
    }
}

// ----------------------------------------------
// Modifier: Complete

.step--complete>span {
    opacity: 1;

    // Circle content
    &::after {
        content: $step-number-icon-complete;
        font-family: $font-family-icon;
        font-size: $font-size-xl;
        line-height: $step-size;
    }
}

// ----------------------------------------------
// Modifier: Danger

.step--danger>span {

    color: $c-red-base;

    &::before {
        background-color: $c-red-base;
    }
}

// ==============================================
// Steps React Component
// ==============================================

.steps-component {
    display: flex;
    text-align: center;

    .steps-segment {
        position: relative;
        flex-basis: 16px;
        flex-grow: 1;
        flex-shrink: 1;

        &:not(:last-child) {

            &:after {
                content: "";
                background-color: $c-blue-base;
                display: block;
                position: absolute;
                height: 2px;
                top: 5px;
                left: 50%;
                right: -50%;
            }
        }

        &.is-complete {
            &:after {
                background: $c-border;
            }

            ~.steps-segment {
                >p {
                    color: $c-text-base;
                }

                .steps-marker,
                &:after {
                    background: $c-border;
                }

            }
        }

        >p {
            margin: 0;
            padding-top: 15px;
            font-size: 14px;
            color: $c-link-base;
        }
    }

    .steps-marker {
        height: 12px;
        width: 12px;
        position: absolute;
        left: calc(50% - 6px);
        background: $c-link-base;
        align-items: center;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        z-index: 10;
    }
}