/**
* Gemaire Frontend
*
* @author    Brendan Falkowski (http://gravitydept.com)
* @package   enterprise_gemaire
* @copyright Copyright 2019 Gravity Department
* @license   All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$c-h1: $c-text-base !default;
$c-h2: $c-text-base !default;
$c-h3: $c-text-base !default;
$c-h4: $c-text-base !default;
$c-h5: $c-text-base !default;
$c-h6: $c-text-base !default;


// ==============================================
// Mixin - Typography
// ==============================================
@mixin bold {
    font-weight: 500;
    letter-spacing: $letter-spacing;
}

@mixin caption {
    color: $c-text-base;
    font-family: $font-family-sans;
    font-size: $font-size-s;
    font-style: normal;
    font-weight: normal;
}

@mixin h1 {
    margin-bottom: 0.5em;
    color: $c-h1;
    font-family: $font-family-sans;
    font-size: 30px;
    font-weight: 400;
    font-style: normal;
    line-height: $line-height-xxs;
    text-rendering: optimizeLegibility;
    text-transform: none;
}

@mixin h2 {
    margin-bottom: 0.6em;
    color: $c-h2;
    font-family: $font-family-sans;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    line-height: $line-height-xxs;
    text-rendering: optimizeLegibility;
    text-transform: none;
}

@mixin h3 {
    margin-bottom: 10px;
    color: $c-h3;
    font-family: $font-family-sans;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.4;
    text-rendering: optimizeSpeed;
    text-transform: none;
}

@mixin h4 {
    margin-bottom: 10px;
    color: $c-h4;
    font-family: $font-family-sans;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.4;
    text-rendering: optimizeSpeed;
    text-transform: uppercase;
}

@mixin h5 {
    margin-bottom: 10px;
    color: $c-h5;
    font-family: $font-family-sans;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.4;
    text-rendering: optimizeSpeed;
    text-transform: uppercase;
}

@mixin h6 {
    margin-bottom: 5px;
    color: $c-h6;
    font-family: $font-family-sans;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.4;
    text-rendering: optimizeSpeed;
    text-transform: uppercase;
}

@mixin hyphenate {
    word-wrap: break-word;
    hyphens: auto;
}

@mixin label {
    font-family: $font-family-sans;
    font-size: $font-size-s;
    font-weight: $font-weight-semibold;
    font-style: normal;
    letter-spacing: $letter-spacing;
}

@mixin overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin price {
    font-size: $font-size-xxxl;
    font-weight: 600;
    line-height: 1;
    white-space: nowrap;
}

@mixin text {
    margin-bottom: 0;
    color: $c-text-base;
    font-family: $font-family-sans;
    font-size: $font-size-base;
    font-weight: normal;
    font-style: normal;
    line-height: $line-height;
    text-rendering: optimizeSpeed;
    text-transform: none;
}

@mixin word-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
  }