/**
* Gemaire Distributing
*/

// ==============================================
// Quickorder
// ==============================================

.quickorder-react-app {
    .content-wrapper {
        @media (min-width: $bp-screen-m) {
            display: flex;

            .quickorder-search {
                flex: 1 1 430px;
                margin-right: 20px;
            }

            .quickorder-results {
                flex-basis: 100%;
            }
        }
    }
}

.quickorder-wrapper {

    td.col-image {
        min-width: 70px;
        text-align: center;

        img {
            height: auto;
        }
    }

    @media (min-width: $bp-screen-m) {
        .dropzone-active,
        .dropzone-accept,
        .dropzone-reject,
        .dropzone-disabled {
            margin-bottom: 0;
            flex: 1;
            margin-right: 20px;
        }
    }

    .typeahead-result a {
        padding: 10px;
    }

    .search-autocomplete > * {

        &:before {
            @include triangle($direction: up, $size: 8px, $color: $c-border);
            top: -8px;
            left: 15px;
        }

        &:after {
            @include triangle($direction: up, $size: 6px, $color: $c-white);
            top: -6px;
            left: 17px;
        }
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .quickorder-wrapper {
        display: flex;

        .form {
            margin: 0 20px 0 0;
        }
    }
}

// ==============================================
// Quickorder Search
// ==============================================

.quickorder-search {
    h3 {
        font-size: $font-size-base;
    }

    .rti--container {
        --rti-bg: #fff;
        --rti-border: #D0D0D0;
        --rti-main: #0368B3;
        --rti-radius: 0;
        min-height: 46px;
        max-width: 388px;
    }

    .rti--tag {
        --rti-radius: 4px;
    }

    .rti--input {
        &::-moz-placeholder {
            font-size: $font-size-xs;
            font-weight: 300;
        }

        &::-webkit-input-placeholder {
            font-size: $font-size-xs;
            font-weight: 300;
        }

        &:-ms-input-placeholder {
            font-size: $font-size-xs;
            font-weight: 300;
        }
    }

    .dropzone {
        margin-bottom: 15px;

        &.focused {
            border-color: $c-blue-base;
        }

        &.accept {
            border-color: $c-success!important;
        }

        &.rejected {
            border-color: $c-danger!important;
        }
    }

    .quickorder-files-list {
        display: flex;
    }

    .suggestions-collapsible {
        padding: 7px 0;
        display: flex;
        align-items: center;

        .button-more {
            display: flex;
            align-items: center;
            font-weight: 300;
            color: $c-text-base;
            margin-left: 10px;
        }
    }
}

// ==============================================
// Quickorder Table
// ==============================================

.quickorder-table {
    .product-item-list-badge-product-name-wrapper {
        flex-basis: calc(100% - 220px);
    }

    &.products-list {
        .product-item-list-left-column {
            flex-basis: calc(100% - 240px);
            margin-right: 10px;
        }
    }

    .product-item {
        border: 0;
        border-bottom: 1px solid $c-border;
        margin: 0;
        padding: 15px 0;
        position: relative;
        z-index: 1 !important;

        &:first-child {
            border-top: 1px solid $c-border;
        }

        .product-item-info {
            padding: 0;
            align-items: flex-start;
        }

        .product-item-name {
            font-size: $font-size-base;
        }

        @media (max-width: $bp-screen-m - 1px) {
            .quickorder-result_remove {
                position: absolute;
                top: 5px;
                right: 0;
            }
        }

        .button--remove {
            width: 30px;
            height: 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .price-box {
            margin-top: 0;
        }

        .price-container {
            display: flex;
            justify-content: flex-end;
        }

        .actions-secondary {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 5px;
        }
    }

}

@media (max-width: $bp-screen-xl - 1px) {
    .quickorder-table.products-list .product-item-photo {
        flex-basis: 90px;
    }
}

@media (max-width: $bp-table-linearize - 1px) {
    .quickorder-table .col-image {
        position: absolute;
        width: auto;
    }

    .quickorder-table .col-product,
    .quickorder-table .col-matches {
        margin-left: 60px;
    }

    .quickorder-table .col-action {
        float: right;
        padding-top: 15px;
    }

    .quickorder-table .col-qty {
         display: inline-flex;
         margin-top: 10px;
         align-items: center;

         .label {
             padding-right: 5px;
         }
    }
}

@media (max-width: $bp-screen-xs - 1px) {
    .quickorder-table .product-item-photo {
        margin-top: 20px;
    }
}


// ==============================================
// Quickorder Options selection
// ==============================================

.quickorder-selection {
    .col-image {
        width: 70px;
    }
}

// ==============================================
// Quickorder Warning
// ==============================================

.quickorder-warning_icon {
    font-size: 30px !important;
    line-height: inherit !important;
}

.quickorder-dragzone-wrapper {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    transition: 200ms;
    opacity: 0;
    pointer-events: none;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }

    .dropzone {
        width: 100%;
        height: 100%;
        background: rgba(228,228,228,0.8);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.quickorder-suggestions {
    background: #FFF4E4;

    ul {
        li {
            padding: 15px 10px;
            border-bottom: 1px solid $c-border;
            display: flex;
            align-items: center;

            .actions {
                margin-left: auto;
                display: flex;
                align-items: center;
            }
        }
    }
}

.unmatched-refinement-input {
    max-width: 500%;
}

.order-templates-button {
    .menu_content .search-input-wrapper {
        margin: 15px 10px 5px;
        min-width: 220px;
    }

    .button--link {
        text-align: left;
    }
}
