/**
* Acme Refrigeration
*/


// ==============================================
// Global
// ==============================================

body {
    background: $c-white;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

}


// ==============================================
// Page Wrapper
// ==============================================

.page-wrapper {
    min-width: $bp-screen-xxs;
}

@media (min-width: $bp-screen-m) {

    .page-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%; // Stretch content area for sticky footer
    }

    .page-main {
        flex: 1 0 auto;
    }

    .ie10 .page-wrapper,
    .ie11 .page-wrapper {
        height: 100%;
    }

    .page-wrapper > .breadcrumbs,
    .page-wrapper > .top-container,
    .page-wrapper > .widget {
        width: 100%;
    }

}


// ==============================================
// Trim
// ==============================================
.block.category.event,
.page-main,
.page-wrapper > .breadcrumbs,
.page-wrapper > .page.messages,
.page-wrapper > .widget,
.top-container {
        padding-left: $layout-indent__width;
        padding-right: $layout-indent__width;
}
