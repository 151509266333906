$sidebar-width: 400px;
$sidebar-gap: 30px;

@media (min-width: $bp-screen-m) {
    .checkoutSectionsWrapper {
        width: calc(100% - $sidebar-width - $sidebar-gap);
    }

    .checkoutSidebarWrapper {
        width: $sidebar-width;
        flex: 1 0 auto;
        margin-left: $sidebar-gap;
    }
}

@media (max-width: $bp-screen-m) {
    .checkoutSidebarWrapper {
        width: 100%;
    }
}

.checkoutPlaceOrderButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkoutSummaryBlock {
    background-color: #F4F4F4;
    padding: 20px;
}

.checkoutTotalsTable {
    margin: 0;
    width: 100%;
}

.checkoutTotalsTable th {
    font-weight: normal;
    padding-right: 10px;
}

.checkoutTotalsTable td {
    text-align: right;
    min-width: 100px;
}

.checkoutTotalsTable .totals.grand {
    border-top: 1px solid #D0D0D0;
}

.checkoutTotalsTable .totals.grand td,
.checkoutTotalsTable .totals.grand th {
    padding-top: 15px;
    font-size: 18px;
}

.checkoutTotalsTable .totals:nth-last-of-type(2) th,
.checkoutTotalsTable .totals:nth-last-of-type(2) td {
    padding-bottom: 15px;
}

.orderSummaryPlaceOrderWrapper .checkoutPlaceOrderButton {
    min-height: 60px;
}

.checkoutOrderReview .checkoutPlaceOrderButton {
    min-height: 40px;
    min-width: 220px;
}

@media (max-width: $bp-screen-m) {
    .checkoutOrderReview .checkoutPlaceOrderButton {
        display: none;
    }
}

.checkoutOrderReview .checkoutPlaceOrderButton .dotsLoader .dot {
    height: 2em;
    width: 2em;
}

.checkoutOrderReview .checkoutPlaceOrderButton .dotsLoader .dot:not(:nth-child(1)) {
    margin-left: 1em;
}

.freeLabel {
    color: #048C49;
}

.dotsLoader {
    display: flex;
    transition: color 150ms ease 0s;
    align-self: center;
    font-size: 4px;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    color: rgb(204, 204, 204);
    padding: 8px;
    box-sizing: border-box;
}

.dotsLoader .dot {
    animation: 1s ease-in-out 0ms infinite normal none running animation-dots;
    background-color: currentcolor;
    border-radius: 1em;
    display: inline-block;
    height: 3em;
    vertical-align: top;
    width: 3em;
    border-radius: 50px;
}

.dotsLoader .dot:nth-child(2) {
    margin-left: 2em;
    animation-delay: 160ms;
}

.dotsLoader .dot:nth-child(3) {
    margin-left: 2em;
    animation-delay: 320ms;
}

@keyframes animation-dots {
    0%, 80%, 100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
}

.checkoutSection + .checkoutSection {
    padding-top: 40px;
    border-top: 2px solid #f1f1f1;
    margin-top: 40px;
}


@media (min-width: $bp-screen-l) {
    .checkoutSection {
        display: flex;
    }
}

.checkoutSectionContent {
    width: 100%;
}

.checkoutSectionTitle {
    width: 230px;
    font-size: 28px;
    flex-shrink: 0;

}

@media (max-width: $bp-screen-l) {
    .checkoutSectionTitle {
        margin-bottom: 20px;
    }
}

.shippingTabsSwitch {
    display: flex;
}

.shippingSwtichButton {
    padding: 13px 20px;
    background: #e3e3e3;
    border: 2px solid #e3e3e3;
    transition: 200ms all;
    min-width: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

@media (max-width: $bp-screen-l) {
    .shippingSwtichButton {
        min-width: auto;
        width: 50%;
    }
}

.shippingSwtichButton i {
    margin-right: 5px;
}

.shippingSwtichButton:hover {
    border-color: #cbcbcb;
}

.shippingSwtichButton.tabActive {
    background: #fff;
    border-color: #006cb1;
}

/* @todo move to @gemaire/product */

.checkoutProductWrapper {
    border-bottom: 1px solid #E9E9E9;
}

.product-item.checkoutProduct {
    border: none;
    margin: 0;
}

.product-item.checkoutProduct .product-inventory_availability {
    display: none!important;
}

.product-item.checkoutProduct .product-item-info {
    grid-template-areas:
        "image name subtotal"
        "image name pricing"
        "image inventory qty"
        "image warrantyInfo orderTemplate"
        "image errorMsg orderTemplate";
    grid-template-columns: 100px auto 200px;
}

@media (max-width: $bp-screen-s - 1px) {
    .product-item.checkoutProduct .product-item-info {
        grid-template-areas:
            "image name name"
            "image inventory inventory"
            "image subtotal qty"
            "image pricing pricing"
            "image errorMsg orderTemplate";
        grid-template-columns: 90px 1fr 1fr;
    }
}

.product-item.checkoutProduct .product-item-list-badge-product-name-wrapper {
    margin-bottom: 10px;
}

@media (min-width: $bp-screen-s) {
    .product-item.checkoutProduct .price-box {
        text-align: right;
    }
}

.product-item.checkoutProduct .checkoutProductQty {
    text-align: right;
}

.product-item.checkoutProduct .price-wrapper.flex-inline {
    display: inline-flex;
}

.product-item.checkoutProduct .checkoutProductQty {
    grid-area: qty;
}

.product-item.checkoutProduct .checkoutProductPrice {
    grid-area: subtotal;
}

.product-item.checkoutProduct .product-pricing-wrapper {
    grid-area: pricing;
}

.product-item.checkoutProduct .product-inventory-wrapper {
    grid-area: inventory;
}

@media (max-width: $bp-screen-s - 1px) {
    .product-item.checkoutProduct .product-inventory-wrapper {
        margin-bottom: 8px;
    }
}

.product-item.checkoutProduct .product-inventory,
.product-item.checkoutProduct .price-box {
    margin: 0;
    align-self: center;
}

.product-item.checkoutProduct .checkoutProductPrice,
.product-item.checkoutProduct .checkoutProductQty {
    margin: 0;
}

.product-item.checkoutProduct .checkoutProductPrice .price {
    font-size: 16px;
}

.product-item.checkoutProduct .product-info-brand,
.product-item.checkoutProduct .product-attributes-wrapper {
    display: none;
}

.product-item.checkoutProduct .product-item-photo {
    width: 100px;
    flex: 0 0 100px;
    margin: 0 20px 0 0;
    vertical-align: top;
}

@media (max-width: $bp-screen-s - 1px) {
    .product-item.checkoutProduct .product-item-photo {
        width: 90px;
    }
}

.product-item.checkoutProduct .product-quickview {
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    right: auto;
    left: 15px;
    top: 9px;
    width: 30px;
    box-shadow: none;
    text-indent: -99999px;
}

.product-item.checkoutProduct .product-quickview span {
    text-indent: 0;
}

.pickupBranchHeadline {
    font-size: 18px;
}

.methodSelectorOption {
    margin-bottom: 8px;
}

.methodSelectorOption label {
    display: inline-block;
    padding-left: 26px;
}

.branchChargesTooltip i {
    color: #006cb1;
    margin-top: -2px;
}

.branchChargesTooltip i:hover {
    color: #005b93;
}

.shippingDiscountRow th {
    padding-left: 15px;
}

.shippingDiscountRow th,
.shippingDiscountRow td {
    opacity: 0.7;
}

.cardConnectContent {
    padding-left: 26px;
}

.selectedCcLabel {
    display: inline-flex;
    align-items: center;
}

.selectedCcLabel svg,
.ccLogo svg {
    display: block;
    border-radius: 5px;
}

.defaultCcLogo {
    width: 40px;
    height: 25px;
    border: 2px solid #006cb1;
    border-radius: 5px;
    position: relative;
}

.defaultCcLogo:after {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 5px;
    background-color: #006cb1;
    left: 0;
    top: 5px;
}

.cardConnectAddCardModal,
.newAddressModal {
    left: 500px;
}

@media (max-width: $bp-screen-m - 1px) {
    .cardConnectAddCardModal,
    .newAddressModal {
        left: 150px;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .cardConnectAddCardModal,
    .newAddressModal {
        left: 80px;
    }
}

.changeAddressModal .modal-content,
.newAddressModal .modal-content,
.cardConnectAddCardModal .modal-content,
.cardConnectEditCardsModal .modal-content {
    position: relative;
    height: 100%;
}

.savedCreditCardsWrapper {
    display: flex;
    flex-wrap: wrap;
}

.securityLockMessage > *:first-child::before {
    content: ''!important;
}

.securityLockMessage svg {
    position: absolute;
    left: 10px;
    top: calc(50% - 10px);
}

.addressBlockCityWrapper span + span:before {
    content: ', ';
    display: inline;
}

.checkoutAddressesBook {
    display: flex;
    flex-wrap: wrap;
}

.addressEditBlock {
    position: relative;
}

.radioButtonSpinner {
    float: left;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 3px solid;
    margin: 3px -19px 3px 2px;
    border-color: #dbdcef;
    border-right-color: #006cb1;
    animation: spinner-d3wgkg 1s infinite linear;
}

@keyframes spinner-d3wgkg {
   to {
      transform: rotate(1turn);
   }
}

.expiredCardItem {
    background: #f4f4f4;
    filter: grayscale(1);
    opacity: 0.6;
}

.addressBookBadge {
    position: absolute;
    right: 10px;
    top: 10px;
}

@media (min-width: $bp-screen-s) {
    .checkoutSectionsWrapper .checkout-order-details .field-row .field {
        width: 25%;
    }
}

.checkoutConfirmationOrderNumber {
    font-size: 20px;
    letter-spacing: 2px;
}

.cartItemsList {
    border-bottom: 1px solid #E9E9E9;
}

.cartProductWrapper {
    border-top: 1px solid #E9E9E9;
    position: relative;
}

.product-item.cartProduct {
    border: none;
    margin-left: 0;
    margin-right: 0;
}

.product-item.cartProduct .product-item-info {
    grid-template-areas:
        "image name subtotal"
        "image attributes pricing"
        "image inventory qty"
        "image warrantyInfo orderTemplate"
        "image errorMsg orderTemplate";
    grid-template-columns: 100px auto 200px;
}

@media (max-width: $bp-screen-s - 1px) {
    .product-item.cartProduct .product-item-info {
        grid-template-areas:
            "image"
            "name"
            "attributes"
            "inventory"
            "subtotal"
            "pricing"
            "qty"
            "errorMsg";
        grid-template-columns: 100%;
    }
}

.cartProductSubtotal {
    grid-area: subtotal;
    margin: 0;
}

.checkoutProductWarrantyInfo {
    grid-area: warrantyInfo;
}

@media (max-width: $bp-screen-s - 1px) {
    .cartProductSubtotal {
        margin-top: 10px;
    }
}

.cartProductQty {
    grid-area: qty;
    margin-top: 15px;
}

.product-item.cartProduct .product-info-brand {
    display: none;
}

.product-item.cartProduct .price-box {
    margin: 0;
}

.product-item.cartProduct .product-attributes-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.product-item.cartProduct .product-attributes-wrapper .product-attribute_value {
    margin-right: 20px;
    display: block;
}

.product-item.cartProduct .product-quickview {
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    right: auto;
    left: 15px;
    top: 9px;
    width: 30px;
    box-shadow: none;
    text-indent: -99999px;
}

.product-item.cartProduct .product-quickview span {
    text-indent: 0;
}

.product-item.cartProduct .price-box.price-final_price {
    margin: 0;
}

.product-item.checkoutProduct .price-box.price-final_price .price,
.product-item.cartProduct .price-box.price-final_price .price {
    font-size: 16px;
    color: #909090;
    white-space: nowrap;
    font-weight: normal;
}

.product-item.cartProduct .stepper--fluid label {
    display: none;
}

.cartProductRemove {
    position: absolute;
    right: 10px;
    top: 13px;
}

.minicartProduct {
    border: none;
    margin-left: 0;
    margin-right: 0;
}

.minicartProduct .product-item-info {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.minicartProduct .product-item-photo {
    width: 100px;
    flex: 0 0 100px;
    margin: 0 20px 0 0;
    vertical-align: top;
}

.minicartProduct .product-item-list-right-column {
    width: 100%;
}

.minicartProduct .product-quickview {
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    right: auto;
    left: 15px;
    top: 9px;
    width: 30px;
    box-shadow: none;
    text-indent: -99999px;
}

.minicartProduct .product-quickview span {
    text-indent: 0;
}

.checkoutAddressField .control {
    display: flex;
}

.checkoutAddressField .control .input-text {
    flex: 1;
    margin-right: 0;
}

.checkoutAddressField .control .tooltip {
    display: flex;
    align-items: center;
    margin-left: 10px;
    color: #0080DF;
}

.saveNewAddressButton {
    display: inline-flex;
    justify-content: center;
    min-height: 42px;
    min-width: 200px;
}

.proceedToCheckoutButton {
    min-width: 200px;
    min-height: 40px;
}

@media (max-width: $bp-screen-m) {
    .cartItemsWrapper .proceedToCheckoutButton {
        display: none;
    }
}

.orderSummaryPlaceOrderWrapper .proceedToCheckoutButton {
    width: 100%;
    white-space: normal;
    font-size: 22px;
    line-height: 30px;
    padding: 15px 30px;
}

.proceedToCheckoutButton .dotsLoader {
    display: flex;
    justify-content: center;
    padding-top: 9px;
    padding-bottom: 9px;
}

.cartProductNotes {
    padding: 15px 10px 0;
}

.cartItemRestriction {
    display: flex;
    align-items: center;
    align-items: flex-start;
}

.cartItemRestriction > i {
    color: #C6B63D;
    margin-right: 5px;
}

.cartItemRestriction.cartItemRestrictionError > i,
.cartItemRestriction.cartItemRestrictionError .cartItemRestrictionTitle span {
    color: #DF3100;
}

.cartItemRestrictionMain {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.cartItemRestrictionTitle {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-weight: bold;
}

.cartProductNotes .cartItemBackorder {
    color: #C6B63D;
}

.rotate {
    animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}

.unauthorizedProductToRemoveItem {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 10px 5px;
}

.couponCodeInput {
    background-color: white;
}

.couponCodeInput.couponError {
    border-color: red;
}

.couponCodeSuccess {
    color: #048C49;
}

.couponCodeInputWrapper {
    position: relative;

    .input-text {
        padding-right: 40px !important;
    }

    .couponCodeButtonWrapper {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);

        button {
            display: flex;
            align-items: center;
        }
    }
}
