/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$bp-filter-offscreen: 1280px !default;

$filter-offscreen-sidebar-width-desktop: 220px !default;
$filter-offscreen-sidebar-width-mobile:  ($liner-base + 220px + $liner-base) !default;


// ==============================================
// Body
// ==============================================

@media (max-width: $bp-filter-offscreen - 1px) {

    [data-ui='filter'] {
        @include prevent-body-scroll;
    }

}


// ==============================================
// Site Overlay
// ==============================================

@media (max-width: $bp-filter-offscreen - 1px) {

    [data-ui='filter'] .site-overlay {
        @include site-overlay-active;
    }

}


// ==============================================
// Filter Offscreen Toggle Button
// ==============================================

.filter-offscreen-toggle-button {
    margin-bottom: $margin-bottom;
}

// ----------------------------------------------

@media (min-width: $bp-filter-offscreen) {

    .filter-offscreen-toggle-button {
        display: none;
    }

}


// ==============================================
// Filter Offscreen
// ==============================================

.filter-offscreen {
    display: flex;
    width: 100%;
}

// ----------------------------------------------

@media print {

    .filter-offscreen {
        display: block;
    }

}


// ==============================================
// Filter Offscreen - Main
// ==============================================

.filter-offscreen_main {
    position: relative;
}

// ----------------------------------------------

@media (max-width: $bp-filter-offscreen - 1px) {

    .filter-offscreen_main {
        width: 100%;
    }

}

// ----------------------------------------------

@media (min-width: $bp-filter-offscreen) {

    .filter-offscreen_main {
        width: calc(100% - #{$filter-offscreen-sidebar-width-desktop});
        padding-right: $liner-l;
    }

}

// ----------------------------------------------

@media print {

    .filter-offscreen_main {
        width: 100%;
        padding-right: 0;
    }

}


// ==============================================
// Filter Offscreen - Sidebar
// ==============================================

@media (max-width: $bp-filter-offscreen - 1px) {

    .filter-offscreen_sidebar {
        @include offscreen-base ($position: 'right', $max-width: $filter-offscreen-sidebar-width-mobile);
        padding: $liner-base;
        background: #FFF;
    }

    [data-ui='filter'] .filter-offscreen_sidebar {
        @include offscreen-active ($position: 'right');
    }

}

// ----------------------------------------------

@media (min-width: $bp-filter-offscreen) {

    .filter-offscreen_sidebar {
        width: $filter-offscreen-sidebar-width-desktop;
    }

}

// ----------------------------------------------

@media print {

    .filter-offscreen_sidebar {
        display: none;
    }

}


// ==============================================
// Filter Offscreen - Sidebar Header
// ==============================================

@media (min-width: $bp-filter-offscreen) {

    .filter-offscreen_sidebar-header {
        display: none;
    }

}


// ==============================================
// Filter Offscreen - Controls
// ==============================================

.filter-offscreen_controls {
    margin-bottom: 15px;
}


// ==============================================
// Filter Offscreen - Date Field
// ==============================================

.filter-offscreen_date-field {
    margin-bottom: 10px;
}

.filter-offscreen_date-field label {
    width: 45px;
}


// ==============================================
// Close Offscreen Button
// ==============================================

@media (min-width: $bp-filter-offscreen) {

    .filter-offscreen .close-offscreen-button {
        display: none;
    }

}
