/**
* Gemaire Frontend
*
* @package    enterprise_gemaire
* @copyright  Copyright 2019 Gemaire Distributors
* @license    All rights reserved.
*/


/* stylelint-disable declaration-no-important */


// ==============================================
// Margin
// ==============================================

.m-0 { margin: 0 !important; }

// ----------------------------------------------
// Top

.m-t-0    { margin-top: 0                    !important; }
.m-t-1    { margin-top: ($margin-bottom * 1) !important; }
.m-t-2    { margin-top: ($margin-bottom * 2) !important; }
.m-t-3    { margin-top: ($margin-bottom * 3) !important; }
.m-t-4    { margin-top: ($margin-bottom * 4) !important; }


// ----------------------------------------------
// Right

.m-r-0    { margin-right: 0                    !important; }
.m-r-1    { margin-right: ($margin-bottom * 1) !important; }
.m-r-2    { margin-right: ($margin-bottom * 2) !important; }
.m-r-3    { margin-right: ($margin-bottom * 3) !important; }
.m-r-4    { margin-right: ($margin-bottom * 4) !important; }


// ----------------------------------------------
// Bottom

.m-b-0    { margin-bottom: 0                    !important; }
.m-b-1    { margin-bottom: ($margin-bottom * 1) !important; }
.m-b-2    { margin-bottom: ($margin-bottom * 2) !important; }
.m-b-3    { margin-bottom: ($margin-bottom * 3) !important; }
.m-b-4    { margin-bottom: ($margin-bottom * 4) !important; }


// ----------------------------------------------
// Left

.m-l-0    { margin-left: 0                    !important; }
.m-l-1    { margin-left: ($margin-bottom * 1) !important; }
.m-l-2    { margin-left: ($margin-bottom * 2) !important; }
.m-l-3    { margin-left: ($margin-bottom * 3) !important; }
.m-l-4    { margin-left: ($margin-bottom * 4) !important; }


// ----------------------------------------------
// X (Right + Left)

.m-x-0 {
    margin-right: 0 !important;
    margin-left:  0 !important;
}

.m-x-1 {
    margin-right: ($margin-bottom * 1) !important;
    margin-left:  ($margin-bottom * 1) !important;
}

.m-x-2 {
    margin-right: ($margin-bottom * 2) !important;
    margin-left:  ($margin-bottom * 2) !important;
}

.m-x-3 {
    margin-right: ($margin-bottom * 3) !important;
    margin-left:  ($margin-bottom * 3) !important;
}

.m-x-4 {
    margin-right: ($margin-bottom * 4) !important;
    margin-left:  ($margin-bottom * 4) !important;
}


// ----------------------------------------------
// Y (Top + Bottom)

.m-y-0 {
    margin-top:    0 !important;
    margin-bottom: 0 !important;
}

.m-y-1 {
    margin-top:    ($margin-bottom * 1) !important;
    margin-bottom: ($margin-bottom * 1) !important;
}

.m-y-2 {
    margin-top:    ($margin-bottom * 2) !important;
    margin-bottom: ($margin-bottom * 2) !important;
}

.m-y-3 {
    margin-top:    ($margin-bottom * 3) !important;
    margin-bottom: ($margin-bottom * 3) !important;
}

.m-y-4 {
    margin-top:    ($margin-bottom * 4) !important;
    margin-bottom: ($margin-bottom * 4) !important;
}


// ----------------------------------------------
// Top

.m-t-xxxl { margin-top: $space-xxxl !important; }
.m-t-xxl  { margin-top: $space-xxl  !important; }
.m-t-xl   { margin-top: $space-xl   !important; }
.m-t-l    { margin-top: $space-l    !important; }
.m-t-base { margin-top: $space-base !important; }
.m-t-s    { margin-top: $space-s    !important; }
.m-t-xs   { margin-top: $space-xs   !important; }
.m-t-xxs  { margin-top: $space-xxs  !important; }
.m-t-xxxs { margin-top: $space-xxxs !important; }
.m-t-0    { margin-top: 0           !important; }
.m-t-auto { margin-top: auto        !important; }

// ----------------------------------------------
// Right

.m-r-xxxl { margin-right: $space-xxxl !important; }
.m-r-xxl  { margin-right: $space-xxl  !important; }
.m-r-xl   { margin-right: $space-xl   !important; }
.m-r-l    { margin-right: $space-l    !important; }
.m-r-base { margin-right: $space-base !important; }
.m-r-s    { margin-right: $space-s    !important; }
.m-r-xs   { margin-right: $space-xs   !important; }
.m-r-xxs  { margin-right: $space-xxs  !important; }
.m-r-xxxs { margin-right: $space-xxxs !important; }
.m-r-0    { margin-right: 0           !important; }
.m-r-auto { margin-right: auto        !important; }

// ----------------------------------------------
// Bottom

.m-b-xxxl { margin-bottom: $space-xxxl !important; }
.m-b-xxl  { margin-bottom: $space-xxl  !important; }
.m-b-xl   { margin-bottom: $space-xl   !important; }
.m-b-l    { margin-bottom: $space-l    !important; }
.m-b-base { margin-bottom: $space-base !important; }
.m-b-s    { margin-bottom: $space-s    !important; }
.m-b-xs   { margin-bottom: $space-xs   !important; }
.m-b-xxs  { margin-bottom: $space-xxs  !important; }
.m-b-xxxs { margin-bottom: $space-xxxs !important; }
.m-b-0    { margin-bottom: 0           !important; }
.m-b-auto { margin-bottom: auto        !important; }

// ----------------------------------------------
// Left

.m-l-xxxl { margin-left: $space-xxxl !important; }
.m-l-xxl  { margin-left: $space-xxl  !important; }
.m-l-xl   { margin-left: $space-xl   !important; }
.m-l-l    { margin-left: $space-l    !important; }
.m-l-base { margin-left: $space-base !important; }
.m-l-s    { margin-left: $space-s    !important; }
.m-l-xs   { margin-left: $space-xs   !important; }
.m-l-xxs  { margin-left: $space-xxs  !important; }
.m-l-xxxs { margin-left: $space-xxxs !important; }
.m-l-0    { margin-left: 0           !important; }
.m-l-auto { margin-left: auto        !important; }

// ----------------------------------------------
// X (Right + Left)

.m-x-xxl {
    margin-right: $space-xxxl !important;
    margin-left:  $space-xxxl !important;
}

.m-x-xxl {
    margin-right: $space-xxl !important;
    margin-left:  $space-xxl !important;
}

.m-x-xl {
    margin-right: $space-xl !important;
    margin-left:  $space-xl !important;
}

.m-x-l {
    margin-right: $space-l !important;
    margin-left:  $space-l !important;
}

.m-x-l-negative {
    margin-right: -$space-l !important;
    margin-left:  -$space-l !important;
}

.m-x-base {
    margin-right: $space-base !important;
    margin-left:  $space-base !important;
}

.m-x-base-negative {
    margin-right: -$space-base !important;
    margin-left:  -$space-base !important;
}

.m-x-s {
    margin-right: $space-s !important;
    margin-left:  $space-s !important;
}

.m-x-s-negative {
    margin-right: -$space-s !important;
    margin-left:  -$space-s !important;
}

.m-x-xs {
    margin-right: $space-xs !important;
    margin-left:  $space-xs !important;
}

.m-x-xs-negative {
    margin-right: -$space-xs !important;
    margin-left:  -$space-xs !important;
}

.m-x-xxs {
    margin-right: $space-xxs !important;
    margin-left:  $space-xxs !important;
}

.m-x-xxs-negative {
    margin-right: -$space-xxs !important;
    margin-left:  -$space-xxs !important;
}

.m-x-xxxs {
    margin-right: $space-xxxs !important;
    margin-left:  $space-xxxs !important;
}

.m-x-0 {
    margin-right: 0 !important;
    margin-left:  0 !important;
}

.m-x-auto {
    margin-right: auto !important;
    margin-left:  auto !important;
}

// ----------------------------------------------
// Y (Top + Bottom)

.m-y-xxl {
    margin-top:    $space-xxxl !important;
    margin-bottom: $space-xxxl !important;
}

.m-y-xxl {
    margin-top:    $space-xxl !important;
    margin-bottom: $space-xxl !important;
}

.m-y-xl {
    margin-top:    $space-xl !important;
    margin-bottom: $space-xl !important;
}

.m-y-l {
    margin-top:    $space-l !important;
    margin-bottom: $space-l !important;
}

.m-y-base {
    margin-top:    $space-base !important;
    margin-bottom: $space-base !important;
}

.m-y-s {
    margin-top:    $space-s !important;
    margin-bottom: $space-s !important;
}

.m-y-xs {
    margin-top:    $space-xs !important;
    margin-bottom: $space-xs !important;
}

.m-y-xxs {
    margin-top:    $space-xxs !important;
    margin-bottom: $space-xxs !important;
}

.m-y-xxxs {
    margin-top:    $space-xxxs !important;
    margin-bottom: $space-xxxs !important;
}

.m-y-0 {
    margin-top:    0 !important;
    margin-bottom: 0 !important;
}

.m-y-auto {
    margin-top:    auto !important;
    margin-bottom: auto !important;
}
