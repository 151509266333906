/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Customer Entry - Grid
//
// Shared by:
//  - .customer-forgot-password-grid
//  - .customer-login-grid
//  - .customer-register-grid
//  - .customer-register-existing-grid
// ==============================================

.customer-entry-grid > .col-1 {
    margin-bottom: ($margin-bottom * 2);
}

.customer-entry-grid > .col-2 {
    margin-bottom: ($margin-bottom * 2);
}

//.customer-entry-aside {}

// ----------------------------------------------

@media (min-width: 950px) {

    .customer-entry-grid > .col-1 {
        float: left;
        width: 60%;
        margin-bottom: 0;
        padding-right: $gap;
    }

    .customer-entry-grid > .col-2 {
        float: right;
        width: 40%;
        margin-bottom: 0;
        padding-left: 5%;
    }

}
