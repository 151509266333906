/**
* Gemaire Distributing
*/


// ==============================================
// Counter
// ==============================================

.counter {
    padding: 2px 8px;
    border-radius: $radius-base;
    background: $c-library-gray-1;
    color: $c-library-gray-7;
    font-size: $font-size-xs;
    white-space: nowrap;
}