/**
* Gemaire Distributing
*/


// ==============================================
// Float
// ==============================================

.float-left  { float: left  !important; }
.float-right { float: right !important; }
