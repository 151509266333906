// ==============================================
// Header Vars
// ==============================================

$c-header-primary-background: $c-blue-base !default;

$header-account-offscreen-width: 360px !default;
$header-cart-offscreen-width: 510px !default;
$header-nav-offscreen-width: 340px !default;
$header-wishlist-offscreen-width: 360px !default;
$header-icons-color: $c-white;
