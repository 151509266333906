/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2018 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Compare Block
// ==============================================

.compare-block {
    padding: $liner-base 20px;
    border-bottom: 1px solid $c-border;
    background: #D9ECFA;
}


// ==============================================
// Compare List
// ==============================================

.compare-list {
    margin-bottom: 1em;
    border: 1px solid $c-border;
    border-radius: $radius-s;
    background: #FFF;
}

.compare-list .item {
    padding: 10px;
    border-bottom: 1px solid $c-border;
    font-size: $font-size-xs;

    &:last-child {
        border-bottom: 0;
    }
}

.compare-list .product-name {
    display: inline-block;
    margin-bottom: 10px;
}

//.compare-remove-link {}

// ----------------------------------------------

@media (min-width: 480px) {

    .compare-list .item {
        position: relative;
        min-height: (10px + 30px + 10px);
        padding-right: (10px + 80px + 10px);
    }

    .compare-remove-link {
        position: absolute;
        top: 10px;
        right: 10px;
    }

}


// ==============================================
// Compare Actions
// ==============================================

.compare-actions {
    overflow: hidden;
}


// ==============================================
// Compare Clear Link
// ==============================================

.compare-clear-link {
    float: right;
}
