/**
* Gemaire Distributing
*/


// ==============================================
// Credit Card Types
// ==============================================

.credit-card-types {
    padding: 0;
}

.credit-card-types .item {
    display: inline-block;
    list-style: none;
    margin: 0 $space-xs 0 0;
    vertical-align: top;
}

.credit-card-types .item._active {
    font-weight: $font-weight-bold;
}

.credit-card-types .item._inactive {
    opacity: 0.4;
}

.credit-card-types .item span {
    display: inline-block;
    padding-top: 6px;
    vertical-align: top;
}

.credit-card-types img {
    filter: grayscale(100%); // For Webkit browsers
    transition: all 0.6s ease; // Fade to color for Chrome and Safari
}

.credit-card-types ._active img {
    filter: grayscale(0%);
}
