/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Data Table
// ==============================================

.data-table {
    width: 100%;
    margin-bottom: $margin-bottom;
    font-size: $font-size-s;
}

.data-table td,
.data-table th {
    padding: 8px;
    border: 1px solid $c-table-border;
    vertical-align: top;
}

.data-table th {
    background: $c-table-background;
    font-weight: bold;
}

.data-table tfoot tr {
    background: $c-table-background;
}

// ==============================================
// Data Table Definition List
// ==============================================

@media (max-width: $bp-screen-s - 1px) {

    .data-table-definition-list thead {
        display: none;
    }

    .data-table-definition-list tbody th {
        padding-bottom: 0;
    }

    .data-table-definition-list tbody th,
    .data-table-definition-list tbody td {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }

}

// ----------------------------------------------

@media print {

    .data-table td,
    .data-table th {
        padding: 4px;
    }

}
