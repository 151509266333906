/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2018 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$c-input-placeholder: #A0A0A0 !default;


// ==============================================
// Input Placeholder
// See: https://css-tricks.com/almanac/selectors/p/placeholder/
// ==============================================

::placeholder {
    color: $c-input-placeholder;
    font-size: 16px; // Prevent iOS zoom on focus.
}

// ----------------------------------------------
// Modifier: Font Size

.input-placeholder--s::placeholder {
    font-size: $font-size-xxs;
}
