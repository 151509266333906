//
//  Utilities
//  _____________________________________________

@mixin lib-visibility-hidden() {
    height: 0;
    visibility: hidden;
}

@mixin lib-visually-hidden() {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin lib-visually-hidden-reset() {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

//
//  Clearfix
//  ---------------------------------------------

@mixin lib-clearfix() {
    &::before,
    &::after {
        content: '';
        display: table;
    }

    &::after {
        clear: both;
    }
}

//
//  Add url to property
//  ---------------------------------------------

// usage:
// .column.left {
//     .lib-url('images/fallback.png');
//     background:url("#{$url}") repeat-y;
// }
// .columns {
//     .lib-url('images/fallback.png', 'Magento_Catalog');
//     background:url("#{$url}") repeat;
// }

$urls-resolved: false;

@mixin lib-url($_path) {
    $url: "../#{$_path}";
}

@mixin lib-url($_path, $_module) {
    @if $_module != false and $_module != '' {
        @if $urls-resolved == true {
            $url: "../#{$_module}/#{$_path}";
        }
        @else if $urls-resolved == false {
            $url: "#{$_module}::#{$_path}";
        }
    }
}

//
//  Arrow
//  ---------------------------------------------

@mixin lib-arrow($_position, $_size, $_color) {
    border: $_size solid transparent;
    height: 0;
    width: 0;
    @include _lib-abbor_el($_position, $_color);
}

@mixin _lib-abbor_el($_position, $_color) {
    @if $_position == left {
        border-right-color: $_color;
    }
    @if $_position == right {
        border-left-color: $_color;
    }
    @if $_position == up {
        border-bottom-color: $_color;
    }
    @if $_position == down {
        border-top-color: $_color;
    }
}
