/**
* Gemaire Distributing
*/

$products-list-gutter-s: $space-l;
$products-list-border-color: $c-border;

// ==============================================
// Products List > Product Item
// ==============================================

.product-item-list-badge-product-name-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 100%;
}

@media (min-width: $bp-screen-s) {

    .products-list .product-item {
        width: 100%;
        margin-bottom: $products-list-gutter-s;
        background: $c-white;
    }

    .products-list .product-attribute {
        display: flex;
        flex-wrap: wrap;
    }

    .products-list {
        .product-attribute_value {

            &.sku,
            &.number,
            &.mfg {
                padding-right: 20px;
            }
        }
    }

    .products-list .product-item-actions {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .products-list .product-item-info {
        display: flex;
        flex-wrap: nowrap;
    }

    .products-list .product-item-photo {
        flex: 0 0 200px;
        margin: 0 $space-l 0 0;
        vertical-align: top;
    }

    .products-list .product-item-details {
        flex: 1 1 auto;
        vertical-align: top;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .products-list .product-item-description {
        margin-top: $space-base;
        font-weight: $font-weight-light;
        display: none;
    }

    .products-list .product-item-description a.more {
        font-weight: $font-weight-normal;
    }

    .products-list .actions-primary {
        flex: 0 1 250px;
    }

    .products-list .actions-secondary {
        flex-basis: 180px;
        flex-grow: 1;
        flex-shrink: 0;
        display: flex;
    }

    .products-list .block-requisition-list {
        order: 1;
        margin-right: 16px;
    }

    .products-list .tocompare {
        order: 2;
    }

    .products-list .product-item .actions-secondary .tocompare {
        display: block;
        float: left;
        margin-top: 8px;
    }

    .products-list .product-item-list-left-column {
        flex-basis: 800px;
        margin-right: 20px;
    }

    .products-list .product-item-list-right-column {
        flex-basis: 220px;
        flex-shrink: 0;
    }

}

// ----------------------------------------------

@media (max-width: $bp-screen-l - 1px) {

    .products-list td.subtotal,
    .products-list th.subtotal,
    .products-list thead {
        display: none;
    }

}

@media (max-width: $bp-screen-m - 1px) {

    .products-list .block-requisition-list {
        margin-left: 0;
    }

    .modal-content {

        .products-list .product-item-details {
            display: block;
        }

    }

}

@media (min-width: $bp-screen-l) {
    .products-list .product-item-name {
        font-size: 20px;
        margin-bottom: 15px;
    }
}

@media (max-width: $bp-screen-s - 1px) {

    .products-list .product-item-details {
        display: block;
    }

    .products-list .product-item-photo {
        flex-basis: 160px;
        flex-shrink: 0;
    }

}

@media (max-width: $bp-screen-xs - 1px) {
    .products-list .product-item-name {
        margin-bottom: 15px;
    }

    .products-list .product-inventory {
        margin: 0 -15px;
        padding: 15px;
        border-top: 1px solid $c-emphasis-background;
    }

    .products-list .product-item-photo {
        margin-bottom: $space-base;
    }
}
