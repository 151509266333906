/**
* Gemaire Frontend
*
* @author    Brendan Falkowski (http://gravitydept.com)
* @package   enterprise_gemaire
* @copyright Copyright 2016 Gravity Department
* @license   All rights reserved.
*/


// **********************************************
// The z-index reference helps track the layering of elements.
// Whenever a z-index is added or changed, it must be documented.
// Otherwise the z-index arms race gets out of control.
// **********************************************


// ==============================================
// Negatives
// ==============================================

// Header faux-backgrounds that fall behind all header elements
$z-header-primary:   -1 !default;
$z-header-secondary: -1 !default;


// ==============================================
// 0 - Content
// ==============================================

$z-owl-carousel:         1 !default;
$z-owl-carousel-buttons: 2 !default;

$z-gravdept-select: 20;

$z-product-grid-vline:   2 !default;
$z-product-image-border: 2 !default;

$z-orb-level-2: 2 !default;
$z-orb-level-3: 3 !default;

$z-cloudzoom-lens: 5 !default;
$z-cloudzoom-zoom: 5 !default;

$z-input-select: 10 !default;

$z-tooltip: 30 !default;

$z-content-loading-backdrop: 50 !default;


// ==============================================
// 100 - Offscreen
// ==============================================

$z-offscreen-backdrop: 100 !default;
$z-offscreen:          101 !default;


// ==============================================
// 200 - Menu
// ==============================================

$z-menu:              200 !default;
$z-menu-arrow-stroke: 201 !default;
$z-menu-arrow-fill:   202 !default;


// ==============================================
// 300 - Modal
// ==============================================

$z-modal-backdrop: 300 !default;
$z-modal-dialog:   301 !default;

// ==============================================
// 1000 - Modal
// ==============================================

$z-modal-backdrop: 1001; // .modal-backdrop
$z-modal:          1001; // .modal

// ==============================================
// 1000 - Notify
// ==============================================

$z-notify: 1100 !default;
