/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2017 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

// If changed, also change JS.
// See: /js/src/app-ui.js
$bp-tab-accordion: 800px !default;


// ==============================================
// Tab Accordion
// ==============================================

.tab-accordion {
    margin-bottom: $gap;
}

// ----------------------------------------------

@media (max-width: $bp-tab-accordion - 1px) {

    .tab-accordion {
        overflow: hidden;
    }

}


// ==============================================
// Tab Accordion - Nav
// ==============================================

.tab-accordion_nav {
    padding-right: $liner-base;
    padding-left:  $liner-base;

    &::after {
        @include clearfix;
    }
}

// ----------------------------------------------

@media (max-width: $bp-tab-accordion - 1px) {

    .tab-accordion_nav {
        display: none;
    }

}

// ----------------------------------------------

@media (min-width: $bp-tab-accordion) {

    .tab-accordion_nav {
        border-bottom: 1px solid $c-border;
    }

}

// ----------------------------------------------

@media (min-width: $bp-page-focal) {

    .tab-accordion_nav {
        padding-right: $liner-l;
        padding-left:  $liner-l;
    }

}


// ==============================================
// Tab Accordion - Nav Link
// ==============================================

.tab-accordion_nav-link {
    position: relative;
    float: left;
    display: block;
    padding: 0.8em 1em;
    border-top: 1px solid $c-border;
    border-right: 1px solid $c-border;
    background: $c-background;
    color: $c-text-base;
    font-size: $font-size-s;
    line-height: $line-height-xs;
    text-align: center;
    text-decoration: none;
    user-select: none;

    &:first-child {
        border-left: 1px solid $c-border;
        border-top-left-radius: 3px;
    }

    &:last-child {
        border-top-right-radius: 3px;
    }
}

.no-touchevents .tab-accordion_nav-link {
    &:hover {
        background: $c-border;
        color: $c-text-base;
    }
}

// ----------------------------------------------
// State: Is Active

.tab-accordion_nav-link.is-active {
    background: #FFF;
    color: $c-action-default-base;
    cursor: default;

    &:first-child {
        border-top-left-radius: 0;
    }

    &:last-child {
        border-top-right-radius: 0;
    }

    // Highlight the top edge
    &::before {
        content: '';
        position: absolute;
        top: -4px;
        left: -1px; // Cover border
        width: calc(1px + 100% + 1px); // Include borders
        height: 4px;
        border-radius: 4px 4px 0 0;
        background: $c-action-default-base;
    }

    // Hide the bottom border
    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background: #FFF;
    }
}

.no-touchevents .tab-accordion_nav-link.is-active {
    &:hover {
        background: #FFF;
        color: $c-action-default-base;
    }
}


// ==============================================
// Tab Accordion - Section
// ==============================================

.tab-accordion_section {
    background: #FFF;
}

// ----------------------------------------------

@media (min-width: $bp-tab-accordion) {

    .tab-accordion_section {
        display: none;

        &.is-active {
            display: block;
        }
    }

}


// ==============================================
// Tab Accordion - Title
// ==============================================

@media (max-width: $bp-tab-accordion - 1px) {

    .tab-accordion_title {
        position: relative;
        margin-bottom: 0;
        padding: 15px $liner-base 15px ($liner-base + 11px + 10px);
        border-top: 1px solid $c-offset-border;
        background: $c-offset-background;
        color: #FFF;
        font-size: $font-size-l;
        font-weight: normal;
        cursor: pointer;

        // Arrow
        &::before {
            @include svg (arrow-11-down-light);
            content: '';
            position: absolute;
            top: 50%;
            left: $liner-base;
            width: 11px;
            height: 11px;
            margin-top: calc(11px / -2);
            transform: rotate(-90deg);
            transition: transform 150ms linear;
        }
    }

    .no-touchevents .tab-accordion_title {
        &:hover {
            background: $c-offset-border;
        }
    }

    .tab-accordion_section:first-child .tab-accordion_title {
        border-bottom: 1px solid $c-offset-border;
    }

    .tab-accordion_section:last-child:not(.is-active) .tab-accordion_title {
        border-bottom: 0;
    }

    // ----------------------------------------------
    // State: Is Active

    .tab-accordion_section.is-active .tab-accordion_title {
        // Arrow
        &::before {
            transform: rotate(0deg);
        }
    }

}

// ----------------------------------------------

@media (min-width: $bp-tab-accordion) {

    .tab-accordion_title {
        display: none;
    }

}


// ==============================================
// Tab Accordion - Body
// ==============================================

.tab-accordion_body {
    padding: $gap $liner-base;
}

.tab-accordion_body > *:last-child {
    margin-bottom: 0;
}

// ----------------------------------------------

@media (max-width: $bp-tab-accordion - 1px) {

    .tab-accordion_body {
        display: none;
    }

    // ----------------------------------------------
    // State: Is Active

    .tab-accordion_section.is-active .tab-accordion_body {
        display: block;
        border-top: 1px solid $c-offset-border;
    }

}

// ----------------------------------------------

@media (min-width: $bp-page-focal) {

    .tab-accordion_body {
        padding-right: $liner-l;
        padding-left:  $liner-l;
    }

}
