/**
* Gemaire Distributing
*/

// Completion Percent

svg.completion-percent {
    position: relative;
    margin-left: auto;
    margin-right: 30px;
}

svg.completion-percent--centered {
    display: block;
    margin: 0 auto;
}

.completion-percent_background,
.completion-percent_progress{
    fill: none;
}

.completion-percent_background{
    stroke: #ddd;
}

.completion-percent_progress{
    stroke-linecap: butt;
    stroke-linejoin: miter;
}

.completion-percent_text{
    font-weight: bold;
}
