/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Promos
// ==============================================

.promo {
    overflow: hidden;
    margin-bottom: 20px;
}

.promo-item {
    margin-bottom: 20px;
}

.promo-link {
    position: relative;
    display: block;
    border-radius: $radius-s;
    background: #D0D0D0;
    color: $c-action-default-base;
}

.no-touchevents .promo-link:hover::before {
    content: '';
    position: absolute;
    z-index: $z-product-image-border;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 5px solid rgba($c-action-danger-base, 0.5);
    border-radius: $radius-s;
}

.promo-details {
    position: absolute;
    top: 15%;
    left: 5%;
}

.promo-tagline {
    font-size: $font-size-xxs;
    text-transform: uppercase;
}

.promo-title {
    margin-bottom: 15px;
    font-size: 26px;
    font-weight: 300;
    line-height: 1;
}

.promo-image {
    width: 100%;
    border-radius: $radius-s;
}

// ---------------------------------------------

@media (min-width: 675px) {

    .promo-item {
        float: left;
        width: 50%;
    }

    .promo-item:nth-child(odd) .promo-link {
        margin-right: 10px;
    }

    .promo-item:nth-child(even) .promo-link {
        margin-left: 10px;
    }

    .promo-title {
        font-size: 26px;
    }

}

// ---------------------------------------------

@media (min-width: 1024px) {

    .promo-title {
        font-size: 32px;
    }

}

// ---------------------------------------------

@media (min-width: 1200px) {

    .promo-title {
        font-size: 48px;
    }

}
