/**
* Gemaire Frontend
*
* @package    enterprise_gemaire
* @copyright  Copyright 2019 Gemaire Distributors
* @license    All rights reserved.
*/


/* stylelint-disable declaration-no-important */


// ==============================================
// Text Align
// ==============================================

.a-center { text-align: center  !important; }
.a-left   { text-align: left    !important; }
.a-right  { text-align: right   !important; }


// ==============================================
// Vertical Align
// ==============================================

.va-bottom { vertical-align: bottom !important; }
.va-middle { vertical-align: middle !important; }
.va-top    { vertical-align: top    !important; }
