/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Newsletter - Subscribe
// ==============================================

//.subscribe-form {}

.subscribe-form label {
    margin-bottom: 5px;
    font-family: $font-family-special;
    font-size: 28px;
    display: inline-block;
    font-weight: 300;
    line-height: 1.4;
}

.subscribe-form .input-text {
    border: 1px solid $c-border;
    padding: 0 10px;
    &:focus {
        border-color: $c-input-border-focus;
    }
}

.subscribe-form .input-text.mage-error {
    border-color: $c-danger;
}


.subscribe-form #newsletter-error {
    padding: 3px 8px;
    border-radius: $radius-s;
    background: $c-danger;
    color: #FFF;
}

// ----------------------------------------------
@media (min-width: $bp-screen-l) {

    .subscribe-form label {
        font-size: 27px;
        line-height: 1.5;
    }

}

@media (max-width: $bp-screen-xs - 1px) {
    .subscribe-form label {
        font-size: 24px;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-xs) {

    .subscribe-liner {
        display: flex;
        align-items: flex-start;
    }

    .subscribe-liner_inner {
        flex: 1;
        margin-bottom: 0;
    }

    //.subscribe-form .button {}

}
