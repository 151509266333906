/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2014 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Product Inventory
// ==============================================

.product-inventory {
    display: block;
    position: relative;
    color: $c-input-border-contrast;
    font-size: $font-size-s;
    line-height: 22px;
    font-weight: 400;

    &:hover,
    &:focus {
        color:inherit;
    }
}


// ==============================================
// Product Inventory - Title
// ==============================================

.product-inventory_title {
    position: relative;
    font-size: $font-size-xxs;
    font-weight: bold;
}


// ==============================================
// Product Inventory - Details
// ==============================================

.product-inventory_details {
    position: absolute;
    top: 5px;
    right: 8px;
    color: $c-link-base;
}

.product-inventory:hover .product-inventory_details {
    color: $c-link-hover;
}


// ==============================================
// Product Inventory - Please Wait
// ==============================================

.product-inventory .please-wait {
    display: none;
}

.product-inventory.is-loading .please-wait {
    display: block;
    font-size: $font-size-xxs;
    line-height: (2 * 17px);
}


// ==============================================
// Product Inventory - Unit
// ==============================================

.product-inventory_unit {
    position: relative;
    padding-left: 40px;
}

.product-inventory_unit .count {
    position: absolute;
    left: 0;
}

[data-inventory-status='in-stock'] .count {
    color: $c-success;
}

[data-inventory-status='out-of-stock'] .count {
    color: $c-danger;
}

//.product-inventory_unit .label {}

// ----------------------------------------------
// State: Is Loading

.product-inventory.is-loading .product-inventory_unit {
    display: none;
}
