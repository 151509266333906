/**
* Gemaire Distributing
*/


// ==============================================
// Bundle Actions
// ==============================================

.bundle-actions {
    margin: $space-base 0;
}


// ==============================================
// Bundle Options Container
// ==============================================

.bundle-options-container {
    clear: both;
    margin-bottom: $space-xxl;
}

.bundle-options-container .legend.title {
    @include h2;
}

.bundle-options-container .product-add-form {
    display: none;
}

.bundle-options-container .product-options-wrapper {
    margin-bottom: $space-xxl;
}

.bundle-options-container .action.back {
    margin-bottom: $space-xxl;
}

// [todo] change to modifier of price-box
.bundle-options-container .price-box .price {
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
}

.bundle-options-container .price-notice {
    @extend .abs-adjustment-incl-excl-tax;
}

.fieldset-bundle-options .price-notice {
    display: none;
}

.bundle-options-container .block-bundle-summary {
    padding: $space-base $space-l;
    background: $c-gray-slate;
}

.bundle-options-container > .title > strong {
    @include h2;
}

.bundle-options-container .bundle-summary {
    margin-top: $space-xxl;
}

.bundle-options-container .bundle-summary.empty {
    display: none;
}

.bundle-options-container .bundle-summary > .subtitle {
    @include h3;
    display: block;
}

.bundle-options-container .bundle.items > li {
    margin-bottom: $space-base;
}

.bundle-options-container .box-tocart .actions {
    display: inline-block;
    margin-bottom: $space-base;
}

.bundle-options-container .nested .field.qty {
    margin-top: $form-field__vertical-indent;
}

.bundle-options-container .nested .field.qty .label {
    display: inline-block;
    margin: 0 0 $space-xs;
    font-weight: $form-field-label__font-weight;
}

.bundle-options-container p.required {
    color: $form-field-label-asterisk__color;
}

.fieldset-bundle-options {

    .cards_item {
        flex-basis: 100%;

        img {
            max-width: 60px;
        }

        .qty-holder {
            position: absolute;
            right: 20px;
            bottom: 15px;
        }
    }    
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .fieldset-bundle-options {

        .cards_item {            
            flex-basis: calc(50% - 30px);

            img {
                max-width: 120px;
            }
        }    
    }

    .bundle-options-container .legend.title {
        margin-left: 0;
    }

    .bundle-options-container .bundle-options-wrapper,
    .bundle-options-container .product-options-wrapper {
        float: left;
        width: 57%;
    }

    .bundle-options-container .block-bundle-summary {
        float: right;
        position: relative;
        width: 40%;
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .bundle-options-container .bundle-options-wrapper,
        .bundle-options-container .block-bundle-summary {
            width: 48%;
        }
    }

}

@media (min-width: $bp-screen-l) {

    .fieldset-bundle-options {
        .cards_item {            
            flex-basis: calc(33.33333% - 30px);
        }    
    }
}
