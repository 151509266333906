/**
* Gemaire Distributing
*/


// ==============================================
// Vars
// ==============================================

$dashboard-grid-gap: 40px;
$dashboard-grid-col-2-width: 280px;


// ==============================================
// Dashboard Accounts
// ==============================================

.dashboard-section_accounts {
    margin-bottom: $space-xxl;
}

.dashboard-accounts {
    display: flex;
    flex-wrap: wrap;
    margin-top: -15px;
    margin-left: -15px;
}

.dashboard-accounts_item {
    margin-top: $space-base;
    margin-left: $space-base;
    padding: $space-s;
    border: 1px solid $c-border;
    border-radius: $radius-base;
    background: $c-white;
}

.dashboard-accounts_item p {
    margin-bottom: 10px;
}

@media (max-width: $bp-screen-s - 1px) {
    .dashboard-accounts_item {
        width: calc(50% - 16px);
    }
}

// ==============================================
// Dashboard GemPay
// ==============================================

.dashboard-section_gempay {
    flex: 0 1 250px;
    max-width: 250px;
    height: 132px;
    align-self: flex-end;
    position: relative;
    margin-bottom: $space-xxl;
}

.dashboard-gempay {
    padding: 26px $space-base;
    transition: $transition-all;
    box-shadow: $box-shadow-base;
}

.dashboard-gempay img {
    margin: 0 auto;
}

.dashboard-section_gempay a {
    color: $c-text-base;
}

.dashboard-section_gempay a:hover {
    text-decoration: none;
}

a:hover .dashboard-gempay {
    box-shadow: $box-shadow-base;
}

.dashboard-gempay--flag {
    position: absolute;
    right: 0;
    top: 0;
}

// ==============================================
// Dashboard Account Profile
// ==============================================

.dashboard-section_accountprofile {
    margin-bottom: $space-xxl;
}

//.dashboard-accountprofile {}

.dashboard-accountprofile_item {
    padding: 20px;
    border: 1px solid $c-border;
    background: $c-white;
    text-align: center;
}

.dashboard-accountprofile_item p {
    margin-bottom: 10px;
    text-align: center;
}
.dashboard-accountprofile_item svg { 
    margin-bottom: 5px;
}

// ==============================================
// Dashboard Grid
// ==============================================

@media (min-width: $bp-screen-l) {

    .dashboard-grid {
        position: relative;
    }

    .dashboard-grid .col-1 {
        float: left;
        width: calc(100% - #{($dashboard-grid-gap + $dashboard-grid-col-2-width)});
        padding-right: $dashboard-grid-gap;
    }

    .dashboard-grid .col-2 {
        width: ($dashboard-grid-gap + $dashboard-grid-col-2-width);
        float: right;
    }
}


// ==============================================
// Dashboard Section
// ==============================================

.account .dashboard-section {
    margin-bottom: $space-xxl;
}

.dashboard-section--top {
    display: flex;
    flex-wrap: wrap;
}


// ==============================================
// Dashboard Quick Lists
// ==============================================

//.dashboard-quick-lists {}

.dashboard-quick-lists_item {
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > a {
        color: $c-text-base;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
