/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// File Icon
// ==============================================

.file-icon {
    @include svg (file-default);
    display: inline-block;
    width: 24px;
    height: 24px;
}

a:hover .file-icon {
    opacity: 0.8;
}

// ----------------------------------------------
// Modifier: Type

.file-icon--csv { @include svg (file-csv); }
.file-icon--jpg { @include svg (file-jpg); }
.file-icon--png { @include svg (file-png); }
.file-icon--pdf { @include svg (file-pdf); }
.file-icon--xls { @include svg (file-xls); }

// ----------------------------------------------
// Modifier: Size

.file-icon--s {
    width: 16px;
    height: 20px;
    background-size: contain;
}
