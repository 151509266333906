/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2018 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Close Offscreen Button
// ==============================================

.close-offscreen-button {
    @include image-replacement;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 28px;
    height: 28px;
    border: 0;
    border-radius: 14px;
    background: $c-action-secondary-base;

    &:hover {
        background: $c-action-default-base;
    }

    &::before {
        @include svg (cross-12-white);
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 12px;
        margin-top: (12px / -2);
        margin-left: (12px / -2);
    }
}
