/**
* Gemaire Frontend
*
* @author    Brendan Falkowski (http://gravitydept.com)
* @package   enterprise_gemaire
* @copyright Copyright 2015 Gravity Department
* @license   All rights reserved.
*/


// ==============================================
// Box Shadow
// ==============================================

$box-shadow-base: 0 3px 1px -2px black(0.2),
0 2px 2px 0 black(0.14),
0 1px 5px 0 black(0.12) !default;
$box-shadow-input-text: inset 0 1px 3px      black(0.15) !default;
$box-shadow-inset:      inset 0 2px 10px 5px black(0.3)  !default;
