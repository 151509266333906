/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$container-max-width: $trim + $content-max-width + $trim !default;


// ==============================================
// Container
// ==============================================

.container {
    position: relative;
    max-width: $container-max-width;
    margin: 0 auto;

    &::after {
        @include clearfix;
    }
}

// ----------------------------------------------

@media (min-width: $bp-page-focal) {

    .container {
        padding: 0 $trim;
    }

}

// ----------------------------------------------

@media print {

    .container {
        padding: 0;
    }

}


// ==============================================
// Modifier: Block
// ==============================================

.container--block {
    max-width: none;
}


// ==============================================
// Modifier: No Pad
// ==============================================

.container--no-pad {
    padding: 0;
}
