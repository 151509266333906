/**
* Gemaire Distributing
*/

// ==============================================
// MultiSelect
// ==============================================


.multiselect {
    height: 45px;
    border: 0;
    border-radius: 0;
    background: transparent;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23666464' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
    background-origin: content-box;
    background-position: right -5px center;
    background-repeat: no-repeat;
    background-clip: padding-box;
    outline: 0;
    appearance: none;
    color: $c-text-base;
    vertical-align: baseline;
    transition: border ease-in-out 180ms;
    border-bottom: 1px solid $c-border;
    display: block;
    overflow: hidden;

    &:focus {
        border-bottom: 1px solid $c-input-border-focus;
        .multiselect-label {
            color: $c-input-border-focus;
        }
    }
}

.multiselect-selection {
    position: relative;
    min-height: 44px;
    padding: 7px 0 0px 0;
    width: 100%;

    .multiselect-ellipsis {
        position: absolute;
        top: 15px;
        right: 28px;
        left: auto;
        font-size: 18px;
        cursor: default;
    }
}

label.multiselect-label {
    font-family: $font-family-sans;
    font-size: $font-size-s;
    font-weight: $font-weight-semibold;
    font-style: normal;
    letter-spacing: $letter-spacing;
    margin-top: -11px;
    margin-bottom: 0px;
}

.multiselect-optionlist{
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: solid $c-border;
    border-width: 0 1px 1px 1px;
    background: #fff;
    z-index: 9999!important;
    transition: 180ms;
    opacity: 0;
    visibility: hidden;
    max-height: 206px;
    overflow-y: auto;

    &.show {
        opacity: 1;
        visibility: visible;
    }
}

.multiselect-option {
    padding: 10px 15px;
    cursor: pointer;

    &:hover {
        background: $c-emphasis-background;
    }
}

.multiselect-option-checkbox-container {
    float: right;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark:before {
        content: $icon-checkbox-outline;
        display: inline-block;
        top: -2px;
        left: 0;
        width: $checkbox-size;
        height: $checkbox-size;
        border-radius: $radius-base;
        box-shadow: none;
        -moz-osx-font-smoothing: grayscale;
        font-size: $checkbox-size;
        font-family: $font-family-icon;
        color: $c-gray-slate;
        line-height: 18px;
        vertical-align: top;
     }

    input:checked ~ .checkmark:before {
        content: $icon-checkbox;
        color: $c-blue-base;
    }
}

.multiselect-selected {
    position: relative;
    display: inline-block;
    padding: 5px 10px;
    margin-right: 5px;
    background: $c-emphasis-background;
    border-radius: 30px;
    min-width: 72.5px;
    max-width: 160px;
    text-overflow: ellipsis;white-space: nowrap;
    overflow: hidden;
}

.multiselect-placeholder {
    padding-top: 5px;
}


// ==============================================
// Modifier: Size
// ==============================================

.multiselect--s {
    max-width: $input-max-width-s;
}

.multiselect--xs {
    max-width: $input-max-width-xs;
}
