/**
* Gemaire Distributors
*/

@keyframes content-placeholder-animation {
    0% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(300%);
    }
}

.content-placeholder {
    position: relative;
    overflow: hidden;
    width: inherit;
    height: inherit;
    background: #f6f6f6;
    border-radius: 3px;

    &:after {
        display: block;
        content: '';
        animation: content-placeholder-animation 1s linear infinite;
        background: -moz-linear-gradient(
            left,
            rgba(15, 15, 15, 0) 0%,
            rgba(219, 219, 219, 0.2) 50%,
            rgba(255, 255, 255, 0) 100%
        );
        background: -webkit-linear-gradient(
            left,
            rgba(15, 15, 15, 0) 0%,
            rgba(219, 219, 219, 0.2) 50%,
            rgba(255, 255, 255, 0) 100%
        );
        background: linear-gradient(
            to right,
            rgba(15, 15, 15, 0) 0%,
            rgba(219, 219, 219, 0.2) 50%,
            rgba(255, 255, 255, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000f0f0f', endColorstr='#00ffffff',GradientType=1 );
        display: block;
        height: inherit;
        left: 0;
        position: absolute;
        top: 0;
        width: 70%;
        will-change: transform;
    }

    &.blue--theme {
        background: #1876c9;
    }
}
