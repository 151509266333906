/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Page Title
// ==============================================

.page-title-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @media (max-width: $bp-screen-m - 1px) {
        .page-title {
            width: 100%;
        }
    }
}

.page-title {
    &::after {
        @include clearfix;
    }

    small {
        display: block;
        font-size: 0.7em;
    }
}

.page-focal > .page-title {
    padding-right: $liner-base;
    padding-left:  $liner-base;
}

// ----------------------------------------------

@media (max-width: 599px) {

    .page-title {
        margin-top: 16px;
        font-size: 28px;
    }

}

// ----------------------------------------------

@media (min-width: 600px) {

    .page-title {
        margin-top: (36px * 0.45);
        font-size: 36px;
    }

}

// ----------------------------------------------

@media (min-width: $bp-page-focal) {

    .page-focal > .page-title {
        padding-right: $liner-l;
        padding-left:  $liner-l;
    }

}


// ==============================================
// Page Title - Pre
// ==============================================

.page-title_pre {
    font-size: $font-size-xs;
    font-weight: bold;
}


// ==============================================
// Page Title - Actions
// ==============================================

.page-title_actions {
    float: right;
    margin: 0 0 5px 10px;
    text-align: right;
}


// ==============================================
// Modifier: Section
// ==============================================

.page-title--section {
    margin-top: 0;
}

.page-title--section .page-title_actions {
    margin-top: -3px;
}


// ==============================================
// Page Title- Subheader
// ==============================================

.page-title--subheader {
    padding: 0 30px;
}

// ----------------------------------------------
// Back button

.page-title-wrapper .back-button {
    margin-left: -0.8rem;
}

@media (max-width: $bp-screen-s - 1px) {
    .page-title-wrapper .back-button {
        padding-top: 4px;
    }
}
