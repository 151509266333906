/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2018 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Controls
// ==============================================

//.controls {}

.controls .control {
    margin-bottom: 0.6em;
}


// ==============================================
// Controls - Title
// ==============================================

.controls_title {
    margin-bottom: 8px;
    font-size: $font-size-xs;
    font-weight: bold;
    line-height: $line-height-xs;
}
