/**
* Gemaire Frontend
*
* @author    Brendan Falkowski (http://gravitydept.com)
* @package   enterprise_gemaire
* @copyright Copyright 2019 Gravity Department
* @license   All rights reserved.
*/


// ==============================================
// Mixin - Focus
// ==============================================

@mixin focus {
    border-color: $c-input-border-focus;
}

@mixin focus-error {
    border-color: $c-danger;
}
