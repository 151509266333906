/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Masthead
// ==============================================

.masthead {
    margin-bottom: 2.75em;
    text-align:center;
}

.masthead-title {
    margin-bottom: 0.4em;
    color: $c-text-base;
    font-family: $font-family-special;
    font-size: 36px;
    font-weight: bold;
}

.masthead .lead {
    max-width: 35em;
    margin-right:auto;
    margin-left:auto;
}

// ----------------------------------------------

@media (max-width: 599px) {

    .masthead-title {
        font-size: 28px;
    }

}

// ----------------------------------------------

@media (min-width: 600px) {

    .masthead-title {
        font-size: 34px;
    }

}

// ----------------------------------------------

@media (min-width: 900px) {

    .masthead-title {
        font-size: 42px;
    }

}

// ----------------------------------------------

@media (min-width: 1100px) {

    .masthead-title {
        font-size: 48px;
    }

}
