/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2017 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Product Cell
// Used in Cart, Checkout Review, and My Products
// ==============================================

//.product-cell {}

.product-cell .product-name {
    display: inline-block;
    margin-bottom: 10px;
    line-height: $line-height-xxs;
}

.product-cell .product-numbers {
    margin-bottom: 10px;
}

.product-cell .item-options-list {
    margin-bottom: 10px;
}

.product-cell .product-inventory {
    margin-bottom: 10px;
}

.product-cell .item-expression {
    margin-bottom: 10px;
}
