/**
* Gemaire Frontend
*
* @author    Brendan Falkowski (http://gravitydept.com)
* @package   enterprise_gemaire
* @copyright Copyright 2019 Gravity Department
* @license   All rights reserved.
*/


// ==============================================
// Mixin - Menu
// ==============================================

@mixin menu {
    z-index: $z-menu;
    border: 1px solid $c-border;
    background: $c-white;
}
