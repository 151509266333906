/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Cards
// ==============================================

.cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.cards_item {
    margin: 0 15px;
    border: 1px solid $c-border;
    padding: $space-l;
    background: $c-white;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    small {
        display: block;
        color: $c-gray-slate;
    }

    .payment-icon {
        position: absolute;
        right: 0;
    }

    .payment_content {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .messages {
        width: 100%;
    }

    .action-group {
        margin-top: auto;
    }
}

.cards_card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0;
    padding: 15px;
    border: 1px solid $c-border;
    border-radius: $radius-base;
    background: $c-background;
}

.cards_card>*:last-child {
    margin-bottom: 0;
}

.spacer {
    margin-bottom: 40px;
}

// ----------------------------------------------

@media (min-width: $bp-screen-s) {

    .cards_item {
        flex-basis: calc(50% - 30px);
    }
}

@media (min-width: $bp-screen-m) {

    .cards_item {
        flex-basis: 420px;

        &.program-item {
            flex-basis: 600px;
        }
    }

}

// ----------------------------------------------

@media print {

    .cards_card {
        padding: (15px / 2);
    }

}

// ==============================================
// Modifier: Order info
// ==============================================

.cards_item--order_info {
    box-shadow: $box-shadow-base;
    flex-basis: 335px;
}

@media (max-width: $bp-screen-l - 1px) {
    .cards_item--order_info {
        flex-basis: calc(50% - 30px);
    }
}

@media screen and (max-width: $bp-screen-m - 1px) {
    .cards_item--order_info {
        flex-basis: 100%;
    }
}

// ==============================================
// Modifier: Three
// ==============================================

@media (min-width: 800px) {

    .cards--three .cards_item {
        width: calc(100% / 3);
        flex-basis: calc(100% / 3);
    }

}


// ==============================================
// Modifier: Four
// ==============================================

@media (min-width: 800px) {

    .cards--four .cards_item {
        width: calc(100% / 3);
        flex-basis: calc(100% / 3);
    }

}

// ----------------------------------------------

@media (min-width: 1100px) {

    .cards--four .cards_item {
        width: 25%;
        flex-basis: 25%;
    }

}


// ==============================================
// Modifier: DMI Cards
// ==============================================

.cards--dmi {

    .cards_item {
        padding: 0;
        overflow-x: auto;
    }

    .panel_header,
    .panel_footer {
        width: 100%;
    }
}

// ==============================================
// Modifier: Dealer Coop Cards
// ==============================================

.cards--dealercoop {

    .dealercoop-exp {
        border-top: 1px solid $c-border;
        margin-top: 16px;
        padding-top: 16px;
    }

}


@media (min-width: $bp-screen-m) {
    .cards--dealercoop {

        .card--dealercoop-wrapper {
            display: flex;
        }

        .dealercoop-logo {
            border-right: 1px solid $c-border;
            padding-right: 16px;
            margin-right: 16px;
        }

        .dealercoop-exp {
            border-left: 1px solid $c-border;
            border-top: 0;
            margin-top: 0;
            padding-top: 0;
            margin-left: 16px;
            padding-left: 16px;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {

    .cards--dealercoop {
        flex-wrap: nowrap;
        overflow-x: scroll;

        .cards_item {
            display: block;
        }

        table {
            width: 100%;
        }
    }

}

// Floating Card
// ==============================================

.floating-card {
    border: 1px solid $c-border;
    box-shadow: $box-shadow-base;
    padding: 20px 15px;
    background-color: $c-yellow-light;
    position: sticky;
    top: -25px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
    flex: 1;
    z-index: 2;
}

@media (min-width: $bp-screen-s) and (max-width: $bp-screen-l - 1px) {
    .floating-card {
        flex-direction: row;

        table {
            margin-bottom: 0;
        }
    }
}

@media (min-width: $bp-screen-l) {

    .floating-card {
        margin-left: 15px;
        order: 2;
        flex-basis: 360px;

        button {
            width: 100%;
        }
    }

    .floating-card-container {
        display: flex;
        align-items: flex-start;
    }
}
