/**
* Gemaire Distributing
*/


// ==============================================
// Page Title Wrapper
// ==============================================

.page-products {

    .page-title-wrapper {
        padding: 0 $layout-indent__width;
        background: $c-white;
    }

    .page-main {
        padding: 0;

        .panel {
            border: 0;
        }
    }
}

.search-auto-correct-container {
    padding-left: $layout__width-xs-indent;
    padding-right: $layout__width-xs-indent;
}

@media (min-width: $bp-screen-m) {
    .search-auto-correct-container {
        padding-left: $layout-indent__width;
        padding-right: $layout-indent__width;
    }
}

// ==============================================
// Product Item Info
// ==============================================

// [todo] refactor.
// Only need this because "_product-item.scss" sets a hard width value.
// This should probably not be defined and let the container determine the width.
// Search for "product-item-info" project-wide to see usage.
.page-products .product-item-info {
    width: 100%;
}


// ==============================================
// Catalog Page - Sidebar
// ==============================================

@media (min-width: $bp-columns) {

    .page-products .sidebar-main {
        width: 265px;
        background: $c-white;
        border-right: 1px solid $c-border;
        border-top: 1px solid $c-border;
        padding-top: 15px;
    }

    .page-products .sidebar-main > div {
        margin-bottom: $space-xs;
        padding: $space-xs 0;
    }

}

