/**
* Gravity Department - Frontend Starter
* https://github.com/gravitydepartment/frontend-starter
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Mixin - SVG
// ==============================================

@mixin svg ($name) {
    background: url(#{$path-svg}#{$name}.svg) no-repeat;
}
