/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Reviews List
// ==============================================

.reviews-list {
    margin-bottom: $gap;
}

.reviews-list > li {
    padding: 15px 0;
    border-bottom: 1px dashed $c-border;
}

.reviews-list > li:first-child {
    padding-top: 0;
}

.reviews-list .rating-box {
    margin-bottom: 10px;
}

.reviews-list h3 {
    @include h4;
    margin-bottom: 2px;
}

.reviews-list .attribution {
    color: $c-text-subtle;
    font-size: $font-size-xs;
}

// ----------------------------------------------

@media (min-width: 480px) {

    .reviews-list .rating-box {
        float: left;
        margin: 3px 10px 0 0;
    }

}
