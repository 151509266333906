/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2015 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Triple Carousel
// ==============================================

.triple-carousel {
    margin-bottom: $gap;
    padding: 0 ($liner-base / 2);
}

.triple-carousel_item {
    padding: 0 ($liner-base / 2);
}

.triple-carousel_image {
    margin: 0 auto;
}

// ----------------------------------------------

@media (min-width: $bp-page-focal) {

    .triple-carousel {
        padding: 0 ($liner-l / 2);
    }

    .triple-carousel_item {
        padding: 0 ($liner-l / 2);
    }

}
