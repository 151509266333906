/**
* Gravity Department - Frontend Starter
* https://github.com/gravitydepartment/frontend-starter
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Path
// ==============================================

$path-png: '../images/png/' !default;
$path-svg: '../images/svg/' !default;
