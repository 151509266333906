/**
* Gemaire Frontend
*
* @author    Brendan Falkowski (http://gravitydept.com)
* @package   enterprise_gemaire
* @copyright Copyright 2015 Gravity Department
* @license   All rights reserved.
*/


// **********************************************
// Web fonts are loaded from a template.
// See: /template/gravdept/page/html/web-font-loader.phtml
// **********************************************


// ==============================================
// Font Stacks
// ==============================================

$font-family-icon:   'Material Icons' !default;
$font-family-mono:    menlo, monaco, consolas, 'Courier New', monospace   !default;
$font-family-sans:    'proxima-nova', 'Helvetica Neue', arial, sans-serif !default;
$font-family-serif:   'Georgia', serif !default;
$font-family-special: 'proxima-nova', 'Helvetica Neue', arial, sans-serif !default;

$font-family-base: $font-family-sans !default;


// ==============================================
// Font Sizes
// ==============================================

$font-size-xxxl:   26px !default;
$font-size-xxl:    22px !default;
$font-size-xl:     20px !default;
$font-size-l:      18px !default;

$font-size-base:   16px !default;

$font-size-s:      15px !default;
$font-size-xs:     14px !default;
$font-size-xxs:    13px !default;
$font-size-xxxs:   12px !default;
$font-size-xxxxs:  11px !default;
$font-size-xxxxxs: 10px !default;


// ==============================================
// Font Weight
// ==============================================

$font-weight-light:    300;
$font-weight-normal:   400;
$font-weight-semibold: 500;
$font-weight-bold:     500;

// ==============================================
// Letter Spacing
// ==============================================

$letter-spacing: 0.04rem;

// ==============================================
// Line Height
// ==============================================

$line-height:      1.5 !default;

$line-height-s:    1.4 !default;
$line-height-xs:   1.3 !default;
$line-height-xxs:  1.2 !default;
$line-height-xxxs: 1.1 !default;


// ==============================================
// Margin Bottom
// ==============================================

$margin-bottom: 1.5em !default;
