/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$c-hr:        $c-border !default;
$c-hr-subtle: $c-divider-border !default;


// ==============================================
// HR
// ==============================================

hr {
    margin-bottom: $gap;
    border: 0;
    border-top: 1px solid $c-hr;
}

// ----------------------------------------------
// Modifier: Subtle

.hr--subtle {
    border-color: $c-hr-subtle;
}

// ----------------------------------------------
// Modifier: Thick

.hr--thick {
    border-width: 5px;
}
