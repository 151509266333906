/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Payment Methods
// ==============================================

.payment-methods {
    margin-bottom: 20px;
}

.payment-methods dt {
    padding: 5px 0;
}

//.payment-methods dd {}

.payment-methods_fields {
    position: relative;
    display: inline-block;
    max-width: 100%;
    margin: 10px 0 20px;
    padding: 15px;
    border: 1px solid $c-border;
    border-radius: $radius-s;
    background: #FFF;

    &::before {
        @include triangle (up, 10px, $c-border);
        top: -11px;
        left: 40px;
    }

    &::after {
        @include triangle (up, 10px, #FFF);
        top: -10px;
        left: 40px;
    }
}


// ==============================================
// Payment Method
// ==============================================

.payment-method {
    border-bottom: 1px solid $c-border;
    padding: 15px 0;

    .table-caption{
        display: none;
    }

    &:first-child {
        padding-top: 0;
    }
}


// ==============================================
// Payment Method Title
// ==============================================

.payment-method-title {
    margin-bottom: 0;
}

.payment-method-title .payment-icon {
    display: inline-block;
    margin-right: $space-xs;
    vertical-align: middle;
}

.payment-method-title .action-help {
    display: inline-block;
    margin-left: $space-xs;
}


// ==============================================
// Payment Method Content
// ==============================================

.payment-method-content {
    display: none;
    width: 100%;
    padding: 20px 0 0;
}

._active .payment-method-content {
    display: inline-block;
}

.payment-method-content button[type='submit'] {
    display: none;
}


// ==============================================
// Payment Method - Summary
// ==============================================

#step-summary-payment {
    display: none;
}

.payment-card {
    display: inline-flex;
}

.payment-card_info {
    padding-top: 6px;
}