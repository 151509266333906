/**
* Acme Refrigeration
*/

//
//  Common
//  _____________________________________________


.navigation-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;
    color: $c-white;
}

.navigation .shop-items_subnav {
    position: static;
}

.shop-items_subnav li {

    a {
        color: $c-text-base;
        position: relative;
        display: block;
        padding: 10px 35px 10px 15px;

        &:hover {
            color: $c-white;
            background: $c-blue-bright;
        }
    }

    &.parent > a {
        @include lib-icon-font(
            $_icon-font-content        : $icon-chevron-right,
            $_icon-font-size           : 20px,
            $_icon-font-line-height    : 20px,
            $_icon-font-vertical-align : middle,
            $_icon-font-margin         : 0,
            $_icon-font-display        : block
        );

        &:before {
            position: absolute;
            right: 15px;
            top: 12px;
        }
    }
}

.navigation {

    ol {

        ol {
            position: absolute;
            background: white;
            top: -5px;
            left: 100%;
            border: 1px solid $c-border;
            visibility: hidden;
        }
    }

    li:hover > ol {
        visibility: visible;
    }

    @media (min-width: $bp-screen-l) {
        .products-dropdown .dropdown-content {
            min-height: 532px;
        }
    }
}

.panel.header .links,
.panel.header .switcher {
    display: none;
}

.nav-sections {
    order: 1;
}

.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size: 28px,
        $_icon-font-line-height: 28px,
        $_icon-font-color: $c-white,
        $_icon-font-color-hover: $c-white,
        $_icon-font-vertical-align: -9px
    );
    cursor: pointer;
    display: block;
    left: $space-base;
    position: absolute;
    top: 110px;
    z-index: 15;
    color: $c-white;
}

.nav-drawer_title {
    @include h2();
}

@media (max-width: $bp-screen-s - 1px) {

    .nav-toggle {
        left: 6px;
    }
}

//
//  New Category Badge Navigation
//  _____________________________________________

.new-category-menu {

    > a:after {
        content: "New";
        color: $c-white;
        background: $c-green;
        display: inline-block;
        padding: 1px 4px;
        font-size: 9px;
        vertical-align: 3px;
        text-transform: uppercase;
        margin-left: 5px;
    }

}

//
//  Customer Navigation
//  _____________________________________________

.customer-navigation {
    order: 3;
}

@media (max-width: $bp-screen-m - 1px) {
    .customer-navigation {
        margin-right: $layout__width-xs-indent;
    }
}

//
//  Dropdown arrow
//  _____________________________________________

.nav-menu:before {
    @include triangle (up, 8px, $c-border);
    top: -9px;
    left: 149px;
    z-index: -2;
}

.nav-menu:after {
    @include triangle (up, 8px, $c-white);
    top: -7px;
    left: 149px;
    z-index: -2;
}

.nav-menu {
    &::before,
    &::after {
        left: var(--arrowPos);
    }
}


//
//  Mobile
//  _____________________________________________

@media (max-width: $bp-screen-l - 1px) {

    .navigation-wrapper {
        float: right;
    }

    .shop {

        &.dropdown {
            display: block;
        }

        .nav-menu {
            display: block;
            position: static;
            border: none;
            box-shadow: none;
            width: 100%;
            visibility: visible;
        }
    }

    .nav-menu:before,
    .nav-menu:after {
        border: none;
    }

    .nav-sections {
        background: $c-white;
    }

    .header.links {
        padding-top: 5px;
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        transition: left 0.3s;
        height: 100%;
        left: -400px;
        overflow: auto;
        position: fixed;
        top: 0;
        width: 100%;
        max-width: 400px;
    }

    .nav-sections .switcher {
        border-top: 1px solid $c-border;
        font-size: $font-size-l;
        margin: 0;
        padding: 0.8rem 3.5rem 0.8rem 2rem;
        @include bold;

        &:last-child {
            border-bottom: 1px solid $c-border;
        }
    }

    .nav-sections .switcher .label {
        display: block;
        margin-bottom: $space-xs;
    }

    .nav-sections .switcher-trigger strong {
        position: relative;
        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 28px,
            $_icon-font-position: after,
            $_icon-font-display: block
        );

        &::after {
            position: absolute;
            right: -40px;
            top: -16px;
        }
    }

    .nav-sections .switcher-trigger.active strong {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-up,
            $_icon-font-position: after
        );
    }

    .nav-sections .switcher-dropdown {
        padding: $space-base 0;
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;
    }

    .nav-before-open .page-wrapper {
        transition: left 0.3s;
        height: 100%;
        left: 0;
        overflow: hidden;
        position: relative;
    }

    .nav-before-open body {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    .nav-open .page-wrapper {
        left: 400px;
    }

    .nav-open .nav-sections {
        box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
        left: 0;
        z-index: 99;
        overflow-x: hidden;
    }

    .nav-open .nav-toggle {
        &::after {
            background: $c-overlay;
            content: '';
            display: block;
            height: 100%;
            position: fixed;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 1;
        }
    }

    .shop-items_title {
        position: relative;
        @include lib-icon-font(
            $_icon-font-content: $icon-pointer-right,
            $_icon-font-size: 24px,
            $_icon-font-position: after,
            $_icon-font-display: block
        );

        &::after {
            position: absolute;
            right: 10px;
            top: 12px;
            color: $c-text-muted;
        }
    }

    .nav-sections-items {
        position: relative;
    }

    .nav-drawer {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: -100%;
        background: $navigation__background;
        transition: left .3s;
    }

    .nav-drawer .shop-items_subnav {
        display: block;
    }

    .nav-drawer_control {
        background: $c-gray-slate;
        color: $c-white;
        padding: $space-s $space-base;
        letter-spacing: 0.04em;
        cursor: pointer;
        @include lib-icon-font(
            $_icon-font-content: $icon-arrow-left-thin,
            $_icon-font-size: 24px,
            $_icon-font-display: block,
            $_icon-font-margin: -3px 10px 0 0
        );
    }

    .nav-drawer_title {
        margin: $navigation__indent-side;
    }

    .nav-sections-item-content,
    .nav-drawer_content {
        @include lib-main-navigation(
            $_nav-level0-item-padding: 10px 35px 10px 15px,
            $_nav-background-color: $c-white,
            $_submenu-background-color: $c-white,
            $_nav-level0-item-margin: 0,
            $_nav-level0-item-border: 0,
            $_nav-level0-item-color: $c-text-base
        );

        .view-all-submenu-link {
            a {
                color: $c-link-base;
            }
        }
    }

    .nav-sections-item-content a.shop-trigger,
    .nav-drawer_content a.shop-trigger,
    .section-item-title.nav-sections-item-title {
        display: none !important;
    }

    .nav-sections-item-content li {

        .shop-wrapper {
            border-bottom: 1px solid $c-border;
        }

        a.dropdown-trigger {

            @include lib-icon-font(
                $_icon-font-content        : $icon-chevron-right,
                $_icon-font-size           : 20px,
                $_icon-font-line-height    : 20px,
                $_icon-font-vertical-align : middle,
                $_icon-font-margin         : 0,
                $_icon-font-display        : block
            );

            &:before {
                position: absolute;
                right: 15px;
                top: 12px;
            }
        }
    }

    .navigation {
        ol {
            width: 100%;
            top: 0 !important;
            box-shadow: none;
            border: 0 !important;
            transition: all ease 0.3s;
            visibility: visible !important;
            position: absolute;
            left: 100%;
            height: 100%;
            background: $c-white;
            z-index: 1;

            &.active {
                left: 0;
            }
        }

        .shop-wrapper ol.shop-items_subnav {
            position: static;
        }
    }

}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .nav-toggle {
        top: 1px;
        @include lib-icon-font($icon-menu,
            $_icon-font-size: 28px,
            $_icon-font-line-height: 67px,
            $_icon-font-color: $c-white,
            $_icon-font-color-hover: $c-white);
        @include lib-icon-text-hide();
        font-size: 0;
    }
}

@media (max-width: $bp-screen-s - 1px) {

    .nav-sections {
        left: calc(-1 * (100% - #{$active-nav-indent}));
        width: calc(100% - #{$active-nav-indent});
    }

    .nav-open .page-wrapper {
        left: calc(100% - #{$active-nav-indent});
    }
}



// ----------------------------------------------

@media (min-width: $bp-screen-l) {

    .nav-toggle {
        display: none;
    }

    .submenu-header {
        display: none;
    }

    .view-all-submenu-link {
        display: none;
    }

    .nav-sections {
        flex-shrink: 0;
        flex-basis: auto;
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;

        > *:not(.menu-top)  {
            display: none;
        }

        > .navigation {
            display: block;
            padding-top: 11px;
            padding-bottom: 11px;

            ol {
                min-width: 340px;
                border-right: 1px solid $c-border;
                width: auto;
            }
        }
    }

    @include lib-main-navigation-desktop(
        $_nav-background-color : transparent,
        $_nav-level0-item-color : $c-white,
        $_nav-level0-item-color-hover : $c-background,
        $_nav-level0-item-color-active : $c-background,
        $_nav-level0-item-margin : 0 15px
    );

    .panel.header .links,
    .panel.header .switcher {
        display: inline-block;
    }

    .nav-menu_liner {
        padding: 20px 0;
        display: flex;
    }

    .shop-items_subnav li {
        break-inside: avoid;
        page-break-inside: avoid;

        &:hover {
            background: $c-border;

           > a {
                text-decoration: none;
            }
        }
    }

    .shop-nav .shop-items_all {
        padding: $space-base;
        background: $c-background;
        border-top: 1px solid $c-border;
    }
}

//
//  Menu mobile label
//  _____________________________________________

.menu-mobile-label {
    font-size: 18px;
    text-transform: none;
    font-weight: normal;
    padding: 10px 15px;
    border-bottom: 1px solid #d0d0d0;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    display: none;

    button {
        line-height: 1.4;
        float: right;
    }

    > span:before {
        content: "< Back";
        display: block;
        font-size: 11px;
    }
}

@media (max-width: $bp-screen-l - 1px) {
    .menu-mobile-label {
        display: flex;
    }
}

//
//  Button Close nav drawer
//  _____________________________________________

.button[data-action="toggle-main-nav"] {
    display: none;
    margin: 20px;
}

@media (max-width: $bp-screen-l - 1px) {
    .button[data-action="toggle-main-nav"] {
        display: inline-block;
    }
}
