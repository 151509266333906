//
//  Variables
//  _____________________________________________

$popup-marker__size: 7px;

//  Popup working with dropdown dialog
.ui-dialog.popup .action.close {
    bottom: $popup-button-close__position-bottom;
    left: $popup-button-close__position-left;
    position: $popup-button-close__position;
    height: 40px;
    right: 0;
    top: 0;
    width: 40px;
    z-index: 1001;
    @include lib-popup-button-close-icon(
        $popup-button-close__icon,
        $popup-icon-font__content,
        $popup-icon-font,
        $popup-icon-font__size,
        $popup-icon-font__line-height,
        $popup-icon-font__color,
        $popup-icon-font__color-hover,
        $popup-icon-font__color-active,
        $popup-icon-font__margin,
        $popup-icon-font__vertical-align,
        $popup-icon-font__position,
        $popup-icon-font__text-hide
    );
    @include _lib-popup-button-close-reset(
        $popup-button-close__reset
    );
}

.popup-pointer {
    margin-left: (-$popup-marker__size * 2);
    position: absolute;
    top: (-$popup-marker__size * 2);

    &::before,
    &::after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-bottom-style: solid;
    }

    &::before {
        left: 2px;
        top: 2px;
        border: solid $popup-marker__size - 1;
        border-color: transparent transparent $c-white transparent;
        z-index: 99;
    }

    &::after {
        left: 1px;
        top: 0;
        border: solid $popup-marker__size;
        border-color: transparent transparent $c-library-gray-4 transparent;
        z-index: 99 - 1;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-s) {
    .ui-dialog.popup {
        width: 420px;
    }
}
