/**
* Gemaire Distributing
*/

.menu-customer-mobile {
    display: none;
    float: right;
    line-height: 67px;
    height: 67px;

    button {
        appearance: none;
        background: 0;
        border: 0;
        color: $c-white;

        @include lib-icon-font($_icon-font-content: $icon-user,
            $_icon-font-size: 30px,
            $_icon-font-line-height: 67px,
            $_icon-font-text-hide: false,
            $_icon-font-position: before,
            $_icon-font-display: block,
            $_icon-font-color: $c-white);
    }

}

// ----------------------------------------------

@media (max-width: $bp-header-alpha - 1px) {

    .menu-customer-mobile {
        display: block;
    }

}

@media (max-width: $bp-screen-s - 1px) {

    .menu-customer-mobile button span {
        display: none !important;
    }

}