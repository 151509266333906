/**
* Gemaire Distributing
*/


// ==============================================
// Block Reorder
// ==============================================

.block-reorder .subtitle {
    display: none;
}

.block-reorder .product-item .checkbox {
    position: relative;
    top: 2px;
    vertical-align: baseline;
}

.block-reorder .product-item .label {
    @include lib-visually-hidden;
}

.block-reorder .product-item .field.item {
    display: inline-block;
}

.block-reorder .product-item-name {
    display: inline-block;
}

.block-reorder .actions-toolbar {
    margin: 17px 0;
    max-width: 178px;
}
