/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Shipping Methods
// ==============================================

.shipping-methods {
    margin-bottom: 20px;
}

.shipping-methods dt {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
}

.shipping-methods dd {
    margin-bottom: $gap;

    &:last-child {
        margin-bottom: 0;
    }
}


// ==============================================
// Shipping Methods - Rates List
// ==============================================

.shipping-methods_rates-list {
    margin-left: 15px;
}

.shipping-methods_rates-list li {
    margin-bottom: 10px;
}

.shipping-methods .price {
    padding-left: 0.8em;
    color: $c-text-subtle;
}

.shipping-methods_info {
    margin-top: $space-s;
    font-size: $font-size-s;
}