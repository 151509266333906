/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2018 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Input Multiple Select
// ==============================================

.input-multiple-select {
    padding: 0.5em;
    border: 1px solid $c-input-border-base;
    border-radius: 5px;
    background: #FFF;
    box-shadow: inset 0 1px 5px 0 black(0.2);

    &:disabled {
        @include input-disabled;
    }

    &:focus {
        @include focus;
    }

    &.validation-failed {
        border-color: $c-danger;

        &:focus {
            @include focus-error;
        }
    }
}
