/**
* Gemaire Distributing
*/

// ==============================================
// Edit Account Form
// ==============================================

.form-edit-account .fieldset.password {
    display: none;
    padding: $space-l $space-l 0;
    border: 1px solid $c-border;
    box-shadow: $box-shadow-base;
    margin-bottom: $space-l;
    margin-top: -$space-l;
    position: relative;

    &:before {
        @include triangle (up, 8px, $c-border);
        top: -9px;
        left: 10px;
    }

    &:after {
        @include triangle (up, 8px, $c-white);
        top: -7px;
        left: 10px;
    }
}
