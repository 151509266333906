//
//  Full Bleed row appearance styles
//  ________________________________

[data-content-type='row'][data-appearance='full-bleed'] {
    box-sizing: border-box;
}

//
//  Responsive Classes
//  _____________________________________________

@media only screen and (max-width: $bp-screen-m - 1px) {
    [data-content-type='row'][data-appearance='full-bleed'] {
        background-attachment: scroll !important;
    }
}
