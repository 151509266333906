/**
* Gemaire Distributing
*/


// ==============================================
// Prices Tier
// ==============================================

.prices-tier {
    background: $c-library-gray-0;
    margin: $space-base 0;
    padding: $space-base (0.75 * $space-l);
}

.prices-tier .price-container {
    display: inline-block;
}

.prices-tier .price-including-tax,
.prices-tier .price-excluding-tax,
.prices-tier .weee {
    display: inline-block;
}

.prices-tier .price-container .price-including-tax + .price-excluding-tax {
    &::before {
        content: '(' attr(data-label) ': ';
    }

    &:last-child::after {
        content: ')';
    }
}

.prices-tier .price-container .weee[data-label] {
    display: inline;

    &::before {
        content: ' +' attr(data-label) ': ';
    }
}
