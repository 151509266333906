/**
* Gemaire Frontend
*
* @package    enterprise_gemaire
* @copyright  Copyright 2019 Gemaire Distributors
* @license    All rights reserved.
*/


/* stylelint-disable declaration-no-important */


// ==============================================
// Hide
// ==============================================

.hide,
.hidden,
._hidden,
.no-display {
    display: none !important;
}


// ==============================================
// Hide Print
// ==============================================

// Hide only for print (but show for web).
// Intended only for this dual purpose.
// Use "hide" otherwise.

@media print {

    .hide-print {
        display: none !important;
    }

}


// ==============================================
// Hide Web
// ==============================================

// Hide only for web (but show for print).
// Intended only for this dual purpose.
// Use "hide" otherwise.

@media only screen {

    .hide-web {
        display: none !important;
    }

}

// ==============================================
// Hide Mobile
// ==============================================

@media (max-width: $bp-screen-s - 1px) {

    .hide-mobile {
        display: none !important;
    }

}

// ==============================================
// Hide Tablet
// ==============================================


@media (min-width: $bp-screen-s) and (max-width: $bp-screen-m - 1px) {

    .hide-tablet {
        display: none !important;
    }

}

// ==============================================
// Hide Desktop
// ==============================================

@media (min-width: $bp-screen-m) {

    .hide-desktop {
        display: none !important;
    }

}



// ==============================================
// Linearized Display Utilities
// ==============================================

@media (max-width: $bp-table-linearize - 1px) {

    .is-linearized-block {
        display: block !important;
    }

    .is-linearized-inline {
        display: inline !important;
    }

    .is-linearized-flex {
        display: flex !important;
    }

    .is-linearized-none {
        display: none !important;
    }

}

// ----------------------------------------------

@media (min-width: $bp-table-linearize) {

    .not-linearized-none {
        display: none !important;
    }

    .not-linearized-block {
        display: block !important;
    }

    .not-linearized-inline {
        display: inline !important;
    }

    .not-linearized-flex {
        display: flex !important;
    }

    .not-linearized-table-cell {
        display: table-cell !important;
    }

}

// ==============================================
// Display Block
// ==============================================

.d-block {
    display: block;
}

// ==============================================
// Display Inline-block
// ==============================================

.d-inline-block {
    display: inline-block;
}

// ==============================================
// Display None
// ==============================================

.d-none {
    display: none;
}

// ==============================================
// Display Inline Flex
// ==============================================

.inline-flex {
    display: inline-flex;
}

// ==============================================
// Overflow Hidden
// ==============================================

.overflow-h {
    overflow: hidden;
}

// ==============================================
// Position
// ==============================================

.pos-r { position: relative; }
.pos-a { position: absolute; }
.pos-f { position: fixed; }
.pos-s { position: static; }
.pos-st { position: sticky; }