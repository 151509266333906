/**
* Gemaire Distributing
*/

// ==============================================
// Branch Inventory
// ==============================================

// Radio Button view

.inventory-content-container {
    padding-bottom: 9%;
}

.inventory-content-input {
    display: flex;
    align-items: center;
    padding: 5px;

    label {
        text-transform: capitalize;
    }
}

.inventory-content-input > div {
    padding-left: 2%;
}

.inventory-content-input-text {
    width: 100%;
    display: flex;
}

.inventory-content-title-radio-container:nth-last-child(3) {
    padding-bottom: 5%;
}

#inventory-online-only {
    padding: 5% 3% 5% 3%;
    border-top: 1px solid $c-emphasis-background;
}

.disabled {
    pointer-events: none;
}

.block-subtitle {
    font-size: inherit;
}

//Mobile View

@media (max-width: $bp-screen-m - 1px) {
    .filters-mobile,
    .filters-mobile-toggle {
        display: block;
    }

    #layered-filter-block .mobile-inventory-content-wrapper {
        display: block;
    }

    .toolbar-wrap--bottom .branch-inventory-wrapper {
        display: none;
    }

    //toolbar top styling
    .toolbar-wrap--top .toolbar-products {
        width: 100%;
        justify-content: space-between;
    }

    .toolbar-mobile-filters {
        display: flex;
        width: 100%;
        align-items: flex-end;
    }

    .toolbar-mobile-filters .filters-mobile-toggle {
        position: relative;
        margin-top: 0;
        left: 0;
        height: 50%;
    }

    .filters-mobile-toggle.button {
        display: flex;
        align-items: end;
        justify-content: center;
        background: $c-white;
        color: $c-link-base;
    }

    .filters-mobile-toggle .material-icons {
        padding: 9px 6px;
    }

    .inventory-content-mobile-wrapper {
        padding: 9px 12px;
        font-weight: $font-weight-normal;
        line-height: 1;
        text-align: center;
        transition: $transition-all;
        background: $c-white;

        .sold-in-mobile-dropdown {
            color: $c-link-base;
        }
    }

    .inventory-content-mobile-wrapper {
        display: flex;
        align-items: center;
        position: relative;
    }

    .inventory-content-mobile-branch-container .block-subtitle {
        font-size: $font-size-base;
        text-transform: capitalize;
        padding-bottom: 0;
        padding-right: 8px;
    }

    .inventory-content-mobile-branch-container {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
    }

    .inventory-content-mobile-branch-text {
        text-transform: capitalize;
        padding-right: 10px;
    }

    .mobile-inventory-content-wrapper {
        margin-left: 5%;

        .inventory-content-mobile-wrapper {
            border: 1px solid $c-action-disabled-base;
        }

        .results-count {
            color: #a4a4a4;
        }
    }

    .nav_menu {
        top: 25px;
        z-index: 200;
        border: 1px solid #d0d0d0;
        background: #ffffff;
        width: auto;
    }

    .header-links-quicklists .dropdown-trigger .sold-in-mobile-dropdown::after {
        content: inherit;
    }

    .inventory-content-mobile-wrapper .sold-in-mobile-dropdown-content {
        min-width: 260px;
        width: auto;
        padding: 16px;
        border: none;
        top: 40px;

        &::before,
        &::after {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-bottom: 10px solid #fff;
            right: 14px;
            top: -10px;
        }

        &::before {
            top: -11px;
            border-bottom-color: $c-action-disabled-base;
        }
    }

    .sold-in-mobile-dropdown-link.dropdown {
        position: static;
    }

    #inventory-online-only {
        margin-top: 5%;
        padding: 5% 3% 1% 3%;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .mobile-inventory-content-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .mobile-inventory-content-wrapper,
    .branch-inventory-wrapper,
    .toolbar-mobile-filters {
        width: 100%;
    }

    .inventory-content-mobile-cta-wrapper {
        width: 27%;
        padding-left: 9px;
    }

    .inventory-content-mobile-wrapper {
        align-items: center;
        justify-content: center;

        .sold-in-mobile-dropdown-content {
            width: 100%;
        }
    }

    .toolbar-mobile-filters {
        display: flex;
        width: 100%;
        align-items: flex-end;
    }    

    .inventory-content-mobile-branch-container {
        display: flex;
        justify-content: space-between;

        .inventory-content-mobile-branch-text {
            order: 999;
            width: 100%;
            padding-top: 8px;
            text-align: left;
        }
    }

    .toolbar-mobile-filters .filters-mobile-toggle .button {
        padding: 0px 20px;
    }
}

@media (max-width: $bp-screen-xs - 1px) {
    .inventory-content-mobile-wrapper,
    .inventory-content-mobile-branch-container {
        width: 100%;
    }

    .toolbar-mobile-filters {
        flex-wrap: wrap-reverse;
    }

    .mobile-inventory-content-wrapper {
        margin-left: 0;
        padding-bottom: 3%;
    }

    .inventory-content-mobile-cta-wrapper {
        width: 33%;
        padding-left: 9px;
    }

    .inventory-content-mobile-wrapper .sold-in-mobile-dropdown-content {
        top: 34px;
    }
}

button.filters-mobile-toggle.button.no-before::before {
    content: none;
}
