/**
* Gemaire Frontend
*
* @package    enterprise_gemaire
* @copyright  Copyright 2019 Gemaire Distributors
* @license    All rights reserved.
*/


/* stylelint-disable declaration-no-important */


// ==============================================
// Width
// ==============================================

.width-auto { width: auto; }

.width-100 { width: 100%   ; }
.width-75  { width: 75%    ; }
.width-66  { width: 66.666%; }
.width-50  { width: 50%    ; }
.width-33  { width: 33.333%; }
.width-25  { width: 25%    ; }
