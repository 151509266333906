/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/

// ==============================================
// Notify - Item
// ==============================================

.gemaire-notifications-wrapper {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: $z-notify;

    .notify {
        position: static;
    }
}

.notify_item {
    border-left: 8px solid #000;
    border-radius: 0;
    background: #fff;
    box-shadow: 0 0 0 1px black(0.1), 0 2px 5px black(0.2);
    font-size: $font-size-xs;
}

.notify_item--danger {
    border-left-color: $c-danger;
}
.notify_item--help {
    border-left-color: $c-help;
}
.notify_item--info {
    border-left-color: $c-info;
}
.notify_item--success {
    border-left-color: $c-success;
}
.notify_item--warn {
    border-left-color: $c-warn;
}
