/**
* Gemaire Distributing
*/


// ==============================================
// OPC Block Summary
// ==============================================


.opc-block-summary h2 {
    margin-bottom: 20px;
}

// ----------------------------------------------

.opc-block-summary .not-calculated {
    font-style: italic;
}

.opc-block-summary .opc-table-totals {
    background: transparent;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .opc-block-summary .opc-table-totals th {
        white-space: normal
    }
}


// ==============================================
// OPC Estimate Wrapper
// ==============================================

.opc-estimated-wrapper {
    display: none;
}


.opc-totals-table {
    .totals-table {
        margin-bottom: 0;
    }
}


// ==============================================
// OPC action
// ==============================================

.opc-summary-action {
    padding: 40px 0 0;
    border-bottom: 0;
    background: white;
    margin: 0 -20px -20px;
}

// ==============================================
// Checkout Summary Section
// ==============================================

.checkout-summary-section {
    margin: 16px -20px 0;
    padding: 20px 0 0;
    border-top: 1px solid #D0D0D0;

    .order-details-summary {
        padding: 0 20px;
    }
}

// ==============================================
// Order Details Summary
// ==============================================

.order-details-summary {
    p {
        margin-bottom: 10px;

        strong {
            min-width: 130px;
            display: inline-block;
        }
    }
} 