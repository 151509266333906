/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Button Group
// ==============================================

.button-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top:  -0.8rem;
    margin-left: -0.8rem;
}

// ----------------------------------------------
// Modifier: Center

.button-group--center {
    justify-content: center;
}


// ==============================================
// Button Group - *Any*
// ==============================================

.button-group > * {
    margin-top:  0.8rem;
    margin-left: 0.8rem;
}


// ==============================================
// Button Group - Label
// ==============================================

.button-group_label {
    white-space: nowrap;
}
