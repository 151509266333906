/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// FAQ
// ==============================================

.faq {
    padding-top: $margin-bottom; // Children have ".std" formatting, but ".accordion_content" negates this.
    border-top: 1px solid $c-border;
}

.faq-question {
    margin-bottom: 10px;
    font-weight: bold;
}

// ----------------------------------------------

@media (max-width: 767px) {

    .faq:first-child {
        padding-top: 0;
        border-top: 0;
    }

}
