/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Media
// ==============================================

.media {
    display: flex;
    align-items: flex-start;
}

.media_item {
    margin-right: 15px;
}

.media_body {
    flex: 1;
}


// ==============================================
// Modifier: Flip
// ==============================================

.media--flip {
    flex-direction: row-reverse;
}

.media--flip .media_item {
    margin-right: 0;
    margin-left: 15px;
}


// ==============================================
// Modifier: Align Vertical
// ==============================================

.media--align-vertical {
    align-items: center;
}

// ==============================================
// Modifier: Center
// ==============================================

.media--center {
    align-items: center;
}