/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Toggle UI
// ==============================================

[data-toggle-trigger] {
    cursor: pointer;
}

[data-toggle-target] {
    display: none;
}

[data-toggle='show'] > [data-toggle-target] {
    display: block;
}
