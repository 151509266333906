/**
* Gemaire Distributing
*/


// ==============================================
// Product Item
// ==============================================

.product-item {
    background: $c-white;
    position: relative;
    margin: 10px;
    border: 1px solid $c-divider-border;

    &:hover .product-quickview {
        pointer-events: all;
        opacity: 1;
    }
}

.product-quickview {
    pointer-events: none;
    opacity: 0;
    transition: ease-in all 300ms;
}

.product-item-photo {
    width: 100%;
    display: block;
    text-align: center;
    position: relative;
    cursor: pointer;

    .product-quickview {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        padding: 5px 15px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
}

.product-item-photo img {
    max-height: 100%;
    width: auto;
    height: auto;
    margin: 0 auto;
}

.product-item .price-box {
    margin: 20px 0;
}

.product-item .price-box .price-label {
    font-size: $font-size-xs;

    &::after {
        content: ':';
    }
}

.product-item .special-price,
.product-item .minimal-price {
    .price {
        font-weight: $font-weight-bold;
    }

    .price-wrapper {
        display: inline-block;
    }

    .price-including-tax + .price-excluding-tax {
        display: block;
    }
}

.product-item .special-price {
    display: block;
}

.product-item .old-price .price {
    font-weight: $font-weight-normal;
}

.product-item .regular-price .price-label {
    display: none;
}

.product-item .minimal-price .price-container {
    display: block;
}

.product-item .minimal-price-link {
    margin-top: 5px;
}

.product-item .minimal-price-link .price-label {
    color: $c-link-base;
}

.product-item .minimal-price-link .price {
    font-weight: $font-weight-normal;
}

.product-item .minimal-price-link,
.product-item .price-excluding-tax,
.product-item .price-including-tax {
    display: block;
    white-space: nowrap;
}

.product-item .price-from,
.product-item .price-to {
    margin: 0;
}

.product-item .tocart {
    white-space: nowrap;
    width: 100%;
    transition: $transition-all;
    line-height: 50px;
}

.product-item .product-item-brand a,
.product-item a.tocompare {
    color: $c-text-muted;
}

.product-item-brand {
    display: none;
}

.product-item-name,
.product-item-name button {
    display: block;
    height: auto;
    line-height: $line-height-s;
    color: $c-input-border-contrast;
    @include word-wrap();
}

.product-item-info {
    max-width: 100%;

    .product-item-name button {
        text-align: left;
    }
}

.product-item-actions .actions-secondary {
    margin-top: $space-xl;
}

.product-item-actions .actions-secondary a {
    text-decoration: none;
}

.product-item-actions .actions-primary .input-text.qty {
    text-align: center;
}

.product-item-actions .actions-primary .fieldset {
    display: flex;
    flex-wrap: nowrap;
}

.product-item-actions .actions-primary .fieldset > .field:not(.choice) > .control {
    float: none;
    width: 100%;
}

.product-item-actions .actions-primary .field {
    margin: 0;
    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: 75px;
    border: 0;
}

@media (max-width: $bp-screen-s - 1px) {
    .product-item-actions .actions-primary .field.qty {
        flex-basis: 70px;
    }
}

.product-item-actions .actions-primary .control {
    margin: 0;
}

.product-item-actions .actions-primary .actions {
    flex-shrink: 1;
    flex-grow: 1;
    margin: 0;
}

// ==============================================
// Requisition List
// ==============================================

.block-requisition-list {

    .add_to_template_icon {

        font-size: 13px;
        text-transform: uppercase;
        color: $c-input-placeholder;

        &::before {
            @include svg (add_to_template);
            content: '';
            width: 28px;
            height: 21px;
            display: inline-block;
            vertical-align: -4px;
        }

    }

}



/*****TEMP*****/

.actions-primary > form {
    display: block !important;
}
