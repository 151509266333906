/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Checkout Agreements
// ==============================================

.checkout-agreements li {
    margin-bottom: 20px;
}

.checkout-agreements .agreement-content {
    overflow-y:auto;
    max-width: 670px;
    max-height: 125px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid $c-border;
    background: $c-background;
}
