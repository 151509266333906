/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/

// ==============================================
// Tabs
// ==============================================

.tabs {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &.tabs--stacked-mobile {
        @media (max-width: $bp-screen-m) {
            display: block;
        }
    }
}

// ==============================================
// Tabs - Nav
// ==============================================

.tabs_nav {
    display: flex;
}

// ==============================================
// Tabs - Nav Link
// ==============================================

.tabs_nav-link {
    display: block;
    position: relative;
    padding: 16px 20px;
    border-top: 1px solid $c-tab-border;
    border-right: 1px solid $c-tab-border;
    background: $c-tab-background;
    font-size: $font-size-s;
    line-height: $line-height-xs;
    text-decoration: none;
    user-select: none;
    text-align: center;
    order: -1;

    .tabs--stacked-mobile & {
        @media (max-width: $bp-screen-m) {
            width: 100%;
            padding-left: 5px;
            text-align: left;
            font-size: $font-size-l;
            border: 1px solid $c-table-border;

            &:not(:first-of-type) {
                border-top: none;
            }

            &::before {
                display: none;
            }

            @include lib-icon-font(
                $_icon-font-content: $icon-next,
                $_icon-font-size: 35px,
                $_icon-font-position: after,
                $_icon-font-display: block
            );

            display: flex;
            align-items: center;

            &:after {
                order: -1;
                position: static;
                width: auto;
                height: auto;
                background: none;
            }

            &.active {
                background: $c-blue-bright;
                border-color: $c-blue-bright;
                color: white;

                &::after {
                    transform: rotate(90deg);
                }
            }
        }
    }

    &.disabled,
    &[disabled] {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
        color: $c-text-base;
    }

    &:first-child {
        border-left: 1px solid $c-tab-border;
    }

    &:hover {
        background: $c-tab-background-hover;
    }
}

// ----------------------------------------------
// State: Is Active

.tabs_nav-link.active {
    @media (min-width: $bp-screen-m + 1) {
        background: $c-white;
        color: $c-tab-text;

        // Highlight the top edge
        &::before {
            content: '';
            position: absolute;
            top: -4px;
            left: -1px; // Cover border
            width: calc(1px + 100% + 1px); // Include borders
            height: 4px;
            background: $c-tab-border-top-active;
        }

        // Hide the bottom border
        &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 1px;
            background: $c-white;
            z-index: 1;
        }
    }
}

// ==============================================
// Tabs - Item
// ==============================================

.tabs_item {
    padding: 20px;
    border: 1px solid $c-tab-border;
    width: 100%;
    background: $c-white;
}

// ==============================================
// Modifier Tabs - Dark
// ==============================================

.tabs--dark {
    .tabs_nav-link.active,
    .tabs_nav-link.active::after,
    .tabs_item {
        background-color: $c-background;
    }
}
