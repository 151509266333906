/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$std-max-width: 45em !default;


// ==============================================
// Standard Formatted Text Block
// ==============================================

.std {
    max-width: $std-max-width;
}

// ----------------------------------------------
// Definition List

.std dl {
    margin-bottom: $margin-bottom;
}

.std dl dt {
    margin-top: ($margin-bottom / 2);
    font-size: $font-size-s;
    font-weight: bold;

    &:first-child {
        margin-top: 0;
    }
}

.std dl dd {
    margin-left: 1.25em;
}

// ----------------------------------------------
// Ordered List

.std ol {
    list-style:decimal outside;
    margin-bottom: $margin-bottom;
}

.std ol li {
    margin-left: 2em;
}

// ----------------------------------------------
// Unordered List

.std ul {
    list-style:disc outside;
    margin-bottom: $margin-bottom;
}

.std ul li {
    margin-left: 2em;
}
