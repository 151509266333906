/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Validation Advice
// ==============================================

div.mage-error {
    margin: 5px 0 0;
    color: $c-danger;
    font-size: $font-size-xs;
}
