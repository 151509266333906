//
//  Default appearance styles
//  _____________________________________________

[data-content-type='button-item'] {
    max-width: 100%;
    [data-element='link'],
    [data-element='empty_link'] {
        max-width: 100%;
        word-wrap: break-word;
    }
    [data-element='empty_link'] {
        cursor: default;
    }
}

a,
button,
div {
    &.pagebuilder-button-link {
        box-shadow: none;
        box-sizing: border-box;
        display: inline-block;
    }
}
