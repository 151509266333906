/**
* Gemaire Distributing
*/


// ==============================================
// Page Title
// ==============================================

.checkout-cart-index h1.page-title {
    margin-bottom: $space-base;
}


// ==============================================
// Cart Summary
// ==============================================

.cart-summary {
    @extend .abs-adjustment-incl-excl-tax;
    background: $c-background;
    margin-bottom: 40px;
    padding: $space-l $space-l 0;
    flex: 0 0 400px;
    max-width: 400px;
    position: relative;
    top: 0;
    align-self: flex-start;
}

.cart-summary>.title {
    @include h2;
    margin-bottom: $space-l;
}

.cart-summary .item-options {
    margin-left: 0;
}

.cart-summary .fieldset {
    margin-top: $space-base;
}

.cart-summary .field {
    margin-bottom: $space-base;
}

.cart-summary .methods .field>.label {
    display: inline;
}

.cart-summary .estimate>.legend,
.cart-summary .estimate>.legend+br {
    display: none;
}

.cart-summary .fieldset.coupon {
    display: flex;
    align-items: flex-end;
    margin-bottom: 25px;

    .control {
        width: calc(100% - 80px);
        position: relative;

        div.mage-error {
            position: absolute;
        }
    }

    .button {
        padding: 0;
        width: 80px;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .cart-summary {
        position: sticky;
    }
}

@media (max-width: ($bp-screen-l - 1px)) {
    .cart-summary {
        flex-basis: 300px;
    }
}

@media (min-width: $bp-screen-m) {

    .column.main .cart-summary .actions-toolbar {
        margin-left: 0;
    }

    .column.main .cart-summary .actions-toolbar>.secondary {
        float: none;
    }

}


// ==============================================
// Cart Totals
// ==============================================

.cart-totals {
    margin: $space-base -20px 0;
    padding: $space-l 0 0;
    border-top: 1px solid $c-border;

    .mark {
        padding-left: 20px;
        padding-bottom: 0;
        vertical-align: middle;
    }

    .amount {
        padding-right: 20px !important;
        text-align: right;
        font-size: 20px;
        font-weight: normal;
        white-space: nowrap;
        padding-bottom: 0;
        line-height: 40px;
    }

    .grand.totals {
        background: $c-border;
        transform: translateY(20px);

        .mark,
        .amount {
            line-height: 2.4;
        }
    }

    table {
        width: 100%;
    }

    .totals {
        &.surcharge-exempt {
            position: relative;
            top: -5px;

            th {
                padding-left: 30px;
            }

            * {
                font-size: 16px;
                font-weight: normal;
            }
        }
    }
}

.total-rules {
    th.mark {
        padding-left: 30px;
    }

    .amount {

        .rule-amount {
            font-size: 16px;
        }
    }
}

.rule-name {
    font-weight: $font-weight-normal;
}

.discount-info .discount.coupon {
    display: none;
}

.discount-info {

    th,
    td {
        border-bottom-color: transparent;
    }
}

.discount-info th {
    min-width: 120px;
}

.discount-info .title.enabled {
    @include lib-button-icon($_icon-font-size: 18px,
        $_icon-font-content: $icon-pointer-up,
        $_icon-font-position: "after",
        $_icon-font-color: $c-text-base,
        $_icon-font-margin: -1px 0 0 3px);
    cursor: pointer;
}

.discount-info .title.enabled.collapsed {
    @include lib-button-icon($_icon-font-size: 18px,
        $_icon-font-content: $icon-pointer-down,
        $_icon-font-position: "after",
        $_icon-font-color: $c-text-base,
        $_icon-font-margin: -1px 0 0 3px);
    cursor: pointer;
}

.cart-totals .grand .amount {
    padding-right: 0;
    text-align: right;

    strong {
        font-size: 32px;
    }
}

.cart-totals .msrp {
    margin-bottom: $space-base;
}

.cart-totals .totals-tax-summary .mark,
.cart-totals .totals-tax-summary .amount {
    border-top: 1px solid $c-border;
    border-bottom: 1px solid $c-border;
    cursor: pointer;
}

.cart-totals .totals-tax-summary .amount .price {
    @include lib-icon-font($icon-down,
        $_icon-font-size : 30px,
        $_icon-font-text-hide : true,
        $_icon-font-position : after,
        $_icon-font-display : block);
    padding-right: $space-l;
    position: relative;

    &::after {
        position: absolute;
        right: -5px;
        top: -12px;
    }
}

.cart-totals .totals-tax-summary.expanded .mark,
.cart-totals .totals-tax-summary.expanded .amount {
    border-bottom: 0;
}

.cart-totals .totals-tax-summary.expanded .amount .price {
    @include lib-icon-font-symbol($_icon-font-content : $icon-up,
        $_icon-font-position : after);
}

.cart-totals .totals-tax-details {
    display: none;
    border-bottom: 1px solid $c-border;

    &.shown {
        display: table-row;
    }
}

//----------------------------------------------

@media (max-width: ($bp-screen-l - 1px)) {

    .cart-totals .amount {
        font-size: 18px;
    }

    .cart-totals .grand .amount {
        strong {
            font-size: 22px;
        }
    }
}

// ==============================================
//  Cart . Table Wrapper
// ==============================================

.cart.table-wrapper .col.subtotal {
    vertical-align: top;
    border-left: 1px solid $c-border;
    min-width: 200px;
    padding-right: 0;

    .cart-price,
    a {
        display: block;
        padding-bottom: 15px;
    }
}

.cart.table-wrapper .items {
    td {
        padding: 20px;
    }

    .price-including-tax,
    .price-excluding-tax {
        font-weight: normal;
    }
}

.cart.table-wrapper .col.qty .control {
    display: flex;
    justify-content: space-between;
}

.cart.table-wrapper .product-item-photo img {
    height: auto;
}

.cart.table-wrapper .gift-registry-name-label {
    &::after {
        content: ':';
    }
}

@media (max-width: ($bp-screen-s - 1px)) {

    .cart.table-wrapper th.col.subtotal {
        display: none;
    }

    .cart.table-wrapper tbody.item {
        border-top: 1px solid $c-border;
        border-bottom: 1px solid $c-border;
    }

    .cart.table-wrapper tbody .col {
        display: block;
        border-top: none;
        border-bottom: none;
        margin: 0;
    }

    .cart.table-wrapper tbody .col.subtotal {

        border: 0;
        padding: 15px 15px 0;
        display: flex;
        justify-content: space-between;
        margin: 0;

        .price-including-tax,
        .price-excluding-tax {
            display: none;
        }
    }

    .cart.table-wrapper tbody .col.inv {
        border-top: 1px solid $c-emphasis-background;
        margin: 0 -15px 10px;
        padding: 15px;
    }

    .cart.table-wrapper tbody .col.actions {
        width: 30%;
        text-align: right;

        >* {
            margin-top: 10px;
        }
    }

    .cart.table-wrapper tbody .col.item {
        display: block;
        position: relative;
        width: auto;
        padding: 20px 15px 0;
    }
}

@media (min-width: $bp-screen-s) {

    .cart.table-wrapper .col.price,
    .cart.table-wrapper .col.msrp {
        text-align: right;
    }

    .cart.table-wrapper .col.qty .label {
        display: none;
    }
}



// ----------------------------------------------

@media (max-width: ($bp-screen-m - 1px)) {

    .cart.table-wrapper {
        overflow: inherit;
    }

    .cart.table-wrapper.item-actions td {
        text-align: left;
    }

    .cart.table-wrapper.col {
        display: block;
        width: 100%;
    }

    .cart.table-wrapper.col .item {
        @include clearfix();
    }

    .cart.table-wrapper.col.qty,
    .cart.table-wrapper.col.price,
    .cart.table-wrapper.col.subtotal,
    .cart.table-wrapper.col.msrp {
        display: block;
        float: left;
        white-space: nowrap;
        width: auto;
        text-align: left;
        padding-top: 0;

        &::before {
            font-size: $font-size-xs;
            color: $c-text-muted;
            content: attr(data-th) ':';
            display: block;
            font-weight: $font-weight-bold;
            padding-bottom: $space-xs;
        }
    }

    .cart.table-wrapper.col.price>.price-excluding-tax,
    .cart.table-wrapper.col.subtotal>.price-excluding-tax {
        line-height: 38px;
    }

    .cart.table-wrapper.col.msrp {
        white-space: normal;
    }

    .cart.table-wrapperthead .col.qty,
    .cart.table-wrapperthead .col.price,
    .cart.table-wrapperthead .col.item,
    .cart.table-wrapperthead .col.subtotal {
        display: none;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    // Google Chrome version 44.0.2403.107 m fix
    .cart.table-wrapper .items {
        min-width: 100%;
        width: auto;
        margin-bottom: 0;
    }

    .cart.table-wrapper .item-actions .actions-toolbar {
        display: table-cell;
    }

    .cart.table-wrapper .cart-products-toolbar+.cart thead th.col {
        padding-bottom: 7px;
        padding-top: 8px;
    }

    .cart.table-wrapper .cart+.cart-products-toolbar {
        margin-bottom: $space-l;
    }

}


// ==============================================
// Order Items . Table Wrapper
// ==============================================

.order-items.table-wrapper .col.price,
.order-items.table-wrapper .col.subtotal,
.order-items.table-wrapper .col.msrp {
    text-align: right;
}

.order-items.table-wrapper .col.qty {
    text-align: center;
}


// ==============================================
// Cart Container
// ==============================================

.cart-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
}

.cart-container .form-cart .continue {
    @include lib-icon-font($_icon-font-content: $icon-prev,
        $_icon-font-position: before,
        $_icon-font-color : $c-blue-base );
}

.cart-container .checkout-methods-items {
    display: flex;
    flex-wrap: wrap;
    background: $c-white;
    padding-top: 40px;
    margin-left: -$space-l;
    margin-right: -$space-l;

    &>* {
        flex: 1 1 auto;
    }

    .split-btn {
        margin-bottom: 10px;
    }
}

.cart-container .checkout-methods-items *:only-child {
    width: 100%;
}


// ----------------------------------------------

@media (max-width: ($bp-screen-m - 1px)) {

    .cart-container {
        display: block;
    }

    .cart-container .form-cart {
        @extend .abs-shopping-cart-items-mobile;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .cart-container {
        @include lib-clearfix;
    }

    .cart-container .form-cart {
        width: 100%;
        position: relative;
        padding-right: 20px;
    }

    .cart-container .widget {
        float: left;
    }

}

@media (min-width: $bp-screen-xl) {

    .cart-container .form-cart {
        padding-right: 50px;
    }

}


// ==============================================
// Cart Products Toolbar
// ==============================================

.cart-products-toolbar .toolbar-amount {
    margin: $space-l 0 $space-base;
    padding: 0;
}

.cart-products-toolbar .pages {
    margin: 0 0 $space-l;
    text-align: center;
}

.cart-products-toolbar .pages .items>.item {
    border-bottom: 0;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .cart-products-toolbar .toolbar-amount {
        line-height: 30px;
        margin: 0;
    }

    .cart-products-toolbar .pages {
        float: right;
        margin: 0 0 1px;
    }

    .cart-products-toolbar .pages .item {
        &:last-child {
            margin-right: 0;
        }
    }

}


// ==============================================
// Cart Products Toolbar Top
// ==============================================

// [todo] rewrite as modifier

// ==============================================
// Cross Sell
// ==============================================

.block.crosssell {
    margin-top: 70px;
}


// ==============================================
// Cart Tax Info
// ==============================================

.cart-tax-info+.cart-tax-total,
.cart .cart-tax-info+.cart-tax-total {
    display: block;
}

// ==============================================
// Block Shipping
// ==============================================

#block-shipping {
    display: none;
}

// ==============================================
// tr empty with height
// ==============================================

.tr_height td {
    padding: 10px;
}

// ==============================================
// Product Cart Item Container
// ==============================================

.product_cart_item_container {
    display: flex;
    align-items: flex-start;

    .col.qty {
        flex: 0 0 200px;
        margin-left: 10px;
    }

    input.qty {
        max-width: none;
    }

    .product-item-photo {
        padding-right: 30px;
        flex-basis: 150px;
        flex-shrink: 0;
        flex-grow: 0;
    }

    .product-item-details {
        flex: 1;

        .product-attribute {
            display: flex;
            flex-wrap: wrap;

            >div {
                padding-right: 20px;
            }
        }

        .product-item-name {
            font-size: 20px;
            margin-bottom: 2px;

            a {
                color: $c-input-border-contrast;

                &:hover {
                    color: $c-blue-bright;
                }
            }
        }
    }

    div.mage-error[generated] {
        margin: 0;
        position: absolute;
        font-size: 14px;
        top: 100%;
    }
}

@media (max-width: ($bp-screen-xl - 1px)) {

    .product_cart_item_container {

        .product-item-details {

            .product-item-name {
                font-size: 16px;
            }

        }
    }

}

@media (max-width: ($bp-screen-l - 1px)) {

    .product_cart_item_container {
        flex-wrap: wrap;
        justify-content: space-between;

        .product-item-photo {
            padding-right: 0;
            width: 90px;
            flex-basis: 90px;
        }

        .product-item-details {
            order: 3;
            flex-basis: 100%;

            .product-item-name {
                margin: 15px 0;
            }
        }
    }
}

@media (max-width: ($bp-screen-s - 1px)) {

    .product_cart_item_container {
        display: block;

        .product-item-photo {
            width: 150px;
            margin: 0 auto;
        }

        .product-item-details {

            .product-item-name {
                margin: 10px 0 30px;
            }
        }
    }

}

@media (max-width: ($bp-screen-xs - 1px)) {
    .cart.main .action-group {
        margin: 0;
        flex-direction: column;

        >* {
            width: 100%;
            margin-left: 0;
        }
    }
}

// ==============================================
// Cart Add All to Order Template
// ==============================================

.button-add-all-order-template {
    .menu_toggle {
        color: $c-white;
    }

    .menu_content {
        left: -30px;
        margin-top: 2px;
    }

    .search-input-wrapper {
        color: #505050;
    }

    .no-found_item {
        font-size: 15px;
        color: #505050;
    }
}