/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Review History List
// ==============================================

.review-history-list {
    margin-bottom: $margin-bottom;
    border-top: 1px dashed $c-border;
}

.review-history-list li {
    overflow: hidden;
    padding: 15px 0;
    border-bottom: 1px dashed $c-border;
}

.review-history-list .product-name {
    line-height: 27px; // Same height as star rating.
}

// ----------------------------------------------

@media (min-width: 600px) {

    .review-history-list .rating-box,
    .review-history-list .product-name {
        float: left;
        margin-right: 20px;
    }

    .review-history-list .button {
        float: right;
    }

}
