/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2017 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Region Exclusion
// ==============================================

.region-exclusion {
    display: block;
    margin-bottom: 15px;
    font-weight: normal;
}
