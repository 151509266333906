/**
* Gemaire Frontend
*
* @author    Brendan Falkowski (http://gravitydept.com)
* @package   enterprise_gemaire
* @copyright Copyright 2018 Gravity Department
* @license   All rights reserved.
*/


// ==============================================
// Breakpoint
// ==============================================

// If "$bp-header-alpha" is changed, you also need to update JS.
// See: /js/src/app-components.js
$bp-header-alpha: 960px !default;

$bp-page-focal: 960px !default;

$bp-screen-xl:  1440px;
$bp-screen-l:   1260px;
$bp-screen-m:   960px;
$bp-screen-s:   600px;
$bp-screen-xs:  480px;
$bp-screen-xxs: 320px;
