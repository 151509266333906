.magnifier-thumb-wrapper {
    position: relative;
    display: block;
    top: 0;
    left: 0;
}

.magnifier-lens {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    border: solid 1px $c-library-gray-3;
    z-index: $z-index-8;
}

.magnifier-loader-text {
    margin-top: 10px;
}

.magnifier-large {
    position: absolute;
    width: 32%;
    z-index: $z-index-1;
}

.magnifier-preview {
    position: absolute;
    top: 215px;
    bottom: 0;
    left: 58%;
    overflow: hidden;
    padding: 0;

    &:not(.hidden) {
        background-color: $c-white;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        max-width: inherit;
    }
}
