/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Panel
// ==============================================

//.panel {}

.panel-item {
    position: relative;
    float: left;
    padding: 20px 0 30px;
    text-align: center;
}

.panel-title {
    margin-bottom: 10px;
}

.panel-description {
    margin-bottom: 15px;
    font-size: $font-size-xs;
}


// ==============================================
// Modifier: Three
// ==============================================

@media (max-width: 899px) {

    .panel--three .panel-item {
        clear: both;
        width: 100%;
    }

}

// ---------------------------------------------

@media (min-width: 900px) {

    .panel--three .panel-item {
        width: 33.333%;
        padding-right: 20px;
        padding-left: 20px;
    }

}


// ==============================================
// Modifier: Four
// ==============================================

@media (max-width: 599px) {

    .panel--four .panel-item {
        clear: both;
        width: 100%;
    }

}

// ---------------------------------------------

@media (min-width: 600px) {

    .panel--four .panel-item {
        width: 50%;
        padding-right: 20px;
        padding-left: 20px;
    }

    .panel--four .panel-item:nth-child(3) {
        clear: left;
    }

}

// ---------------------------------------------

@media (min-width: 1024px) {

    .panel--four .panel-item {
        width: 25%;
    }

    .panel--four .panel-item:nth-child(3) {
        clear: none;
    }

}

// ==============================================
// Vars
// ==============================================

$panel-action-gap:        $space-l;
$panel-action-double-gap: $panel-action-gap + $panel-action-gap;


// ==============================================
// Panel
// ==============================================

.panel {
    position: relative;
    margin-bottom: $gap;
    border: 1px solid $c-border;
    background: $c-white;
}

@media (max-width: $bp-screen-m - 1px) {
    .account .panel {
        border: 0;
        margin-bottom: 0;
    }
}


// ==============================================
// Panel - Header
// ==============================================

.panel_header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: $space-l $space-l 0;
}

@media (max-width: $bp-screen-m - 1px) {
    .account .panel_header {
        padding: 0;
    }
}

// ==============================================
// Panel - Info
// ==============================================

.panel_info {
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: $space-l;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .panel_info {
        width: 100%;
    }

}


// ==============================================
// Panel - Action
// ==============================================

.panel_action {
    margin-bottom: $space-l;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .panel_action {
        order: 1;
        width: 100%;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-s) {

    .panel_action {
        flex: 0 0 360px;
    }

    .panel_action .action-group {
        float: right;
    }

}


// ==============================================
// Panel - Float
// ==============================================

.panel_float {
    margin: -$panel-action-gap 0 0 $space-l;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .panel_float {
        position: absolute;
        height: calc(100% + #{$panel-action-double-gap});
        top: -$panel-action-gap;
        right: 6px;
        margin: 0;
        z-index: $z-index-3;
    }

    .panel_float .float-button {
        position: sticky;
        top: calc(100% - 68px);
    }

}


// ==============================================
// Panel - Body
// ==============================================

.panel_body {
    padding: $space-l;
    min-height: 80px;
}

.panel_body > *:last-child {
    margin-bottom: 0;
}

@media (max-width: $bp-screen-m - 1px) {
    .account {

        .panel_body {
            padding: 15px 0 0;
        }

        .panel_footer {
            padding: 0;
        }

    }
}


// ==============================================
// Panel - Footer
// ==============================================

.panel_footer {
    padding: $space-l;
    border-top: 1px solid $c-border;
}

// ==============================================
// Panel - To Top Arrow
// ==============================================
.panel_to_top {
    @include lib-icon-font(
        $_icon-font-content        : $icon-arrow-up,
        $_icon-font-position: after
    );

    float: right;
    cursor: pointer;
    display: block;
    padding-bottom: 10px;
    display: none;
}


// ==============================================
// Modifier: Blank Panel - Panel without styling
// ==============================================

.panel.panel--blank {
    overflow: visible; // Reset to make the dropdowns and popups visible
    border: 0;

    .panel_header {
        padding: 0 0 $space-l;
    }

    .panel_body {
        padding: 0;
    }

    .admin__data-grid-loading-mask {
        margin: 0;
        background: $c-background;
    }

    @media (max-width: $bp-screen-m - 1px) {
        .account & {
            background: $c-white;

            .admin__data-grid-loading-mask {
                background: $c-white;
            }
        }
    }
}
