/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/

// ==============================================
// Nav List
// ==============================================

.nav-list {
    &::after {
        @include clearfix;
    }
}

.nav-link {
    position: relative;
    display: block;
    padding: 0 15px;
    line-height: $header-element-height;
    color: $c-text-base;

    &:hover {
        color: $c-link-base;
    }
}

// ----------------------------------------------
// Modifier: Menu

.nav-link--menu {
    &::before {
        content: '';
        position: absolute;
        width: 11px;
        height: 11px;
    }
}

// ----------------------------------------------
// Modifier: New

.nav-link--new {
    &::after {
        content: 'NEW';
        position: absolute;
        bottom: 50%;
        margin-left: 2em;
        padding: 2px 4px;
        border-radius: $radius-s;
        background: $c-badge-new;
        color: #FFF;
        font-size: $font-size-xxxxxs;
        letter-spacing: 1px;
        line-height: 1;
        transform: translateY(50%);
    }
}

// ----------------------------------------------

@media (min-width: $bp-header-alpha) {

    .nav-link--new {
        &::after {
            bottom: -8px;
            left: 50%;
            margin-left: 0;
            transform: translateX(-50%);
        }
    }

}


// ==============================================
// Nav Menu
// ==============================================

.nav-menu {
    visibility: hidden;
}

@media (min-width: $bp-screen-m) {

    .nav-menu {
        top: 25px;
        @include menu;   
        width: auto; 
    }
}

@media (max-width: $bp-screen-l - 1px) {

    .nav-menu {
        position: static;
        background: $c-white;
        width: auto;
        border: 0;
        box-shadow: none;
        margin: 0;
        visibility: visible;
    }
}

[data-ui='nav-1']  .nav-1  .nav-menu,
[data-ui='nav-2']  .nav-2  .nav-menu,
[data-ui='nav-3']  .nav-3  .nav-menu,
[data-ui='nav-4']  .nav-4  .nav-menu,
[data-ui='nav-5']  .nav-5  .nav-menu,
[data-ui='nav-6']  .nav-6  .nav-menu,
[data-ui='nav-7']  .nav-7  .nav-menu,
[data-ui='nav-8']  .nav-8  .nav-menu,
[data-ui='nav-9']  .nav-9  .nav-menu,
[data-ui='nav-10'] .nav-10 .nav-menu {
    visibility: visible;
}

[data-ui='nav-1'] .nav-1,
[data-ui='nav-2'] .nav-2,
[data-ui='nav-3'] .nav-3,
[data-ui='nav-4'] .nav-4,
[data-ui='nav-5'] .nav-5,
[data-ui='nav-6'] .nav-6,
[data-ui='nav-7'] .nav-7,
[data-ui='nav-8'] .nav-8,
[data-ui='nav-9'] .nav-9,
[data-ui='nav-10'] .nav-10 {
    a.dropdown-trigger {
        &::after {
            transform: rotate(180deg);
        }
    }
}


// ==============================================
// Nav Menu - List
// ==============================================

.nav-menu_liner {
    overflow: hidden;
}

.nav-menu_list li {
    border-top: 1px solid $c-contrast-border;
}

.nav-menu_list a {
    display: block;
    padding: 12px 15px;
    color: #FFF;
    line-height: $line-height-xs;
}

.no-touchevents .nav-menu_list a:hover {
    background: $c-action-default-base;
    color: #FFF;
}


// ==============================================
// Nav Menu - View All Link
// ==============================================

.nav-menu_view-all-link {
    display: block;
    padding: 12px 15px;
    border-top: 1px solid $c-contrast-border;
    color: #FFF;
}

.no-touchevents .nav-menu_view-all-link:hover {
    background: $c-action-default-base;
    color: #FFF;
}


// ==============================================
// Menu Top
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .menu-top {
        position: static;
        padding: 12px 16px;
        background: $c-contrast-background;
        color: $c-text-contrast-base;
        display: none;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .menu-top {
        position: absolute;
        top: 98px;
        right: $layout-indent__width;
    }

    .menu-top .greet.welcome {
        display: none;
    }

}
