/**
* Gemaire Distributing
*/

// ==============================================
// Account Dropdown - Wrap
// ==============================================

.account-link {
    display: flex;
    align-items: flex-end;
    margin: 10px 15px;
}

.account-link a {
    text-decoration: none;
}

@media (max-width: $bp-screen-m - 1px) {
    .account-link {

        > .dropdown-trigger,
        .account-dropdown:before,
        .account-dropdown:after {
            display: none;
        }

        .account-dropdown {
            margin-top: 0;
            height: 100vh;
            overflow: auto;
        }

        &.active {
            .mage-dropdown-dialog {                
                right: 0;
            }

            &:after {
                content: "";
                position: fixed;
                background: rgba(0, 0, 0, 0.25);
                top: 0;
                width: 100%;
                height: 100%;
                left: 0;
                z-index: 100;
            }
        }

        .mage-dropdown-dialog {
            position: fixed;
            top: 0;
            right: -$account-nav-width;
            width: $account-nav-width;
            border-left: 1px solid $c-border;
            transition: all ease 0.4s;
            padding: 0;
            z-index: 101;
            display: block !important;
        }
    }

        
}

// ==============================================
// Account Dropdown
// ==============================================

.account-dropdown {

    @include menu;

    &_links a {
        color: $c-text-base;

        &.button {
            font-weight: normal;
            color: $c-white;
            margin: 0 $space-base $space-base;
            background: $c-blue-bright !important;
            padding: 0;
            display: block;
        }
    }

    &:before {
        @include triangle($direction: up, $size: 8px, $color: $c-border);
        top: -8px;
        right: 2px;
    }

    &:after {
        @include triangle($direction: up, $size: 6px, $color: $c-white);
        top: -6px;
        right: 4px;
    }

    .authorization-link {
        padding: $space-s $space-base;
        border-top: 1px solid $c-border;
        
        span {
            color: $c-text-base;
        }
    }

}

@media (max-width: $bp-screen-m - 1px) {

    .account-dropdown {
        width: 100%;
    }
}

.account-dropdown_links a {
    display: flex;
    padding: $space-xs $space-base;
    font-weight: 600;
}

.account-dropdown_links a:hover {
    background: $c-background;
    text-decoration: none;
}