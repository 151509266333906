/**
* Gemaire Distributing
*/


// ==============================================
// Hack to fix the delay on hidding store locator for puchout customers
// ==============================================

.is-punchout-session .header-store,
.is-punchout-session .header-store_toggle::before {
    color: $c-blue-bright !important;
}
