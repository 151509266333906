/**
* Gemaire Distributing
*/


// ==============================================
//  Sidebar
// ==============================================

.sidebar .block-title {
    @include h3;
    margin-bottom: 10px;
}

.sidebar .widget.block:not(:last-child),
.sidebar .widget:not(:last-child) {
    margin-bottom: $space-xxl;
}
