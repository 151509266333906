/**
* Gemaire    Frontend
* @author    Andres Fraguela
* @copyright Gemaire Distributors. All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$c-verticalscroll-background: $c-border !default;

$c-verticalscroll-triangle: #FFF !default;


// ==============================================
// Vertical Scroll
// ==============================================

// Prevent from applying to "@media print".
@media only screen {

    [data-verticalscroll='scroll'] {
        position: relative;
        overflow-y: hidden;
        width: 100%;
    }

    [data-verticalscroll='scroll'].hint {
        padding-bottom: 15px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 15px;
            background: $c-verticalscroll-background;
        }

        &::after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateY(-50%);
            @include triangle (down, 7px, $c-verticalscroll-triangle);
        }
    }

    [data-verticalscroll='scroll'] .verticalscroll_liner {
        overflow-y: scroll;
    }

}
