.tooltip.wrapper {
    @include lib-tooltip(
        $_tooltip-position        : bottom,
        $_tooltip-selector-content: '.tooltip.content',
        $_tooltip-selector-toggle : '.tooltip.toggle'
    );
}

.tooltip.wrapper .tooltip.content dl {
    margin-bottom: 0;
}

.tooltip.wrapper .tooltip.content dd {
    white-space: normal;
}

.tooltip.wrapper .tooltip.content .subtitle {
    display: inline-block;
    margin-bottom: $space-base;
    font-size: $font-size-l;
    font-weight: 500;
}

.tooltip.wrapper .tooltip.content .label {
    margin-top: $space-base;

    &:first-child {
        margin-top: 0;
    }
}

.tooltip.wrapper .tooltip.content .values {
    margin: 0;
}

.ui-tooltip {
    position: absolute;
    z-index: 9999;
}

// ----------------------------------------------

@media (max-width: $bp-screen-xs - 1px) {

    .tooltip.wrapper .tooltip.content {
        min-width: 10rem;
    }

}
