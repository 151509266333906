/**
* Gemaire Distributing
*/

// ==============================================
// Zendesk - Mobile button
// ==============================================

._has-modal .zEWidget-launcher {
    z-index: 98 !important;
}

@media (max-width: $bp-screen-s - 1px) {
    .zEWidget-launcher {
        left: 0 !important;
        right: auto  !important;
        z-index: 98 !important;
        bottom: 6px !important;
        visibility: visible;
    }

    .nav-open .zEWidget-launcher,
    .nav-before-open .zEWidget-launcher {
        transition: visibility 0.4s;
        visibility: hidden;
    }
}