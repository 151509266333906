/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Product Image
// ==============================================

.product-image {
    position: relative;
    display: block;

    &::before {
        content: '';
        position: absolute;
        z-index: $z-product-grid-vline;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: $radius-s;
    }
}

.product-image img {
    border-radius: $radius-s;
}

.product-image:hover::before {
    border: 5px solid rgba($c-action-default-base, 0.5);
}


// ==============================================
// Modifier: Border
// ==============================================

.product-image--border {
    padding: 5px;
    background: #FFF;

    &::before {
        border: 1px solid $c-border;
    }
}

.product-image--border img {
    width: 100%;
    height: auto;
    border-radius: 0;
}

// ==============================================
// Product Image Thumbnail 60X60
// ==============================================

.image-thumbnail-60x60 {
    width: 60px;
    height: 60px;
}
