/**
* Gemaire Distributing
*/


// ==============================================
// Simple Loading
// ==============================================

.simple-loading {
    width: 30px;
    height: 30px;
    margin: 10px auto;
    background-image: $icon-loading--blue;
    animation: rotate-clockwise 4s linear infinite;
}

.s7viewer { 
    .simple-loading {
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
    }
}
