/**
* Gemaire Distributing
*/

// ==============================================
// Branch Finder
// ==============================================

.branch-finder {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        'branch-product'
        'branch-search'
        'branch-map'
        'branch-results';

    .header-store & {
        display: none;
    }

    address.inline-address {
        span+span {
            &::before {
                content: ', ';
            }
        }
    }

    .table {
        margin-bottom: 0;

        tbody {
            tr {
                cursor: pointer;
            }
        }

        td {
            vertical-align: top;
        }

        .col-name {
            width: 26%;
        }

        .col-address {
            width: 36%;
        }

        .col-distance {
            width: 14%;
        }
    }

    .branch-buttons {
        width: 100%;
        display: flex;
        align-items: flex-end;

        .button,
        .message {
            min-width: 110px;
        }

        .button {
            padding: 0 10px;
            font-size: $font-size-xxs;
        }

        .message {
            margin-bottom: 0;
            font-size: $font-size-xxs;
            line-height: 14px;
            padding-right: 8px;

            &:first-child {
                margin-top: 0;
            }
        }

        @media (min-width: $bp-screen-m + 1) {
            flex-direction: column;

            >*+* {
                margin-top: 10px;
            }
        }

        @media (max-width: $bp-screen-m) {
            >*+* {
                margin-left: 10px;
            }
        }
    }

    .get-directions {
        display: block;
        white-space: nowrap;
    }

    .product-availability {
        white-space: nowrap;

        .availability-count {
            font-weight: 600;
        }

        &.available .availability-count {
            color: $c-green;
        }

        &.unavailable .availability-count {
            color: $c-red-base;
        }

        .stock {
            flex-wrap: nowrap;
            font-size: $font-size-xs;
        }
    }

    .branch-marker-label {
        width: 29px;
        height: 17px;
        line-height: 17px;
        border: 1px solid #fee1d7;
        border-radius: 3px;
        background: #eb3a44;
        color: #fee1d7;
        text-align: center;
        font-size: 9px;
    }

    @media (min-width: $bp-screen-m + 1) {
        grid-template-columns: 5fr 4fr;
        grid-template-rows: auto auto 1fr;
        grid-template-areas:
            'branch-product branch-product'
            'branch-search branch-map'
            'branch-results branch-map';
    }

    @media (max-width: $bp-screen-m) {
        h2 {
            font-size: 20px;
        }

        .table,
        .table thead,
        .table tbody,
        .table td,
        .table th {
            display: block;
        }

        .table tr {
            display: flex;
        }

        .table thead tr {
            background: $c-table-background;
            border-top: 1px solid $c-border;
            border-bottom: 1px solid $c-border;

            th {
                border: none;
            }

            .col-name {
                width: 70%;
                padding-left: 25px;
            }

            .col-availability {
                width: 30%;
            }

            .col-address,
            .col-distance,
            .col-buttons {
                display: none;
            }
        }

        .table tbody tr {
            flex-wrap: wrap;
            padding-bottom: 10px;
            border-bottom: 1px solid $c-border;
            position: relative;

            @include lib-icon-font($_icon-font-content: $icon-down,
                $_icon-font-size: 22px,
                $_icon-font-position: after,
                $_icon-font-display: block);

            display: flex;

            &:after {
                position: absolute;
                top: 11px;
                left: 0;
            }

            &.expanded {
                &:after {
                    transform: rotate(180deg);
                }
            }

            &:not(.expanded) {

                .col-address,
                .col-distance,
                .col-buttons {
                    display: none;
                }
            }

            td {
                padding: 10px 10px 0 25px;
                border: none;
            }

            .col-name {
                order: -2;
                width: 70%;

                br {
                    display: none;
                }
            }

            .col-availability {
                order: -1;
                width: 30%;
                padding-left: 10px;

                .separator {
                    text-indent: -9999999px;
                    display: inline-block;

                    &:after {
                        content: ' / ';
                        text-indent: 0;
                        display: inline-block;
                        float: left;
                        padding-left: 5px;
                    }
                }
            }

            .col-address,
            .col-distance,
            .col-buttons {
                width: 100%;
            }

            .get-directions {
                display: inline;
                margin-left: 10px;
            }
        }
    }
}

.store-hours-collapsible {
    &[aria-expanded='false'] {
        [data-role='content'] {
            display: none;
        }
    }

    &[aria-expanded='true'] {
        .dropdown-trigger:after {
            transform: rotate(180deg);
        }
    }
}

.store-hours {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &.store-opened {
        .store-hours-label {
            color: $c-green;
        }
    }

    &.store-closed {
        .store-hours-label {
            color: $c-red-base;
        }
    }

    .dropdown-trigger {
        display: block;
        position: relative;
        padding-right: 22px;
        color: $c-text-base;

        &:after {
            position: absolute;
            right: 0;
            top: 1px;
        }
    }
}

.store-hours-content {
    display: inline-block;
    margin-top: 5px;
    padding: 12px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
    background: #fff;

    .day-row {
        display: flex;

        .day {
            width: 90px;
            margin-right: 10px;
        }
    }

    .after-hours {
        margin: 8px -12px 0;
        padding: 8px 12px 0;
        border-top: 2px solid #d3d3d3;
    }
}

.modal--branch-finder {
    &.modal--slide {
        left: 15%;

        .modal-content {
            padding-top: 0;
        }
    }

    @media (min-width: $bp-screen-m + 1) {

        .modal-content,
        .branch-finder-wrapper,
        .branch-finder {
            height: 100%;
        }
    }

    @media (max-width: $bp-screen-l) {
        &.modal--slide {
            left: 0;
        }
    }

    @media (max-width: $bp-screen-m) {
        &.modal--slide {
            .modal-content {
                padding: 0 15px 15px;
            }
        }
    }
}

.modal--branch-finder-overlay {
    z-index: calc(#{$z-modal-backdrop} + 10);
}

// ==============================================
// Branch Finder - Map
// ==============================================

.branch-map {
    grid-area: branch-map;
    position: relative;

    .map-container {
        height: 100%;
        overflow: hidden;
    }

    .branch-buttons {
        flex-direction: row;
        justify-content: flex-start;

        >*+* {
            margin-top: 0;
            margin-left: 10px;
        }
    }

    .branch-map--marker {
        appearance: none;
        border: 0;
        background: none;
        cursor: pointer;

        img {
            transition: all 200ms;
        }

        .branch-map--label {
            position: absolute;
            width: 34px;
            left: calc(50% - 17px);
            top: calc(100% + 2px);
            padding: 2px 0;
            background: #f33d45;
            border-radius: 3px;
            border: 1px solid #c84035;
            color: rgba(255, 255, 255, 0.85);
            font-size: 10px;
            text-align: center;
            font-weight: 600;
            transition: 200ms all;
            transform: translateY(-10px);
            opacity: 0;
            pointer-events: none;
        }

        .branch-map--info-box {
            box-sizing: border-box;
            width: 270px;
            padding: 20px;
            border-radius: 5px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            position: absolute;
            bottom: calc(100% + 24px);
            left: calc(50% - 136px);
            background: #fff;
            transition: 200ms all;
            transform: translateY(-20px);
            opacity: 0;
            pointer-events: none;
            cursor: default;

            address,
            a {
                font-size: $font-size-xs;
            }

            .action-close {
                padding: 0;
                right: 5px;
                top: 5px;
            }

            &:after {
                background: linear-gradient(45deg,
                        rgba(255, 255, 255, 1) 50%,
                        rgba(255, 255, 255, 0) 51%,
                        rgba(255, 255, 255, 0) 100%);
                box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
                content: '';
                height: 15px;
                left: 50%;
                position: absolute;
                top: 100%;
                transform: translate(-50%, -50%) rotate(-45deg);
                width: 15px;
            }
        }

        &.current {
            .branch-map--label {
                background: #1e5ca5;
                border-color: #3a7fc2;
            }

            .branch-map--info-box {
                bottom: calc(100% + 15px);
            }
        }

        &.pinned {
            z-index: 3;

            .branch-map--info-box {
                opacity: 1;
                transform: translateY(0);
                pointer-events: auto;
            }
        }

        &.hovered {
            z-index: 2;
        }

        &.hovered,
        &.pinned {
            .branch-map--label {
                transform: translateY(0);
                opacity: 1;
            }

            &:not(.current) img {
                transform: scale(1.2) translateY(-4px);
            }
        }
    }

    .branch-map--cluster {
        appearance: none;
        border: 0;
        background: none;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 500;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        transform: translate(-50%, -50%);

        &.small-cluster {
            width: 25px;
            height: 25px;
            background: #f5a243d0;
            font-size: $font-size-xs;
        }

        &.medium-cluster {
            width: 30px;
            height: 30px;
            background: #d32222b7;
        }

        &.large-cluster {
            width: 35px;
            height: 35px;
            background: #3f1bddbb;
            font-size: $font-size-xs;
        }

        &:before,
        &:after {
            display: block;
            content: '';
            position: absolute;
            z-index: -1;
            opacity: 0.4;
            width: inherit;
            height: inherit;
            background: inherit;
            border-radius: inherit;
            transform: scale(1.4);
        }

        &:before {
            transform: scale(1.8);
        }
    }

    .map-expander {
        width: 100%;
        height: 24px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: $c-blue-bright;
        z-index: 1;
        border: none;

        @include lib-icon-font($_icon-font-content: $icon-down,
            $_icon-font-size: 22px,
            $_icon-font-position: after,
            $_icon-font-display: block);
        display: flex;
        justify-content: center;
        align-items: center;

        &:after {
            color: white;
        }

        &.opened {
            transform: rotate(180deg);
        }
    }

    @media (max-width: $bp-screen-m) {
        .map-container {
            height: 100px;

            &.opened {
                height: 400px;
            }
        }
    }

    @media (min-width: $bp-screen-m + 1) {
        .map-expander {
            display: none;
        }
    }
}

// ==============================================
// Branch Finder - Search
// ==============================================

.branch-search {
    grid-area: branch-search;

    .control-search {
        .search-bar {
            display: flex;
            align-items: flex-end;

            .input-text {
                flex-basis: calc(100% - 100px);
                border-radius: 0;
                padding: 0;
            }

            .button {
                margin-left: 10px;
            }
        }

        .my-location {
            display: inline-flex;
            align-items: center;
            color: $c-link-base;

            &:hover {
                color: $c-link-hover;
            }

            &:focus {
                color: $c-link-focus;
            }

            &:active {
                color: $c-link-active;
            }
        }

        @media (max-width: $bp-screen-m) {
            margin-top: 0;
        }
    }
}

// ==============================================
// Branch Finder - Results
// ==============================================

.branch-results {
    grid-area: branch-results;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &:not(.expanded) {

        .table tbody tr:nth-child(n + 5),
        .all-other-branches {
            display: none;
        }
    }

    @media (min-width: $bp-screen-m + 1) {
        overflow-y: auto;
    }

    .branch-results-list {
        .results-more {
            width: 100%;
            text-transform: uppercase;
            padding: 15px;
            display: block;
            text-align: center;
            color: $c-link-base;

            &:hover {
                color: $c-link-hover;
            }

            &:focus {
                color: $c-link-focus;
            }

            &:active {
                color: $c-link-active;
            }
        }
    }
}

// ==============================================
// Branch Finder - Product
// ==============================================

.branch-product {
    grid-area: branch-product;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-top: 24px;
    background: $c-background;

    .branch-product-image {
        margin-right: 10px;
    }
}