/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Simple Table
// ==============================================

.simple-table {
    margin-bottom: $margin-bottom;
}

.simple-table th {
    font-weight: bold;
}

.simple-table th,
.simple-table td {
    padding-right: 1.75em;
    padding-bottom: 0.5em;
    line-height: $line-height-xs;
    vertical-align: top;

    &:last-child {
        padding-right: 0;
    }
}

.simple-table tr:last-child th,
.simple-table tr:last-child td {
    padding-bottom: 0;
}
