/**
* Gemaire Distributing
*/


// ==============================================
// Vars
// ==============================================

$checkout-sidebar-width: 400px;

// ==============================================
// Checkout Container
// ==============================================

body:not(.gemaire-checkout-page) .checkout-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    body:not(.gemaire-checkout-page) .checkout-container {
        flex-wrap: wrap;
    }
}

body:not(.gemaire-checkout-page) .checkout-container .no-quotes-block {
    margin: $space-l 0;
}


// ==============================================
// OPC Wrapper
// ==============================================

.opc-wrapper {
    flex: 1 1 100%;
    order: 2;
}

.opc-wrapper .step-content {
    padding: $space-l;
    border: 1px solid $c-border;
    margin-bottom: $space-l * 2;

    &#checkout-step-shipping {
        border: 0;
        padding: 0;
    }

    .tabs_item {
        padding: $space-l;
        margin: 0;
        border-bottom: 0;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .opc-wrapper {
        flex-basis: auto;
        padding-right: 20px;
        order: 1;
    }

}

@media (min-width: $bp-screen-xl) {

    .opc-wrapper {
        padding-right: 50px;
    }

}


// ==============================================
// OPC Sidebar
// ==============================================

.opc-sidebar {
    flex: 0 1 $checkout-sidebar-width;
    max-width: $checkout-sidebar-width;
    order: 1;
    padding: $space-l $space-l 0;
    background: $c-background;
    margin-bottom: 40px;

    .loading-mask {
        width: auto;
        margin-top: -$space-l;
        margin-left: -$space-l;
        margin-right: -$space-l;
    }
}

// ----------------------------------------------

@media (max-width: $bp-screen-l - 1px) and (min-width: $bp-screen-m) {

    .opc-sidebar {
        flex-basis: 300px;
    }

}

@media (min-width: $bp-screen-m) {

    .opc-sidebar {
        order: 2;
        flex-shrink: 0;
        margin-top: $space-base;
        position: sticky;
        top: 0;
    }

    .opc-sidebar .action-close {
        display: none;
    }

    .opc-sidebar .modal-inner-wrap {
        transform: none;
        overflow: hidden;
        background: transparent;
    }

}

// ==============================================
// Checkout Control
// ==============================================

.checkout-control {
    margin: 0 0 35px 0;
    border: 1px solid $c-border;
    padding: $space-l;
}

// ----------------------------------------------

@media (min-width: $bp-screen-s) {

    .checkout-control .control {
        margin-right: $space-l;
    }

    .checkout-control .field {
        width: 100%;
    }

    .checkout-control .job-selector {
        margin-left: 0;
    }

    .checkout-control .label {
        display: block;
    }

}

// ----------------------------------------------

// ==============================================
// Checkout PO Number
// ==============================================

.ponumber-tooltip {
    position: absolute;
    right: 0;
    bottom: 0;
}

.ponumber-tooltip i {
    font-size: $font-size-xxl;
    color: $c-text-base;
}

@media (min-width: $bp-screen-s) {

    .checkout-ponumber {
        padding-right: 22px;
    }

}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .checkout-ponumber, .checkout-jobname {
        margin-top: 26px;
    }

}

// ==============================================
// Checkout Order Details Section
// ==============================================
.ahri-nums {
    margin: 15px 0 -20px;
}

// Step Title
//-----------------------------------------

.step-title {
    font-weight: normal;
    font-size: 32px;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .step-title {
        font-size: 28px;
    }

}


// Hide steps temporarily
//-----------------------------------------

.checkout-container .steps {
    display: none;
}

// Hide Apply Gift Card
//-----------------------------------------

.opc-payment-additional.giftcardaccount {
    display: none;
}


// Billing Step
//-----------------------------------------

//.checkout-billing-address {}

// Inventory Message
//-----------------------------------------

.opc > .message {
    margin-bottom: 35px;
}

// Review Step
//-----------------------------------------

.review-item {

    td {
        vertical-align: top;
    }

    .product_cart_item_container {
        flex-wrap: nowrap;
    }

    .product-item-details {
        padding: 20px 0 0 0;
        order: 0;
        flex-basis: auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        > div:first-child {
            flex-basis: 500px;
        }

        .product-attribute {
            padding-top: 20px;
            margin-bottom: 5px;
        }
    }
}

.review-item-image {
    img {
        max-height: 100px;
    }
}

@media (min-width: $bp-screen-s) {

    .review-item {

        .review-item-qty {
            text-align: center;
        }

        .product-item-photo {
            padding: 0;
        }

        .product-item-details {
            padding: 0 0 0 20px;
        }

    }

}

@media (max-width: $bp-screen-s - 1px) {

    .table-checkout-review {

        border-left: 1px solid $c-border;
        border-right: 1px solid $c-border;

        tbody td {
            padding: 2px 20px;
        }

    }

    .action-group-place-order {

        justify-content: center;

        button {
            order: 1;
            width: 100%;
        }
    }

}

// ==============================================
// Hide-prices Checkout
// ==============================================

.hide-prices-checkout {
    #opc-sidebar,
    .table-checkout-review th:nth-child(3),
    [data-role='cartPriceBox'],
    [data-role='cartSubtotalBox'],
    .action-group-place-order .action-group_label,
    .no-print-prices,
    .order-totals {
        display: none;
    }

    .opc-wrapper {
        padding: 0;
    }
}

.gemaire-checkout-page {
    .checkout-page-app-wrapper {
        margin-bottom: 40px;
    }

    .page-main {
        padding: 0;
    }

    .cart-totals .amount {
        line-height: 24px;
        padding: 8px 0;
    }

    .header.content.checkout-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 75px;
        background: #006CB1;

        .logo-wrapper {
            margin-left: 30px;
        }

        .logo_image {
            width: 160px;
        }

        @media (min-width: $bp-screen-m) {
            .header-store_wrapper,
            .back-to-cart-button {
                background-color: #035491;
                transition: 200ms;
            }

            button.header-store_wrapper,
            .back-to-cart-button {
                &:hover {
                    background: lighten(#035491, 2%);
                }
            }
        }

        .back-to-cart-button {
            color: white;
            margin-right: 30px;
            display: flex;
            align-items: center;
            height: 50px;
            padding: 0 10px;
            font-size: 20px;

            i {
                margin-right: 10px;
                font-size: 30px;
            }

            @media (max-width: $bp-screen-m - 1px) {
                margin-right: 0;

                span {
                    display: none;
                }

                i {
                    font-size: 24px;
                }
            }
        }
    }

    .checkout-container {
        max-width: 1640px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 0 auto;

        @media (min-width: $bp-screen-m) {
            display: flex;
        }
    }

    .branch-finder-checkout-wrapper {
        margin-left: auto;
        margin-right: 10px;
    }

    .branch-finder-checkout-wrapper .header-store {
        position: static;
    }
}

.cartProductQtyError .input-text.qty {
    border-color: $c-red-base;
    color: $c-red-base;
}
