/**
* Gemaire Distributing
*/


// ==============================================
// Individual Loading
// ==============================================

.individual-loading {
    display: inline;
    padding: $space-xxxs $space-xxs;
    background: linear-gradient(300deg, $c-library-gray-0, $c-library-gray-3, $c-library-gray-0);
    background-size: 1200%;
    color: $c-gray-slate;
    font-size: $font-size-xs;
    font-weight: 300;
    letter-spacing: 0.03rem;
    text-rendering: optimizeLegibility;
    text-transform: lowercase;
    animation: gradientLoading 3s ease infinite;
}
