/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2017 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$c-express-pickup-border: $c-blue-base !default;

$c-express-pickup-top-background: $c-blue-base !default;
$c-express-pickup-top-text:       #FFF !default;

$c-express-pickup-bottom-background: #FFF !default;


// ==============================================
// Express Pickup
// ==============================================

.express-pickup {
    overflow: hidden;
    max-width: 320px;
    border: 1px solid $c-express-pickup-border;
    border-radius: $radius-base;
}


// ==============================================
// Express Pickup - Top
// ==============================================

.express-pickup_top {
    padding: 10px 15px 15px;
    background: $c-express-pickup-top-background;
    color: $c-express-pickup-top-text;
}

.express-pickup_top-label {
    margin-bottom: 0.5em;
    font-size: $font-size-xs;
    letter-spacing: 2px;
}


// ==============================================
// Express Pickup - Bottom
// ==============================================

.express-pickup_bottom {
    padding: 10px 15px;
    background: $c-express-pickup-bottom-background;
}
