/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// App Store List
// ==============================================

.app-store-list {
    overflow: hidden;
}

.app-store-list li {
    float: left;
    margin-right: 15px;
}

.app-store-list a {
    display: block;
}
