//
//  Collage left appearance styles
//  _____________________________________________

[data-appearance='collage-left'] {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay {
            margin-right: auto;
        }
    }
}
