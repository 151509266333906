/**
* Gemaire Frontend
*
* @author     Anika Abreu
*
*/



// ==============================================
// Online Only Inventory Pill
// ==============================================

.online-only-inventory-pill {
    display: flex;
    width: 50%;
}

// ==============================================
// Online Only Inventory Text
// ==============================================

.inventory_product-count-text {
    color: $c-text-base;
}