/**
* Acme Refrigeration
*/


// ==============================================
// Units
// ==============================================

// Hex is the percentage difference between 101010 and 202020.
// Used with Sass functions darken() and lighten().
$hex: 6.1% !default;


// ==============================================
// Named Colors
// ==============================================

// Named colors are rarely used in other partials. They establish the standard
// palette to be referenced from other variables in this file.

$c-blue-base:    #003764 !default;
$c-blue-bright:  #0080DF !default;
$c-blue-light:   #E0F2FF !default;
$c-gray-slate:   #808088 !default; // Blue channel tinted +8
$c-darkgray:     #666464 !default;
$c-green:        #048C49 !default;
$c-orange:       #FC6D1E !default;
$c-red-base:     #C70009 !default;
$c-red-ruby:     #CC395E !default;
$c-purple-base:  #7A61AB !default;
$c-purple-light: #AE9ADB !default;
$c-turquoise:    #2E92A6 !default;
$c-yellow-base:  #C6B63D !default;
$c-yellow-light: #FFF5E3 !default;
$c-white:        #FFFFFF !default;
$c-black:        #000000 !default;


// ==============================================
// UI State Colors
// ==============================================

$c-danger:   $c-red-base         !default;
$c-help:     $c-purple-base      !default;
$c-info:     $c-blue-base        !default;
$c-security: $c-blue-light       !default;
$c-success:  $c-green            !default;
$c-warn:     $c-yellow-base      !default;
$c-accent:   $c-library-orange-8 !default;


// ==============================================
// Tone Colors
// ==============================================

// Tone colors set the mood. The default tone defines the standard for
// backgrounds and borders of most elements. Other tones attract/divert
// attention relative to the default tone. Use these variables liberally in
// partials to keep the UI consistent.

// ----------------------------------------------
// Default - Baseline for most elements.

$c-body-background: #EFF3F5 !default;
$c-background: #DFE5E8 !default;
$c-border:     #D0D0D0 !default;
$c-overlay: black(0.25);

// ----------------------------------------------
// Contrast - Opposite of the default tone (light/dark).

$c-contrast-background: #4E4E56 !default; // Blue channel tinted +8
$c-contrast-border:     darken(#4E4E56, 10%) !default;

// ----------------------------------------------
// Emphasis - Like the default tone but more priority.

$c-emphasis-background: #E8E8E8 !default;
$c-emphasis-border:     #C0C0C0 !default;

// ----------------------------------------------
// Divider - Thicker border to separate modules.

$c-divider-border: #E9E9E9 !default;

// ----------------------------------------------
// Offset - In between the default and contrast tones.

$c-offset-background: $c-gray-slate !default;
$c-offset-border:     darken($c-gray-slate, 10%) !default;

// ----------------------------------------------
// Priority - Highlight with a color that stands out from the default tone.

$c-priority-background: $c-yellow-light !default;


// ==============================================
// Action Colors
// ==============================================

// Highest priority
$c-action-danger-base:   $c-danger              !default;
$c-action-danger-hover:  darken($c-danger, 5%)  !default;
$c-action-danger-focus:  darken($c-danger, 5%)  !default;
$c-action-danger-active: darken($c-danger, 10%) !default;

// High priority
$c-action-primary-base:   $c-green              !default;
$c-action-primary-hover:  darken($c-green, 5%)  !default;
$c-action-primary-focus:  darken($c-green, 5%)  !default;
$c-action-primary-active: darken($c-green, 10%) !default;

// Default priority
$c-action-default-base:   $c-blue-bright              !default;
$c-action-default-hover:  darken($c-blue-bright, 5%)  !default;
$c-action-default-focus:  darken($c-blue-bright, 5%)  !default;
$c-action-default-active: darken($c-blue-bright, 10%) !default;

// Low priority
$c-action-secondary-base:   $c-gray-slate              !default;
$c-action-secondary-hover:  darken($c-gray-slate, 5%)  !default;
$c-action-secondary-focus:  darken($c-gray-slate, 5%)  !default;
$c-action-secondary-active: darken($c-gray-slate, 10%) !default;

$c-action-toolbar-base: #E3E3E3 !default;
$c-action-toolbar-hover: lighten($c-action-toolbar-base, 1.8%)  !default;
$c-action-toolbar-active: $c-white !default;

// Lowest priority
$c-action-disabled-base: #E4E4E4 !default;
$c-action-disabled-text: #B6B6B6 !default;

$c-action-disabled-loading-text: $c-gray-slate !default;

// ==============================================
// Text Colors
// ==============================================

$c-text-base:    #231F20    !default;
$c-text-danger:  $c-danger  !default;
$c-text-subtle:  #909090    !default;
$c-text-success: $c-success !default;
$c-text-warn:    #827800    !default;

$c-text-contrast-base:   #FFF    !default;
$c-text-contrast-subtle: #C0C0C0 !default;

$c-text-muted:       black(0.54);
$c-text-muted-light: black(0.42);
$c-text-muted-dark:  black(0.66);


// ==============================================
// Input Colors
// ==============================================

$c-input-border-base:     #D0D0D0 !default;
$c-input-border-contrast: #202020 !default;
$c-input-border-focus:    #0368B3 !default;

// ----------------------------------------------
// Form

$c-input-border:        $c-border;
$c-input-border-focus:  $c-input-border-focus;
$c-input-focus-error:   $c-red-base;
$c-input-placeholder:   $c-gray-slate;
$c-input-text:          $c-text-base;
$c-input-text-fill:     $c-gray-slate;

$c-label:               $c-text-muted;
$c-label-focus:         $c-input-border-focus;

// ----------------------------------------------
// Contrast

$c-contrast-flat-button: $c-blue-light;

$c-contrast-input-border-bottom: $c-white;
$c-contrast-input-border-focus:  $c-blue-light;
$c-contrast-input-text:          $c-white;
$c-contrast-input-text-fill:     $c-border;

$c-contrast-label:       $c-border;
$c-contrast-label-focus: $c-blue-light;

// ==============================================
// Link Colors
// ==============================================

$c-link-base:   $c-blue-bright               !default;
$c-link-hover:  darken($c-blue-bright, 4%)   !default;
$c-link-focus:  darken($c-blue-bright, 4%)   !default;
$c-link-active: darken($c-blue-bright, $hex) !default;


// ==============================================
// Table Colors
// ==============================================

$c-table-background: #F4F4F4 !default;
$c-table-border:     $c-border !default;

$c-table-hover: $c-background !default;

$c-table-zebra-light: #FFF    !default;
$c-table-zebra-dark:  #FAFAFA !default;




// ----------------------------------------------
// Tabs

$c-tab-border: #D3D3D3;
$c-tab-background: #E3E3E3;
$c-tab-background-hover: #F5F5F5;
$c-tab-border-bottom:           transparent;
$c-tab-border-top-active:    $c-blue-bright;
$c-tab-text:                    $c-text-base;
$c-tab-text-hover:              $c-blue-base;
$c-tab-text-active:             $c-blue-bright;

// ----------------------------------------------
