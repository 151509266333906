/**
* Baker Distributing
*/


// ==============================================
// Menu
// ==============================================

.menu {
    display: inline-block;
    position: relative;

    &::after {
        @include clearfix;
    }
}

// ---------------------------------------------
// State: Active

.menu.active {
    overflow: visible;
}


// ==============================================
// Menu - Toggle
// ==============================================

.menu_toggle {
    @include lib-icon-font(
        $_icon-font-content: $icon-arrow-dropdown,
        $_icon-font-position: 'after',
        $_icon-font-size: 24px,
        $_icon-font-line-height: 24px
    );

    &::after {
        margin-top: -2px;
        width: 18px;
    }

    &:hover {
        text-decoration: none;
    }

    &.open {
        @include lib-icon-font(
        $_icon-font-content: 'arrow_drop_up',
        $_icon-font-position: 'after',
        $_icon-font-size: 24px,
        $_icon-font-line-height: 24px
    );
    }
}

// ==============================================
// Menu - Content
// ==============================================

.menu_content {
    display: none;
    position: absolute;
    top: 38px;
    z-index: $z-index-2;
    width: auto;
    min-width: 120px;
    height: auto;
    margin-top: 0;
    border: 1px solid $c-border;
    background: $c-white;
    box-shadow: $box-shadow-base;

    .search-input-wrapper {
        margin: -1px 5px;
    }
}

.menu--short .menu_content {
    max-height: 200px;
    overflow: auto;
}

.menu--two-columns .menu_content {
    li a {
        padding: 5px $space-s;
    }
}

.menu_content li {
    margin: 0;

    &:hover {
        background: $c-background;
        cursor: pointer;
    }

    &.no-found_item {
        cursor: auto;
        padding: 8px;
    }
}

.menu_content a {
    display: block;
    padding: $space-xs $space-base;
}

.menu_content a:hover {
    text-decoration: none;
}

@media (min-width: $bp-screen-s) {
    .menu--two-columns .menu_content {
        min-width: 350px;
    }
}

// ---------------------------------------------
// Parent Modifier: Right (align content box to the right)

.menu--right .menu_content {
    right: 0;
}

// ----------------------------------------------
// Parent State: Active

.menu.active .menu_content {
    display: block;
}
