/**
* Gemaire Distributing
*/

// ==============================================
// Vars
// ==============================================


$sidebar-width: 200px;
$bp-product-view-heading-stack: 700px;
$product-container: $bp-screen-xl;

// ==============================================
// Column . Main
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .catalog-product-view .column.main {
        display: flex;
        flex-direction: column;
    }

}

// ==============================================
// Product Max Container
// ==============================================

.product-max-container {
    max-width: $product-container;
    margin: 0 auto;
}

#ahrilist-app-item .product-max-container {
    max-width: none;
    margin-right: -20px;
}

// ==============================================
// Product View - Col 1
// ==============================================

.product-view_col1 {
    width: 100%;
}

.product-view_col1 .product-attribute {
    display: inline-block;
}

.product-view_col1 .product-attribute .mfg {
    margin-left: $space-l;
}

.product-view_col1 .product.photo .photo.image {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
}

.product-view_col1 .placeholder .photo.container {
    max-width: 100%;
}

.product-view_col1 .notice {
    margin: $space-base 0;
    color: $c-text-muted;
    font-size: $font-size-xs;
}

.product-view_col1 .product.thumbs {
    margin: $space-l 0 $space-xxl;
}

.product-view_col1 .items.thumbs>li {
    display: inline-block;
    vertical-align: top;
}

.product-view_col1 .items.thumbs .active {
    display: block;
    line-height: 1;
}

.product_attributes_wrapper {
    margin-top: 15px;

    .product-attribute {
        margin: 0;
    }
}


// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .product-view_col1 {
        width: 50%;
    }

}

@media (min-width: $bp-screen-xs) {

    .product_attributes_wrapper {
        display: flex;

        .product-attribute {
            margin: 0 25px 5px 0;
        }
    }
}

// ==============================================
// Product View
// ==============================================

.product-view {
    display: flex;
    flex-wrap: wrap;
    margin: 20px auto;
    min-height: 600px;
}

// ==============================================
// Product View - Form Group
// ==============================================

.product-form-group {
    display: flex;
    border-top: 1px solid $c-divider-border;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 20px;
    margin-top: 20px;

    .product-info-price {
        min-height: 40px;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .product-form-group {
        flex-direction: column;
    }
}

// ==============================================
// Product View - Inventory
// ==============================================

.product-view .inventory {
    flex: 1;
    order: -1;

    .inventory_instore {
        border-bottom: 1px solid $c-divider-border;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .dropdown-content {
        padding: 10px;

        ul.inventory_stock {
            border-bottom: 1px solid $c-border;
            margin-bottom: 5px;
        }
    }
}

@media (min-width: $bp-screen-s) {

    .product-view .inventory {
        flex: 0 0 300px;
        order: 0;
        min-height: 325px;

        [data-toggle="trigger-nearby-dropdown"] {
            display: none;
        }
    }
}

// ==============================================
// Product View - Social Links
// ==============================================
.product-social-links {

    margin-top: 20px;

    .block-requisition-list .add_to_template_icon {
        color: $c-blue-bright;
        text-transform: none;
        font-size: 16px;
        line-height: 1.8;

        &::before {
            display: none;
        }
    }
}

// ==============================================
// Product . Attribute . Overview
// ==============================================

.product.attribute.overview {
    margin-bottom: $space-base;
    padding-bottom: 10px;
    border-bottom: 1px solid $c-border;
}


// ==============================================
// Product Info Main
// ==============================================

.product-view_col2 {
    width: 100%;
}

.page-title-wrapper.product .page-title {
    margin: 0;
    font-size: 24px;
    line-height: 1.4;
    @include word-wrap();
}

.product-attribute {
    display: block;
    margin-bottom: 20px;
}

.product-attribute--inline {
    display: inline-block;
}

.product-attribute_value {
    display: inherit;
    word-break: break-all;
}

.product-attribute_value.description {
    word-break: break-word;
}

.product-attribute_value.sku,
.product-attribute_value.number,
.product-attribute_value.mfg {
    color: $c-text-subtle;
    font-size: $font-size-s;
}

.product-view_col2 .product.alert {
    margin: $space-base 0;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .product-view_col2 {
        padding-left: $space-base;
        width: 50%;
    }

    .page-title-wrapper.product .page-title {
        font-size: 28px;
    }

}

// ==============================================
// Product Details
// ==============================================

//.product-details {}

// ==============================================
// Product Info Brand
// ==============================================

.product-info-brand {
    font-weight: bold;
    text-transform: capitalize;
}

// ==============================================
// Product Details Sidebar
// ==============================================

.catalog-product-view {
    .content-wrapper {
        height: auto;
    }

    .product-labels-wrapper {
        z-index: 2;
    }
}

.product-details_sidebar {

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        font-size: 18px;
        background: $c-tab-background;
        padding: 0;
        margin-right: 0px;
        border-width: 1px 1px 0px 0;
        border-color: $c-tab-border;
        border-style: solid;
        position: relative;

        &:first-child {
            border-left: 1px solid $c-tab-border;
        }

        &:hover {
            background: $c-tab-background-hover;
        }

        &.ui-state-active {
            background: $c-background;

            a {
                color: $c-tab-text;

                &:before {
                    content: "";
                    position: absolute;
                    height: 4px;
                    width: calc(100% + 2px);
                    background: $c-tab-border-top-active;
                    top: -4px;
                    left: -1px;
                }

                &:after {
                    content: "";
                    background: $c-background;
                    height: 1px;
                    width: 100%;
                    bottom: -1px;
                    position: absolute;
                    left: 0px;
                }
            }
        }
    }

    a {
        display: block;
        padding: 16px 20px;
    }
}

@media (max-width: $bp-screen-m - 1px) {

    .product-details_sidebar {
        display: none;
    }

}

// ==============================================
// Product Options Wrapper
// ==============================================

#product-details_content {

    .product-details_content {
        background: $c-background;
        padding: 20px;
        display: none;
        max-width: none;

        .product-max-container {
            display: flex;

            .documentation-item {
                padding: 0 20px;
                flex-basis: 300px;
                flex-shrink: 0;
            }
        }

        .ahriTable {
            min-height: 300px;
            position: relative;
        }
    }

    .ahriTable {
        flex: 1;

        .filterHide {
            display: none;
        }

        #filters_btn {
            top: 0;
            position: absolute;
            width: auto;
        }

        table {
            border-left: 1px solid $c-border;

            td,
            th {
                border-right: 1px solid $c-border;
            }
        }

        .ahriTable_infoContainer {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .ahriTable_toggleButton {
                .ahriTable_columnContainer {
                    display: none;
                }
            }

            .ahriTable_toggleButton--toggled {
                position: relative;

                .ahriTable_columnContainer {
                    display: block;
                }
            }

            .ahriTable_columnContainer {
                position: absolute;
                width: 100%;
                min-width: 320px;
                padding: 15px 0;
                border: 1px solid #D0D0D0;
                right: 0;
                margin-top: 2px;
                max-width: 30em;
                z-index: 200;
                background: #fff;
                font-size: 14px;

                >div {
                    float: left;
                    width: 50%;
                    padding: 0 10px;
                }
            }
        }
    }

    .ahriTable_filterRow {
        display: flex;
        align-items: center;
    }

    .ahriTable_filterFields {
        flex: 1;
    }

    .ahriTable_filterField {
        flex: 1;
        margin-right: 15px;
    }
}

.product-details_title {
    display: none;
    font-weight: normal;
    font-size: 18px;
    padding: 19px 20px 19px 42px;
    border-top: 1px solid $c-divider-border;
    margin: 0;
    position: relative;

    &::after {
        @include svg (arrow-down-dark);
        content: '';
        position: absolute;
        top: 50%;
        left: 18px;
        width: 11px;
        height: 11px;
        margin-top: -6px;
        transform: rotate(-90deg);
        transition: transform 150ms linear;
    }

}

.ui-state-active .product-details_title::after {
    transform: rotate(0deg);
}

@media (min-width: $bp-screen-xl) {
    #product-details_content {
        .ahriTable {
            .sidebar-additional {
                margin-top: -20px;
                margin-bottom: -20px;
            }
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    #product-details_content {
        padding-top: 0;
        margin-top: 0;
        border-bottom: 1px solid $c-divider-border;

        .product-details_content {
            height: auto !important;

            .product-max-container {
                display: block;

                .documentation-item {
                    padding: 0;
                }
            }
        }

        .ahriTable {
            .columns {
                display: block;
            }
        }

    }

    .product-details_title {
        display: block;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .product-details_title {
        display: block;
        padding-right: 20px;
        padding-left: 36px;

        &::after {
            left: 15px;
        }
    }
}

// ==============================================
// AHRI Filters Popup
// ==============================================

@media (min-width: $bp-screen-s) {
    #ahri-filters-popup {
        ul {
            columns: 2;
        }
    }
}

// ==============================================
// Product Options Wrapper
// ==============================================

.product-options-wrapper .fieldset-product-options-inner .legend {
    display: inline-block;
    float: none;
    margin: 0 0 $space-xs;
    padding: 0;
    border: 0;
    font-weight: $font-weight-bold;
}

//  Date & Time custom option (Affect Time that goes only after Date)
.product-options-wrapper .fieldset-product-options-inner input.datetime-picker~select.datetime-picker {
    margin-top: $space-base;
}

.product-options-wrapper .fieldset-product-options-inner.required .legend,
.product-options-wrapper .fieldset-product-options-inner._required .legend {
    &::after {
        content: '*';
        margin: $form-field-label-asterisk__margin;
    }
}

.product-options-wrapper .field .note {
    display: block;
}

.product-options-wrapper .field .price-notice {
    @extend .abs-adjustment-incl-excl-tax;
}

// ==============================================
// Box To Cart
// ==============================================

.product-view_col2 .box-tocart .field.qty {
    flex: 0 0 50px;
    margin-top: 0px;
}

.product-view_col2 .box-tocart .input-text.qty {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    +.mage-error {
        position: absolute;
        bottom: -25px;
        font-size: 14px;
    }
}

.product-view_col2 .box-tocart .actions {
    text-align: left;
    flex: 1 1 auto;
    margin-top: 20px;
}

// ----------------------------------------------

// ==============================================
// Block Related
// ==============================================

.block.related .action.select {
    margin: 0 $space-xs;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .block.related .action.select {
        display: block;
        margin: $space-xs 0;
    }

}

// ==============================================
// PDP Sidebar
// ==============================================

.sidebar .product-items .product-item {
    margin-bottom: $space-l;
    position: relative;
}

.sidebar .product-items .product-item-info {
    position: relative;
    width: auto;
}

.sidebar .product-items .product-item-info .product-item-photo {
    left: 0;
    position: absolute;
    top: 0;
}

.sidebar .product-items .product-item-name {
    margin-top: 0;
}

.sidebar .product-items .product-item-details {
    margin: 0 0 0 85px;
}

.sidebar .product-items .product-item-actions {
    display: block;
    margin-top: $space-base;
}

.sidebar .product-items .price-box {
    display: block;
    margin: 7px 0;
}

.sidebar .product-items .text {
    margin-right: 8px;
}

.sidebar .product-items .counter {
    color: $c-blue-base;
    font-size: $font-size-xs;
    white-space: nowrap;
}

.sidebar .product-items .minilist .price {
    display: inline;
    padding: 0;
}

.sidebar .product-items .minilist .weee::before {
    display: inline-block;
}

.sidebar .action.delete {
    position: absolute;
    right: 0;
    top: 0;
}

.sidebar .subtitle {
    display: none;
}

// ----------------------------------------------
//  Product images only

.sidebar .product-items-images {
    @include lib-clearfix;
    margin-left: -$space-xs;
}

.sidebar .product-items-images .product-item {
    float: left;
    padding-left: $space-xs;
}

// ----------------------------------------------
// Product names only

.sidebar .product-items-names .product-item {
    margin-bottom: $space-base;
}

.sidebar .product-items-names .product-item-name {
    margin: 0;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .sidebar .product-items .product-item-info .product-item-photo {
        float: left;
        left: auto;
        margin: 0 $space-base $space-base 0;
        position: relative;
        top: auto;
    }

    .sidebar .product-items .product-item-details {
        margin: 0;
    }

    .sidebar .product-items .product-item-actions {
        clear: left;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-xl) {

    .sidebar .product-items .product-item-info .product-item-photo {
        float: none;
        left: 0;
        margin: 0;
        position: absolute;
        top: 0;
    }

    .sidebar .product-items .product-item-details {
        margin-left: 85px;
    }

}

.additional-attributes th {
    white-space: normal;
}

.change-qty {
    background: $c-white;
    width: 50px;
    height: 50px;
    border: 1px solid $c-divider-border;
    font-size: 30px;
    margin: 0 5px;
    flex-shrink: 0;
    color: $c-text-base;

    &.dec {
        margin-left: 0;
    }

    &.inc {
        margin-right: 0;
    }
}

.box-tocart .control {
    display: flex;
    align-items: center;

    label {
        padding-right: 15px;
        position: static;
        color: #505050;
        letter-spacing: normal;
        line-height: 1;
        position: static;
    }
}

.product-addtocart-button {
    width: 277px;
}

.product-info-price {

    .price-box .price {
        font-size: 40px;
    }
}

// ==============================================
// Supersede product
// ==============================================

.supersede-container {
    border-top: 1px solid $c-divider-border;
    padding-top: 20px;
    margin-top: 20px;
    float: left;
    width: 100%;

    .products-list {

        .product-item-photo {
            flex-basis: 150px;

            img {
                width: 200px;
            }
        }

        .product-item-details {
            display: block;
        }
    }
}

// ==============================================
// Crossseling
// ==============================================

.crossselling-list {

    .tabs_item {
        margin-bottom: 50px;
        margin-left: -20px;
        margin-right: -20px;
        border-bottom: 0;
        border-right: 0;
        border-left: 0;
        width: calc(100vw - 20px);
    }

    .tabs_nav-link {
        white-space: nowrap;
    }

    .products-grid .product-items {
        padding: 0;
        background: transparent;
        margin: 0 -10px;
        display: block;

        >li {
            flex-grow: 0;
        }
    }

    .slick-dots {

        .slick-active {
            button {
                background: $c-text-base;
            }
        }

        button {
            text-indent: -99999px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: $c-border;
        }
    }

    .slick-arrow {
        background: $c-white;
        box-shadow: $box-shadow-base;

        &.slick-prev {
            left: -50px;
        }

        &.slick-next {
            right: -50px;
        }
    }

    .ahri-details-view & {

        .tabs_item {
            background: $c-white;
        }
    }
}

@media (min-width: $bp-screen-xl + 50px) {
    .crossselling-list {
        .tabs_item {
            margin-left: calc((1420px - 100vw) / 2);
            margin-right: calc((1420px - 100vw) / 2);
            width: 100vw;
        }
    }
}

@media (max-width: 1600px - 1px) {

    .crossselling-list {

        .slick-arrow {

            &.slick-prev {
                left: 15px;
            }

            &.slick-next {
                right: 15px;
            }
        }
    }
}

@media (min-width: $bp-screen-m) {

    .crossselling-list {

        .ahri-details-view & {

            .tabs_item {
                border: 1px solid $c-tab-border;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {

    .crossselling-list {

        .products-grid .product-items {
            flex-wrap: nowrap;
            overflow-x: auto;

            >li {
                flex-shrink: 0;
            }
        }

        .tabs_item {
            margin: 0;
            padding: 0;
            border: 0;
            width: 100%;

            &:last-child .product-details_title {
                border-bottom: 1px solid $c-divider-border;
            }

            .products-grid {
                background-color: $c-background;
                padding: 20px;
            }
        }
    }
}

// ==============================================
// PDP Reference Only
// ==============================================

.pdp-reference-only {

    .product-info-price {
        display: none;
    }

    .product-form-group-left {
        flex: 1;
    }
}
