/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2014 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Product Carousel
// ==============================================

.product-carousel {
    margin-bottom: ($gap * 1.25);
    padding-top: $gap;
    border-top: 4px solid $c-divider-border;
}

.product-carousel--no-border-top {
    border-top: 0;
}


// ==============================================
// Product Carousel - Title
// ==============================================

.product-carousel_title {
    margin-bottom: 24px;
    padding-right: (15px + 40px + 8px + 40px + 10px);
}

// ==============================================
// Product Carousel - Item
// ==============================================

.product-carousel .item {
    position: relative;
    padding: 15px;
    text-align: center;
}

// ==============================================
// Product Carousel - Slick
// ==============================================

.product-carousel {

    .slick-slide {
        margin: 0 10px;
    }

    .slick-list {
        margin: 0 -10px;
    }

    .slick-prev,
    .slick-next {
        top: -35px;
        background-color: $c-background;
        color: $c-text-base;
    }

    .slick-next {
        right: 0;
    }

    .slick-prev {
        right: 60px;
        left: auto;
    }
     
}

// ==============================================
// Product Carousel - Owl Buttons
// ==============================================

.product-carousel .owl-next,
.product-carousel .owl-prev {
    position: absolute;
    z-index: $z-owl-carousel-buttons;
    top: (-40px + -20px);
}

.product-carousel .owl-prev {
    right: (8px + 40px + 10px);
}

.product-carousel .owl-next {
    right: 10px;
}


// ==============================================
// Product Image
// ==============================================

.product-carousel .product-image {
    width: 100%;
    margin-bottom: 12px;
}


// ==============================================
// Product Name
// ==============================================

.product-carousel .product-name {
    overflow: hidden;
    display: block;
    height: (1.3em * 4);
    margin-bottom: 10px;
    font-size: $font-size-xs;
    line-height: $line-height-xs;
    text-overflow: ellipsis;
}


// ==============================================
// Product Numbers
// ==============================================

.product-carousel .product-numbers {
    margin-bottom: 10px;
}


// ==============================================
// Inventory
// ==============================================

.product-carousel .product-inventory {
    margin-bottom: 15px;
}


// ==============================================
// Price
// ==============================================

//.product-carousel .price-wrap {}


// ==============================================
// Product Actions
// ==============================================

.product-carousel .product-actions {
    margin-bottom: 10px;
}


// ==============================================
// Add To Cart Wrapper
// ==============================================

.product-carousel .add-to-cart-wrapper {
    overflow: hidden;
    margin-bottom: 10px;
}

.product-carousel .add-to-cart-wrapper .qty {
    width: 100%;
    margin-bottom: 5px;
    line-height: 40px; // May cause caret to be abnormally tall, but fixes vertical alignment of placeholder text.
}

.product-carousel .button {
    width: 100%;
}

// ----------------------------------------------

@media (min-width: 400px) {

    .product-carousel .add-to-cart-wrapper--qty .qty {
        float: left;
        width: 30%;
        margin-right: 4%;
        margin-bottom: 0;
    }

    .product-carousel .add-to-cart-wrapper--qty .button {
        float: left;
        width: 66%;
        padding-right: 0;
        padding-left: 0;
    }

}


// ==============================================
// Alternate Actions List
// ==============================================

.product-carousel .alternate-actions-list {
    padding-top: 5px;
}

.product-carousel .add-to-compare-link,
.product-carousel .add-to-wishlist-link {
    display: inline-block;
    padding: 4px 0;
    font-size: $font-size-xs;
}
