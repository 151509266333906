$bp-warranty-claim-item-block-title: 700px !default;

.cms-warranty-lookup-index {
    .page-main {
        width: 100%;
        max-width: $content-max-width + $layout-indent__width * 2;
        margin: 0 auto;
    }
}

.warranty-banner {
    @media print {
        display: none !important;
    }

    .button {
        a {
            color: $c-white;
        }
    }

    .button + .button {
        margin-left: 10px;
    }

    [data-content-type='text'] {
        .widget.block & {
            h2,
            p {
                margin-bottom: 0;
            }
        }
    }

    // Mobile
    @media (max-width: $bp-screen-m) {
        // Overriding pagebuilder inline styling
        padding: 20px !important;
        background-image: none !important;

        [data-content-type='text'] {
            &:not(:last-of-type) {
                span {
                    font-size: 18px !important;
                }
            }

            &.warranty-banner-heading {
                h2 {
                    span {
                        font-size: 36px !important;

                        &:not(:first-child) {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
}

.warranty-lookup-tabs {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $bp-screen-m) {
        display: block;
    }

    .tabs_nav-link {
        position: relative;
        display: flex;
        align-items: center;

        .simple-loading {
            position: absolute;
            top: 0;
            left: calc(50% - 15px);
        }

        @media (max-width: $bp-screen-m) {
            width: 100%;
            padding-left: 5px;
            text-align: left;
            font-size: $font-size-l;
            border: 1px solid $c-table-border;

            &:not(:first-of-type) {
                border-top: none;
            }

            &::before {
                display: none;
            }

            @include lib-icon-font(
                $_icon-font-content: $icon-next,
                $_icon-font-size: 35px,
                $_icon-font-position: after,
                $_icon-font-display: block
            );

            display: flex;
            align-items: center;

            &:after {
                order: -1;
                position: static;
                width: auto;
                height: auto;
                background: none;
            }

            &.active {
                background: $c-blue-bright;
                border-color: $c-blue-bright;
                color: white;

                &::after {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .tabs_item {
        width: 100%;
    }

    .control-search {
        .search-bar {
            @include lib-button-icon(
                $_icon-font-content: $icon-search,
                $_icon-font-text-hide: true,
                $_icon-font-color: $c-gray-slate
            );
            display: flex;
            position: relative;

            &:before {
                position: absolute;
                top: calc(50% - 11px);
                left: 0;
            }

            .button {
                margin-left: 10px;
            }
        }

        .input-text {
            padding-right: 0;
            padding-left: 25px;

            ~ label {
                padding-left: 25px;
            }

            &:not([value='']) ~ label,
            &[value]:focus ~ label {
                padding-left: 0;
            }
        }
    }
}

.warranty-details-wrapper {
    .warranty-details {
        min-height: 200px;
        position: relative;

        .simple-loading {
            position: absolute;
            top: calc(50% - 15px);
            left: calc(50% - 15px);
        }

        .submit-claim-helper-button {
            margin: 0 10px;

            &:not(:disabled) {
                background: $c-green;
                color: #fff;

                &:active,
                &:hover {
                    background: darken($c-green, 10%);
                    color: #fff;
                }
            }
        }

        .active-warranty-note {
            margin: 3px 0 0;
            font-size: 14px;
        }
    }

    .warranty-history-list {
        margin: 30px 0;
        border-bottom: 2px solid $c-divider-border;
    }

    .warranty-history-item {
        &.opened {
            .warranty-history-title {
                @include lib-icon-font(
                    $_icon-font-content: $icon-down,
                    $_icon-font-size: 35px,
                    $_icon-font-position: before,
                    $_icon-font-display: block
                );

                display: flex;
            }
        }

        &:not(.opened) {
            .warranty-history-content {
                display: none;
            }
        }
    }

    .warranty-accordion {
        &.collapsible {
            + .collapsible {
                margin-top: 20px;

                .accordion_group & {
                    margin-top: 0;

                    .accordion_header {
                        border-top: 0;
                    }
                }
            }
        }

        .collapsible_title {
            transition: 200ms;
            padding: 16px 20px 16px 42px;
            border: 1px solid $c-border;
            background: $c-background;
            font-weight: 400;
            margin: 0;
            border-radius: 0;
            position: relative;

            @media (min-width: $bp-screen-m + 1) {
                display: flex;
                align-items: baseline;
            }

            &::before {
                display: none;
            }

            // Icon
            &::after {
                @include svg(arrow-11-down-dark);
                content: '';
                position: absolute;
                top: 28px;
                left: 15px;
                width: 11px;
                height: 11px;
                margin-top: calc(11px / -2);
                transform: rotate(-90deg);
                transition: transform 150ms linear;
            }

            &:hover,
            &:active {
                background: darken($c-background, 3%);
            }

            p {
                margin: 0;
                font-size: 14px;

                &.description {
                    width: 100%;
                }
            }

            h2,
            h3 {
                margin-bottom: 0;
                margin-right: 30px;
                font-size: 18px;
                font-weight: 400;
                user-select: none;
            }

            &.active,
            &.open,
            &.collapsible_title--opened {
                &::after {
                    transform: rotate(0deg);
                }
            }
        }

        .collapsible_content {
            margin: 0;
            padding: 20px;
            border-left: 1px solid $c-border;
            border-right: 1px solid $c-border;
            border-bottom: 1px solid $c-border;

            @media (max-width: $bp-screen-s - 1px) {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }

    .warranty-collapsible {
        .collapsible_title {
            margin: 0;
            border-radius: 0;
            position: relative;
            padding: 18px 18px 18px 50px;
            border-top: 2px solid $c-divider-border;
            cursor: pointer;
            transition: 200ms;
            width: 100%;

            @include lib-icon-font(
                $_icon-font-content: $icon-next,
                $_icon-font-size: 35px,
                $_icon-font-position: before,
                $_icon-font-display: block
            );

            display: flex;
            align-items: center;

            &:before {
                position: absolute;
                left: 7.5px;
                top: calc(50% - 8px);
                transition: 200ms;
            }

            &:hover,
            &:active {
                background: #fff;
            }

            h3 {
                margin: 0;

                span {
                    font-weight: 400;
                }

                + h3 {
                    margin-left: 25px;
                }
            }

            &.collapsible_title--opened:before {
                transform: rotate(90deg);
            }
        }

        .collapsible_content {
            padding: 10px 20px;
            margin-left: 0;
        }
    }

    .warranty-additional-serials {
        margin: 30px 0;
    }

    .additional-serials-list {
        list-style-type: disc;
        padding-inline-start: 25px;
        margin-bottom: 20px;
    }

    .table {
        .price-box {
            .price {
                font-size: $font-size-base;
            }

            .uom-product {
                font-size: $font-size-xs;
            }
        }
    }
}

.warranty-available {
    color: $c-green;
}

.warranty-expired {
    color: $c-red-base;
}

.warranty-lookup-form-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    .warranty-banner-dashboard {
        margin-left: 20px;
    }
}

.warranty-banner-dashboard {
    max-width: 440px;
}

.warranty-lookup-form {
    .legend {
        font-size: $font-size-xl;
        margin-bottom: 20px;
    }

    .control-checkbox {
        margin-top: 20px;

        [type='checkbox'] + label {
            &:after {
                top: 0;
            }
        }
    }

    button[type='submit'] {
        position: relative;

        .simple-loading {
            position: absolute;
            top: calc(50% - 15px);
            left: calc(50% - 15px);
            margin: 0;
        }
    }

    .control-checkbox {
        display: flex;

        .tooltip-dark {
            margin-left: 10px;
        }
    }
}

.warranty-index-index {
    .sidebar-additional {
        display: block;
        padding: 77px 20px 0 0;
        background: $c-background;

        .warranty-banner-dashboard {
            width: 240px;

            .button {
                a {
                    color: $c-white;
                }
            }
        }
    }

    .warranty-banner {
        p,
        h2 {
            margin-bottom: 0;
        }
    }
}

// ==============================================
// Warranty Parts List
// ==============================================

.parts-list-container {
    &.expanded {
        .collapsible_title {
            pointer-events: none;
            display: flex;

            &:before {
                transform: rotate(90deg);
            }
        }

        .collapsible_content {
            display: block !important;
        }
    }

    .parts-list-group {
        .collapsible_title {
            padding: 21px 15px 20px 30px;
            border-top-width: 1px;
            border-top-color: $c-table-border;
            background: $c-table-background;

            .partsTable & {
                background: darken($c-table-background, 5%);

                &:hover {
                    background: darken($c-table-background, 2%);
                }
            }

            h3,
            span {
                font-size: $font-size-base;
                line-height: 14px;
                text-transform: capitalize;
            }

            span {
                margin-left: 5px;
            }

            &:before {
                font-size: 30px;
                left: 0;
            }
        }

        .collapsible_content {
            padding: 0;

            .partsTable & {
                tr:not(.replacement-part) {
                    background: $c-table-background;
                }
            }
        }
    }

    .parts-list-head-table {
        &.table {
            margin-bottom: 0;

            td {
                border-bottom: 0;
                background: $c-table-background;
                font-weight: 600;

                .partsTable & {
                    background: darken($c-table-background, 5%);
                }
            }
        }
    }

    .warranty-parts-list {
        &.table {
            margin: 0 0 -1px;
            font-size: $font-size-base;

            td {
                padding: 15px 10px;
                vertical-align: top;
            }
        }

        .common-indicator {
            margin-left: 4px !important;
            margin-top: -10px !important;

            i {
                display: block;
            }

            &.tooltip {
                cursor: default;

                &::after {
                    transform: none;
                    left: -10px;
                    top: auto;
                    bottom: 100%;
                    padding: 10px 15px;
                }

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-top: 6px solid $c-library-gray-7;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    left: 4px;
                    bottom: calc(100% - 6px);
                    z-index: $z-index-2;
                    transition: all 0.2s ease;
                    transition-delay: 0.1s;
                    opacity: 0;
                }

                &:hover {
                    &::before,
                    &::after {
                        opacity: 1;
                        transform: translateY(-10px);
                    }
                }
            }
        }

        @media (min-width: $bp-screen-m + 1) {
            .part-item {
                &.expanded {
                    .part-number {
                        &:before,
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            z-index: 1;
                            left: 15px;
                            bottom: -2px;
                            width: 0;
                            height: 0;
                            border-right: 8px solid transparent;
                            border-left: 8px solid transparent;
                            border-bottom: 8px solid $c-priority-background;
                        }

                        &:before {
                            border-bottom-color: $c-table-border;
                            bottom: -1px;
                        }
                    }
                }
            }
        }
    }

    .replacement-part {
        background: $c-priority-background;
    }

    .replacement-toggle-button {
        display: block;
        margin-top: 5px;
        padding-left: 25px;
        position: relative;

        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 18px,
            $_icon-font-position: before,
            $_icon-font-display: block
        );

        &:before {
            position: absolute;
            left: 4px;
            top: calc(50% - 10px);
            transition: 200ms;
        }

        &:not(.active):before {
            transform: rotate(-90deg);
        }
    }

    // Desktop + tablet only
    @media (min-width: $bp-screen-m + 1) {
        .part-selection {
            width: 50px;
        }

        .part-number {
            width: 15%;
            position: relative;
        }

        .part-name {
            width: 35%;
        }

        .part-availability {
            width: 18%;
        }

        .part-price {
            width: 16%;
        }

        .part-addto {
            width: 16%;
        }
    }

    // Mobile only
    @media (max-width: $bp-screen-m) {
        .warranty-parts-list {
            &.table {
                tr {
                    display: flex;
                    flex-direction: column;
                }

                td {
                    padding-top: 3px;
                    padding-bottom: 3px;
                }
            }
        }

        .parts-list-head-table {
            display: none;
        }
    }
}

.catalog-product-view {
    .partsTable,
    .partsTable .parts-list-container {
        width: 100%;
    }
}

.tooltip-dark {
    @include lib-tooltip(
        $_tooltip-selector-toggle: '.tooltip-toggle',
        $_tooltip-selector-content: '.tooltip-dark-content',
        $_tooltip-background: #505050,
        $_tooltip-border-color: #d0d0d0,
        $_tooltip-border-width: 1px,
        $_tooltip-border-radius: 0,
        $_tooltip-color: #fff,
        $_tooltip-cursor: help,
        $_tooltip-font-size: $font-size-xs,
        $_tooltip-font-family: $font-family-sans,
        $_tooltip-padding: 20px,
        $_tooltip-min-width: 245px,
        $_tooltip-max-width: 245px,
        $_tooltip-arrow-size: 8px
    );

    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50px;
    text-align: center;
    background: #808088;
    color: #fff;
    cursor: help;

    .tooltip-dark-content {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        transform: translateX(calc(-50% + 10px));
        margin-bottom: 15px;

        &:before,
        &:after {
            left: 50%;
        }
    }
}

// ==============================================
// Warranty Submit Claim General
// ==============================================

.submit-claim-container {
    counter-reset: submitClaimNav;
}

.claim-section {
    .claim-section-title {
        margin-bottom: 15px;

        &:before {
            content: counter(submitClaimNav) '. ';
            counter-increment: submitClaimNav;
        }
    }

    label {
        pointer-events: auto;
    }

    .failed-part-form .field {
        position: relative;
        flex: 0 1 auto;

        input,
        select {
            min-width: 250px;
        }
    }
}

.warranty-claim-existing_option,
.warranty-part-lookup {
    position: relative;

    .lookup-autocomplete {
        position: relative;
        margin-top: 10px;

        &:before,
        &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            border-bottom: 8px solid $c-border;
            position: absolute;
            bottom: 100%;
            left: 20px;
        }

        &:after {
            bottom: calc(100% - 1px);
            border-bottom-color: $c-background;
        }

        .lookup-autocomplete-container {
            max-height: 350px;
            overflow-y: auto;
            background: $c-background;
            border: 1px solid $c-border;

            @media (min-width: $bp-screen-m + 1) {
                li {
                    display: flex;
                }

                .part-number {
                    padding-right: 15px;
                }
            }

            @media (max-width: $bp-screen-m) {
                .part-description {
                    display: block;
                    width: 100%;
                }
            }

            .common-group-name {
                text-transform: capitalize;
                font-size: $font-size-xxs;
            }
        }

        .group-title {
            font-size: $font-size-xs;
            font-weight: 300;
            color: $c-text-subtle;
            padding: 14px 15px 6px;
            margin: 0;
        }

        ul + .group-title {
            border-top: 1px solid $c-action-disabled-base;
            margin-top: 5px;
        }

        ul {
            li {
                padding: 5px 15px;
                cursor: pointer;
                transition: 200ms;

                &:hover {
                    background: $c-border;
                }
            }
        }

        .autocomplete-notice {
            padding: 15px;
        }
    }
}

.warranty-claim-existing_option {
    .button {
        transition-property: background;
    }

    .button--loading.button--disabled {
        padding: 0 24px;
    }
}

.modal--claim-validation,
.warranty-order-find-modal {
    pointer-events: none;
}

// Warranty Existing/New Order Switcher

.claim-switcher {
    .controls {
        .control--primary [type='radio'] {
            + label {
                padding-left: 40px;
                font-weight: 300;
                border-radius: 0;
                color: $c-text-base;
                transition: 200ms;

                &:hover {
                    background: darken($c-background, 3%);
                }

                &:after {
                    top: 20px;
                }
            }

            &:not(:checked) {
                + label[for='warranty-claim-type-existing'] {
                    border-bottom-color: transparent;
                }

                + label[for='warranty-claim-type-new'] {
                    border-top-color: transparent;
                }
            }
        }
    }
}

.parts-lookup-form {
    .warranty-part-lookup {
        .input-text::-webkit-input-placeholder {
            color: #808088;
        }

        .input-text ~ label {
            padding-left: 0;
            color: $c-text-base;
            font-weight: 700;
        }
    }
}

.failed-parts-list {
    .warranty-collapsible {
        &:not(:first-of-type) .collapsible_title {
            border-top: none;
        }
    }

    .collapsible_title {
        background: $c-table-background;

        .remove-button {
            appearance: none;
            margin-left: auto;
            width: 30px;
            height: 30px;
            @include svg(delete);
            background-position: center;
            border: none;
            text-indent: -999999px;
        }
    }

    .collapsible_title,
    .collapsible_content {
        border-bottom: 2px solid #e9e9e9;
    }

    .collapsible_content {
        padding-bottom: 0;
    }

    .replacement-part {
        &.disabled {
            opacity: 0.5;
        }

        &:not(.disabled) {
            cursor: pointer;

            td {
                transition: 200ms;
            }

            &:hover {
                td {
                    background: $c-table-background;
                }
            }
        }

        [type='radio'] + label {
            margin-left: -14px;
            padding-left: 0;
        }
    }

    .failed-parts-replacement-table {
        thead td {
            background: $c-table-background;
            font-weight: 600;
        }
    }
}

.unmatched-parts-message {
    font-size: $font-size-s;

    h3 {
        font-weight: 400;
    }

    ul {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;

        + ul {
            margin-top: 20px;
        }
    }
}

.claim-info + .failed-part-form {
    margin-top: 30px;
}

// ==============================================
// Warranty Submit Claim Item - Block Title
// ==============================================

@media (min-width: $bp-warranty-claim-item-block-title) {
    .warranty-claim-item_block-title {
        position: relative;
        padding-right: 110px; // Magic number
    }
}

// ==============================================
// Warranty Claim Item - Block Title Action
// ==============================================

@media (max-width: $bp-warranty-claim-item-block-title - 1px) {
    .warranty-claim-item_block-title-action {
        margin-top: $space-xxs;
    }
}

// ----------------------------------------------

@media (min-width: $bp-warranty-claim-item-block-title) {
    .warranty-claim-item_block-title-action {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: $space-base;
    }
}

// ==============================================
// Warranty Claim Item - Field Row
// ==============================================

.warranty-claim-item .field-row {
    clear: both;
}

// ==============================================
// Warranty Claim Item - Cancel Warranty
// ==============================================

.warranty-cancel {
    padding-top: $space-xs;
}

// ----------------------------------------------

@media (min-width: 800px) {
    .warranty-cancel {
        text-align: right;
        padding-top: 0;
    }
}

// ==============================================
// Warranty Spiral Img
// ==============================================

// [todo] not used. Check if we can delete.
.warranty-spiral-img {
    display: inline-block;
}

// Warranty Existing Order Flow

.existing-order-parts {
    .existing-part-switch {
        margin-left: auto;

        input + label {
            padding-left: 0;
            padding-right: 59px;
        }

        .switch_control {
            left: auto;
            right: 0;
        }
    }

    .warranty-existing-part-group {
        .selected-items-count {
            margin-left: auto;
            display: flex;
            align-items: center;
        }

        .selected-count {
            display: block;
            background: $c-gray-slate;
            border-radius: 50px;
            width: 26px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            color: #fff;
            margin-left: 10px;
            font-size: $font-size-xs;

            &.selected {
                background: $c-green;
            }
        }
    }
}

// ==============================================
// Warranty Submit Claim Received
// ==============================================

.claim-next-steps {
    counter-reset: warrantyClaimSteps;

    li {
        padding-left: 38px;
        margin-bottom: 15px;
        position: relative;

        &:before {
            display: block;
            content: counter(warrantyClaimSteps);
            counter-increment: warrantyClaimSteps;
            width: 24px;
            height: 24px;
            line-height: 24px;
            border-radius: 50px;
            background: #a8a6a6;
            margin-right: 15px;
            text-align: center;
            font-size: $font-size-base;
            font-weight: 300;
            color: #fff;
            position: absolute;
            left: 0;
        }
    }

    .button {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.submit-claim-information {
    .claim-homeowner-information {
        @media (min-width: $bp-screen-m + 1) {
            padding-left: 8px;
        }
    }

    @media print {
        .claim-unit-information,
        .claim-homeowner-information {
            width: 50%;
        }

        .claim-homeowner-information {
            padding-left: 4px;
        }

        .claim-base-data {
            font-size: $font-size-base !important;
        }
    }
}

@media print {
    body[class*='warranty'] {
        .page.messages,
        .page-title-wrapper {
            display: none;
        }
    }
}

// ==============================================
// Warranty Order Success Page
// ==============================================

.warranty-order-success-callouts {
    @media (min-width: $bp-screen-m + 1) {
        display: flex;
        justify-content: space-between;

        > div {
            width: calc(50% - 15px);
        }
    }
}

.claims-table {
    .view-details-icon {
        color: #0368b3 !important;
    }
}

.claims-table .sales-order,
.claim-view {
    .tooltip:hover:after {
        transform: translate3d(-50%, calc(30px), 0);
    }
}

// ==============================================
// Warranty Claim View
// ==============================================

.claim-view {
    .actions-dropdown {
        .actions-dropdown-menu {
            width: auto;
        }
    }
}

.warranty-frequently-claimed {
    .product-items {
        padding: 0;
        margin: -10px;
        background: transparent;
        flex-wrap: nowrap;
        overflow-x: auto;

        > li {
            width: 264px;
            min-width: 220px;
            flex-grow: unset;
        }
    }
}

.warranty-frequent-claim-actions {
    .button {
        width: 100%;
    }

    .remove-from-claim-message {
        margin: 0;
        font-size: 13px;
    }
}

.warranty-part-badges {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    flex-grow: 1;
    align-items: center;
    margin-bottom: 0;
    justify-content: flex-end;

    .badge {
        margin-bottom: 2px;
        margin-top: 2px;
        padding: 3px 5px;
    }

    + .part-description {
        width: 100%;
    }
}

.common-failed-parts-autocomplete {
    border-bottom: 1px solid $c-border;

    .group-title,
    ul {
        background: #fbf4e7;
    }
}

.parts-list-badges-wrapper {
    margin-bottom: 10px;

    .warranty-part-badges {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
}

.common-indicator {
    margin: -6px 0px;
    margin-left: auto !important;
    pointer-events: auto;

    i {
        color: $c-gray-slate;
        transition: 200ms all;
    }

    &:hover {
        i {
            color: $c-blue-bright;
        }
    }
}

.warranty-disabled-tooltip {
    position: absolute;
    left: calc(100% + 10px);
    top: calc(50% - 10px);

    .tooltip-toggle {
        font-size: $font-size-base;
        line-height: 20px;
    }
}
