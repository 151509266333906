/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$bp-site-matter: 900px !default;


// ==============================================
// Site Matter
// ==============================================

//.site-matter {}

// ----------------------------------------------

@media (min-width: $bp-site-matter) {

    .site-matter {
        padding: $space-xxl 0;
        background: #F4F4F4;
    }

}

// ----------------------------------------------

@media print {

    .site-matter {
        padding: 0;
    }

}
