/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2015 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$bp-catalog-offscreen: 950px !default;

$catalog-offscreen-sidebar-width-desktop: 250px !default;
$catalog-offscreen-sidebar-width-mobile:  320px !default;


// ==============================================
// Body
// ==============================================

@media (max-width: $bp-catalog-offscreen - 1px) {

    [data-ui='collection-filters'] {
        @include prevent-body-scroll;
    }

}


// ==============================================
// Site Overlay
// ==============================================

@media (max-width: $bp-catalog-offscreen - 1px) {

    [data-ui='collection-filters'] .site-overlay {
        @include site-overlay-active;
    }

}

// ==============================================
// Catalog Offscreen Toggle Button
// ==============================================

.catalog-offscreen-toggle-button {
    margin-bottom: $margin-bottom;
}

// ----------------------------------------------

@media (min-width: $bp-catalog-offscreen) {

    .catalog-offscreen-toggle-button {
        display: none;
    }

}


// ==============================================
// Catalog Offscreen Toggle Button - Count
// ==============================================

.catalog-offscreen-toggle-button_count {
    display: inline-block;
    margin-left: 0.75em;
    padding: 0 6px;
    border-radius: 2px;
    background: #FFF;
    color: $c-action-default-base;
    font-size: $font-size-xxxs;
    line-height: 20px;
    text-transform: uppercase;
}


// ==============================================
// Catalog Offscreen
// ==============================================

.catalog-offscreen {
    display: flex;
    width: 100%;
}


// ==============================================
// Catalog Offscreen - Main
// ==============================================

.catalog-offscreen_main {
    position: relative;
}

// ----------------------------------------------
// Parent Modifier: Empty

.catalog-offscreen--empty .catalog-offscreen_main {
    width: 100%;
}

// ----------------------------------------------

@media (max-width: $bp-catalog-offscreen - 1px) {

    .catalog-offscreen_main {
        width: 100%;
    }

}

// ----------------------------------------------

@media (min-width: $bp-catalog-offscreen) {

    .catalog-offscreen_main {
        width: calc(100% - #{$catalog-offscreen-sidebar-width-desktop});
    }

}


// ==============================================
// Catalog Offscreen - Sidebar
// ==============================================

@media (max-width: $bp-catalog-offscreen - 1px) {

    .catalog-offscreen_sidebar {
        @include offscreen-base ($position: 'right', $max-width: $catalog-offscreen-sidebar-width-mobile);
        padding: $liner-base;
        background: #FFF;
    }

    [data-ui='collection-filters'] .catalog-offscreen_sidebar {
        @include offscreen-active ($position: 'right');
    }

}

// ----------------------------------------------

@media (min-width: $bp-catalog-offscreen) {

    .catalog-offscreen_sidebar {
        width: $catalog-offscreen-sidebar-width-desktop;
        border-right: 4px solid $c-border;
    }

}

// ----------------------------------------------
// Parent Modifier: Empty

.catalog-offscreen--empty .catalog-offscreen_sidebar {
    display: none;
}


// ==============================================
// Catalog Offscreen - Sidebar Header
// ==============================================

@media (min-width: $bp-catalog-offscreen) {

    .catalog-offscreen_sidebar-header {
        display: none;
    }

}


// ==============================================
// Close Offscreen Button
// ==============================================

@media (min-width: $bp-catalog-offscreen) {

    .catalog-offscreen .close-offscreen-button {
        display: none;
    }

}
