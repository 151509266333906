/**
* Gemaire Distributing
*/


// ==============================================
// Block Static Block
// ==============================================

.block-static-block.widget {
    margin-bottom: 0;
}

.links .block-static-block.widget {
    margin-bottom: 0;
}
