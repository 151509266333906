/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2017 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Browser Warning
// ==============================================

// See: /js/src/browser-detection.js
.browser-warning {
    display: none;
    padding: 12px 10px;
    background: $c-warn;
    text-align: center;
}

// ==============================================
// Browser Support message
// ==============================================

.browser-support {
    padding: $space-base;
    text-align: center;
    background: $c-yellow-base;
}