/**
* Gemaire Frontend
*
* @author     Andres Fraguela (https://www.gemaire.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2018 Gemaire Distributors
* @license    All rights reserved.
*/

// ==============================================
// Vars
// ==============================================

$switch-size-base: 22px !default;

/*
$switch-sizes: (
    small: 18px,
    large: 26px
);
*/

// ==============================================
// Switch
// ==============================================

// ----------------------------------------------
// Switch Left (Default)

.switch input + label {
    position: relative;
    padding-left: ($switch-size-base * 2) + 15px;
    line-height: $switch-size-base;

    &:after,
    &:before {
        display: none;
    }
}

// ----------------------------------------------
// State: Off (Default)

.switch input {
    visibility: hidden;
    width: 0;
    height: 0;
}

.switch_control {
    display: none;
}

.switch .switch_control {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: $switch-size-base * 2.2;
    height: $switch-size-base;
    border-radius: 500px;
    background-color: $c-text-subtle;
    cursor: pointer;
    transition: background-color 200ms linear;
    z-index: 0;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        z-index: 2;
        width: $switch-size-base * 0.6;
        height: $switch-size-base * 0.6;
        margin: $switch-size-base * 0.2;
        border-radius: 500px;
        background-color: $c-text-contrast-base;
        transition: left 200ms linear;
    }

    &::after {
        content: 'OFF';
        position: absolute;
        top: ($switch-size-base - ($switch-size-base / 2)) / 2;
        right: 0;
        z-index: 1;
        width: ($switch-size-base * 2.2) - ($switch-size-base * 0.6);
        color: $c-text-contrast-subtle;
        font-size: $switch-size-base / 2;
        line-height: 1;
        text-align: center;
    }
}

// ----------------------------------------------
// State: On

[type='checkbox']:checked + label .switch_control {
    background-color: $c-link-base;

    &::before {
        left: 54%;
        // left: ($switch-size-base * 2) - ($switch-size-base * 0.8);
    }

    &::after {
        content: 'ON';
        right: auto;
        left: 0;
        color: $c-text-contrast-base;
    }
}

// ==============================================
// Modifier: Justify
// ==============================================

.switch--justify input + label {
    display: block;
    padding-right: ($switch-size-base * 2) + 15px;
    padding-left: 0;
}

.switch--justify .switch_control {
    right: 0;
    left: auto;
}

// ==============================================
// Modifier: Right
// ==============================================

.switch--right input + label {
    padding-right: ($switch-size-base * 2) + 15px;
    padding-left: 0;
}

.switch--right .switch_control {
    right: 0;
    left: auto;
}

// ==============================================
// Modifier: Toggle
// ==============================================

.switch--toggle input + label {
    display: inline-flex;
    padding-left: 0;
    white-space: nowrap;
}

.switch--toggle .switch_label-option {
    &:first-child {
        padding-right: 10px;
    }

    &:last-child {
        display: block;
        padding-left: 10px;
    }
}

.switch--toggle .switch_control {
    position: relative;
    background-color: $c-link-base;

    &::after {
        display: none;
    }
}
