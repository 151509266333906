/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2017 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$drag-handle-size: 30px !default;


// ==============================================
// Drag Handle
// ==============================================

.drag-handle {
    display: inline-block;
    overflow: hidden;
    width: $drag-handle-size;
    height: $drag-handle-size;
    background-position: center center;
    vertical-align: middle;
    // Both properties required for browser compatibility
    cursor: move;
    cursor: grab; /* stylelint-disable-line declaration-block-no-duplicate-properties */
}

// Dragging
.gu-mirror,
.gu-mirror .drag-handle {
    cursor: grabbing;
}
