/**
* Gemaire Distributing
*/


// ==============================================
// Block Search
// ==============================================

.block-search {
    margin-bottom: 0;
}

.block-search .block-title {
    display: none;
}

.block-search .block-content {
    margin-bottom: 0;
    position: relative;
}

.block-search .control {
    clear: both;
    border-top: 0;
    margin: 0;
    padding: 0;
}

.block-search .actions {
    margin-top: -37px;

    .loading-mask {
        width: 50px;
        right: 43px;
        left: auto;
        transform: scale(0.7);
        background: #fff;
    }
}

.block-search .nested {
    display: block;
    padding-top: 5px;
    position: absolute;
}

.block-search .field {
    margin: 0;
}

.block-search input {
    background: $c-white;
    position: static;
    border: 0;
    height: $header-element-height;
    margin: 0;
    padding-right: 60px;
    padding-left: 8px;
    width: 100%;
    transition: 0.2s ease all;
    box-shadow: none;
}

@media (max-width: $bp-header-alpha - 1px) {
    .block-search input {
        border-bottom: 1px solid $c-border;

        &:focus {
            border-bottom-color: $c-input-border-focus;
        }

    }

}

.block-search .action.search {
    padding: 5px;
    width: 48px;
    height: 44px;
    position: absolute;
    background-color: transparent;
    top: 3px;
    border: 0;
    right: 3px;
    @include lib-button-icon($_icon-font-content: $icon-search,
        $_icon-font-text-hide: true,
        $_icon-font-color: $c-text-base,
        $_icon-font-color-hover: $c-blue-bright,
        $_icon-font-size: 26px,
        $_icon-font-line-height: 26px);

    &::before {
        position: absolute;
        top: 10px;
        left: 12px;
    }
}

// ----------------------------------------------

@media (max-width: $bp-header-alpha - 1px) {

    .block-search {
        width: 100%;
    }

}

// ----------------------------------------------

@media (min-width: $bp-header-alpha) {

    .block-search {
        position: absolute;
        top: 27px;
        width: calc(100% - 100px - 30px - 210px - 220px - 25px - 60px);
        right: 389px;
    }

}


// ==============================================
// Search Autocomplete
// ==============================================

.search-autocomplete {

    ul {
        margin-bottom: 0;
    }

    >ul {
        box-shadow: 0 3px 8px lighten($color: $c-black, $amount: 65);
        border: 0;
        padding: 0;
        background: $c-white;
    }

}

// ----------------------------------------------

@media (max-width: $bp-header-alpha - 1px) {

    .search-autocomplete>ul {
        top: 0 !important;
        margin-top: 1px;
    }

}

// ----------------------------------------------

@media (min-width: $bp-header-alpha) {

    .search-autocomplete>ul {
        @include menu;
        position: fixed;
        top: 78px !important;
        right: auto;
        left: 50% !important;
        width: 960px !important;
        transform: translateX(-50%);
    }
}

@media (min-width: $bp-screen-l) {

    .search-autocomplete>ul {
        position: absolute;
        top: (50px + 1px) !important;
        width: 100% !important;
        min-width: 960px;
    }
}

// ==============================================
// Typeahead Result
// ==============================================

.typeahead-result {
    margin: 0;
    background-color: $c-white;

    &:first-child {
        border-top: 0;
    }

    &:hover {
        background: #e5f2fc;
    }

    .delete-typeahead-recent-action:hover {
        .material-icons {
            background: #DFE5E8;
            border-radius: 50%;
        }
    }
}

.typeahead-result a {
    padding: 5px 0;
    display: block;
    text-decoration: none;
    color: $c-text-base;
    display: flex;
    align-items: flex-start;
    font-weight: bold;
    padding-left: 5px;

    .material-icons {
        margin-left: auto;
        margin-right: 3px;
        font-size: 20px;
        line-height: 24px;
        width: 24px;
        color: $c-gray-slate;
    }

    mark {
        color: $c-text-base;
        font-weight: normal;
        background-color: transparent;
    }

    &:hover,
    &.ui-state-focus {
        background-color: transparent;
        color: $c-text-base;
        text-decoration: none;
    }
}

.typeahead-result_category a {
    padding-left: $space-xxl;
    color: $c-link-base
}

.typeahead-result_product {
    border-bottom: 1px solid $c-border;

    &:last-child {
        border-bottom: 0;
    }
}

.typeahead-result_image {
    margin-right: 10px;
    width: 70px;
    height: 70px;
    flex-shrink: 0;
    text-align: center;

    img {
        width: 100%;
        height: auto;
    }
}

.typeahead-result_actions {
    float: none;
    text-align: right;
    margin-top: -42px;
    margin-bottom: 8px;
}


@media (max-width: $bp-screen-s - 1px) {
    .typeahead-result_actions {
        float: none;
        text-align: right;
        margin: -20px 0 10px;
    }
}

// ==============================================
// Search . Summary
// ==============================================

// [todo] where is this used?
.search.summary {
    margin-bottom: $space-base;
}

// ==============================================
// Search Autocomplete
// ==============================================

.watsco_search_wrapper {
    padding: 15px;

    h5 {
        font-size: $font-size-xs;
        font-weight: normal;
        color: $c-text-subtle;
        margin: 0 0 5px;
    }
}

.watsco_search_products {
    margin-top: 20px;

    .product-item-list-badge-product-name-wrapper {
        align-self: center;
    }

    .products-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
    }

    .product-items {
        padding: 0;
        border: 0;
        background: transparent;
        display: block;
        overflow: hidden;
    }

    .product-item {
        width: 100%;
        max-width: 100%;
        margin: 0;
        border-bottom-width: 0;
        border-left-width: 0;
        border-right-width: 0;

        &:first-child {
            border-top-width: 0;
        }

        .tocart {
            @extend .button--s;
            width: auto;
        }
    }

    .product-item-info {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        display: flex;
    }

    .product-inventory {
        min-height: 20px;
        margin: 0;
        display: none;

        &.hide-inventory-autocomplete {
            display: none;
        }
    }

    .product-info-brand a {
        pointer-events: none;
        color: $c-text-subtle;
    }

    .product-inventory_availability {
        display: none;
    }

    .inventory_wrapper {
        .stock {
            text-indent: -999999px;
            display: block;
            padding-left: 25px;
            font-size: 14px;

            +.stock,
            .status {
                display: none;
            }

            &:after {
                content: '';
                text-indent: 0;
                display: block;
                margin-top: -25px;
            }

            &.available {
                &:after {
                    content: 'In Stock';
                }
            }

            &.out-of-stock {
                &:after {
                    content: 'Out of Stock';
                }
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                text-indent: 0;
                top: -12px;
                left: 0;
                font-family: 'Material Icons';
                font-size: 24px;
            }

            &.available {
                &:before {
                    content: '\e86c';
                    color: $c-green;
                }
            }

            &.out-of-stock {
                &:before {
                    content: '\e5c9';
                    color: $c-gray-slate;
                }
            }
        }
    }

    .product-item-photo {
        width: 90px;
        margin-right: 10px;
        position: relative;
    }

    .product-quickview {
        @extend .button--reset;
        right: auto;
        left: 0;
        top: -6px;
        width: 30px;
        box-shadow: none;
        text-indent: -99999px;

        span {
            text-indent: 0;
        }
    }

    .product-item-details {
        width: calc(100% - 100px);
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-areas:
            ". pricing"
            "attributes pricing";
    }

    .product-item-list-left-column {
        grid-area: attributes;
    }

    .product-item-list-right-column {
        grid-area: pricing;
        text-align: right;

        .message {
            font-size: $font-size-xxxs;
            text-align: left;
        }
    }

    .price-box {
        margin: 0 0 10px;
        min-height: 24px;

        .price-wrapper {
            justify-content: flex-end;
        }

        .price {
            font-size: $font-size-l;
        }

        .uom-product {
            font-size: $font-size-s;
        }
    }

    .product-attribute {
        margin-bottom: 0;
    }

    .price-message {
        font-size: $font-size-base;
    }

    .field.qty {
        display: none;
    }
}

@media (min-width: $bp-screen-m) {
    .watsco_search_wrapper {
        display: flex;
    }

    .watsco_search_suggestions {
        width: 40%;
        margin-right: 30px;
    }

    .watsco_search_products {
        width: 60%;
        margin-top: 0;
    }
}

.search-autocomplete .product-labels-wrapper {
    display: none !important;
}

@media (max-width: $bp-screen-xs - 1px) {
    .watsco_search_products {

        .product-item-photo {
            position: absolute;
        }

        .product-item-list-badge-product-name-wrapper {
            padding-left: 100px;
            min-height: 100px;
        }

        .product-item-list-left-column {
            padding-left: 100px;
        }

        .product-item-details {
            width: 100%;
        }
    }
}