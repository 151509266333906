/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Grid Box
// ==============================================

.grid-box {
    &::after {
        @include clearfix;
    }
}

.grid-box-1-3,
.grid-box-1-2,
.grid-box-2-3 {
    margin-bottom: $gap;
}

// ----------------------------------------------
// Elements

.grid-box-image-wrapper {
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    background: $c-border;
}

//.grid-box-image {}

//.grid-box-content {}

.grid-box-title {
    @include h1;
}

// ---------------------------------------------

@media (min-width: 768px) {

    .grid-box-1-3,
    .grid-box-1-2,
    .grid-box-2-3 {
        float: left;
    }

    // ----------------------------------------------
    // Grid Box 1-2

    .grid-box-1-2 {
        width: 50%;

        &:first-child {
            padding-right: 2%;
        }

        &:last-child {
            padding-left: 2%;
        }
    }

    // ----------------------------------------------
    // Grid Box 1-3

    .grid-box-1-3 {
        width: 33.333%;

        &:first-child {
            padding-right: 2%;
        }

        &:last-child {
            padding-left: 2%;
        }
    }

    // ----------------------------------------------
    // Grid Box 2-3

    .grid-box-2-3 {
        width: 66.666%;

        &:first-child {
            padding-right: 2%;
        }

        &:last-child {
            padding-left: 2%;
        }
    }

}
