/**
* Gemaire Distributing
*/


// ==============================================
// Wrapper Panel
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .wrapper-panel {
        float: right;
        z-index: $z-index-9;
    }

}
