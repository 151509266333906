/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Blocks
// ==============================================

.blocks {
    margin-bottom: 15px;
}

.blocks .block {
    margin-bottom: 15px;
}


// ==============================================
// Block
// ==============================================

.block {
    margin-bottom: $gap;
}

.block_title {
    position: relative;
    padding: 10px 15px;
    border: 1px solid $c-border;
    border-radius: 5px 5px 0 0;
    background: $c-background;
}

.block_title h2,
.block_title h3 {
    @include h3;
    margin-bottom: 0;
    font-size: $font-size-base;
}

.block_content {
    padding: 15px;
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: $c-border;
    border-radius: 0 0 5px 5px;
    background: #FFF;
}

.block_content > *:last-child {
    margin-bottom: 0;
}


// ==============================================
// Block - With Toggle
// ==============================================

.block[data-toggle] > .block_title {
    padding-left: (15px + 11px + 15px);
    border-radius: 5px;
    cursor: pointer;
    user-select: none;

    &:hover {
        background: darken($c-background, 5%);
    }

    // Arrow
    &::before {
        @include svg (arrow-11-down-dark);
        content: '';
        position: absolute;
        left: 15px;
        top: 50%;
        width: 11px;
        height: 11px;
        margin-top: calc(11px / -2);
        transform: rotate(-90deg);
        transition: transform 150ms linear;
    }
}

// ----------------------------------------------
// Toggle - Show

.block[data-toggle='show'] > .block_title {
    border-radius: 5px 5px 0 0;

    &::before {
        transform: rotate(0deg);
    }
}
