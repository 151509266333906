/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Breadcrumbs
// ==============================================

.breadcrumbs {
    overflow: hidden;
    padding: 10px $liner-base;
    border-bottom: 1px solid $c-border;
    font-size: $font-size-xxs;
    background: $c-white;
}

.breadcrumbs li {
    float: left;
}

.breadcrumbs a {
    float: left;

    &:after {
        content: "/";
        padding: 0 7px;
        color: $c-text-base;
    }
}

.breadcrumbs strong {
    font-weight:normal;
}

.breadcrumbs:last-child {
    color: #505050
}

// ----------------------------------------------

@media print {

    .breadcrumbs {
        display: none;
    }

}