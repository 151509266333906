/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Inline Frame
// ==============================================

.iframe-wrapper {
    margin-bottom: $gap;
}

.iframe-wrapper iframe {
    width: 100%;
    height: 650px;
    border: 0;
}
