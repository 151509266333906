/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2018 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// File Drop
// ==============================================

.file-drop {
    margin-bottom: $gap;
    padding: 20px;
    border: 2px dashed $c-border;
    border-radius: 10px;
    background: #FFF;
    color: $c-text-subtle;
    text-align: center;
}

// ----------------------------------------------
// Modifier: Dragging

.file-drop--dragging {
    border-color: $c-action-default-base;
}


// ==============================================
// File Drop - Input
// ==============================================

.file-drop_input {
    display: none;
}


// ==============================================
// File Drop - Misc.
// ==============================================

.file-drop p {
    margin-bottom: 15px;
}

.file-drop_message {
    margin-top: 15px;
}
