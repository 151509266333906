/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2015 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Related Categories
// ==============================================

.related-categories {
    margin: $margin-bottom $liner-base;
}

.related-categories_title {
    font-size: $font-size-s;
}

//.related-categories_item {}

.related-categories_link {
    display: inline-block;
    margin-bottom: 0.3em;
    font-size: $font-size-xs;
    line-height: $line-height-xs;
}
