/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/


/* stylelint-disable no-duplicate-selectors */

// ==============================================
// Product Items
// ==============================================

.product-items {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}


// ==============================================
// Product Grid
// ==============================================

.products-grid {
    position: relative;

    &::after {
        @include clearfix;
    }
}

.products-grid .product-items>li {
    position: relative;
    flex-basis: 240px;
    flex-grow: 1;
}

.product-item-info {
    padding: 15px;
    text-align: left;
}

@media (max-width: $bp-screen-s - 1px) {
    .products-grid .product-items>li {
        flex-basis: 200px;
    }
}

// ----------------------------------------------
// Product Grid - Product Name

.products-grid .product-item-name {
    display: flex;
    margin-bottom: 20px;
    min-height: 90px;
    overflow: hidden;

    &:hover {
        color: $c-action-default-base;
    }
}

// ----------------------------------------------
// Product Numbers

.products-grid .product-numbers {
    margin-bottom: 10px;
}

// ----------------------------------------------
// Product Details

//.products-grid .product-details {}

// ----------------------------------------------
// Product Inventory

.products-grid .product-inventory {
    margin: 0 -15px;
    padding: 15px;
    border-top: 1px solid $c-emphasis-background;
}

// ----------------------------------------------
// Price

//.products-grid .price-wrap {}

.products-grid .price-hidden {
    margin-bottom: 5px;
}

// ----------------------------------------------
// Product Actions

//.products-grid .product-actions {}

.products-grid .add-to-cart-wrapper {
    overflow: hidden;
    margin-bottom: 10px;
}

.products-grid .add-to-cart-wrapper .qty {
    float: left;
    width: 30%;
    margin-right: 4%;
    line-height: 40px; // May cause caret to be abnormally tall, but fixes vertical alignment of placeholder text.
}

.products-grid .add-to-cart-wrapper .button {
    float: left;
    width: 66%;
}

// ----------------------------------------------
// Alternate Actions List

.products-grid .alternate-actions-list {
    padding-top: 5px;
}

.products-grid .add-to-compare-link,
.products-grid .add-to-wishlist-link {
    display: inline-block;
    padding: 4px 0;
    font-size: $font-size-xs;
}

// ----------------------------------------------
// Prop 65 Tooltip

.products-grid .prop-65-tooltip {
    margin-top: 10px;
}

// ==============================================
// Products Grid - Product Photo
// ==============================================

.products-grid .product-item-photo {
    margin-bottom: $space-base
}