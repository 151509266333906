/**
* Gemaire Distributing
*/


// ==============================================
// Var
// ==============================================

$pager-next-color:     #929090;
$pager-previous-color: #929090;


// ==============================================
// Pages
// ==============================================

//.pages {}

.pages > .label {
    @include lib-visually-hidden();
}


// ==============================================
// Pages - Items
// ==============================================

//.pages .items {}


// ==============================================
// Pages - Item
// ==============================================

.pages .item {
    display: inline-block;
    margin: 0;
    font-size: 15px;
    line-height: 32px;
}


// ==============================================
// Pages - Page
// ==============================================

.pages a.page {
    display: inline-block;
    padding: 0 13px;
}

.pages strong.page {
    display: inline-block;
    background: $c-blue-bright;
    font-size: 15px;
    letter-spacing: normal;
    line-height: 32px;
    color: #ffffff;
    padding: 0 13px;
}


// ==============================================
// Pages - Item Label
// ==============================================

.pages .item .label {
    @include lib-visually-hidden();
}


// ==============================================
// Pages - Action
// ==============================================

.pages .action {
    width: 34px;
}

.pages .action.previous {

    @include lib-icon-font(
        $_icon-font-content        : $icon-prev,
        $_icon-font                : $font-family-icon,
        $_icon-font-size           : 28px,
        $_icon-font-line-height    : 1,
        $_icon-font-color          : $pager-previous-color,
        $_icon-font-vertical-align : -10px
    );

    &:visited::after {
        color: $pager-previous-color;
    }

    &:active::after {
        color: $pager-previous-color;
    }
}

.pages .action.next {

    @include lib-icon-font(
        $_icon-font-content        : $icon-next,
        $_icon-font                : $font-family-icon,
        $_icon-font-size           : 28px,
        $_icon-font-line-height    : 1,
        $_icon-font-color          : $pager-next-color,
        $_icon-font-vertical-align : -10px
    );

    &:visited::after {
        color: $pager-next-color;
    }

    &:active::after {
        color: $pager-next-color;
    }
}

.pages .action.next > span,
.pages .action.previous > span {
    @include lib-visually-hidden();
}
