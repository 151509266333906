/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2016 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Row
// ==============================================

.row {
    margin-right: -15px;
    margin-left: -15px;

    &::after {
        @include clearfix;
    }
}

[class^='row-'] {
    float: left;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

// ----------------------------------------------

// For print (always)
// For wide screens
@media print, (min-width: 800px) {

    .row-1 { width: percentage(calc(1 / 12)); }
    .row-2 { width: percentage(calc(2 / 12)); }
    .row-3 { width: percentage(calc(3 / 12)); }
    .row-4 { width: percentage(calc(4 / 12)); }
    .row-5 { width: percentage(calc(5 / 12)); }
    .row-6 { width: percentage(calc(6 / 12)); }
    .row-7 { width: percentage(calc(7 / 12)); }
    .row-8 { width: percentage(calc(8 / 12)); }
    .row-9 { width: percentage(calc(9 / 12)); }
    .row-10 { width: percentage(calc(10 / 12)); }
    .row-11 { width: percentage(calc(11 / 12)); }

}


// ==============================================
// Row-Persist - Modifier
// No breakpoints
// ==============================================

.row-1--persist { width: percentage(calc(1 / 12)); }
.row-2--persist { width: percentage(calc(2 / 12)); }
.row-3--persist { width: percentage(calc(3 / 12)); }
.row-4--persist { width: percentage(calc(4 / 12)); }
.row-5--persist { width: percentage(calc(5 / 12)); }
.row-6--persist { width: percentage(calc(6 / 12)); }
.row-7--persist { width: percentage(calc(7 / 12)); }
.row-8--persist { width: percentage(calc(8 / 12)); }
.row-9--persist { width: percentage(calc(9 / 12)); }
.row-10--persist { width: percentage(calc(10 / 12)); }
.row-11--persist { width: percentage(calc(11 / 12)); }


// ----------------------------------------------
// Modifier: Width 600

.row--width-600 {
    max-width: 600px;
}


// ==============================================
// Row - Item
// ==============================================

//.row_item {}

// ----------------------------------------------
// Modifier: Grow

.row_item--grow {
    flex: 1;
}

// ----------------------------------------------
// Modifier: Pad

.row_item--pad {
    margin-right: 0.75em;
}
