/**
* Gemaire Distributing
*/

// ==============================================
// MultiSelect
// ==============================================

.multiinput {
    padding-bottom: 26px;

    .input-hint {
        position: absolute;
    }

    .multiinput-label {
        font-weight: 600 !important;
        font-size: $font-size-base !important;
        letter-spacing: normal;
        color: $c-text-base;
    }
}

.multiinput-textarea {
    height: 45px;
    width: 100%;
    border: 0;
    border-radius: 0;
    background: transparent;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23666464' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
    background-origin: content-box;
    background-position: right -5px center;
    background-repeat: no-repeat;
    background-clip: padding-box;
    outline: 0;
    appearance: none;
    color: $c-text-base;
    vertical-align: baseline;
    transition: border ease-in-out 180ms;
    border-bottom: 1px solid $c-border;
    display: block;
    overflow: hidden;

    &:focus {
        border-bottom: 1px solid $c-input-border-focus;
        .multiinput-label {
            color: $c-input-border-focus;
        }
    }
}

.multiinput-selection {
    position: relative;
    min-height: 44px;
    padding: 7px 0 0px 0;
    width: 100%;
    height: auto;
    border: none;
}

.multiinput-optionlist {
    width: auto;
    max-width: calc(100% - 10px);
    min-width: 260px;
    position: absolute;
    top: calc(100% - 34px);
    left: 5px;
    border: 1px solid $c-border;
    background: #fff;
    z-index: 9999 !important;
    transition: 180ms;
    opacity: 0;
    visibility: hidden;
    max-height: 206px;
    overflow-y: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

    &.show {
        opacity: 1;
        visibility: visible;
    }
}

.multiinput-option {
    padding: 10px 15px;
    cursor: pointer;

    &:hover {
        background: $c-emphasis-background;
    }
}

.multiinput-selected {
    position: relative;
    display: inline-block;
    padding: 1px 20px 1px 5px;
    margin-right: 5px;
    background: $c-emphasis-background;
    border-radius: 3px;
    min-width: 72.5px;
    max-width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .multiinput-selected-delete {
        position: absolute;
        width: 10px;
        height: 10px;
        right: 5px;
        top: calc(50% - 5px);
        cursor: pointer;

        svg {
            width: 10px;
            height: 26px;
            margin-top: -8px;
        }
    }
}

.multiinput-placeholder {
    padding-top: 5px;
}

// ==============================================
// Modifier: Size
// ==============================================

.multiinput--s {
    max-width: $input-max-width-s;
}

.multiinput--xs {
    max-width: $input-max-width-xs;
}
