/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2015 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Hero Carousel
// ==============================================

.hero-carousel {
    margin-bottom: ($gap * 1.25);
    padding-bottom: 40px; // Spacing for ".owl-pagination"
}

// ----------------------------------------------

@media (max-width: $bp-page-focal - 1px) {

    .hero-carousel {
        overflow: hidden; // Prevent ".owl-buttons" from causing horizontal scroll by overflowing the viewport
    }

}


// ==============================================
// Hero Carousel - Item
// ==============================================

//.hero-carousel .item {}


// ==============================================
// Hero Carousel - Owl Buttons
// ==============================================

.hero-carousel .owl-next,
.hero-carousel .owl-prev {
    position: absolute;
    z-index: $z-owl-carousel-buttons;
    top: 50%;
    margin-top: (40px / -2);
}

.hero-carousel .owl-prev {
    left: -5px;
}

.hero-carousel .owl-next {
    right: -5px;
}

// ----------------------------------------------

@media (min-width: $bp-page-focal) {

    .hero-carousel .owl-prev {
        left: -15px;
    }

    .hero-carousel .owl-next {
        right: -15px;
    }

}


// ==============================================
// Hero Carousel - Owl Pagination
// ==============================================

.hero-carousel .owl-pagination {
    position: absolute;
    bottom: -40px;
}
