/**
* Gemaire Distributing
*/

// ==============================================
// Search Main
// ==============================================

#search-main {
    width: 100%;
}

.search-results-content {
    background: $c-table-background;
}

// ==============================================
// Sidebar Main
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .page-with-filter .columns .sidebar-main {
        order: 0;
    }

    .sidebar-main .filter hr {
        display: none;
    }

}

// ==============================================
// #layered-filter-block
// ==============================================

#layered-filter-block {
    hr {
        border-color: $c-divider-border;
    }

    .block-content {
        font-size: 14px;
        padding: 0 $space-l;

        .filter-options.all-shown li {
            display: block;
        }

        li {
            padding-top: 2px;
            padding-bottom: 2px;

            &:nth-of-type(n+11) {
                display: none;
            }
        }

        &.filter-content {
            .collapsible_title {
                padding: 8px 0;
                font-weight: bold;
                border-radius: 0;
                margin: 0;

                &:hover {
                    background: $c-background;
                }
            }

            a {
                color: $c-text-base;
                padding: 5px 0;
                display: block;

                &.view-more-facets {
                    color: $c-blue-bright;

                    i {
                        margin-left: -4px;
                        vertical-align: -5px;
                        font-size: 19px;
                    }
                }

                &:hover {
                    color: $c-link-base;
                }
            }
        }
    }
}

.search-navigation {
    .block-content {
        font-size: 14px;
        padding: 0 $space-l;

        li {
            padding-top: 2px;
            padding-bottom: 2px;
        }
    }
}

.filter-item {
    color: $c-text-base;
}

.filters-search {
    width: 100%;

    @include lib-button-icon($_icon-font-content: $icon-search,
        $_icon-font-text-hide: true,
        $_icon-font-color: $c-text-base,
        $_icon-font-color-hover: $c-blue-bright,
        $_icon-font-size: 26px,
        $_icon-font-line-height: 26px);

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        line-height: 40px;
        height: 40px;
        pointer-events: none;
    }

    &:hover::before {
        color: currentColor;
    }

    .filters-search-input {
        padding-left: 40px;
        background: $c-background;
        @include placeholder($color: $c-text-subtle);
    }

    .clear-search {
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        line-height: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-size: 18px;
        }
    }
}

.filters-block {
    .filter-block {
        &:not(:first-of-type) {
            border-top: 1px solid #E4E4E4;
        }

        &.opened {
            .filter-toggle .icon {
                transform: rotate(90deg);
            }
        }
    }

    .button--link {
        color: $c-text-base;
        text-align: left;

        &:hover {
            color: $c-link-base;
        }
    }

    .filter-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .filter-toggle {
            display: flex;
            align-items: center;
            padding: 8px 0;
            font-weight: bold;
            border-radius: 0;
            margin: 0;
            flex-grow: 1;

            .icon {
                font-size: 22px;
            }

            &:hover {
                background: transparent;
                color: $c-link-base;
            }
        }

        .clear-filter {
            display: flex;
            align-items: center;
            white-space: nowrap;
            color: $c-text-base;

            &:hover {
                color: $c-link-base;
            }

            .icon {
                font-size: 20px;
            }
        }
    }

    .filter-content {
        padding: 0 0 10px 22px;

        .option-link {
            width: 100%;
            padding: 3px 0;

            label {
                display: inline-block;
                width: 100%;
            }

            &[disabled] {
                color: $c-action-disabled-text;
                cursor: not-allowed;

                * {
                    color: $c-action-disabled-text;
                    cursor: not-allowed;
                }
            }
        }
    }

    .facets-see-more {
        margin-top: 3px;
        color: $c-link-base;
    }
}

.category-filters {
    .items {
        li {
            &:not(.parent-category) {
                margin-left: 14px;
            }

            button {
                text-align: left;
                color: $c-text-base;

                &:hover {
                    color: $c-link-base;
                }
            }
        }

        .parent-category button {
            @include lib-icon-font($_icon-font-content: $icon-prev,
                $_icon-font-size: 22px,
                $_icon-font-position: before,
                $_icon-font-display: block);
            display: flex;
            align-items: center;

            &:before {
                margin-left: -7px;
            }
        }
    }
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    #layered-filter-block .block-content {
        padding: 0;
    }

    .block.filter .categories-content {
        display: none;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .block.filter {
        margin-bottom: $space-xxl;
    }

}


// ==============================================
// Block Subtitle
// ==============================================

.block-subtitle {
    display: inline-block;
    font-size: 13px;
    font-style: normal;
    letter-spacing: .04rem;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 15px;
}

// ==============================================
// Applied Filter Main
// ==============================================

.applied-filters-main {
    background: $c-yellow-light;
    border-top: 1px solid $c-border;
    padding: 16px 20px;

    >.block-content {
        display: inline-block;
    }

    .filter-clear {
        display: flex;
        color: $c-text-base;
    }
}

// ==============================================
// Filter Title
// ==============================================

.filter-title {
    margin-bottom: $space-l;
}

.filter-title strong {
    font-size: $font-size-xl;
}


// ==============================================
// Filter Current
// ==============================================

.filter-current {
    display: flex;
    align-items: center;

    &-subtitle {
        padding-right: 20px;
        vertical-align: top;
    }

    .items {
        display: inline-block;
    }

    .item {
        position: relative;
        display: inline-block;
        background: white;
        padding: 6px 34px 6px 10px;
        border: 1px solid $c-border;
        margin-right: 10px;

        .action.remove {
            font-size: 0px;
            position: absolute;
            right: 3px;
            top: 6px;

            @include lib-icon-font($icon-remove,
                $_icon-font-size : 24px,
                $_icon-font-line-height : 24px);
        }
    }
}


@media (max-width: $bp-screen-s - 1px) {
    .filter-current {
        display: block;

        .items {
            display: block;
        }

        .item {
            padding-top: 5px;

            .action.remove {
                &::before {
                    top: 10px;
                }
            }
        }
    }
}

// ==============================================
// Filter Options
// ==============================================

.filter-options {
    margin: 0;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .block.filter {
        width: 80%;
        position: fixed;
        top: 0;
        right: -80%;
        background: $c-white;
        z-index: 15;
        border-left: 1px solid $c-border;
        padding-top: 20px;
        transition: all ease 0.4s;
        height: 100vh;
        overflow: scroll;
    }

    .filters-open .block.filter {
        right: 0;
    }

    .filter-mobile_options .filter-options {
        display: block;
    }

    .search-navigation>div {
        position: static !important;
    }
}


// ==============================================
// Filter Options Item
// ==============================================

.count {
    color: $c-text-subtle;

    &::before {
        content: '(';
    }

    &::after {
        content: ')';
    }
}

// ==============================================
// Filter Options Content
// ==============================================

.inventory-content {

    button {
        padding: 8px 12px;
        background: $c-action-disabled-base;
        color: $c-blue-base;
        border: 0;
        font-size: 14px;
        border: 1px solid $c-action-disabled-base;

        &.active {
            background: $c-white;
            color: $c-text-base;
        }
    }
}

.inventory-content .filter-count-label,
.filter-options .filter-count-label {
    @include lib-visually-hidden;
}

.filter-options .item {
    position: relative;
}

.toolbar-wrap--bottom .inventory-content {
    display: none;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .inventory-content {
        width: 100%;
        margin-bottom: 10px;
    }

    .filter-options .item {
        padding: 12px 0;
    }

    .filters-mobile,
    .filters-mobile-toggle {
        display: block;
    }

    .products.wrapper~.toolbar .filters-mobile-toggle {
        display: none;
    }

    .filters-open .filters-mobile-toggle:after {
        background: rgba(0, 0, 0, 0.25);
        content: "";
        display: block;
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 14;
    }

}

@media (max-width: $bp-screen-s - 1px) {

    .inventory-content {

        margin-bottom: 22px;

        button {
            width: 100%;
        }
    }
}


// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .filters-mobile,
    .filters-mobile-toggle {
        display: none;
    }

}

// ==============================================
// Filter Actions
// ==============================================

.filter-actions {
    padding: 0 5px;
    display: inline-block;
}

@media (max-width: $bp-screen-s - 1px) {
    .filter-actions {
        padding-top: 15px;
    }
}

// ==============================================
// Close Filters Mobile
// ==============================================

#close_filters_mobile {
    width: 100%;
    margin: 10px 0;
    display: none;
}

@media (max-width: $bp-screen-s - 1px) {
    #close_filters_mobile {
        display: block;
    }
}