/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2017 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$c-stepper-button:        $c-action-secondary-base   !default;
$c-stepper-button-hover:  $c-action-secondary-hover  !default;
$c-stepper-button-active: $c-action-secondary-active !default;

$stepper-size:              60px !default;
$stepper-size-large:        70px !default;
$stepper-button-size:       40px !default;
$stepper-button-size-large: 48px !default;


// ==============================================
// Stepper
// ==============================================

.stepper {
    width: $stepper-size + ($stepper-button-size * 2);
}

.stepper_wrap {
    display: flex;
    align-items: flex-start;
    margin: 0 -5px;
}

// ----------------------------------------------
// Modifier: Large

.stepper--l {
    width: $stepper-size-large + ($stepper-button-size-large * 2);
}

// ----------------------------------------------
// Modifier: Wide

.stepper.stepper--wide {
    width: ($stepper-size * 2) + ($stepper-button-size * 2);
}

.stepper--l.stepper--wide {
    width: ($stepper-size-large * 2) + ($stepper-button-size-large * 2);
}

// ----------------------------------------------
// Modifier: Fluid

.stepper--fluid {
    width: 100%;

    input.qty {
        max-width: none;
    }

    .control {
        flex: 1;
    }
}


// ==============================================
// Stepper - Label
// ==============================================

.stepper_label {
    display: inline-block;
    line-height: 16px;
    color: $c-label;
    letter-spacing: 0.04rem;
    font-size: 13px;
    height: 30px;
}


// ==============================================
// Stepper - Liner
// ==============================================

.stepper_liner {
    display: flex;
    margin-top: -17px;
    
    &::after {
        @include clearfix;
    }
}


// ==============================================
// Stepper - Input
// ==============================================

.stepper_input {
    float: left;
    // width: $stepper-size;
    width: calc(100% - (#{$stepper-button-size} * 2));
    height: $stepper-button-size;
    padding: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    text-align: center;
    box-shadow: none;
}

// ----------------------------------------------
// Modifier: Large

.stepper--l .stepper_input {
    // width: $stepper-size-large;
    width: calc(100% - (#{$stepper-button-size-large} * 2));
    height: $stepper-button-size-large;
}

// ----------------------------------------------
// Modifier: Wide

// .stepper.stepper--wide .stepper_input {
//     width: $stepper-size * 2;
// }

// .stepper--l.stepper--wide .stepper_input {
//     width: $stepper-size-large * 2;
// }

// ----------------------------------------------
// Modifier: Fluid

// .stepper.stepper--fluid .stepper_input {
//     width: calc(100% - (#{$stepper-button-size} * 2));
// }

// .stepper--l.stepper--fluid .stepper_input {
//     width: calc(100% - (#{$stepper-button-size-large} * 2));
// }


// ==============================================
// Stepper - Button
// ==============================================

.stepper_button {
    @include image-replacement;
    position: relative;
    float: left;
    width: $stepper-button-size;
    height: $stepper-button-size;
    padding: 0;
    border: 0;
    border-bottom: 1px solid $c-border;
    background: transparent;
    box-shadow: none; // Prevent styles from ".button"
    user-select: none;

    &:hover {
        box-shadow: none; // Prevent styles from ".button"
    }

    &:focus {
        box-shadow: none; // Prevent styles from ".button"
    }

    &::before {
        @include image-replacement-override;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 24px;
        // margin-top: (24px / -2);
        // margin-left: (24px / -2);
        transform: translate3d(-50%, -50%, 0);

    }
}

// ----------------------------------------------
// Modifier: Large

.stepper--l .stepper_button {
    width: $stepper-button-size-large;
    height: $stepper-button-size-large;
}

// ----------------------------------------------
// Modifier: Down

.stepper_button--down {
    @include lib-icon-font(
        $_icon-font-content        : $icon-minus,
        $_icon-font-size           : 24px,
        $_icon-font-line-height    : 24px,
        $_icon-font-vertical-align : middle
    );
}

// ----------------------------------------------
// Modifier: Up

.stepper_button--up {
    @include lib-icon-font(
        $_icon-font-content        : $icon-add,
        $_icon-font-size           : 24px,
        $_icon-font-line-height    : 24px,
        $_icon-font-vertical-align : middle
    );
}
