/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Minimal Table
// ==============================================

.minimal-table {
    width: 100%;
    font-size: $font-size-s;
    margin-bottom: $gap;
}

.minimal-table th,
.minimal-table td {
    padding: 8px;
}

.minimal-table thead {
    border-bottom: 1px solid $c-table-border;
}

.minimal-table thead th {
    padding-top: 0;
    font-weight: bold;
    white-space:nowrap;
}

.minimal-table tbody tr {
    border-bottom: 1px solid $c-table-border;
}

.minimal-table tfoot {
    border-top: 1px solid $c-table-border;
}

.minimal-table thead th:first-child,
.minimal-table tbody td:first-child,
.minimal-table tfoot td:first-child {
    padding-left: 0;
}

.minimal-table thead th:last-child,
.minimal-table tbody td:last-child,
.minimal-table tfoot td:last-child {
    padding-right: 0;
}
