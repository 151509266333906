/**
* Gemaire Distributing
*/


// ==============================================
// Checkout Success
// ==============================================


.checkout-onepage-success .column.main {
    width: 100%;
}

.checkout-success-actions {
    display: block;
}

@media (max-width: $bp-screen-xs - 1px) {
    .checkout-success-actions > a, 
    .checkout-success-actions button{
        width: 100%;
        margin: 0 0 10px 5px;
    }
}

.checkout-success-icon {
    font-size: 100px !important;
}

