/**
* Gemaire Distributing
*/


// ==============================================
// Product Image Wrapper
// ==============================================

.product-image-wrapper {
    display: block;
    position: relative;
    padding: 0 !important;
}
