/**
* Gemaire Distributing
*/

// ==============================================
// Text Field
// ==============================================

@mixin text-field($input-border, $input-padding) {
    appearance: none;
    background: transparent;
    background-clip: padding-box;
    border: $input-border;
    border-radius: 0;
    border-bottom: 1px solid $c-border;
    height: 40px;
    padding: $input-padding;
    width: 100%;
    outline: 0;
    transition: 0.2s ease all;

    // ==============================================
    // Placeholder
    // See: https://css-tricks.com/almanac/selectors/p/placeholder/
    // ==============================================

    .control & {
        @include placeholder($color: transparent);
    }

    &:focus {
        border-bottom-color: $c-input-border-focus;
    }

    &:focus,
    &.hasValue,
    &._has-datepicker {
        @include placeholder();
    }

    // ---------------------------------------------------
    // Auto Fill

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: $c-input-text-fill;
        -webkit-box-shadow: 0 0 0px 1000px $c-blue-light inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    // Fix outline on invalid input texts - Firefox
    &:invalid {
        box-shadow: none;
    }

    &:-moz-submit-invalid {
        box-shadow: none;
    }

    &:-moz-ui-invalid {
        box-shadow: none;
    }

    @include input-disabled;
}

@mixin input-hint {
    @include caption;
    margin-top: $space-xxs;
    min-height: 26px;
}

@mixin input-disabled {
    &:disabled {
        border-bottom: 1px dotted $c-input-border-base;
        background-color: $c-library-gray-0;
    }
}

@mixin placeholder($color: $c-input-placeholder) {
    &::-moz-placeholder {
        color: $color;
        font-size: $form-elem-font-size; // Prevent iOS zoom on focus.
    }

    &::-webkit-input-placeholder {
        color: $color;
        font-size: $form-elem-font-size; // Prevent iOS zoom on focus.
    }

    &:-ms-input-placeholder {
        color: $color;
        font-size: $form-elem-font-size; // Prevent iOS zoom on focus.
    }
}

@mixin label-color($label, $label-focus) {
    & ~ label {
        color: $label;
    }

    &:focus ~ label {
        color: $label-focus;
    }
}

@mixin label-position($label-top, $label-top-focus, $label-left, $label-font-size, $label-font-size-focus) {
    & ~ label {
        top: $label-top;
        left: $label-left;
        font-size: $label-font-size;
    }

    &:not([value='']) ~ label {
        top: $label-top-focus;
        font-size: $label-font-size-focus;
    }

    &:not([value]) ~ label {
        top: $label-top;
        font-size: $label-font-size;
    }

    &._has-datepicker ~ label,
    &.hasValue ~ label,
    &:not(:empty) ~ label {
        top: $label-top-focus;
        font-size: $label-font-size-focus;
        font-weight: $font-weight-semibold;
    }

    &:-webkit-autofill ~ label,
    &:-webkit-autofill:hover ~ label,
    &:-webkit-autofill:focus ~ label {
        top: $label-top-focus;
        font-size: $label-font-size-focus;
    }

    &:focus ~ label,
    &[value='']:focus ~ label,
    &[value]:focus ~ label,
    &.hasValue:focus ~ label {
        top: $label-top-focus;
        font-size: $label-font-size-focus;
    }
}

@mixin input-date($placeholder, $placeholder-focus) {
}
