$box-shadow-minisplits-base: 0 2px 4px rgba(0, 0, 0, 0.2);
$box-shadow-minisplits-hover: 0 2px 8px rgba(0, 0, 0, 0.25);

$ahri-addto-box-width: 330px;
$ahri-addto-box-margin: 30px;

// ==============================================
// AHRI Details Page
// ==============================================

.ahriDetails {
    position: relative;

    .ahri-type {
        .ahri-type-title {
            font-weight: 600;
        }
    }

    .ahriDetails_smallTables {
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin: 0 -16px 32px;

        .ahriDetails_smallTable {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            width: 50%;
            margin: 0 20px;

            .table:not(.fake-table) {
                width: 100%;
                margin-bottom: 1.5em;
                font-size: 15px;
            }
        }
    }

    .ahriDetails_item {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        box-shadow: $box-shadow-base;

        .product-item-list-right-column {
            flex-basis: 190px;
        }
    }

    .ahriDetails_addToCart {
        width: 100%;
        max-width: $ahri-addto-box-width;
        margin-bottom: 20px;
        margin-left: 20px;
        border: 1px solid $c-border;
        box-shadow: $box-shadow-base;
        padding: 20px;
        background-color: $c-yellow-light;
        order: 2;

        .product-info-price {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.3;

            .price-box .price {
                font-size: 36px;
            }
        }

        .actions-primary {
            .field.qty {
                width: 100%;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 50px;
                flex: 0 0 50px;
                margin: 0;
            }

            .actions {
                text-align: left;
                -webkit-box-flex: 1;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
            }
        }

        &.sticky {
            position: fixed;
            margin: 0;
            left: 0;
            right: 0;
            max-width: none;
            top: 0;
            z-index: 100;

            .field.qty {
                display: none;
            }

            .button--xl {
                font-size: 16px;
                line-height: 40px;
                padding: 0 30px;
            }

            .ahriDetails_addToCartMain {
                display: flex;
                justify-content: space-between;
                align-items: center;

                * {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                }
            }
        }
    }
}

@media (min-width: $bp-screen-l) {
    .ahriDetails .ahriDetails_addToCart {
        position: fixed;
        top: 260px;
        right: 0;
        margin: 0 20px;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .ahriDetails .ahriDetails_addToCart {
        margin-left: 0;
        max-width: none;
    }
}

// ==============================================
// AHRI Card
// ==============================================

.ahri-system-button {}

.ahri-card {
    box-shadow: $box-shadow-base;
    text-align: center;
    padding: 20px;
    font-weight: 600;
    margin: 0 $space-s 20px;
    border: 1px solid $c-border;
    min-width: 140px;
    flex: 1;

    span {
        font-size: 30px;
        font-weight: normal;
        display: block;
    }

    .ahri-card--matchups-list &:first-child span {
        font-weight: 600;
    }

    &--matchups {
        position: relative;
        font-weight: normal;
        display: flex;
        align-items: center;
        width: 210px;
        text-align: left;
        padding: 10px;
        background-color: white;
        flex: 0 1 auto;

        .system-picture {
            width: 60px;
            height: 60px;
            flex-shrink: 0;
            margin-right: 15px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        span {
            font-size: $font-size-base;
            max-width: 100px;
        }

        &:hover,
        &.active {
            border-color: $c-blue-bright;
        }
    }
}

// ==============================================
// AHRI Details Column
// ==============================================

.ahri-details-column {
    max-width: 100%;

    @media (min-width: $bp-screen-l) {
        width: calc(100% - #{$ahri-addto-box-width + $ahri-addto-box-margin});
    }
}

// ==============================================
// AHRI Details Table + Add to Cart Wrapper
// ==============================================

@media (min-width: $bp-screen-m) {
    .ahriDetails_table_addToCart_wrapper {
        display: flex;
        align-items: flex-start;
    }
}

// ==============================================
// AHRI Link Product Item
// ==============================================

.ahri-link-product-item {
    display: none;
}

// ==============================================
// AHRI Matchups
// ==============================================

.ahri-matchups {
    position: relative;

    .product-item {
        .actions-primary {
            display: none;
        }

        .ahri-link-product-item {
            display: block;
        }
    }
}

@media (min-width: $bp-screen-s) {
    .ahri-matchups select {
        width: 170px;
    }
}

.ahri-systems-panel {
    background: white;
    padding: 20px;
    margin: 20px;
    border: 1px solid $c-border;
}

.ahri-toolbar {
    background: white;
    padding: 20px;
    border-top: 1px solid $c-border;
    border-bottom: 1px solid $c-border;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

// ==============================================
// AHRI Matchups List
// ==============================================

.ahri-card--matchups-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

// ==============================================
// AHRI Mini Splits
// ==============================================

.field-row .field.mini-split-field {
    flex-grow: unset;
}

.minisplits-systems-wrapper {
    background: $c-background;
    border-top: 1px solid $c-border;

    .minisplits-vendor-header {
        box-shadow: $box-shadow-minisplits-base;
        background: #505050;
        color: #fff;
        padding: 4px 15px;
    }

    .minisplits-family {
        box-shadow: $box-shadow-minisplits-base;
        padding: 20px;
    }

    .minisplits-familyname {
        @include h2;
        display: flex;
        align-items: center;
        content: '';

        .familyname-subtitle {
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
            color: $c-text-subtle;
        }
    }

    .minisplits-systems-accordion.accordion {
        margin: 0 -20px -20px;

        .accordion_header {
            border-left: none;
            border-right: none;
            transition: 200ms all;

            .accordion_title {
                transition: 200ms all;
                color: $c-link-base;
            }

            &:hover,
            &:active {
                background: #eef4fe;
            }

            &::after {
                background-size: 100%;
                top: 26px;
                width: 16px;
                height: 14px;
            }

            &:not(:first-of-type) {
                border-top: none;
            }
        }

        .accordion_body {
            background: $c-action-disabled-base;
        }
    }

    .minisplits-systems-table-wrapper {
        overflow-x: auto;
    }

    table.minisplits-systems-table {
        th {
            span {
                font-weight: $font-weight-normal;
            }
        }

        td {
            white-space: nowrap;
        }

        .inventory_wrapper.inventory_simple .stock {
            flex-wrap: nowrap;
        }
    }

    .minisplits-multi-zone {
        .minisplits-systems-table-wrapper {
            margin: 0 -20px -20px;
            padding: 15px;
            background: $c-action-disabled-base;

            th {
                vertical-align: top;
                line-height: 1.15;
            }
        }
    }
}

.minisplits-index-index .page-main {
    padding: 0;
}

.minisplits-family-info {
    @media (max-width: $bp-screen-m) {
        .grid_4 {
            padding-top: $space-base;
        }
    }
}

.table.fake-table {
    margin: 0;
    width: 155px;

    td {
        width: 45px;
        padding: 0;
        border: 0;

        &.separator {
            width: 15px;
        }
    }
}

.ahri-details-page,
.singlezone-details-page,
.multizone-details-page {
    .ahri-details-content {
        display: flex;
        align-items: flex-start;
    }

    .content-wrapper {
        position: relative;

        &:before {
            content: '';
            background-color: $c-background;
            position: absolute;
            height: 100%;
            width: 100vw;
            left: 0;
            z-index: -1;
            top: 0;
        }
    }

    &.ahriDetails {
        @media (max-width: $bp-screen-m) {
            .ahriDetails_table_addToCart_wrapper {
                position: fixed !important;
                bottom: 0;
                left: 0;
                margin: 0;
                width: 100%;
                max-width: 100vw;
            }

            .ahriDetails_addToCart {
                width: 100%;
                max-width: 100%;
                margin: 0;
                box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
                border: none;

                .ahriDetails_addToCartMain {
                    display: flex;
                    justify-content: space-between;

                    .field.qty {
                        display: none;
                    }
                }

                .product-info-price {
                    display: flex;
                    align-items: center;

                    >div {
                        margin: 0 !important;
                    }

                    .price-box .price {
                        font-size: $font-size-xxxl;
                    }
                }

                .button.action {
                    font-size: 14px;
                    margin: 0 !important;
                    padding: 0 20px;
                    line-height: 35px;
                }

                .equipment-selection {
                    margin-bottom: 0;
                    line-height: 16px;
                }
            }
        }
    }


    @media (min-width: $bp-screen-m) {
        &.ahriDetails .ahriDetails_table_addToCart_wrapper {
            position: static;
            margin: 0 0 0 32px;
            width: 330px;
            display: block;

            .ahriDetails_addToCart {
                position: static;
                margin: 0;
            }
        }
    }
}

.ahri-details-page,
.multizone-details-page,
.singlezone-details-page {

    .ahriDetails_item,
    .ahriDetails_addToCart {
        .stepper_wrap {
            margin-left: 0;
        }
    }
}

.multizone-details-page {
    .multizone-equip-type-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .multizone-ahri-num-button {
            width: 340px;
            margin-bottom: 20px;
            padding: 15px 15px 10px;
            background: #fff;
            box-shadow: $box-shadow-minisplits-base;
            text-align: left;
            transition: 200ms all;
            border: 1px solid #fff;
            margin-right: 30px;

            &.active {
                border-color: $c-link-base;
            }

            &:hover,
            &:active {
                box-shadow: $box-shadow-minisplits-hover;
            }
        }
    }

    .multizone-ahri-num-table-title {
        background-color: $c-table-background;
        color: $c-text-subtle;
        font-size: $font-size-xxxs;
        line-height: 20px;
        text-transform: uppercase;
        text-align: center;
        margin: 10px 0 5px;
    }

    .multizone-ahri-num-table {
        table-layout: fixed;
        margin-bottom: 0;

        th,
        td {
            text-align: center;
            border: none;
            background-color: #fff;
            padding: 0;
        }

        th {
            font-size: $font-size-xs;
        }

        td {
            font-size: $font-size-l;
        }
    }

    .multizone-ahri-num-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .radio-button {
            [type='radio']+label::before {
                top: 1px;
            }

            label {
                padding-left: 30px;
                font-weight: 600;
            }
        }
    }

    .tabs {
        .tabs_nav-link {
            text-transform: capitalize;
        }

        @media (max-width: $bp-screen-m) {
            display: block;

            .tabs_nav-link {
                width: 100%;
                padding-left: 5px;
                text-align: left;
                font-size: $font-size-l;
                border: 1px solid $c-table-border;

                &:not(:first-of-type) {
                    border-top: none;
                }

                &::before {
                    display: none;
                }

                @include lib-icon-font($_icon-font-content: $icon-next,
                    $_icon-font-size: 35px,
                    $_icon-font-position: after,
                    $_icon-font-display: block);

                display: flex;
                align-items: center;

                &:after {
                    order: -1;
                    position: static;
                    width: auto;
                    height: auto;
                    background: none;
                }

                &.active {
                    background: $c-blue-bright;
                    border-color: $c-blue-bright;
                    color: white;

                    &::after {
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }

    @media (min-width: $bp-screen-m) {

        .minisplits-capacity-box {
            padding: 15px 15px 10px;
            background: #fff;
            box-shadow: $box-shadow-base;
            border: 1px solid $c-divider-border;
        }
    }

    .minisplits-capacity-info-box {
        display: flex;
        align-items: center;
        margin: 30px -15px;

        .capacity-info {
            flex: 1 1 0;

            +.capacity-info {
                margin-top: 15px;
            }
        }
    }

    .heating-cooling-switch {
        border: 2px solid $c-border;
        display: flex;

        .capacity-switch-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            padding: 4px;
            line-height: 30px;
            font-size: $font-size-base;
            transition: 200ms background-color;

            &.active {
                border: 2px solid $c-blue-bright;
                margin: -2px;
                width: calc(50% + 4px);
                z-index: 1;
                cursor: default;
            }

            &:not(.active):hover {
                background-color: $c-blue-light;
            }

            .material-icons {
                margin-right: 3px;
            }
        }
    }

    .CircularProgressbar .CircularProgressbar-path {
        stroke: $c-green;
    }

    .exceeded {
        .CircularProgressbar .CircularProgressbar-path {
            stroke: $c-danger;
        }

        .capacity-value {
            color: $c-danger;
        }
    }

    .extended {
        .CircularProgressbar .CircularProgressbar-path {
            stroke: #c6b63d;
        }
    }

    .capacity-label {
        color: $c-text-subtle;
        font-size: $font-size-xxxs;
    }

    .capacity-sublabel {
        color: $c-text-subtle;
        font-size: $font-size-xxxxxs;
    }

    .capacity-progressbar .capacity-value {
        font-size: 24px;
    }

    .ahriDetails_item {
        .stepper--fluid {
            label {
                color: $c-text-subtle;
                font-size: $font-size-xxs;
            }
        }

        &.product-item .price-box {
            margin-top: 8px;
        }
    }

    .multizone-indoor-filters {
        display: flex;
        flex-wrap: wrap;
    }

    .table.multizone-capacity-allocation-table {

        th,
        td {
            padding: 0 4px;
            border: none;
        }

        th {
            font-size: $font-size-xxxs;
        }

        td {
            padding: 6px 4px;
        }

        tr:first-child {
            td {
                padding-top: 15px;
            }
        }

        tr:last-child {
            td {
                padding-bottom: 15px;
                border-bottom: 1px solid $c-table-border;
            }
        }

        .qty {
            width: 30px;
            height: 24px;
        }

        .remove {
            display: flex;
            color: $c-gray-slate;
        }
    }

    .button.button--capacity-upgrade {
        background: #e8590c;
    }

    .minisplits-additional-components-tabs {
        .tabs_nav-link {
            text-transform: capitalize;
        }
    }

    .zones-box {
        display: flex;
        justify-content: center;
        border-bottom: 1px solid $c-table-border;

        >div {
            padding: 5px;
            text-align: center;
            font-size: $font-size-xxl;
            flex: 1 1 auto;

            .subtitle {
                font-size: $font-size-base;
            }

            +div {
                border-left: 1px solid $c-table-border;
            }
        }
    }

    .ahriDetails_addToCartMain {
        .message {
            margin: 0;
        }
    }

    .branch-box-icon {
        &.branch-box-tooltip.tooltip-dark {
            display: block;
            width: auto;
            height: auto;
            line-height: inherit;
            border-radius: 0;
            text-align: center;
            background: transparent;
            color: inherit;
            cursor: default;

            .tooltip-dark-content {
                left: 50%;
                transform: translateX(calc(-50% - 60px));
                max-width: 208px;
                min-width: 208px;
                border: none;
                border-radius: 5px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
                padding: 15px;

                &:before,
                &:after {
                    left: calc(50% + 60px);
                }
            }
        }
    }

    .multizone-page-loader {
        .loading-mask.section-mask {
            position: fixed !important;

            &::before {
                top: 50%;
            }
        }
    }

    .additional-docs {
        border-top: 1px solid $c-table-border;
        padding-top: 20px;
        margin: 0 15px 20px;

        .additional-doc a {
            display: flex;
        }

        .additional-doc+.additional-doc {
            margin-top: 10px;
        }
    }

    .equipment-switch {
        input {
            display: none;
        }

        label {
            margin: 0;
        }
    }

    .danger-zone-progressbar {
        position: absolute;
        width: calc(100% - 20px);
        left: 10px;
        top: 10px;
    }

    &.ahriDetails {
        @media (max-width: $bp-screen-m) {
            .content-wrapper {
                overflow: hidden;
            }

            .ahriDetails_table_addToCart_wrapper {
                display: flex;
                box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
                background-color: $c-yellow-light;
            }

            .ahriDetails_addToCart {
                width: calc(100% - 285px);
                margin-left: auto;
                box-shadow: none;

                .ahriDetails_addToCartMain {
                    justify-content: flex-end;

                    >div {
                        display: flex;
                        align-items: center;

                        .equipment-selection {
                            margin-right: 10px;
                        }
                    }
                }
            }

            .minisplits-capacity-box:not(.active) {
                display: none;
            }

            .product-info-price {
                margin-left: auto;
                margin-right: 20px;
            }

            .mobile-capacity-block {
                width: 285px;
                max-width: 50%;
                padding: 15px;
                background-color: white;
                font-size: $font-size-xs;

                .capacity-progressbar {
                    float: left;
                    margin: 5px 15px 5px 0;
                }

                .mobile-cap-label {
                    display: flex;
                    line-height: 22px;
                    float: left;
                }

                .mobile-cap-volume {
                    float: left;

                    span+span {
                        margin-left: 10px;
                    }
                }
            }

            .minisplits-capacity-box {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 1;
                background: white;
                width: 320px;
                max-width: 100%;
                margin: 0 !important;
                padding: 20px 20px 10px;
                box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
                z-index: 2;
                max-height: 100vh;
                overflow: auto;
            }

            .cap-close-button {
                position: absolute;
                right: 10px;
                top: 10px;
            }

            .text-danger {
                color: $c-danger;
            }

            .message {
                position: absolute;
                bottom: 100%;
                z-index: 1;
                background: #f4f4f4;
                width: 100%;
                left: 0;
                box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
            }
        }

        @media (max-width: $bp-screen-s) {
            .ahriDetails_addToCart {
                width: calc(100% - 170px);
                padding: 10px 15px;

                .ahriDetails_addToCartMain {
                    flex-direction: column;
                    align-items: flex-end;
                    text-align: right;

                    .product-info-price {
                        margin: 0 0 5px;

                        small {
                            display: none;
                        }
                    }

                    .price-box .price {
                        font-size: $font-size-l;
                    }

                    .equipment-selection {
                        text-align: right;
                        margin: 0 0 5px;
                        font-size: 12px !important;
                    }
                }
            }

            .mobile-capacity-block {
                padding: 10px 15px;
                width: 170px;
                display: flex;
                flex-wrap: wrap;

                .mobile-cap-label {
                    order: -1;
                    width: 100%;
                }

                .capacity-progressbar {
                    margin: 0 10px 0 0;
                }

                .mobile-cap-volume {
                    display: flex;
                    flex-direction: column;
                    text-align: left;

                    span {
                        margin: 0 !important;
                    }
                }
            }
        }
    }
}

.minisplits-index-index {
    @media (max-width: $bp-screen-m) {
        padding-bottom: 68px;

        .site-footer,
        .site-footer .container {
            position: static;
        }
    }
}

.ahri-preloader,
.ahri-preloader svg {
    max-width: 100%;
}

@media (min-width: $bp-screen-m) {
    .minisplits-desktop-hide {
        display: none !important;
    }
}

@media (max-width: $bp-screen-m) {
    .minisplits-mobile-hide {
        display: none !important;
    }
}

.minisplits-single-zone-icon circle#Ellipse_1 {
    transform: translate(8.413px, 12.846px); // issue with normalize overriding svg icon positioning styles
}



.ahri-systems-panel {
    .toolbar-wrap {
        &.toolbar-wrap--top {
            border: none;
        }

        .toolbar {
            background: #fff;
            padding: 10px 0;

            .limiter-options {
                width: auto;
                border: 1px solid $c-border;
            }
        }
    }
}

.featured-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .control {
        margin-top: 0;
    }

    @media (max-width: $bp-screen-s) {
        flex-direction: column;
        margin-bottom: 15px;
        width: 100%;

        .control {
            width: 100% !important;
            margin: 0 0 10px !important;
        }
    }
}

.ahri-matchups-system-wrapper {
    .direct-ship-filter {
        display: none !important; // Hide Direct Ship filter for AHRI systems matchup
    }

    @media (min-width: $bp-screen-m) {
        .search-results-content {
            max-width: calc(100% - 265px);
        }
    }

    .ahri-systems-list {
        overflow-x: auto;
        width: 100%;
    }
}

.ahri-systems-list {
    .price-label {
        font-size: $font-size-xxs;
        color: #999;
        font-weight: 300;
    }
}

.minisplits-table-actions,
.ahri-table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .menu {
        display: flex;
    }

    .menu_content {
        left: auto;
        right: 0;
        top: 28px;
        width: 220px;

        a,
        .button--link {
            text-align: left;
            color: $c-link-base;
            white-space: normal;
            padding-top: 10px;
            padding-bottom: 10px;
            line-height: 18px;
        }
    }

    .section-mask:before {
        left: calc(50% - 15px);
        top: calc(50% - 15px);
        width: 30px;
        height: 30px;
    }
}

.equipment-selection {
    button {
        text-transform: capitalize;
        font-weight: 600;
    }
}

.ahri-seer-wrapper {
    margin-left: auto;
    display: flex;
    align-items: center;
    min-height: 40px;

    .ahri-seer-subtitle {
        margin-right: 10px;
    }

    .seer-switch {
        padding-left: 25px;
    }
}

.ahri-systems-list {
    table {
        td {
            line-height: 26px;
        }

        .focus-button-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                display: flex;
                align-items: center;
                height: 26px;


                &:hover:not(.disabled) {
                    color: $c-link-active;
                }

                &.disabled {
                    opacity: 0.2;
                }
            }
        }
    }
}

.table {
    .sort-button-wrapper {
        cursor: default;
        display: flex;
        align-items: center;
    }

    .sort-buttons {
        height: 18px;
        display: flex;
        flex-direction: column;
        margin-top: -1px;

        .sorting-desc,
        .sorting-asc {
            height: 9px;
            line-height: 9px;
            transform: 200ms all;
            color: $c-text-subtle;

            span {
                display: block;
                height: 9px;
                line-height: 9px;
                overflow: hidden;
            }

            &.active,
            &:hover {
                color: $c-link-active;
            }
        }
    }
}

.ahri-pdp-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ahri-pdp-wrapper {
    .columns {
        margin: 20px -20px -20px;
        width: calc(100% + 40px);

        .search-results-content {
            margin-bottom: 0;
            border-bottom: 0;
            border-right: 0;
            background: #fbfbfb;

            .ahri-systems-panel {
                margin: 0;
                border: 0;
                background: transparent;

                .toolbar {
                    background: transparent;
                }
            }
        }

        .search-navigation {
            width: 280px;
        }
    }

    .direct-ship-filter {
        display: none !important; // Hide Direct Ship filter for AHRI systems matchup
    }

    .applied-filters-main {
        border-left: 1px solid $c-border;
        border-right: 1px solid $c-border;
    }

    .ahri-systems-list {
        max-width: 1117px;
        overflow: auto;
    }
}

.filter-toggle-switch {
    &.switch label {
        margin: 0;
        width: 100%;
        padding-left: 48px;
    }

    .switch_control {
        transform: scale(0.8);
        left: -4px;
    }
}

.ahri-pdp-toolbar {
    .toolbar-limiter {
        margin-left: 20px;
    }

    .ahriTable_columnsSelector {
        position: relative;
        margin-left: auto;
    }

    .ahriTable_columnContainer {
        >div {
            width: 100%;
            float: none;
        }
    }
}

.ahriTable_columnContainer {
    position: absolute;
    width: 100%;
    min-width: 240px;
    padding: 15px 0;
    border: 1px solid #D0D0D0;
    right: 0;
    margin-top: 2px;
    max-width: 30em;
    z-index: 200;
    background: #fff;
    font-size: 14px;

    >div {
        float: left;
        width: 50%;
        padding: 0 10px;
    }
}