/**
* Gemaire Distributing
*/


// ==============================================
// Field
// ==============================================

// [todo] move this pattern to component

.field .control._with-tooltip {
    position: relative;
}

.field.choice .field-tooltip {
    display: inline-block;
    margin-left: $space-base;
    position: relative;
    top: -3px;
}

.field.choice .field-tooltip-action {
    line-height: $checkout-tooltip-icon__font-size;
}

.field._error .control input,
.field._error .control select,
.field._error .control textarea {
    border-color: $form-element-validation__border-error;
}
