/**
* Gemaire Distributing
*/

// ==============================================
// Product Inventory - container
// ==============================================

.product-inventory {
    margin-top: 10px;
    font-weight: $font-weight-light;
}

.product-inventory_availability {
    font-weight: $font-weight-normal;
}

// ==============================================
// Inventory - stock
// ==============================================

.stock.available,
.stock.unavailable {
    vertical-align: top;
    display: flex;
}

.stock.available .status {
    color: $c-green;
    /*@include lib-icon-font(
                    $icon-inventory-available,
            $_icon-font-color: $c-green,
            $_icon-font-size: 14px,
            $_icon-font-margin: -3px 2px 0 0
    )*/
}

.stock.out-of-stock .status,
.stock.unavailable .status {
    color: $c-gray-slate;
}

.stock span {
    font-weight: $font-weight-normal;
}

// ==============================================
// Inventory
// ==============================================

.inventory {
    display: inline-block;
    margin: $space-base 0;
}

.inventory_stock.available {
    color: $c-green;
}

.inventory_stock.unavailable,
.inventory_stock.out-of-stock {
    color: $c-gray-slate;
}

.inventory_store-name {
    @include lib-icon-font($icon-location,
        $_icon-font-color: $c-library-gray-7,
        $_icon-font-size: 16px,
        $_icon-font-margin: -2px 2px 0 -4px);
}

.inventory_wrapper {
    line-height: 1.4;
    text-transform: capitalize;

    .status {
        min-width: 40px;
        display: inline-block;
        font-weight: 600;
    }

    &.inventory_simple {
        .stock {
            display: flex;
            align-content: baseline;
            text-transform: capitalize;
            margin: 3px 0;

            &.available i {
                background: $c-green;

                &::before {
                    width: 6px;
                    left: calc(50% - 7px);
                    top: calc(50%);
                }

                &::after {
                    left: calc(50% - 4px);
                }
            }

            &.tooltip {
                cursor: default;

                &::after {
                    transform: none;
                    left: -10px;
                    top: auto;
                    bottom: 100%;
                    padding: 10px 15px;
                }

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-top: 6px solid $c-library-gray-7;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    left: 4px;
                    bottom: calc(100% - 6px);
                    z-index: $z-index-2;
                    transition: all 0.2s ease;
                    transition-delay: 0.1s;
                    opacity: 0;
                }

                &:hover {

                    &::before,
                    &::after {
                        opacity: 1;
                        transform: translateY(-10px);
                    }
                }
            }
        }

        i {
            display: inline-block;
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 40px;
            margin-right: 5px;
            background: #a4a4a4;
            flex: 0 0 20px;

            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: calc(50% - 1px);
                left: calc(50% - 6px);
                width: 12px;
                height: 2px;
                background: #fff;
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }
    }
}

.inventory_stock__item {
    padding-bottom: 4px;
}