/**
* Gemaire Distributing
*/

// ==============================================
// Shipping: Errors
// ==============================================

.pickup-date_error,
.delivery-date_error {
    position: absolute;
}

// ==============================================
// Shipping: Pick up
// ==============================================

.checkout-pickup_branch {
    margin-bottom: $space-base;
}

.checkout-pickup_branch address {
    font-weight: $font-weight-light;
}

.pickup-date {
    padding-top: 5px;
}

// ==============================================
// Form Shipping Address
// ==============================================

.opc-wrapper .edit-address-link {
    display: block;
    float: left;
    margin: 20px 10px 0 0;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .opc-wrapper .action-show-popup {
        width: 100%;
    }

    .opc-wrapper .edit-address-link {
        @include lib-icon-font(
                $icon-settings,
            $_icon-font-size         : 28px,
            $_icon-font-line-height  : 28px,
            $_icon-font-text-hide    : true,
            $_icon-font-color        : $c-library-gray-9,
            $_icon-font-color-hover  : $c-library-gray-9,
            $_icon-font-color-active : $c-library-gray-9
        );
        margin: 0;
        position: absolute;
        right: 0;
        top: 1px;
    }

}

// ==============================================
// Shipping Address Item
// ==============================================

.delivery-address_items {
    margin-bottom: $space-l;

    .delivery-address_item {
        display: none;
        padding: 0;
        border: 0;

        &:hover {
            background: transparent;
        }

        &.active {
            display: block;
        }

        .button-group {
            display: none;
        }
    }
}

.delivery-address_item {
    word-wrap: break-word;
    padding: 15px 10px;
    border-bottom: 1px solid $c-border;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    &:hover {
        background: lighten($c-blue-bright, 52%);
    }

    .input-radio {
        margin-right: 10px;

        &::before {
            top: 4px;
        }
    }

    .badge-group {
        margin-bottom: 0;
    }

    .button-group {
        display: flex;
        flex-direction: column;
    }

    .button {
        width: 140px;
    }
}

// ==============================================
// Checkout Shipping Method
// ==============================================

#checkout-shipping-method-load {

    h3 {
        flex-basis: 100%;
    }

    .field-item {
        padding-left: 25px;
        position: relative;

        [type='radio'] {
            position: absolute;
            top: 4px;
            left: 2px;
            margin: 0;

            & + label {
                padding-left: 0;
                margin-left: 0;

                &:before {
                    left: -25px;
                }
            }
        }

        b {
            font-weight: normal;
            color: $c-text-subtle;
        }
    }

}


// ==============================================
// Table Checkout Shipping Method
// ==============================================

.delivery-method_list thead th {
    display: none;
}

// ==============================================
// Checkout Charges Tooltip
// ==============================================
.charges-tooltip-wrap {
    position: relative;
    margin-left: 5px;
    display: inline-flex;
    vertical-align: top;

    .tooltip-icon {
        position: absolute;
    }

    .charges-tooltip-content {
        z-index: 200;
        background: #FFFFFF;
        padding: 16px;
        bottom: 0;
        margin-left: -148px;
        margin-bottom: 14px;
        color: #616161;
        width: 320px;

        &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-right: 13px solid transparent;
            border-left: 13px solid transparent;
            border-top: 13px solid #D0D0D0;
            border-bottom: none;
            bottom: -13px;
            left: 50%;
            margin-left: -13px;
        }

        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            border-top: 10px solid #FFFFFF;
            border-bottom: none;
            bottom: -10px;
            right: 6px;
            left: 50%;
            margin-left: -10px;
        }

        &.summary-charges {
            margin-left: -60px;

            &:before {
                bottom: -14px;
                left: 71px;
            }

            &:after {
                border-right: 11px solid transparent;
                border-left: 11px solid transparent;
                border-top: 11px solid #FFFFFF;
                border-bottom: none;
                bottom: -11px;
                left: 70px;
            }
        }

        .close-btn {
            position: absolute;
            right: 10px;
            top: 11px;
            width: 14px;
            height: 14px;
            opacity: 0.6;

            &:hover {
                opacity: 0.7;
            }

            &:before, &:after {
                position: absolute;
                left: 6px;
                content: ' ';
                height: 15px;
                width: 2px;
                background-color: #555;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }

        .title {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.4;
            text-transform: capitalize;
        }

        .charges-list {
            .charges-line {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                padding: 0 10px;
                font-weight: normal;
                height: 20px;

                &:first-child {
                    border-bottom: 1px solid #d0d0d0;
                    font-weight: bold;
                    padding-bottom: 7px;
                    margin-bottom: 5px;
                    height: auto;
                }

                &.illuminated {
                    background: #E3E3E3;
                }

                .charges-row {
                    display: flex;
                    flex-direction: column;
                    flex-basis: 100%;
                    flex: 1;
                    font-size: 14px;

                    &.row-right {
                        text-align: right;
                    }

                    &.green {
                        color: #068d4b;
                    }
                }
            }
        }
    }
}

#co-shipping-method-form {
    .field-item {
        .double-price {
            > .price {
                &:first-child {
                    text-decoration: line-through;
                    color: #CCC;
                }
            }
        }

        .price {
            .free-price {
                color: #068d4b;
            }
        }
    }
}

@media (max-width: 1279px) and (min-width: 960px) {
    .opc-sidebar {
        .charges-tooltip-wrap {
            .charges-tooltip-content.summary-charges {
                width: 290px;
                padding: 14px;
                margin-left: -94px;

                &:before {
                    left: 104px;
                }

                &:after {
                    left: 103px;
                }
            }
        }
    }
}
