/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/

// ==============================================
// Vars
// ==============================================

$bp-callout: 750px !default;

$callout-max-width: 500px !default;

// ==============================================
// Callout
// ==============================================

.callout {
    margin-bottom: $margin-bottom;
    padding: 20px;
    border: 1px solid $c-border;
    background: $c-background;

    @media (max-width: $bp-callout - 1px) {
        padding: 20px 15px;
    }
}

.callout > *:last-child {
    margin-bottom: 0;
}

// ----------------------------------------------

@media print {
    .callout {
        padding: (15px / 2);
    }
}

// ==============================================
// Modifier: Colors
// ==============================================

.callout--light {
    background: #fff;
}

.callout--contrast {
    background: $c-text-base;
    color: #fff;
}

.callout--primary {
    background: #e5f2fc;
    border-color: #006cb1;
}

.callout--alert {
    background: $c-yellow-light;
    border-color: #baa06f;
}

.callout--success {
    background: #e5f3ec;
    border-color: $c-green;
}

.callout--danger {
    background: #fceae5;
    border-color: $c-red-base;
}

// ==============================================
// Modifier: Shadow
// ==============================================

.callout--shadow {
    box-shadow: $box-shadow-base;
}

// ==============================================
// Modifier: Skeleton
// ==============================================

.callout--skeleton {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
}

// ==============================================
// Callout - Media
// ==============================================

.callout_media {
    display: flex;
    align-items: center;
    max-width: $callout-max-width;
}

// ----------------------------------------------

@media (max-width: $bp-callout - 1px) {
    .callout_media {
        margin-bottom: 20px;
    }
}

// ----------------------------------------------

@media (min-width: $bp-callout) {
    .callout_media {
        width: 50%;
    }
}

// ==============================================
// Callout - Body
// ==============================================

.callout_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: $callout-max-width;
}

// ----------------------------------------------

@media (min-width: $bp-callout) {
    .callout_body {
        width: 50%;
        padding-left: ($gutter * 2);
    }
}

// ==============================================
// Modifier: Flip
// ==============================================

@media (min-width: $bp-callout) {
    .callout--flip {
        flex-direction: row-reverse;
    }

    .callout--flip .callout_body {
        padding-right: ($gutter * 2);
        padding-left: 0;
    }
}
