/**
* Gemaire Distributing
*/


// ==============================================
// Action Icon
// ==============================================

.action-icon {
    display: inline-block;
    color: $c-text-base;
    line-height: 1.4;
    transition: color $transition-base;
    cursor: pointer;

    &:hover,
    &:active {
        color: $c-black
    }
}

.action-icon > i {
    padding: 0.8rem;
    margin: -0.8rem 0;
    line-height: 1.4;
}
