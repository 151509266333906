/**
* Gemaire Distributing
*/

.company-control {
    .loading-mask:before {
        width: 30px;
        height: 30px;
        top: calc(50% - 15px);
    }

    .control {
        display: block;
    }
}

.menu-top .company-control .control {
    margin-top: 0;
}

// ==============================================
// Account Selector
// ==============================================

@media (max-width: $bp-screen-m - 1px) {
    .menu-top .company-control {
        margin-top: 5px;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .company-control {
        max-width: 400px;
    }

    .menu-top .company-control {
        margin-top: 4px;
    }
}

// ==============================================
// Account Selector - Block
// ==============================================

@media (max-width: $bp-screen-m - 1px) {
    .company-control .block {
        margin-bottom: 0;
    }

    .menu-top > .company-control select {
        color: $c-text-contrast-base;
        border-bottom-color: $c-input-border-contrast;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23e9e9e9' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");

        &:focus {
            border-bottom-color: $c-input-border-contrast;
        }
    }
}
