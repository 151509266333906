/**
* Gravity Department - Frontend Starter
* https://github.com/gravitydepartment/frontend-starter
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Transition
// ==============================================

$transition-base: all 200ms linear 0ms !default;

$transition-all: all $transition-base !default;

$transition-background: background-color 0.2s ease-out;