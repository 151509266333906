.dropzone-active,
.dropzone-accept,
.dropzone-reject,
.dropzone-disabled {
  margin-bottom: $space-xxl;
  padding: $space-base;
  text-align: center;
  border-radius: $radius-base;

  i {
    font-size: 50px;
  }

  input {
    width: 100%;
  }
}

.dropzone-active {
  border: 2px dashed $c-border;
  color: $c-text-muted;
  cursor: pointer;

  i {
    color: $c-text-muted;
  }
}

.dropzone-accept {
  border: 2px solid $c-success;
  color: $c-success;
  i {
    color: $c-success;
  }
}

.dropzone-reject {
  border: 2px solid $c-danger;
  color: $c-danger;
  i {
    color: $c-danger;
  }
}

.dropzone-disabled {
  border: 2px dashed $c-text-muted-light;
  background: $c-library-gray-0;
  color: $c-text-muted-light;
  i {
    color: $c-text-muted-light;
  }
}

