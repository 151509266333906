/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Hero
// ==============================================

.hero {
    position: relative;
    margin-bottom: 50px;
}

// ----------------------------------------------
// Hero Link
// Covers the entire promo.

.hero-link {
    display: block;
}

// ----------------------------------------------
// Image

//.hero-image img {}

// ----------------------------------------------
// Content

.hero-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hero-content {
    width: auto;
}

.hero-content--width-33 { width: 33.333%; }
.hero-content--width-40 { width: 40%; }
.hero-content--width-50 { width: 50%; }
.hero-content--width-60 { width: 60%; }
.hero-content--width-66 { width: 66.666%; }
.hero-content--width-70 { width: 70%; }
.hero-content--width-80 { width: 80%; }
.hero-content--width-90 { width: 90%; }

// ----------------------------------------------
// Title

.hero-title {
    margin-bottom: 0;
    color: $c-text-base;
    font-family: $font-family-special;
    font-weight: bold;
    line-height: 1.1;
}

.hero-title-pre,
.hero-title-post {
    font-size: 16px;
    font-weight:normal;
}

.hero-title-pre {
    margin-bottom: 5px;
}

.hero-title-post {
    margin-top: 5px;
}

// ---------------------------------------------

@media (min-width: 600px) {

    .hero-title {
        font-size: 42px;
    }

    .hero-title-pre,
    .hero-title-post {
        font-size: 20px;
        font-weight: 300;
    }

}

// ---------------------------------------------

@media (min-width: 1024px) {

    .hero-title {
        font-size: 64px;
    }

    .hero-title-pre,
    .hero-title-post {
        font-size: 32px;
    }

    .hero-title-pre {
        margin-bottom: 10px;
    }

    .hero-title-post {
        margin-top: 10px;
    }

}


// ==============================================
// Modifiers
// ==============================================

// ----------------------------------------------
// Contrast - Dark

.hero--contrast-dark .hero-title,
.hero--contrast-dark .hero-title-pre,
.hero--contrast-dark .hero-title-post {
    color: $c-text-base;
    text-shadow: 0 2px 2px white(0.25);
}

// ----------------------------------------------
// Contrast - Light

.hero--contrast-light .hero-title,
.hero--contrast-light .hero-title-pre,
.hero--contrast-light .hero-title-post {
    color: #FFF;
    text-shadow: 0 2px 2px black(0.25);
}

// ----------------------------------------------
// CTA (Call To Action)

.hero--cta .hero-title {
    margin-bottom: 0.6em;
}

// ----------------------------------------------
// Downshift

@media (max-width: 599px) {

    .hero--downshift {
        margin-bottom: (50px - 20px); // Reduce margin for extra padding on ".hero-content-wrapper"
    }

    .hero--downshift .hero-content-wrapper {
        position:static;
        width: 100%;
        padding: 20px 15px;
        transform:translate(0, 0);
    }

    // Downshift + Light
    .hero--downshift.hero--contrast-light .hero-content-wrapper {
        background: #808088; // Tint +8 blue channel
    }

    // Downshift + Offset
    .hero--downshift.hero--offset-dark .hero-content,
    .hero--downshift.hero--offset-light .hero-content,
    .hero--downshift.hero--offset-blue .hero-content {
        width: 100%;
        padding: 0;
        border-radius: 0;
        background: none;
    }

}

// ----------------------------------------------
// No Margin

.hero--no-margin {
    margin: 0;
}

// ----------------------------------------------
// Offset

.hero--offset-dark .hero-content {
    padding: 20px;
    border-radius: 5px;
    background: black(0.6);
}

.hero--offset-light .hero-content {
    padding: 20px;
    border-radius: 5px;
    background: white(0.6);
}

.hero--offset-action .hero-content {
    padding: 20px;
    border-radius: 5px;
    background:rgba($c-action-default-base, 0.6);
}

// ----------------------------------------------
// Position - Center

.hero--center .hero-content-wrapper {
    text-align:center;
}

// ----------------------------------------------
// Position - Left

.hero--left .hero-content-wrapper {
    justify-content: flex-start;
    text-align: left;
}

.hero--left .hero-content {
    margin-left: 5%;
}

// ----------------------------------------------
// Position - Right

.hero--right .hero-content-wrapper {
    justify-content: flex-end;
    text-align: right;
}

.hero--right .hero-content {
    margin-right: 5%;
}
