/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2018 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Inventory Modal - Branch List
// ==============================================

.inventory-modal_branch-list {
    margin-bottom: $margin-bottom;
    border: 1px solid $c-border;
    border-radius: 5px;
    background: $c-background;
}

.inventory-modal_type:last-child .inventory-modal_branch-list {
    margin-bottom: 0;
}


// ==============================================
// Inventory Modal - Branch List Item
// ==============================================

.inventory-modal_branch-list li {
    position: relative;
    padding: 10px 10px 10px (10px + 44px + 10px);
    border-bottom: 1px solid $c-border;

    &:last-child {
        border-bottom: 0;
    }
}


// ==============================================
// Inventory Modal - Misc.
// ==============================================

.inventory-modal_branch-list .branch-name {
    margin-bottom: 0;
    font-size: $font-size-base;
}

.inventory-modal_branch-list .branch-address {
    font-size: $font-size-s;
}

.inventory-modal_branch-list .branch-count {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 44px;
    border-radius: $radius-s;
    background: $c-success;
    color: #FFF;
    font-size: $font-size-xs;
    line-height: 26px;
    text-align: center;
}

.inventory-modal_branch-list .branch-count.out-of-stock {
    background: $c-danger;
}
