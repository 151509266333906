/**
* Acme Refrigeration
*/

// ==============================================
// Page Header
// ==============================================

.page-header {
    position: relative;
    z-index: 12;
    min-height: 118px;

    @media (max-width: $bp-header-alpha - 1px) {
        background: $c-header-primary-background;
    }
}

// ==============================================
// Header Bars - Primary and Secondary
// ==============================================

.header-primary {
    @media (min-width: $bp-header-alpha) {
        width: 100%;
        height: $header-primary-large-height;
        background: $c-header-primary-background;
    }
}

.header-secondary {
    @media (min-width: $bp-header-alpha) {
        display: block;
        width: 100%;
        height: $header-secondary-height;
        border-bottom: 1px solid $c-border;
        background: $c-header-primary-background;
    }
}

.form.minisearch {
    max-width: none;
    margin-bottom: 0;
}

.quote-dropdown-content,
.quicklist-dropdown-content {
    padding: 16px;
    @include menu;
    top: 23px;
    color: $c-text-base;

    form + ul.header-quicklists {
        border-top: 1px solid $c-divider-border;
    }

    .dropdown-footer {
        border-color: $c-border;
        margin-top: 0;
        padding: 16px;
        background: $c-background;
    }

    &:before {
        @include triangle($direction: up, $size: 8px, $color: $c-border);
        top: -8px;
        right: 2px;
    }

    &:after {
        @include triangle($direction: up, $size: 6px, $color: $c-white);
        top: -6px;
        right: 4px;
    }
}

.header-quotes_item {
    margin-bottom: 8px;
}

.header-quicklists {
    margin-left: -16px;
    margin-right: -16px;

    .header-quicklists_item {
        border-bottom: 1px solid $c-divider-border;
        padding: 10px 16px;
        display: flex;
        justify-content: space-between;

        a {
            color: $c-text-base;
        }
    }
}

@media (min-width: $bp-screen-m) {
    .header-links-signin > a,
    .account-link > a,
    .header-links-quotes > a,
    .header-links-quicklists > a {
        color: $c-text-base;

        &:hover,
        &:focus,
        &:active {
            color: $c-blue-bright;
        }
    }
}

@media (max-width: $bp-header-alpha - 1px) {
    .header.sign-in {
        display: none;
    }
}

// ----------------------------------------------

@media (max-width: $bp-header-alpha - 1px) {
    .customer-navigation .header.links {
        display: none;
    }

    .header-links-quotes .dropdown-trigger:after,
    .header-links-quicklists .dropdown-trigger:after {
        content: '';
    }

    .header-links-quotes.dropdown,
    .header-links-quicklists.dropdown {
        display: block;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .page-header .switcher {
        display: inline-block;
    }
}

// ==============================================
// Header Content
// ==============================================

.header.content {
    position: relative;
    /*max-width: 1298px;*/
    margin: 0 auto;

    @media (min-width: $bp-screen-m) {
        height: 101px;
    }
}

// ==============================================
// Header Panel
// ==============================================

@media (min-width: $bp-screen-m) {
    .customer-navigation {
        display: flex;
        flex-wrap: nowrap;
        margin-right: 5px;
    }

    .customer-navigation > .header.links,
    .customer-navigation > .header.sign-in {
        margin: 0;
        padding: 0;
        list-style: none;
        display: inline-flex;
        align-items: flex-end;
    }

    .customer-navigation > .header.links {
        order: 20;
    }

    .customer-navigation > .header.sign-in {
        order: 10;
    }

    .customer-navigation > .header.sign-in > li {
        margin: 10px 15px;
    }

    .customer-navigation > .header.links > li:not(.header-links-gempay) {
        margin: 10px 15px;
    }

    .customer-navigation > .header.links > li.welcome,
    .customer-navigation > .header.links > li > a,
    .customer-navigation > .header.sign-in > li a {
        line-height: 1.4;
        text-decoration: none;
        color: $c-text-contrast-base;

        &:hover {
            text-decoration: none;
        }
    }
}

// ==============================================
// Header Links - Gem Pay
// ==============================================

.header-links-gempay {
    margin-left: 25px;
    position: relative;
}

.header-links-gempay svg {
    width: 74px;
    margin-bottom: 0px;
}

.header-links-gempay_mobile svg {
    height: 20px;
    margin-bottom: -6px;
}

.header-gempay_flag {
    left: 50%;
    transform: translateX(-50%);
    bottom: -12px;
}

.header-gempay-mobile_flag {
    margin-left: 8px;
}

// ==============================================
// Header Links - Quick Lists
// ==============================================

@media (min-width: $bp-screen-m) {
    .header-links_quicklists {
        @include lib-icon-font(
            $_icon-font-content: $icon-quicklists,
            $_icon-font-color: $header-icons-color,
            $_icon-font-size: 20px
        );

        &::before {
            margin-top: -5px;
        }
    }
}

// ==============================================
// Header Links - Quotes
// ==============================================

@media (min-width: $bp-screen-m) {
    .header-links_quotes {
        @include lib-icon-font(
            $_icon-font-content: $icon-quotes,
            $_icon-font-color: $header-icons-color,
            $_icon-font-size: 20px
        );

        &::before {
            margin-top: -5px;
        }
    }
}

// ==============================================
// Header Placeholders
// ==============================================

.page-header {
    .content-placeholder {
        &.logo-placeholder {
            width: 201px;
            height: 50px;
            margin-top: 3px;

            @media (max-width: $bp-screen-m) {
                height: 40px;
                margin: 13px 0 13px -28px;
            }
        }

        &.search-placeholder,
        &.store-placeholder {
            width: 100%;
            height: 50px;
        }

        &.minicart-placeholder {
            width: 106px;
            min-width: 106px;
            height: 50px;

            @media (max-width: $bp-screen-m) {
                height: 40px;
                margin: 13px 57px 0 -50px;
            }
        }
    }

    .nav-placeholders {
        display: flex;
        height: 46px;
        align-items: center;
        margin: 0 20px;

        .nav-placeholder {
            height: 18px;
            margin-right: 20px;

            &:nth-child(1) {
                width: 160px;
            }

            &:nth-child(2) {
                width: 90px;
            }

            &:nth-child(3) {
                width: 100px;
            }

            &:nth-child(4) {
                width: 90px;
            }

            &:nth-child(5) {
                width: 110px;
            }
        }
    }

    .acc-placeholders {
        display: flex;
        height: 46px;
        align-items: center;
        margin: 0 15px;

        .acc-placeholder {
            height: 18px;
            margin-left: 20px;

            &:nth-child(1) {
                width: 160px;
            }

            &:nth-child(2) {
                width: 130px;
            }
        }

        @media (max-width: $bp-screen-m) {
            display: none;
        }
    }
}
