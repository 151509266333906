/**
* Gemaire Frontend
*/

.gem-rewards-widget_heading {
    padding: 15px 20px;
    line-height: 1;
    text-align: center;

    img {
        max-width: 200px;
    }
}

.gem-rewards-widget_intro {
    padding: 5.2%;
    text-align: center;
}

.gem-rewards-points {
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    align-items: center;
}

// ==============================================
// Rewards Widget - CTA
// ==============================================

.gem-rewards-widget_cta {
    position: relative;
    padding: 1.5em 0;
    background: $c-blue-base;
    display: flex;
    justify-content: center;

    // Plus sign
    &::before {
        content: '+';
        position: absolute;
        top: 2px;
        left: 2px;
        z-index: 0;
        margin: 0;
        color: white(0.2);
        font-size: 100px;
        font-weight: bold;
        line-height: 0.6;
    }

 
}


// ==============================================
// Rewards Widget - Outro
// ==============================================

.gem-rewards-widget_outro {
    padding: 0.6em 0;
    display: flex;
    justify-content: center;
}

.gem-rewards-widget_outro a {
    padding: 0.5em;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
}


// ==============================================
// Rewards Widget - Please Wait
// ==============================================

.gem-rewards-widget .please-wait {
    padding-top: 0.6em;
    padding-bottom: 0.6em;
}

// ==============================================
// Rewards Widget - Form
// ==============================================

.gem-rewards-form-wrapper{
display: flex;
}

.gem-rewards-not-eligible {
    display: flex;
    justify-content: center;
}

.gem-rewards-text{
    display: flex;
    flex-direction: column;
    line-height: 1.3;
}

.gem-rewards-label{
    top: 0 !important;
    left: 6% !important;
}

@media (min-width: $bp-screen-m) {

    .gem-rewards-form-wrapper > .col-1 {
        order: 2;
        width: 40%;
        padding-left: $space-xl;
    }

    .gem-rewards-form-wrapper > .col-2 {
        order: 1;
        width: 60%;
        padding-right: $space-xl;
    }

}