.pricebooks-react-app {
    padding-bottom: 60px;

    .pricebook-account-switcher {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;

        label {
            padding-right: 10px;
        }

        select {
            width: auto;
        }

        @media (max-width: $bp-screen-m - 1px) {
            margin-bottom: 30px;
        }

        @media (max-width: $bp-screen-s - 1px) {
            select {
                width: 100%;
            }
        }
    }

    .pricebook-download {
        @media (max-width: $bp-screen-s - 1px) {
            thead {
                display: none;
            }

            .download-cell {
                margin-top: 10px;
            }

            .table--linearize tbody tr {
                position: relative;
                padding: 15px 35px 15px 10px;
            }

            .button--remove {
                position: absolute;
                right: 10px;
                top: 10px;
            }
        }
    }

    .table {
        .menu_toggle {
            color: $c-link-active;
        }

        .button--link {
            text-align: left;
        }

        .menu_content {
            white-space: nowrap;
            left: auto;
            right: 0;
            top: 25px;

            @media (max-width: $bp-screen-s - 1px) {
                left: 0;
                right: auto;
            }

            button {
                width: 100%;
                text-align: left;
                color: $c-link-active;
            }

            li + li {
                button {
                    width: 100%;
                    border-top: 1px solid #d0d0d0;
                    text-align: left;
                }
            }
        }

        .pricebook-processing {
            position: relative;
            padding-left: 30px;

            .loading-mask::before {
                top: calc(50% - 10px);
                filter: grayscale(100%);
                opacity: 0.5;
                width: 20px;
                height: 20px;
                left: 0;
                background-repeat: no-repeat;
            }
        }

        .button--remove {
            font-size: 0px;
            appearance: none;
            margin-left: auto;
            width: 30px;
            height: 30px;
            @include svg(delete);
            background-position: center;
            border: none;
            text-indent: -999999px;
        }
    }

    .grouping-options-list {
        list-style: decimal;
        display: inline-flex;
        flex-wrap: wrap;

        li {
            margin-left: 12px;

            + li {
                margin-left: 20px;
            }
        }
    }

    .future-price-indicator {
        display: block;

        button {
            display: flex;
            flex-wrap: wrap;
            align-content: baseline;

            &:hover,
            &:active {
                background: transparent;
            }
        }

        i {
            @include lib-icon-font(
                $_icon-font-content: $icon-price-update,
                $_icon-font-size: 22px,
                $_icon-font-line-height: 20px,
                $_icon-font-vertical-align: middle,
                $_icon-font-color: inherit,
                $_icon-font-color-hover: inherit,
                $_icon-font-color-active: inherit
            );
            display: inline-flex;
            font-style: normal;
            margin-right: 5px;
        }
    }
}
