/**
* Gemaire Distributing
*/

// ==============================================
// Page Main
// ==============================================

.account .page-main {
    padding: 0;
}

@media (max-width: $bp-screen-l - 1px) {

    .account .page-main {
        position: relative;
        overflow: visible;
    }

}


// ==============================================
// Columns
// ==============================================

.account .column.main {
    background: $c-background;
    padding: 0 20px;
    flex: 1;
}

.account .sidebar-main {
    border-right: 1px solid $c-border;
    padding: 0 10px;
    background: $c-white;
    transition: all ease 0.4s;
    opacity: 1;
    overflow: hidden;
    width: $account-nav-width;

    &.closed {
        padding: 0;
        width: 0;
        height: 0;
        opacity: 0;
        transform: translate3d(-$account-nav-width, 0, 0);
    }
}

.account.sales-order-history,
.account.quotes-index-index,
.account.orders-index-index,
.account.warranty-claims-index,
.account.equote-index-index,
.account.contractorders-index-index,
.invoice-index-index,
.ahriTable {

    .columns .sidebar-additional {
        display: block;
        border-left: 1px solid $c-border;
        padding: 0 10px;
        background: $c-white;
        transition: all ease 0.4s;
        opacity: 1;
        width: $account-nav-width;

        &.closed {
            width: 0;
            padding: 0;
            height: 0;
            opacity: 0;
            transform: translate3d($account-nav-width, 0, 0);
        }

        h4 {
            font-size: 20px;
            font-weight: normal;
            text-transform: none;
            padding: 24px 0 0;
            line-height: 1.5;
            margin-bottom: 15px;

            .close_filters {
                float: right;
                line-height: 42px;
                cursor: pointer;
                display: none;
            }
        }
    }
}

// ----------------------------------------------

@media (max-width: $bp-screen-xl - 1px) {

    #product-details_content .ahriTable,
    .account .columns {
        .sidebar-additional {
            position: fixed;
            right: -$account-nav-width;
            top: 0;
            z-index: 1000;
            height: 100vh;
            overflow: scroll;

            h4 .close_filters {
                display: block !important;
            }
        }
    }
}

@media (max-width: $bp-screen-l - 1px) {

    .account .columns {
        display: block;

        .column.main {
            background: $c-white;
        }

        .content-wrapper {
            background: $c-white;
        }

        .sidebar-main {
            position: fixed;
            left: -$account-nav-width;
            top: 0;
            z-index: 100;
        }
    }

}

@media (min-width: $bp-screen-l) {

    .account .columns {
        overflow: hidden;
    }

    .account.equote-index-index,
    .account.contractorders-index-index,
    .account.quotes-index-index,
    .account.orders-index-index,
    .account.warranty-claims-index,
    .invoice-index-index {
        .column.main {
            padding-right: 0;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .account {
        .page-title-wrapper {
            margin-top: 20px;
        }
    }
}

// ==============================================
// Dashboard Button
// ==============================================

#dashboard_btn {
    background: $c-white;
    color: $c-blue-bright;
    position: relative;
    margin-left: -21px;
    margin-right: 5px;
    border-color: $c-border;
    border-left-color: $c-white;
    padding: 24px 6px 12px;

    span {
        display: none;
    }

    i {
        padding-top: 7px;
        vertical-align: top;

        &.mobile_toggle {
            display: inline-block;
            font-size: 28px;
            position: absolute;
            top: -4px;
            left: -9px;
        }
    }

    @media (min-width: $bp-screen-l) {
        &.opened {
            padding: 0 10px;
            margin-left: 0;
            border-color: $c-border;

            span {
                display: inline;
            }

            i.mobile_toggle {
                display: none;
            }
        }
    }
}

@media (max-width: $bp-screen-l - 1px) {
    #dashboard_btn {
        padding: 0 10px;
        margin-left: 0;
        border-color: $c-border;

        &.opened:after {
            content: "";
            position: fixed;
            background: rgba(0, 0, 0, 0.25);
            top: 0;
            width: 100%;
            height: 100%;
            left: 0;
            z-index: 100;
        }

        span {
            display: inline;
        }

        i.mobile_toggle {
            display: none;
        }
    }
}

@media (max-width: $bp-screen-s - 1px) {
    #dashboard_btn {
        width: calc(50% - 5px);
        margin: 0;
    }
}

// ==============================================
// Filters Button
// ==============================================

#filters_btn {
    background: $c-white;
    color: $c-blue-bright;
    position: relative;
    margin-right: -21px;
    border-color: $c-border;
    border-right-color: $c-white;
    padding: 24px 6px 12px;
    position: absolute;
    right: 0;
    top: 20px;

    span {
        display: none;
    }

    i {
        padding-top: 7px;
        vertical-align: top;

        &.mobile_toggle {
            display: inline-block;
            font-size: 28px;
            position: absolute;
            top: -4px;
            right: -9px;
        }
    }

    @media (min-width: $bp-screen-xl) {
        &.opened {
            padding: 0 10px;
            margin-right: 0;
            border-color: $c-border;

            span {
                display: inline;
            }

            i.mobile_toggle {
                display: none;
            }
        }
    }
}

@media (max-width: $bp-screen-xl - 1px) {
    #filters_btn {
        padding: 0 10px;
        margin-right: 0;
        border-color: $c-border;

        &.opened:after {
            content: "";
            position: fixed;
            background: rgba(0, 0, 0, 0.25);
            top: 0;
            width: 100%;
            height: 100%;
            right: 0;
            z-index: 100;
        }

        span {
            display: inline;
        }

        i.mobile_toggle {
            display: none;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    #filters_btn {
        top: 0;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    #filters_btn {
        width: calc(50% - 5px);
        margin: 0 0 0 auto;
        position: static;
    }
}

// ==============================================
// View Details icon
// ==============================================

.view-details-icon {
    &.material-icons {
        cursor: pointer;
        color: $c-gray-slate;
        vertical-align: -3px;

        &:hover {
            color: $c-blue-base;
        }

        .tooltip--icon.blue & {
            color: $c-blue-base;

            &:hover {
                color: lighten($c-blue-base, 10%);
            }
        }
    }
}

// ==============================================
// React Account Container
// ==============================================

@media (min-width: $bp-screen-m) {
    .react-account-container {
        display: flex;

        .react-account-content {
            flex: 1;
            padding-right: 20px;
        }
    }
}

// ==============================================
// Warranty Status
// ==============================================

.status {
    &.green {
        color: $c-green;
    }

    &.red {
        color: $c-red-base;
    }
}

// ==============================================
// Warranty Claims Table
// ==============================================

.claims-table {
    td:nth-child(4) {
        word-break: break-all;
    }
}

// ==============================================
// Claims View
// ==============================================

.claim-view {
    .message {
        ol {
            list-style: decimal;
            padding-left: 15px;
        }
    }
}

.claim-block table td:first-child {
    font-weight: 700;
    background: $c-background;
    width: 50%;
}

.claim-messages-steps {
    p {
        margin-bottom: 10px;
    }
}

.claim-comment {
    overflow: hidden;
    position: relative;
    padding-right: 20px;
    cursor: pointer;
    display: block;
    text-decoration: none;
    max-height: 22px;
    transition: all ease 0.4s;

    &:after {
        position: absolute;
        top: 0;
        right: 0;
    }

    @include lib-icon-font($_icon-font-content: $icon-down,
        $_icon-font-size: 20px,
        $_icon-font-position: after,
        $_icon-font-display: block);

    &.expanded {
        max-height: 300px;

        &:before {
            content: "";
        }

        @include lib-icon-font($_icon-font-content: $icon-up,
            $_icon-font-size: 22px,
            $_icon-font-position: after,
            $_icon-font-display: block);
    }
}

@media (min-width: $bp-screen-m) {
    .claim-blocks {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .claim-block {
            flex: 0 0 48%;
        }
    }
}

@media (min-width: $bp-screen-l) {
    .claim-blocks {
        .claim-block {
            flex: 0 0 32%;
        }
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .claim-view {
        .flex-inline {
            display: block;
        }
    }
}

@media (min-width: $bp-screen-m) and (max-width: $bp-screen-l - 1px) {
    .claims-table {

        td:nth-child(3),
        td:nth-child(8) {
            display: none;
        }
    }
}

@media print {

    .print-popup {
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;

        @page {
            size: landscape;
        }

        .panel_body {
            padding: 20px;
            border: 1px solid $c-border;
        }

        .claim-blocks {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .claim-block {
                width: 32%;
            }
        }
    }
}

// ==============================================
// Addresses
// ==============================================

.addresses {
    .name_address_template {
        font-weight: normal;
        font-size: 18px;
        padding-bottom: 10px;
        display: inline-block;
    }

    .delivery-address_item {
        border-bottom: 0;
        padding: 0;
    }
}

@media (min-width: $bp-screen-s) {
    .addresses {
        .cards_item {
            padding-right: 80px;

            .badges {
                position: absolute;
                right: 15px;
                top: 23px;
            }
        }
    }
}

.form-address-edit {
    .field-row .field {
        flex-basis: 50%;
    }
}

// ==============================================
// Checkout Preferences
// ==============================================

.checkout-preferences {
    .subselection {
        display: none;

        .cards_item .payment-icon {
            right: 20px;
            top: 20px;
        }
    }

    input:checked+label+.subselection {
        display: block;
    }
}

// ==============================================
// Linked Accounts Table
// ==============================================

.linked-accounts-table {

    th:nth-child(4),
    th:nth-child(5) {
        text-align: right;
    }
}

@media (max-width: $bp-table-linearize - 1px) {
    .linked-accounts-table {
        tbody {
            td {
                padding-left: 120px;

                &:before {
                    content: attr(data-th);
                    display: inline-block !important;
                    position: absolute;
                    left: 0;
                    font-weight: 300;
                }
            }
        }

    }
}

// ==============================================
// Company Profile
// ==============================================

.account-profile-grid {

    .accordion_body {

        .field {
            max-width: 448px;
        }

        form {
            max-width: none;
        }
    }
}

.account-profile-label {
    font-weight: 600;
}

@media (min-width: $bp-screen-s) {

    .organization_tab,
    .operations_tab,
    .history_tab {
        .field {
            width: 50%;
        }
    }
}

// ==============================================
// User session notification
// ==============================================

.lac-notification-sticky {
    display: none !important;
}