/**
* Gemaire Distributing
*/


// ==============================================
// Product Image Photo
// ==============================================

.product-image-photo {
    display: block;
    max-width: 100%;
    height: auto;
    margin: auto;
}
