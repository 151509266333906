//
// Variables changed for Gemaire Theme
//____________________________________________________________________________________


$button-icon__font                          : $font-family-icon;
$button__border-radius                      : $radius-base;
$button__font-weight                        : $font-weight-normal;
$button__background                         : transparent;
$button__border                             : 0;
//
//  Buttons
//  ---------------------------------------------

//  Display settings
$button__display                              : inline-block !default;
$button__cursor                               : pointer !default;



//  Gradient button
$button__gradient                             : false !default; // [true|false] - button has a gradient background
$button__gradient-direction                   : false !default; // button gradient direction if button has a gradient background

//  Button with icon
$button-icon__use                             : false !default;
$button-icon__content                         : $icon-settings !default;
$button-icon__font                            : $icon-font !default;
$button-icon__font-size                       : 22px !default;
$button-icon__line-height                     : $button-icon__font-size !default;
$button-icon__color                           : inherit !default;
$button-icon__margin                          : 0 !default;
$button-icon__vertical-align                  : top !default;
$button-icon__position                        : $icon__position !default;
$button-icon__text-hide                       : false !default;

$button-icon__hover__font-color               : inherit !default;
$button-icon__active__font-color              : inherit !default;
