/**
* Gemaire Distributing
*/


// ==============================================
// Select
// ==============================================

select {
    width: 100%;
    height: 40px;
    padding: 8px 25px 8px 5px;
    border: none;
    border-bottom: 1px solid $c-border;
    border-radius: 0;
    background: $c-white;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23666464' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
    background-origin: content-box;
    background-position: right -25px center;
    background-repeat: no-repeat;
    background-clip: padding-box;
    outline: 0;
    appearance: none;
    color: $c-text-base;
    vertical-align: baseline;
    transition: border ease-in-out 180ms;

  
    &:disabled {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23bebcbc' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
    }

    &::-ms-expand {
        display: none;
    }
}

select option {
    color: $c-text-base;
}


// ==============================================
// Select ~ Label
// ==============================================
.control select ~ label {
    font-weight: $font-weight-semibold;
}

select ~ label {
    top: -12px;
    font-size: 13px;
}

// ==============================================
// Modifier: Size
// ==============================================

.select--s {
    max-width: $form-elem-width-s;
}

.select--xs {
    max-width: $form-elem-width-xs;
}

.select--xxs {
    max-width: $form-elem-width-xxs;
}
