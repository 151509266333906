/**
* Gemaire Distributing
*/


input.qty {
    text-align: center;
    background: $c-background;
    border: 1px solid $c-divider-border;
    height: 50px;
    max-width: 88px;

    &:focus {
        border-color: $c-divider-border;
    }
}
