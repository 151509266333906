/**
* Gemaire Distributing
*/


// ==============================================
// Check Agreements Block
// ==============================================

.checkout-agreements-block .checkout-agreements {
    margin-bottom: $space-l;
}

.checkout-agreements-block .action-show {
    vertical-align: baseline;
}


// ==============================================
// Checkout Agreements Items
// ==============================================

.checkout-agreements-items {
    padding-bottom: $space-xxl;
}

.checkout-agreements-item {
    margin-bottom: $space-l;
}

//.checkout-agreements-item-title {}

.checkout-agreement-item-content {
    overflow: auto;
}
