/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Section
// ==============================================

.section {
    padding-top: $gap;
    padding-bottom: $gap;
}

// ----------------------------------------------

@media (min-width: 900px) {

    .section {
        padding-top: ($gap * 2);
        padding-bottom: ($gap * 2);
    }

}


// ==============================================
// Modifier: Padding X
// ==============================================

.section--p-x {
    padding-right: $liner-base;
    padding-left: $liner-base;
}

// ----------------------------------------------

@media (min-width: 900px) {

    .section--p-x {
        padding-right: $liner-l;
        padding-left: $liner-l;
    }

}


// ==============================================
// Modifier: Margin Bottom
// ==============================================

.section--m-b {
    margin-bottom: $gap;
}

// ----------------------------------------------

@media (min-width: 900px) {

    .section--m-b {
        margin-bottom: ($gap * 2);
    }

}


// ==============================================
// Modifier: Margin Top
// ==============================================

.section--m-t {
    margin-top: $gap;
}

// ----------------------------------------------

@media (min-width: 900px) {

    .section--m-t {
        margin-top: ($gap * 2);
    }

}


// ==============================================
// Section - Misc.
// ==============================================

.section > *:last-child {
    margin-bottom: 0;
}
