/**
* Gemaire Frontend
*
* @author    Brendan Falkowski (http://gravitydept.com)
* @package   enterprise_gemaire
* @copyright Copyright 2013 Gravity Department
* @license   All rights reserved.
*/


// ==============================================
// Default Typography
// ==============================================

body,
button,
input,
table,
textarea {
    font-family: $font-family-base;
    color: $c-text-base;
    font-size: $font-size-base;
    line-height: $line-height;
}


// ==============================================
// Anchor
// ==============================================

a {
    color: $c-link-base;
    text-decoration: none;

    &:hover {
        color: $c-link-hover;
        text-decoration: none;
    }

    &:focus,
    &:active {
        outline: 0;
        color: $c-link-active;
    }
}


// ==============================================
// Blockquote
// ==============================================

blockquote {
    position: relative;
    margin-left: 1em;
    margin-bottom: $gap;
    padding-left: (5px + 20px);
    font-family: $font-family-special;
    font-size: 20px;
    font-weight: 300;
    font-style: italic;
    line-height: $line-height-xs;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 100%;
        border-radius: 5px;
        background: $c-border;
    }
}

blockquote p {
    margin-bottom: 1em;

    &:last-child {
        margin-bottom: 0;
    }
}

blockquote footer {
    color: $c-text-subtle;
    font-size: $font-size-base;
    font-style: italic;

    &::before {
        content: '– ';
    }
}


// ==============================================
// Headings
// ==============================================

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: inherit;
    font-weight: normal;
    font-style: normal;
}

h1, .h1 { @include h1; }
h2, .h2 { @include h2; }
h3, .h3 { @include h3; }
h4, .h4 { @include h4; }
h5, .h5 { @include h5; }
h6, .h6 { @include h6; }

// ----------------------------------------------

@media print {

    /* stylelint-disable declaration-no-important */
    h1, .h1 { font-size: 24px !important; }
    h2, .h2 { font-size: 18px !important; }
    h3, .h3 { font-size: 14px !important; }
    h4, .h4 { font-size: 12px !important; }
    /* stylelint-enable */

}


// ==============================================
// Ordered + Unordered List
// ==============================================

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}


// ==============================================
// Paragraph
// ==============================================

p {
    margin: 0 0 $margin-bottom;
}
