/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$bp-page-liner:  900px !default;


// ==============================================
// Page Liner
// ==============================================

.page-liner {
    padding-right: $liner-base;
    padding-left:  $liner-base;
}

// ----------------------------------------------

@media (min-width: $bp-page-liner) {

    .page-liner {
        padding-right: $liner-l;
        padding-left:  $liner-l;
    }

}

// ----------------------------------------------

@media print {

    .page-liner {
        padding: 0;
    }

}
