/**
* Gemaire Frontend
*
* @package    enterprise_gemaire
* @copyright  Copyright 2019 Gemaire Distributors
* @license    All rights reserved.
*/


// ==============================================
// Background
// ==============================================

.background-base     { background: $c-background          !important; }
.background-priority { background: $c-priority-background !important; }
.background-white    { background: $c-white               !important; }
.background-blue     { background: $c-blue-base           !important; }
