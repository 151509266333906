/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2015 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// File List
// ==============================================

.file-list {
    margin-bottom: $margin-bottom;
}

.file-list_item {
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }
}

.file-list_link {
    display: block;
    position: relative;
    padding-left: (16px + 10px);
    font-size: $font-size-s;
    line-height: 20px; // Magic number: height of icon
}

.file-list .file-icon {
    position: absolute;
    top: 0;
    left: 0;
}
