/**
* Carrier Enterprise
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    ce_default
* @copyright  Copyright 2017 Gravity Department
* @license    All rights reserved.
*/


/* stylelint-disable selector-no-qualifying-type */


// ==============================================
// Category Tree
// ==============================================

//.category-tree {}


// ==============================================
// Category Tree - Level 0
// ==============================================

.category-tree li.level0 {
    padding: 20px 0 10px;
    border-top: 1px solid $c-border;
}

.category-tree a.level0 {
    display: inline-block;
    margin-bottom: 1em;
    font-size: $font-size-xxl;
    font-weight: bold;
}


// ==============================================
// Category Tree - Level 1
// ==============================================

.category-tree ul.level0 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.category-tree li.level1 {
    flex: 1 0 250px;
    margin: 0 10px 20px;
    padding: 15px;
    border: 1px solid $c-border;
    border-radius: $radius-base;
    background: $c-background;
}

.category-tree a.level1 {
    display: inline-block;
    font-weight: bold;
    line-height: $line-height-xs;
}


// ==============================================
// Category Tree - Level 2
// ==============================================

.category-tree ul.level1 {
    margin-top: 10px;
    list-style: disc outside;
}

.category-tree li.level2 {
    margin-bottom: 5px;
    margin-left: 1.5em;
    font-size: $font-size-s;
    line-height: $line-height-xs;
}
