/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$c-footer-background: $c-background !default; // Blue channel tinted +8
$c-footer-border:     $c-border !default;


// ==============================================
// Site Footer
// ==============================================

.site-footer {
    position: relative;
    border-top: 1px solid $c-footer-border;
    background: $c-footer-background;
}

.site-footer .container {
    padding: 0 $trim;
}


// ==============================================
// Footer Primary
// ==============================================

.footer-primary {
    padding-bottom: 20px;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .footer-primary .container {
        padding: 0;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-s) {

    .footer-primary {
        padding-top: 40px;
    }

}

// ==============================================
// Footer Nav Lists
// ==============================================

.footer-nav-title {
    @include text;
    font-weight: bold;
    text-transform: uppercase;
}

.footer-nav-list a {
    padding: 0 0 0.6em;
    color: $c-text-base;
    line-height: $line-height-xxxs;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .footer-nav-lists > li {
        border-bottom: 1px solid $c-footer-border;
    }

    .footer-nav-title {
        position: relative;
        margin-bottom: 0;
        padding: 10px $liner-base 10px ($liner-base + 11px + 10px);
        cursor: pointer;
        user-select: none;
    }

    .no-touchevents .footer-nav-title:hover {
        background: darken($c-footer-background, 5%);
    }

    .footer-nav-group-active {
        background-color: $c-emphasis-background;
    }

    .footer-nav-title::after {
        @include svg (arrow-down-dark);
        content: '';
        position: absolute;
        top: 50%;
        left: $liner-base;
        width: 11px;
        height: 11px;
        margin-top: -6px;
        transform: rotate(-90deg);
        transition: transform 150ms linear;
    }

    .footer-nav-lists .footer-nav-group-active .footer-nav-title::after {
        transform: rotate(0deg);
    }

    // ----------------------------------------------
    // Footer Nav List

    .footer-nav-list {
        display: none;
    }

    .footer-nav-group-active .footer-nav-list {
        display: block;
    }

    .footer-nav-list a {
        display: block;
        padding: 10px 15px;
    }

    .footer-nav-list li:last-child a {
        border-bottom: 0;
    }

    .no-touchevents .footer-nav-list a:hover {
        background: $c-footer-border;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-s) {

    .footer-nav-lists {
        overflow: hidden;
    }

    .footer-nav-lists > li {
        float: left;
        width: percentage(1 / 3);
        padding-right: $gap;

        &:last-child {
            padding-right: 0;
        }
    }

    .footer-nav-title {
        margin-bottom: 12px;
    }

    .footer-nav-list a {
        display: inline-block;

        &:hover {
            opacity: 0.75;
        }
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .footer-nav-lists {
        float: left;
        width: calc(100% - 290px);
    }

}


// ==============================================
// Footer Support
// ==============================================

.footer-support {
    clear: left;
    padding: 30px 0 0;
    text-align: center;
    border-top: 1px solid #d0d0d0;
    margin-top: 30px;
}

.footer-support a:not(.button) {
    color: $c-text-base;

    &:hover {
        opacity: 0.75;
    }
}

.footer-support .support-message {
    margin: 10px 0 20px;
}

.footer-support .footer-nav-title {
    flex: 1 0 100%;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .footer-support {
        float: right;
        width: 290px; // Calc fixed value
        clear: none;
        padding: 0;
        text-align: center;
        border: 0;
        margin: 0;

        .footer-nav-title {
            margin-bottom: 0;
        }

        .support-phone,
        .support-email {
            a.button {
                display: none;
            }
        }

    }

}

@media (max-width: $bp-screen-m - 1px) {

    .footer-support {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        a.button {
            display: block;
        }

        .support-email,
        .support-phone {
            width: 28%;
        }

        .support-message {
            display: none;
        }
    }

}

@media (max-width: $bp-screen-s - 1px) {

    .footer-support {
        padding: 0 15px;
        border: 0;
        justify-content: space-between;

        .footer-nav-title {
            padding-right: 0;
            padding-left: 0;

            &::after {
                display: none;
            }
        }

        .support-email,
        .support-phone {
            width: 46%;
            margin-bottom: 20px;
        }
    }

}

@media (max-width: $bp-screen-xs - 1px) {

    .footer-support {
        display: block;

        .support-email,
        .support-phone {
            width: 170px;
            margin: 0 auto 20px;
        }
    }

}




// ==============================================
// Footer Secondary
// ==============================================

.footer-secondary {
    padding: 10px 0 40px;
}


// ==============================================
// Footer Subscribe
// ==============================================

.footer-subscribe {
    background: $c-white;
    border: 1px solid $c-border;
    padding: 20px;
    margin: 0 auto 25px;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .footer-subscribe {
        float: left;
        width: calc(50% - 15px);
        margin: 0;
    }

}

@media (max-width: $bp-screen-xs - 1px) {

    .footer-subscribe {
        text-align: center;

        .button {
            margin-top: 10px;
            width: 170px;
        }

        .input-text {
            text-align: center;
        }
    }

}




// ==============================================
// Footer Social
// ==============================================

/*
.footer-social h2 {
    margin-bottom: 10px;
    color: #FFF;
    font-family: $font-family-special;
    font-size: 21px;
    font-weight: 300;
    text-shadow: black(0.3) 0 1px 1px;
}

// ----------------------------------------------

@media (min-width: 768px) {

    .footer-social {
        float: right;
    }

    .footer-social h2 {
        text-align: right;
    }

}
*/


// ==============================================
// Footer Tertiary
// ==============================================

.footer-tertiary {
    overflow: hidden;
    padding: 30px 0;
    text-align: center;
}


// ==============================================
// Footer Badges
// ==============================================

// [refactor] Disabled temporarily while social icons are off
/*
.footer-badges {
    margin-bottom: 15px;
}
*/

.norton-link {
    @include image-replacement;
    @include svg (norton);
    display: block;
    width: 140px;
    height: 80px;
    border-radius: $radius-s;

    &:hover {
        opacity: 0.75;
    }
}

// ----------------------------------------------

/*
@media (min-width: 480px) {

    .footer-badges {
        float: left;
        margin-bottom: 0;
    }

}
*/

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .footer-badges {
        float: right;
    }

}

// ==============================================
// Footer Logo
// ==============================================

.footer-logo {
    text-align: center;
}

// ==============================================
// Footer Legal
// ==============================================

//.footer-legal {}

//.copyright {}

.policies a {
    display: inline-block;
    padding: 0 5px;
    color: $c-text-base;
}

.policies a:hover {
    opacity: 0.75;
}

// ----------------------------------------------

// Disabled while social icons are off.
/*
@media (min-width: 480px) {

    .footer-legal {
        float: right;
        text-align: right;
    }

    .policies a {
        padding-right: 0;
        padding-left: 10px;
    }

}
*/

// ==============================================
// Footer App links
// ==============================================

.footer-app-links {
    padding: 20px 20px 18px;
    border: 1px solid $c-border;
    margin: 0 auto;
    background: $c-white url(../images/mobile-footer-app.png) no-repeat right bottom;

    h4 {
        font-size: 28px;
        text-transform: none;
        font-weight: 300;

        @media (min-width: $bp-screen-l) {
            font-size: 27px;
        }
    }

    .app-links {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        a {
            padding-right: 10px;


            &.learn-more-app-links {
                @media (max-width: $bp-screen-l - 1px) and (min-width: $bp-screen-m) {
                    margin-right: calc(100% - 100px);
                    margin-top: 12px;
                }
            }
        }
    }
}


@media (min-width: $bp-screen-m) {
    .footer-app-links {
        float: right;
        width: calc(50% - 15px);
    }
}

@media (max-width: $bp-screen-xs - 1px) {
    .footer-app-links {
        text-align: center;
        background: $c-white;
        padding-left: 10px;
        padding-right: 10px;

        .app-links { 
            justify-content: space-around;

            a {
                padding: 0 0 5px;
            }
        }

        h4 {
            font-size: 24px;
        }
    }
}


.social-link {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    a {
        padding: 0 10px;
    }
}

@media (max-width: $bp-screen-m - 1px) and (min-width: $bp-screen-s) {
    .social-link {
        justify-content: space-between;
    
        a {
            padding: 0;
        }
    }
}

.social-link_item .social-link_background {
    fill-rule: evenodd;
    fill: $c-input-border-contrast;
    transition: $transition-all;
}

.social-link_item:hover .social-link_background {
    fill: $c-black;
    transition: $transition-all;
}

.social-link_icon {
    fill: $c-white;
    fill-rule: evenodd;
}

.app-link {
    width: 120px;
    margin-left: 20px;
    margin-top: $gap;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.app-link_item {
    width: 120px;
    height: 40px;
    margin-bottom: $space-l;
}

.app-link_item .app-link_color {
    fill: $c-gray-slate;
    fill-rule: evenodd;
    transition: $transition-all;
}

.app-link_item:hover .app-link_color {
    fill: $c-library-gray-9;
    transition: $transition-all;
}


// ----------------------------------------------

.footer-nav_item--social p {
    margin-bottom: 5px;
}

@media (max-width: $bp-screen-m - 1px) {
    .footer-nav_item--social {
        width: 28%;
    }
}

@media (max-width: $bp-screen-s - 1px) {

    .footer-nav_item--social {
        flex: 1 0 100%;
        width: 100%;
    }
}

// ==============================================
// Back Top
// ==============================================

.back-top {
    display: inline-block;
    position: absolute;
    right: $space-l;
    top: -25px;
    width: 48px;
    height: 48px;
    margin-bottom: (50px / -2);
    background: $c-white;
    border-radius: 50px;
    box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
    text-decoration: none;
    cursor: pointer;
    transition: $transition-all;
    z-index: 1;

    &:hover {
        transform: scale(1.1);
    }

    @include lib-icon-font(
        $_icon-font-content: $icon-up,
        $_icon-font-color:   $c-text-base
    );

    &::before {
        padding: 15px 13px;
    }
}



