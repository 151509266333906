.small-wrapper {
    max-width: $bp-screen-xs;
}

.medium-wrapper {
    max-width: $bp-screen-s;
}

.large-wrapper {
    max-width: $bp-screen-m;
}

.extralarge-wrapper {
    max-width: $bp-screen-xl;
}


// -----------------------------------
// App wrapper - div for react apps

.app-wrapper,
.app-wrapper > div,
.app-wrapper > div > div {
    height: 100%;
}

#app-wrapper,
.app-wrapper {
    position: relative;
    flex: 1;
    min-height: 200px;
}
