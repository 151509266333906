/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Category Grid
// ==============================================

.category-grid {
    overflow: hidden;
}

.category-grid .item {
    position: relative;
    float: left;
}

.category-grid .product-image {
    margin-bottom: 12px;
}

.category-grid .category-name {
    display: block;
    font-size: $font-size-s;
    line-height: $line-height-xs;
}

// ----------------------------------------------

@media (max-width: 359px) {

    .category-grid .item {
        clear: left;
        min-height: 80px;
        margin-bottom: 15px;
        padding-left: (80px + 10px);
    }

    // For non-square images (3: 2 ratio)
    .category-grid--landscape .item {
        min-height: (80px * 0.666);
    }

    .category-grid .product-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 80px;
    }

}

// ----------------------------------------------

@media (min-width: 360px) {

    .category-grid .item {
        width: 50%;
        margin-bottom: $gap;
    }

    .category-grid .item:nth-child(odd) {
        padding-right: 10px;
        clear: left;
    }

    .category-grid .item:nth-child(even) {
        padding-left: 10px;
    }

    .category-grid .category-name {
        text-align: center;
    }

}

// ----------------------------------------------

@media (min-width: 600px) {

    // Undo two-column config

    .category-grid .item:nth-child(odd) {
        clear: none;
        padding-right: 0;
    }

    .category-grid .item:nth-child(even) {
        padding-left: 0;
    }


    // Set three-column config

    .category-grid .item {
        width: 31.333%;
        margin-left: 3%;
    }

    .category-grid .item:nth-child(3n+1) {
        clear: left;
        margin-left: 0;
    }

}

// ----------------------------------------------

@media (min-width: 900px) {

    // Undo three-column config

    .category-grid .item:nth-child(3n+1) {
        clear: none;
        margin-left: 2.5%;
    }

    // Set four-column config

    .category-grid .item {
        width: 23.125%;
        margin-left: 2.5%;
    }

    .category-grid .item:nth-child(4n+1) {
        clear: left;
        margin-left: 0;
    }

}

// ----------------------------------------------

@media (min-width: 1170px) {

    // Undo four-column config

    .category-grid .item:nth-child(4n+1) {
        clear: none;
        margin-left: 2.5%;
    }

    // Set five-column config

    .category-grid .item {
        width: 18%;
        margin-left: 2.5%;
    }

    .category-grid .item:nth-child(5n+1) {
        clear: left;
        margin-left: 0;
    }

}
