/**
* Gemaire Distributing
*/


// ==============================================
// Login page
// ==============================================

@media (min-width: $bp-screen-m) {

    .login-grid {
        display: flex;
    }

    .login-grid > .col-1 {
        padding-right: $space-base;
        flex-basis: 25rem;
    }

    .login-grid > .col-2 {
        padding-left: $space-base;
        flex: 1;
    }

}