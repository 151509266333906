/**
* Gemaire Distributing
*/


// ==============================================
// Actions Toolbar
// ==============================================

.actions-toolbar > .primary,
.actions-toolbar > .secondary {
    text-align: center;
    margin-bottom: $space-base;

    &:last-child {
        margin-bottom: 0;
    }
}

.actions-toolbar > .primary .action,
.actions-toolbar > .secondary .action {
    margin-bottom: $space-base;

    &:last-child {
        margin-bottom: 0;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .actions-toolbar {
        @include lib-actions-toolbar();
    }

    .actions-toolbar > .secondary,
    .actions-toolbar > .primary {
        margin-bottom: 0;
    }

    .actions-toolbar > .secondary .action,
    .actions-toolbar > .primary .action {
        margin-bottom: 0;
        width: auto;
    }

    .column:not(.sidebar-main) form .actions-toolbar {
        margin-left: $form-field-type-label-inline__width;
    }

}
