/**
* Gemaire Distributing
*/

.order-view {
    background: $c-white;

    .quote-item {
        &.component {
            td {
                border-top: none;
                border-bottom: none;
                padding: 4px 10px;

                &.quote-item-qty, &.quote-item-price {
                    font-size: 14px;
                }
            }
            td.quote-item-name, td.quote-item-sku {
                > * {
                    margin-left: 20px;
                    font-size: 14px;
                }
            }
        }

        &.combination {
            td {
                border-bottom: none;
            }
        }
    }

    .gempay-dockside-wrap {
        max-width: 700px;
        flex-basis: 600px;

        .gempay-container {
            box-shadow: $box-shadow-base;
            flex: 1 1 460px;
            margin-bottom: 30px;
            max-width: 700px;
            background: url('https://s7d2.scene7.com/is/image/Watscocom/gemaire_content_20191107_gempay-banner-lg') no-repeat right;
            background-size: contain;

            .gempay-header {
                flex-direction: row;
                align-items: center;
                padding: 20px 20px 10px 20px;

                .gempay-logo {
                    max-width: 160px;
                }

                .gempay-btn {
                    background: $c-white;
                    color: $c-blue-bright;
                    border: 1px solid $c-border;
                    padding: 0 15px;

                    span {
                        font-size: 15px;

                        @include lib-icon-font(
                            $_icon-font-content:      $icon-open-new,
                            $_icon-font-size:         $account-nav-icon-size - 8px,
                            $_icon-font-line-height:  $account-nav-icon-size - 8px,
                            $_icon-font-color:        $c-blue-bright,
                            $_icon-font-color-hover:  $c-blue-bright,
                            $_icon-font-color-active: $c-blue-bright,
                            $_icon-font-margin:       -3px 5px 0,
                            $_icon-font-position:     'after'
                        )
                    }
                }
            }

            .gempay-footer {
                padding: 0 20px 20px 20px;

                .action.more {
                    display: inline-block;
                    margin-left: 10px;
                    font-size: 14px;
                }
            }
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .order-view {
        .gempay-dockside-wrap {
            .gempay-container {
                .gempay-footer {
                    .action.more {
                        display: block;
                        margin: 0;
                    }
                }
            }
        }
    }

    #order-view-popup {
        .action-group {
            flex-direction: column;
            align-items: start;
        }

        .order-cancel-link {
            margin-top: 1.8rem;
            margin-left: 0.8rem;
            font-size: 14px;
        }
    }
}

.receipt-info-section {
    font-size: 15px;

    p {
        margin-bottom: 15px;
    }
}

@media (min-width: $bp-screen-s) {

    .receipt-info-section {
        align-items: center;
        display: flex;

        > ul {
            margin-right: 30px;
        }
    }
}

.order-signature {
    border: 1px solid $c-border;
    position: relative;
    padding-top: 25px;

    strong {
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 12px;
    }

    img {
        max-height: 120px;
    }
}

.order-owner-filter {
    position: absolute;
    margin-top: -60px;
}

@media (max-width: $bp-table-linearize) {
    .order-item {

        td {
            padding-bottom: 5px !important;
        }

        .button {
            margin-top: 15px;
        }

    }
}

@media (min-width: $bp-table-linearize) {
    .order-info {
        width: 50%;
        max-width: 340px;
        padding-right: 20px;
        flex-shrink: 0;
    }
}


@media (min-width: $bp-table-linearize) {
    .subtotal_summary_wrapper {
        border-bottom: 1px solid $c-border;
        display: flex;
    }
}

.subtotal_summary {
    background: $c-table-background;
    padding: $space-base;
    flex-basis: 285px;

    > * {
        margin-bottom: $space-xxs;
        display: flex;
        justify-content: space-between;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: $bp-table-linearize - 1px) {
    .subtotal_summary {
        border-top: 1px solid $c-border;
        border-bottom: 1px solid $c-border;
    }
}

.active-orders-wrapper {
    max-height: 242px;
    overflow: hidden;
    transition: $transition-base;

    &.opened,
    &.opened .order-active-cards  {
        max-height: 4000px;
        overflow: visible;
    }
}

.order-active-cards {
    margin: 0 -8px;
    display: flex;
    flex-wrap: wrap;
    max-height: 220px;
    padding: 10px 0;
    overflow: hidden;
}

.order-active-card {
    box-shadow: $box-shadow-base;
    background: $c-white;
    padding: 15px;
    margin: 0 10px 20px;
    width: 100%;

    &-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .text-link {
            cursor: pointer;
        }
    }
}

@media (min-width: $bp-screen-s) {
    .order-active-card {
        width: calc(50% - 20px);
    }
}

@media (min-width: $bp-screen-l) {
    .order-active-card {
        width: 350px;
    }
}

.orders-history {
    .col.action {
        .actions-dropdown {
            > .button {
                &:first-child {
                    width: 100%;
                    text-align: left;
                }
            }

            .actions-dropdown-toggle {
                min-width: 36px;
            }

            .actions-dropdown-menu {
                width: 100%;
            }
        }
    }
}
