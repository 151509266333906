//
// Avoid using the extends from this file
//  _____________________________________________

//
//  Missing variables for extends
//  ---------------------------------------------

$checkout-tooltip-icon__font-size               : 24px;
$checkout-tooltip-icon-arrow__font-size         : 10px;
$checkout-tooltip-content-mobile__top           : 30px + $checkout-tooltip-icon-arrow__font-size;
$checkout-tooltip-content-mobile__right         : -($space-base);
$checkout-tooltip-content__background-color     : $c-library-gray-0;
$checkout-tooltip-content__padding              : $space-s;
$checkout-tooltip-content__border-width         : 1px;
$checkout-tooltip-content__border-color         : $c-border;
$checkout-tooltip-icon-arrow__left              : -( $checkout-tooltip-content__padding + $checkout-tooltip-icon-arrow__font-size - $checkout-tooltip-content__border-width);


//
//  Blocks in 2 columns
//  ---------------------------------------------

@mixin abs-blocks-2columns() {
    width: 48.8%;

    &:nth-child(odd) {
        clear: left;
        float: left;
    }

    &:nth-child(even) {
        float: right;
    }
}

@media (min-width: $bp-screen-m) {
    .abs-blocks-2columns {
        @include abs-blocks-2columns();
    }
}

@media (min-width: $bp-screen-s) {
    .abs-blocks-2columns-s {
        @include abs-blocks-2columns();
    }
}

//
//  Reset image alignment in container
//  ---------------------------------------------

// [todo] Need to change HTML to remove this. The "product-image-wrapper" has bad base styles.
.abs-reset-image-wrapper {
    height: auto;
    padding: 0 !important;

    .product-image-photo {
        position: static;
    }
}

//
//  Account blocks
//  ---------------------------------------------

.abs-account-blocks {
    .block-title {
        > .action {
            margin-left: $space-base;
        }
    }

    .box-title {
        display: inline-block;
        margin: 0 0 $space-xs;

        > span {
            @include h4;
        }

        > .action {
            font-weight: 400;
            margin-left: $space-base;
        }
    }

    .block-content {
        p:last-child {
            margin-bottom: 0;
        }

        .box {
            margin-bottom: $space-l;
        }
    }
}

//
//  Split button
//  ---------------------------------------------

.abs-split-button {
    @include lib-dropdown-split(
        $_options-selector                        : '.items',
        $_dropdown-split-button-border-radius-fix : true
    );
    vertical-align: middle;
}

//
//  Excl/Incl tax
//  ---------------------------------------------

.abs-adjustment-incl-excl-tax {
    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;
        white-space: nowrap;
    }

    .price-including-tax + .price-excluding-tax {
        display: inline-block;
        font-size: $font-size-xxs;

        &::before {
            content: '('attr(data-label)': ';
        }

        &::after {
            content: ')';
        }
    }
}

//
//  Checkout order review
//  ---------------------------------------------

@media (max-width: $bp-screen-s - 1px) {
    .abs-checkout-order-review {
        tbody tr {
            &:not(:last-child) {
                border-bottom: 1px solid $c-border;
            }

            @include lib-clearfix;

            .col {
                &.item {
                    &::before {
                        display: none;
                    }
                }

                &.qty,
                &.price,
                &.subtotal {
                    float: left;
                    text-align: center;
                    white-space: nowrap;
                    width: 33%;

                    &::before {
                        content: attr(data-th) ':';
                        display: block;
                        font-weight: $font-weight-bold;
                        padding-bottom: $space-base;
                    }
                }
            }

            .product-item-name {
                margin: 0;
            }
        }
    }
}

//
//  Add colon
//  ---------------------------------------------

.abs-colon {
    &::after {
        content: ': ';
    }
}

//
//  Icon - create add
//  ---------------------------------------------

.abs-icon-add {
    @include lib-icon-font(
        $_icon-font-content        : $icon-expand,
        $_icon-font-size           : 10px,
        $_icon-font-line-height    : 10px,
        $_icon-font-vertical-align : middle
    );
}

@media (max-width: $bp-screen-m - 1px) {
    .abs-icon-add-mobile {
        @include lib-icon-font(
            $_icon-font-content        : $icon-expand,
            $_icon-font-size           : 10px,
            $_icon-font-line-height    : 10px,
            $_icon-font-vertical-align : middle,
            $_icon-font-margin         : 0 5px 0 0,
            $_icon-font-display        : block
        );
    }
}

//
//  Dropdown items - create new
//  ---------------------------------------------

.abs-dropdown-items-new {
    .items .item:last-child {
        &:hover {
            background: $dropdown-list-item__hover;
        }
    }

    .action.new {
        @extend .abs-icon-add;
        &::before {
            margin-left: -17px;
            margin-right: $space-xs;
        }
    }
}

//
//  Abstract toggle title block
//  ---------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .abs-toggling-title-mobile {
        border-bottom: 1px solid $c-border;
        border-top: 1px solid $c-border;
        cursor: pointer;
        margin-bottom: 0;
        padding: $space-base $space-xxxl $space-base $layout__width-xs-indent;
        position: relative;

        @include lib-icon-font(
            $_icon-font-content   : $icon-down,
            $_icon-font-size      : 28px,
            $_icon-font-text-hide : false,
            $_icon-font-position  : after,
            $_icon-font-display   : block
        );

        &::after {
            position: absolute;
            right: $space-base;
            top: 0;
        }

        &.active {
            @include lib-icon-font-symbol(
                $_icon-font-content  : $icon-up,
                $_icon-font-position : after
            );
        }
    }
}


//
//  Pager toolbar for non-catalog pages mobile
//  ---------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .abs-pager-toolbar-mobile {
        .toolbar-amount,
        .toolbar-limiter,
        .pages {
            float: none;
            margin-bottom: $space-l;
        }
    }
}

//
//  Pager toolbar for non-catalog pages mobile
//  ---------------------------------------------

@media (max-width: $bp-screen-s - 1px) {
    .abs-pager-toolbar-mobile-s {
        .toolbar-amount,
        .toolbar-limiter,
        .pages {
            margin-bottom: $space-l;
        }
    }
}

//
//  Pager toolbar for non-catalog pages desktop
//  ---------------------------------------------

@media (min-width: $bp-screen-m) {
    .abs-pager-toolbar {
        @include lib-clearfix;
        margin-bottom: $space-l;
        position: relative;

        .toolbar-limiter {
            float: right;
            position: relative;
            z-index: 1;
        }

        .toolbar-amount {
            float: left;
            line-height: normal;
            padding: 7px 0 0;
            position: relative;
            z-index: 1;
        }

        .pages {
            position: absolute;
            width: 100%;
            z-index: 0;
        }
    }
}

//
//  Shopping cart items
//  ---------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .abs-shopping-cart-items-mobile {
        .actions {
            text-align: center;
        }

        .action {
            &.update,
            &.continue,
            &.clear {
                margin: 0 auto $space-base;
            }

            &.update,
            &.clear {
                display: block;
            }
        }
    }
}

@media (min-width: $bp-screen-m) {
    .abs-shopping-cart-items-desktop {
        float: left;
        position: relative;
        width: 72%;

        .actions {
            text-align: right;
        }

        .action {
            &.clear,
            &.update {
                margin-left: $space-base;
            }

            &.continue {
                float: left;
            }
        }
    }
}

//
//  Checkout Tooltip Content (position: top)
//  ---------------------------------------------

@mixin abs-checkout-tooltip-content-position-top {
    right: $checkout-tooltip-content-mobile__right;
    top: $checkout-tooltip-content-mobile__top;
    left: auto;

    &::before,
    &::after {
        @include lib-arrow(
            $_position : top,
            $_size     : $checkout-tooltip-icon-arrow__font-size,
            $_color    : $checkout-tooltip-content__background-color
        );
        margin-top: $checkout-tooltip-icon-arrow__left;
        right: $space-base;
        left: auto;
        top: 0;
    }

    &::before {
        border-bottom-color: $checkout-tooltip-content__border-color;
    }

    &::after {
        border-bottom-color: $checkout-tooltip-content__background-color;
        top: 1px;
    }
}

.abs-checkout-tooltip-content-position-top {
    @include abs-checkout-tooltip-content-position-top();
}

@media (max-width: $bp-screen-m - 1px) {
    .abs-checkout-tooltip-content-position-top-mobile {
        @include abs-checkout-tooltip-content-position-top();
    }
}
