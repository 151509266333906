/**
* Gemaire Frontend
*
* @author    Brendan Falkowski (http://gravitydept.com)
* @package   enterprise_gemaire
* @copyright Copyright 2019 Gravity Department
* @license   All rights reserved.
*/


// **********************************************
// Override:
// Use this partial to override the variables defined in /core/var/*.scss.
// This partial is imported last, so all changes here override the default values.
// **********************************************


/* stylelint-disable scss/dollar-variable-default */


// ==============================================
// Modal
// See: /vendor/gravdept/_modal.scss
// ==============================================

$modal-close-icon-size: 12px;
$modal-close-icon-svg:  'cross-12-white';
