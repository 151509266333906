/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$loading-size-base: 24px !default;
$loading-size-s:    16px !default;


// ==============================================
// Loading Wrapper
// ==============================================

.loading-wrapper,
.please-wait-wrapper {
    padding: 20px;
    border: 1px solid $c-border;
}


// ==============================================
// Loading
// ==============================================

.loading,
.please-wait {
    position: relative;
    display: inline-block;
    padding-left: ($loading-size-base + 8px);
    font-size: $font-size-s;
    line-height: $loading-size-base;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: $loading-size-base;
        height: $loading-size-base;
        margin-top: ($loading-size-base / -2);
        background-image: $icon-loading--blue;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-name: rotate-clockwise;
        animation-timing-function: linear;
    }
}

// ----------------------------------------------
// Modifier: Small

.loading--s {
    padding-left: ($loading-size-s + 8px);
    font-size: $font-size-xs;
    line-height: $loading-size-s;

    &::before {
        width: $loading-size-s;
        height: $loading-size-s;
        margin-top: ($loading-size-s / -2);
        background-size: cover;
    }
}


/**
* Gemaire Distributing
*/


// ==============================================
// Loading
// ==============================================

.load.indicator,
.loading-mask {
    z-index: 9999;
    background: white(0.6);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;

    &::before {
        background-image: $icon-loading--blue;
        content: '';
        display: inline-block;
        height: 40px;
        width: 40px;
        left: calc(50% - 22px);
        position: absolute;
        top: 50%;
        animation: rotate-clockwise 4s linear infinite;
        top: calc(50% - 50px);
    }

    &::after {
        content: attr(data-text);
        text-align: center;
        vertical-align: baseline;
        position: absolute;
        width: 100%;
        top: calc(50% + 15px);
        font-size: 3.4rem;
        font-weight: 300;
        line-height: 4rem;
        letter-spacing: normal;
        text-decoration: inherit;
        text-transform: inherit;
    }

    &.section-mask {
        position: absolute;
        z-index: 11;

        &::before {
            left: calc(50% - 20px);
            top: calc(50% - 20px);
        }
    }
}

.load.indicator > span,
.loading-mask > span {
    display: none;
}

.loading-mask-content {
    z-index: 9999;
    background: white(0.6);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;

    &::before {
        background-image: $icon-loading--blue;
        content: '';
        display: inline-block;
        height: 40px;
        width: 40px;
        left: calc(50% - 22px);
        position: absolute;
        top: 50%;
        animation: rotate-clockwise 4s linear infinite;
        top: calc(50% - 50px);
    }

    p,
    &::after {
        content: attr(data-text);
        text-align: center;
        vertical-align: baseline;
        position: absolute;
        width: 100%;
        top: calc(50% + 15px);
        font-size: 3.4rem;
        font-weight: 300;
        line-height: 4rem;
        letter-spacing: normal;
        text-decoration: inherit;
        text-transform: inherit;
    }
}

.loading-mask p,
.loading-mask-content p {
    display: none;
}

.loading-mask .loader > img,
.loading-mask-content .loader > img {
    display: none;
}

._block-content-loading {
    position: relative;
}

.minicart-wrapper .action.showcart .counter.qty._block-content-loading {
    background: $c-red-base;
}

.minicart-wrapper .action.showcart .counter.qty._block-content-loading .loading-mask {
    background: $c-red-base;
}

.minicart-wrapper .action.showcart .counter.qty._block-content-loading .loader {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTIgNTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUyIDUyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6I0ZGRkZGRjt9PC9zdHlsZT48Zz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzQwLjAwMDAwMCwgLTYyOS4wMDAwMDApIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzYuMDAwMDAwLCA2MjkuMDAwMDAwKSI+PGc+PHBhdGggY2xhc3M9InN0MCIgZD0iTTQsMjZoNmMwLTExLDktMjAsMjAtMjBjNi4xLDAsMTEuOCwyLjgsMTUuNiw3LjVsNC43LTMuOEM0NS40LDMuNiwzOCwwLDMwLDBDMTUuNiwwLDQsMTEuNiw0LDI2eiIvPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik01NCwxNy44YzAuMSwwLjUtMC40LDAuOS0wLjksMC44bC0xMS45LTIuNWMtMC41LTAuMS0wLjYtMC41LTAuMi0wLjhsMTEuOS05LjZjMC40LTAuMywwLjgtMC4yLDAuOCwwLjRMNTQsMTcuOHoiLz48L2c+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIuMDAwMDAwLCAzOS4wMDAwMDApIHJvdGF0ZSgtMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC0zMi4wMDAwMDAsIC0zOS4wMDAwMDApIHRyYW5zbGF0ZSg0LjAwMDAwMCwgMjYuMDAwMDAwKSI+PHBhdGggY2xhc3M9InN0MCIgZD0iTTQsMjZoNmMwLTExLDktMjAsMjAtMjBjNi4xLDAsMTEuOCwyLjgsMTUuNiw3LjVsNC43LTMuOEM0NS40LDMuNiwzOCwwLDMwLDBDMTUuNiwwLDQsMTEuNiw0LDI2eiIvPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik01NCwxNy44YzAuMSwwLjUtMC40LDAuOS0wLjksMC44bC0xMS45LTIuNWMtMC41LTAuMS0wLjYtMC41LTAuMi0wLjhsMTEuOS05LjZjMC40LTAuMywwLjgtMC4yLDAuOCwwLjRMNTQsMTcuOHoiLz48L2c+PC9nPjwvZz48L2c+PC9zdmc+);
    margin: $space-xxxs;
    width: 18px;
    height: 18px;
    position: static;
    animation: rotate-clockwise 4s linear infinite;
}

.minicart-wrapper .action.showcart .counter.qty._block-content-loading img {
    display: none;
}


// ==============================================
// Used for magento lists
// ==============================================

.admin__data-grid-outer-wrap {
    min-height: 100px;
}
.admin__data-grid-loading-mask {
    height: 100%;
    width: 100%;
    position: absolute;
    background: $c-white;
    top: 0;
    left: 0;

    .spinner {
        background-image: $icon-loading--blue;
        content: '';
        display: inline-block;
        height: 40px;
        width: 40px;
        left: calc(50% - 22px);
        position: absolute;
        top: 50%;
        animation: rotate-clockwise 4s linear infinite;
        top: calc(50% - 22px);
    }
}
