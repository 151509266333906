/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Tier Prices
// ==============================================

.tier-prices {
    display: inline-block;
    padding: 4px 8px;
    border-radius: $radius-s;
    background: #FF9;
    border: 1px solid #E9EB77;
}

.tier-prices li {
    font-size: $font-size-xxs;
}

.tier-prices .price {
    font-weight: bold;
}
