/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2013 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Availability
// ==============================================

.availability {
    font-size: $font-size-xxs;
    text-transform: uppercase;
}

.availability.in-stock,
.availability.available {
    color: $c-success;
}

.availability.available-soon,
.availability.out-of-stock,
.c-danger {
    color: $c-danger;
}
