/**
* Gemaire Frontend
*
* @author     Brendan Falkowski (http://gravitydept.com)
* @package    enterprise_gemaire
* @copyright  Copyright 2015 Gravity Department
* @license    All rights reserved.
*/


// ==============================================
// Product Discontinued
// ==============================================

.product-discontinued {
    display: inline-block;
    padding: 5px 7px;
    border: 1px solid $c-danger;
    border-radius: $radius-s;
    color: $c-danger;
    font-size: $font-size-xs;
    line-height: $line-height-xs;
}
