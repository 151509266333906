/**
* Gemaire Distributing
*/


// ==============================================
// Vars
// ==============================================

$account-nav-background: $c-library-gray-9;
$account-nav-width:      276px;

$account-nav-icon-size: 24px;

$account-nav-item-background-hover: $c-action-toolbar-base;
$account-nav-item-background-focus: $c-action-toolbar-base;
$account-nav-item-border:           $c-divider-border;
$account-nav-item-color:            $c-text-base;
$account-nav-item-color-focus:      $c-text-base;
$account-nav-item-color-hover:      $c-text-base;

$account-nav-item-current-background: $c-action-toolbar-base;

$account-nav-sub-item-color:       $c-library-gray-2;
$account-nav-sub-item-color-focus: $c-library-gray-2;
$account-nav-sub-item-color-hover: $c-text-base;

$account-nav-sub-item-current-color: $c-text-base;

$account-nav-item-current-background: $c-action-toolbar-base;


// ==============================================
// Account Nav
// ==============================================

.account-nav {
    margin-bottom: 0;
}

.account-nav .title {
    display: none;
}

@media (max-width: $bp-screen-l - 1px) {
    .account-nav {
        height: 100vh;
        overflow: scroll;
    }
}


// ==============================================
// Account Nav - Item
// ==============================================

.account-nav_item {
    border-bottom: 1px solid $account-nav-item-border;
    transition: background-color 0.1s linear;

    &.dashboard span.account-nav_label {
            font-weight: normal;
            padding: 24px 20px 15px;
            display: block;
            color: $c-text-base;
            font-size: 20px;
        }

    &:last-child {
        border-bottom: 0;
    }
}

.account-nav_item .flag {
    margin-left: $space-xs;
    position: absolute;
}

// ----------------------------------------------
// Modifier: Current

//.account-nav_item--current:not(.dashboard) {}


// ==============================================
// Account Nav - Link
// ==============================================

.account-nav_item {
.account-nav_link {
    display: block;
    color: $account-nav-item-color;
    text-decoration: none;
}
}

// ----------------------------------------------
// Parent Modifier: Current

.account-nav_item--current .account-nav_link {
    color: $account-nav-item-color-hover;

    &:hover {
        background-color: $account-nav-item-background-hover;
        transition: background-color 0.1s linear;
    }
}


// ==============================================
// Account Nav - Label
// ==============================================

.account-nav_label {
    padding: 10px 0;
    font-weight: bold;

    &::before {
        padding-right: 5px;
        margin-top: -2px;
    }
}


// ==============================================
// Account Nav - Dropdown
// ==============================================

.account-nav_dropdown {
    margin-bottom: 15px;
}


// ==============================================
// Account Nav - Dropdown Item
// ==============================================

.account-nav_dropdown a,
.account-nav_dropdown strong {
    display: block;
    padding: 6px 0 6px 33px;
    text-decoration: none;
    color: $c-text-base;
}

.account-nav_dropdown a {

    &.new-tab-icon {
        @include lib-icon-font(
            $_icon-font-content:      $icon-open-new,
            $_icon-font-size:         $account-nav-icon-size - 8px,
            $_icon-font-line-height:  $account-nav-icon-size - 8px,
            $_icon-font-color:        $account-nav-item-color,
            $_icon-font-color-hover:  $c-blue-bright,
            $_icon-font-color-active: $account-nav-item-color,
            $_icon-font-margin:       -3px 5px 0,
            $_icon-font-position:     'after'
        )
    }

    &:hover {
        color: $c-blue-bright;
    }
}

.account-nav_dropdown strong,
.account-nav_dropdown li.current a {
    color: $c-white;
    font-weight: normal;
    background: $c-blue-bright;
}

// ==============================================
// Close Dashboard icon
// ==============================================

.close_dashboard {
    float: right;
    line-height: 42px;
    cursor: pointer;
    display: none;
}

@media (max-width: $bp-screen-m - 1px) {
    .close_dashboard {
        display: inline-block;
    }
}


// ==============================================
// Icons
// ==============================================

// Quotes
.account-nav_item.quotes span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-quotes,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

// Orders
.account-nav_item.orders span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-history,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

// Requisition
.account-nav_item.requisition span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-store,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

// Stocking Lists
.account-nav_item.stocking-list span {
    display:  $icon-font__display;
    text-decoration: none;

    &:before {
        margin-left: 3px;
        margin-right: 4px;
        padding-right: 0;
        display: $icon-font__display;
        content: "";
        background-repeat: no-repeat;
        background-image: $icon-qr-code;
        background-size: $account-nav-icon-size - 4px;
        width: $account-nav-icon-size - 4px;
        height: $account-nav-icon-size - 4px;
        line-height: $account-nav-icon-size;
        font-size: $account-nav-icon-size;
        vertical-align: $icon-font__vertical-align;
        overflow: hidden;
    }
}

// Invoices
.account-nav_item.invoices span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-invoices,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

// Company
.account-nav_item.company span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-settings,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

// My Account
.account-nav_item.my-account span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-users,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

// Resources
.account-nav_item.resources span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-resources,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

// Warranty
.account-nav_item.warranty span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-warranty,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

